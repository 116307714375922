import { Conversation, Message } from 'src/chatbot/types/chat';
import { CogniAIModel, CogniAIModelID, CogniAITitle } from 'src/chatbot/types/cogniai';
import { ErrorMessage } from 'src/chatbot/types/error';
import { FolderInterface } from 'src/chatbot/types/folder';
import { PluginKey } from 'src/chatbot/types/plugin';
import { Prompt } from 'src/chatbot/types/prompt';
import { IProfessorTitlesR } from 'src/models/dto/ProfessorRequest';
import { ActionResult } from 'src/models/Result';

import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';

export interface ProfessorInitialState {
  apiKey: string;
  pluginKeys: PluginKey[];
  loading: boolean;
  lightMode: 'light' | 'dark';
  messageIsStreaming: boolean;
  modelError: ErrorMessage | null;
  models: CogniAIModel[];
  titles: CogniAITitle[];
  titleError: ErrorMessage | null;
  titleRefetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<ActionResult<IProfessorTitlesR> | null, unknown>>,
  folders: FolderInterface[];
  conversations: Conversation[];
  selectedConversation: Conversation | undefined;
  currentMessage: Message | undefined;
  prompts: Prompt[];
  temperature: number;
  showChatbar: boolean;
  showPromptbar: boolean;
  currentFolder: FolderInterface | undefined;
  messageError: boolean;
  searchTerm: string;
  defaultModelId: CogniAIModelID | undefined;
  defaultTitle: CogniAITitle | undefined;
  serverSideApiKeyIsSet: boolean;
  serverSidePluginKeysSet: boolean;
}

export const initialState: ProfessorInitialState = {
  apiKey: '{CF317DD5-793B-4611-AFD1-1E18A85F5DDC}', // TODO: this is for development and testing only
  loading: false,
  pluginKeys: [],
  lightMode: 'light',
  messageIsStreaming: false,
  modelError: null,
  models: [],
  folders: [],
  conversations: [],
  selectedConversation: undefined,
  currentMessage: undefined,
  prompts: [],
  temperature: 0.0,
  showPromptbar: true,
  showChatbar: true,
  currentFolder: undefined,
  messageError: false,
  searchTerm: '',
  defaultModelId: undefined,
  defaultTitle: undefined,
  serverSideApiKeyIsSet: false,
  serverSidePluginKeysSet: false,
  titles: [],
  titleError: null,
  titleRefetch: () => new Promise(() => null),
};
