import { saveAs } from 'file-saver';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Locale } from 'src/localization/Locale';
import { Book } from 'src/models/Book';
import {
    FormType, UserFormSubmissionAttachment, UserFormSubmissionViewModel
} from 'src/models/UserContent';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';

import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';

import { Image } from '../foundation/Assets';
import { Loading } from '../foundation/Controls';
import iframeControlTester from '../foundation/Controls/iframeControlTester';
import RichContentControl from '../foundation/Controls/RichContentControl';
import richContentControlTester from '../foundation/Controls/richContentControlTester';
import TableIdWithOffset from '../foundation/Controls/TableIdWithOffset';
import tableIdWithOffsetTester from '../foundation/Controls/tableIdWithOffsetTester';
import { BookContext } from '../state/Contextes';

const mime = require("mime/Mime");
export interface IModalState {}

export interface IModalProps {
  show: boolean;
  handleClose: (userFormSubmissionRef: string) => void;
  currentSubmission: UserFormSubmissionViewModel;
  attachments: UserFormSubmissionAttachment[];
  localization: Locale;
  loading: boolean;
  children?: React.ReactNode;
}
export class CriticalSubmissionModal extends React.Component<IModalProps, IModalState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IModalProps) {
    super(props);
    this.state = {
      loading: false,
      attachments: [],
    };
  }
  private saveAttachment = async (attachment: UserFormSubmissionAttachment) => {
    if (ResourcePlatform.CanDownloadAttachmentsNatively()) {
      await this.context.downloadAttachmentNative({
        UserFormAttachmentRef: attachment.TableGuid,
      });
    } else {
      let binaryString = window.atob(attachment.AttachmentData.toString());
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      let mimeType = mime.getType(attachment.AttachmentExtension);
      let mimeTypeString = mimeType as string;

      let blob = new Blob([bytes], { type: mimeTypeString });
      saveAs(blob, attachment.Name + attachment.AttachmentExtension);
    }
  };
  render(): any {
    const schema = JSON.parse(this.props.currentSubmission?.Definition.DataSchema ?? "");
    const uischema = JSON.parse(this.props.currentSubmission?.Definition.ReaderUiSchema ?? "");
    const renderers = [
      ...materialRenderers,
      // register custom renderers
      { tester: iframeControlTester, renderer: RichContentControl },
      { tester: tableIdWithOffsetTester, renderer: TableIdWithOffset },
      { tester: richContentControlTester, renderer: RichContentControl },
    ];

    let style = {};
    if (this.props.loading) {
      style = { minHeight: "100pt" };
    }

    return (
      <div className="innerCommunity flex-fill criticalContainer">
        <div className="unbound-form-modal criticalModal" />
        <div className="unbound-form-bulletins criticalContent">
          <div className="full-width full-height">
            <div className="communityFormContainer" style={{ display: "flex" }}>
              <Loading className="full-width full-height" isLoading={this.props.show} theme="opaque" status="Loading...">
                <div className="form-container">
                  <div className="submissionInfo">
                    <h1><span className="criticalSubHeader">Critical {FormType[this.props.currentSubmission.Definition.FormType]}</span></h1>
                    <div>
                      {this.props.localization.currentLocale.CommunityView.LABEL_CREATED_ON}{" "}
                      {Convert.dateToFormattedString(this.props.currentSubmission.Submission.CreationDate!, this.props.localization.currentCulture)}
                    </div>
                    <div>
                      {this.props.localization.currentLocale.CommunityView.LABEL_MODIFIED_ON}{" "}
                      {Convert.dateToFormattedString(this.props.currentSubmission.Submission.LastModificationDate!, this.props.localization.currentCulture)}
                    </div>
                    <div>
                      {this.props.localization.currentLocale.CommunityView.LABEL_SUBMISSION_REF} {this.props.currentSubmission.Submission.TableGuid}
                    </div>
                  </div>
                  <div>
                    <Loading style={style} isLoading={this.props.loading} status={this.props.localization.currentLocale.CommunityView.LABEL_LOADING_ATTACHMENTS}>
                      {this.props.attachments.length > 0 && <h6>{this.props.localization.currentLocale.CommunityView.LABEL_ATTACHMENTS}</h6>}
                      <div className="attachmentContainer">
                        {this.props.attachments.length > 0 &&
                          this.props.attachments.map((x, i) => (
                            <div key={i} className="attachment">
                              <div className="attachmentIcon">
                                <Image.attachment />
                              </div>
                              <a href="#" onClick={() => void this.saveAttachment(x)} key={x.Name + x.AttachmentExtension + i}>
                                {x.Name + x.AttachmentExtension}
                              </a>
                            </div>
                          ))}
                      </div>
                    </Loading>
                  </div>
                  <JsonForms
                    schema={schema}
                    uischema={uischema}
                    data={JSON.parse(this.props.currentSubmission.Submission.Submission)}
                    renderers={renderers}
                    cells={materialCells}
                    readonly={true}
                  />
                </div>
              </Loading>
            </div>
          </div>
          <Button className="saveButton" onClick={() => this.props.handleClose("")} variant="outline-primary" color="primary">
            Dismiss
          </Button>
        </div>
      </div>
    );
  }
}
