import * as React from 'react';
import { Locale } from 'src/localization/Locale';

import * as Stripe from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';

import * as Messages from '../Messages';
import { useResponsiveFontSize } from './UseResponsiveFontSize';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = React.useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};
export interface ISplitFormProps {
  purchaseCancelled(): void;
  initiatePurchase(method: PaymentMethod): void;
  localization: Locale;
}
export const SplitForm = (props: ISplitFormProps) => {
  const stripe = Stripe.useStripe();
  const elements = Stripe.useElements();
  const options = useOptions();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    let cardEl = elements.getElement(Stripe.CardElement)!;
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardEl,
      billing_details: {
        name: ((event.target as HTMLFormElement).elements["name" as keyof HTMLFormControlsCollection] as HTMLInputElement).value,
        email: ((event.target as HTMLFormElement).elements["email" as keyof HTMLFormControlsCollection] as HTMLInputElement).value,
      },                       
    });
    if (payload.error) {
      Messages.Notify.error(payload.error.message);
    } else if (payload.paymentMethod) {
      props.initiatePurchase(payload.paymentMethod);
    }
  };

  return (
    <form onSubmit={(e) => void handleSubmit(e)}>
      <div>
        <label>{props.localization.currentLocale.Store.LABEL_CARD_NUMBER}</label>
        <Stripe.CardElement options={options} onReady={() => {}} onChange={() => {}} onBlur={() => {}} onFocus={() => {}} />
      </div>
      <div>
        <label>{props.localization.currentLocale.Store.LABEL_CARD_Name}</label>
        <input name="name" type="text" placeholder="Jane Doe" required />
      </div>
      <div>
        <label>{props.localization.currentLocale.Store.LABEL_CARD_EMAIL}</label>
        <input name="email" type="email" placeholder="jane.doe@example.com" required />
      </div>
      <button type="submit" disabled={!stripe}>
        {props.localization.currentLocale.Store.LABEL_SUBSCRIBE}
      </button>
      <button className="cancel" onClick={props.purchaseCancelled} type="button">
        {props.localization.currentLocale.Store.LABEL_STORE_CANCEL}
      </button>
    </form>
  );
};

export default SplitForm;
