import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { Log } from 'src/Logger';

import { CallbackWrapper } from '../../utilities/CallbackWrapper';
import { IHtmlElementProps } from '../foundation/Controls';

export interface IFrameProps extends IHtmlElementProps {
  head?: React.ReactElement;
  children?: React.ReactNode;
  scripts?: Array<{ src: string; head?: boolean }>;
  documentProvider?: (document: Document)=> void;
  skipWrapper?: boolean;
}
export interface IFrameState {
  currentIFrame: HTMLIFrameElement;
}
export class Frame extends React.Component<IFrameProps, IFrameState> {
  waitForHead: boolean;
  waitForBody: boolean;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  constructor(props: IFrameProps | Readonly<IFrameProps>) {
    super(props);
    this.headReady = this.headReady.bind(this);
    this.bodyReady = this.bodyReady.bind(this);
    this.iframeRef = React.createRef<HTMLIFrameElement>();
  }

  componentDidMount() {
    this.renderFrameContents();
    this.setState({ currentIFrame: this.iframeRef.current! });
  }

  componentWillUnmount() {}

  async headReady() {
    this.waitForHead = false;
    if (!this.waitForBody) {
      await this.documentReady();
    }
  }

  async bodyReady() {
    this.waitForBody = false;
    if (!this.waitForHead) {
      await this.documentReady();
    }
  }

  async documentReady() {
    try {
      let frameDocument: Document = this.iframeRef.current!.contentDocument!;
      if (this.props.documentProvider !== undefined) {
        this.props.documentProvider(frameDocument);
      }
    } catch (e) {
      await Log.error("Failed document Ready", e);
    }
  }
  async installScripts() {
    try {
      let doc = this.getDocument()!;
      doc.querySelector("html")!.setAttribute("overflow-y", "hidden");
      if (doc.readyState === "complete") {
        await this.createScripts(doc, true); // let's add scripts in header first, in case body scripts are dependent on it.
      }
      doc.onreadystatechange = async () => {
        if (doc.readyState === "complete") {
          await this.createScripts(doc, true); // let's add scripts in header first, in case body scripts are dependent on it.
        }
      };
    } catch (e) {
      await Log.error("Script install fail", e);
    }
  }
  getDocument() {
    return this.iframeRef.current!.contentDocument;
  }

  async createScripts(targetDocument: Document, inHeader: boolean) {
    try {
      if (this.props.scripts) {
        if (inHeader === true) {
          let headScripts = this.props.scripts.filter((item) => (item.head || false) === true);
          headScripts.map((script) => {
            let scriptElement = targetDocument.createElement("script");
            scriptElement.text = script.src;
            targetDocument.head.appendChild(scriptElement);
          });
        } else {
          let bodyScripts = this.props.scripts.filter((item) => (item.head || false) === false);
          bodyScripts.map((script) => {
            let scriptElement = targetDocument.createElement("script");
            scriptElement.text = script.src;
            targetDocument.body.children[0].appendChild(scriptElement);
          });
        }
      }
    } catch (e) {
      await Log.error("Could not load scripts!", e);
    }
  }

  renderFrameContents() {
    let doc = null;
    if (this.iframeRef && this.iframeRef.current) {
      doc = this.iframeRef.current.contentDocument!;
    }
    if (doc && doc.readyState === "complete") {
      let div = doc.createElement("div");
      div.setAttribute("id", "root");
      doc.body.appendChild(div);
      if (this.props.children !== undefined) {
        this.waitForBody = true;
        ReactDOMClient.createRoot(div).render(
            <CallbackWrapper callback={() => void this.bodyReady()} isBody={true}>
              {this.props.children as React.ReactElement}
            </CallbackWrapper>
        );          
      }
      if (this.props.head !== undefined) {
        this.waitForHead = true;
        ReactDOMClient.createRoot(doc.head).render(
          <CallbackWrapper callback={() => void this.bodyReady} isBody={false}>
            {this.props.head}
          </CallbackWrapper>
        );   
      }
    } else {
      setTimeout(() => {
        this.renderFrameContents();
      }, 100);
    }
  }

  render() {
    let frame = <iframe title="iFrameX" ref={this.iframeRef} className={this.props.className} id={this.props.id} style={this.props.style} />;
    if (!this.props.skipWrapper) {
      frame = (
        <div className="scrolling-reader-container">
          <iframe title="iFrameXScrolling" ref={this.iframeRef} className={this.props.className} id={this.props.id} style={this.props.style}  />
        </div>
      );
    }
    return frame;
  }
}
