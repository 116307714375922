interface Props {
  size?: string;
  className?: string;
}

const Spinner = ({ className = '' }: Props) => (
  <div className="spinner-border text-primary" role="status">
    <span className={`sr-only ${className}`}>Loading...</span>
  </div>
);

export default Spinner;
