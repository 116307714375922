import { Locale } from 'src/localization/Locale';
import { Wire } from 'src/network/Wire';
import { EventHandler } from 'src/utilities/Events';
import { Communication } from 'src/utilities/Helpers';

import { ContentSegment } from './Content';
import * as MigrationRequest from './dto/MigrationRequest';
import { Resource } from './Resource';
import { ActionResult } from './Result';
import { Annotation, AnnotationType, Favourite } from './UserContent';

export class MigrationConflict {
  GlobalId: number;
  UnresolvedConflicts: number;
  TotalConflicts: number;
  ConflictState: ConflictType;
  ModelName: string;
  AnnotationModel: Annotation;
  AnnotationTypeModel: AnnotationType;
  FavouriteModel: Favourite;
  Location: string;
  FromAnchor: number;
  ToAnchor: number;
  FromTopDoc: number;
  ToTopDoc: number;
  FromPreview: ContentSegment[];
  ToPreview: ContentSegment[];
  FromPopup: ContentSegment;
  ToPopup: ContentSegment;
  CurrentPage: number;
  FromTitle: string;
  ToTitle: string;
}

export enum MigratingBook {
  Old = 0,
  New = 1
}
export enum ConflictType {
  Resolved = 0,
  Conflict = 1,
  ConflictResolved = 2,
  ConflictNotResolved = 3,
  Deleted = 4,
  DeletedResolved = 5,
  DeletedNotResolved = 6
}
export enum ManipulateType {
  Save,
  Discard,
  Cancel
}
export enum ConflictNavigation {
  NextConflict,
  PreviousConflict,
  FirstConflict,
  LastConflict,
  SpecificConflict
}

export class Migration {
  initialConflict: MigrationConflict;
  migrationSet: MigrationRequest.IMigrationSet;
  fromStyle: string;
  toStyle: string;
  fromJavascript: string;
  toJavascript: string;
  wire: Wire;
  onMigrationExited: EventHandler<number>;
  fromResources: Resource;
  toResources: Resource;
  localization: Locale;
  constructor(initialConflict: MigrationRequest.IExecuteMigrationR, currentSet: MigrationRequest.IMigrationSet, wire: Wire, localization: Locale) {
    this.initialConflict = initialConflict.FirstConflict;
    this.fromStyle = initialConflict.FromStyle;
    this.toStyle = initialConflict.ToStyle;
    this.localization = localization;
    this.fromJavascript = initialConflict.FromJavascript;
    this.toJavascript = initialConflict.ToJavascript;
    this.migrationSet = currentSet;
    this.wire = wire;
    this.onMigrationExited = new EventHandler<number>();
    this.fromResources = new Resource(Wire.shield(this.wire), currentSet.VersionFrom);
    this.toResources = new Resource(Wire.shield(this.wire), currentSet.VersionTo);
  }
  async navigateConflict(request: MigrationRequest.IConflictRequest): Promise<ActionResult<MigrationConflict>> {
    return await Communication.handleResponse<MigrationRequest.IConflictRequestR>(this.wire, "migration/navigateConflict", request, (data, result) => {
      result.data = data.NewConflict;
    });
  }
  async manipulateModel(request: MigrationRequest.IManipulateModel): Promise<ActionResult<MigrationConflict>> {
    return await Communication.handleResponse<MigrationRequest.IManipulateModelR>(this.wire, "migration/manipulateModel", request, (data, result) => {
      result.data = data.NewConflict;
    });
  }
  async reapplyAnnotation(request: MigrationRequest.IReapplyAnnotation): Promise<ActionResult<MigrationConflict>> {
    return await Communication.handleResponse<MigrationRequest.IReapplyAnnotationR>(this.wire, "migration/reapplyAnnotation", request, (data, result) => {
      result.data = data.NewConflict;
    });
  }
  async reapplyFavourite(request: MigrationRequest.IReapplyFavourite): Promise<ActionResult<MigrationConflict>> {
    return await Communication.handleResponse<MigrationRequest.IReapplyFavouriteR>(this.wire, "migration/reapplyFavourite", request, (data, result) => {
      result.data = data.NewConflict;
    });
  }
  async decideForMe(request: MigrationRequest.IDecideForMe): Promise<ActionResult> {
    return await Communication.handleResponse<MigrationRequest.IDecideForMeR>(this.wire, "migration/decideForMe", request, () => {});
  }
  async fetchPopup(request: MigrationRequest.IFetchPopup): Promise<ActionResult<ContentSegment>> {
    return await Communication.handleResponse<MigrationRequest.IFetchPopupR>(this.wire, "migration/fetchPopup", request, (data, result) => {
      result.data = data.Popup;
    });
  }
  // Exit is dispatched to the App context to remove this migration from the table as well. Exits can occur within a migration as well as by clicking the close of the tab.
  exitMigration(request: MigrationRequest.IExitMigration) {
    this.onMigrationExited.dispatch(request.MigrationSet.VersionTo, this);
  }

  // region Resource
  async requestResource(data: string, book: MigratingBook) {
    if (book === MigratingBook.New) {
      await this.toResources.openResource(data);
    } else {
      await this.fromResources.openResource(data);
    }
  }
  async openExternalWebsite(externalWebsiteUrl: string, book: MigratingBook) {
    if (book === MigratingBook.New) {
      await this.toResources.openExternalWebsite(externalWebsiteUrl);
    } else {
      await this.fromResources.openExternalWebsite(externalWebsiteUrl);
    }
  }
  // endregion
}
