import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Chat } from 'src/chatbot/components/Chat/Chat';
import { Navbar } from 'src/chatbot/components/Mobile/Navbar';
import { useCreateReducer } from 'src/chatbot/hooks/useCreateReducer';
import useErrorService from 'src/chatbot/services/errorService';
import useApiService from 'src/chatbot/services/useApiService';
import { Conversation } from 'src/chatbot/types/chat';
import { CogniAIModels, fallbackModelID, fallbackTitle } from 'src/chatbot/types/cogniai';
import { KeyValuePair } from 'src/chatbot/types/data';
import { FolderInterface, FolderType } from 'src/chatbot/types/folder';
import { Prompt } from 'src/chatbot/types/prompt';
import { cleanConversationHistory, cleanSelectedConversation } from 'src/chatbot/utils/app/clean';
import {
    REACT_APP_DEFAULT_SYSTEM_PROMPT, REACT_APP_DEFAULT_TEMPERATURE
} from 'src/chatbot/utils/app/const';
import {
    saveConversation, saveConversations, updateConversation
} from 'src/chatbot/utils/app/conversation';
import { saveFolders } from 'src/chatbot/utils/app/folders';
import { savePrompts } from 'src/chatbot/utils/app/prompts';
import { getSettings } from 'src/chatbot/utils/app/settings';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { v4 as uuidv4 } from 'uuid';

import { useQuery } from '@tanstack/react-query';

import { LibraryContext } from '../state/Contextes';
import { ProfessorContext } from '../state/professor.context';
import { initialState, ProfessorInitialState } from '../state/professor.state';

interface IProfessorViewProps {
  isActive: boolean;
  // serverSideApiKeyIsSet: boolean;
  // serverSidePluginKeysSet: boolean;
  // defaultModelId: CogniAIModelID;
}

export const ProfessorView = (props: IProfessorViewProps) => {
  const { isActive } = props;
  const defaultModelId = fallbackModelID;
  const defaultTitle = fallbackTitle;
  const { t } = useTranslation('chat');
  const library = useContext(LibraryContext);
  const { getTitles } = useApiService(library);
  const { getTitlesError } = useErrorService();
  const contextValue = useCreateReducer<ProfessorInitialState>({initialState});
  
  const {
    state: {
      apiKey,
      lightMode,
      folders,
      conversations,
      selectedConversation,
      prompts
    },
    dispatch,
  } = contextValue;

  const stopConversationRef = useRef<boolean>(false);

  const handleSelectConversation = (conversation: Conversation) => {
    dispatch({
      field: 'selectedConversation',
      value: conversation,
    });

    saveConversation();
  };

  // FETCH TITLES ----------------------------------------------
  const { data: titleResponse, error: titleError, refetch: titleRefetch } = useQuery(
    ['GetTitles', apiKey],
    ({ signal }) => {
      if (!apiKey) return null;

      let titles = getTitles(
        {
          key: apiKey,
        },
        signal
      );
      return titles;
    },
    { enabled: true, refetchOnMount: false, refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5 },
  );

  useEffect(() => {
    if (titleResponse && titleResponse.data) dispatch({ field: 'titles', value: titleResponse.data.ChatTitles });
  }, [titleResponse, dispatch]);

  useEffect(() => {
    if (titleError) dispatch({ field: 'titleError', value: getTitlesError(titleError) });
  }, [titleError, dispatch, getTitlesError]);

  useEffect(() => {
    if (titleRefetch) dispatch({ field: 'titleRefetch', value: titleRefetch});
  }, [titleRefetch, dispatch]);

  // FOLDER OPERATIONS  --------------------------------------------

  const handleCreateFolder = (name: string, type: FolderType) => {
    const newFolder: FolderInterface = {
      id: uuidv4(),
      name,
      type,
    };

    const updatedFolders = [...folders, newFolder];

    dispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);
  };

  const handleDeleteFolder = (folderId: string) => {
    const updatedFolders = folders.filter((f) => f.id !== folderId);
    dispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);

    const updatedConversations: Conversation[] = conversations.map((c) => {
      if (c.folderId === folderId) {
        return {
          ...c,
          folderId: null,
        };
      }

      return c;
    });

    dispatch({ field: 'conversations', value: updatedConversations });
    saveConversations();

    const updatedPrompts: Prompt[] = prompts.map((p) => {
      if (p.folderId === folderId) {
        return {
          ...p,
          folderId: null,
        };
      }

      return p;
    });

    dispatch({ field: 'prompts', value: updatedPrompts });
    savePrompts(updatedPrompts);
  };

  const handleUpdateFolder = (folderId: string, name: string) => {
    const updatedFolders = folders.map((f) => {
      if (f.id === folderId) {
        return {
          ...f,
          name,
        };
      }

      return f;
    });

    dispatch({ field: 'folders', value: updatedFolders });

    saveFolders(updatedFolders);
  };

  // CONVERSATION OPERATIONS  --------------------------------------------

  const handleNewConversation = () => {
    const lastConversation = conversations[conversations.length - 1];

    const newConversation: Conversation = {
      id: uuidv4(),
      name: t('New Conversation'),
      messages: [],
      model: lastConversation?.model || {
        id: CogniAIModels[defaultModelId].id,
        name: CogniAIModels[defaultModelId].name,
        maxLength: CogniAIModels[defaultModelId].maxLength,
        tokenLimit: CogniAIModels[defaultModelId].tokenLimit,
      },
      title: lastConversation?.title || {
        versionId: defaultTitle.versionId,
        title: defaultTitle.title,
      },
      prompt: REACT_APP_DEFAULT_SYSTEM_PROMPT,
      temperature: lastConversation?.temperature ?? REACT_APP_DEFAULT_TEMPERATURE,
      folderId: null,
    };

    const updatedConversations = [...conversations, newConversation];

    dispatch({ field: 'selectedConversation', value: newConversation });
    dispatch({ field: 'conversations', value: updatedConversations });

    saveConversation();
    saveConversations();

    dispatch({ field: 'loading', value: false });
  };

  const handleUpdateConversation = (
    conversation: Conversation,
    data: KeyValuePair,
  ) => {
    const updatedConversation = {
      ...conversation,
      [data.key]: data.value,
    };

    const { single, all } = updateConversation(
      updatedConversation,
      conversations,
    );

    dispatch({ field: 'selectedConversation', value: single });
    dispatch({ field: 'conversations', value: all });
  };

  // EFFECTS  --------------------------------------------

  useEffect(() => {
    const mainElement = document.querySelector('main');
    if (mainElement && mainElement.offsetWidth < 200) {
      dispatch({ field: 'showChatbar', value: false });
      dispatch({ field: 'showPromptbar', value: false });
    }
  }, [dispatch]);

  useEffect(() => {
    if (defaultModelId) {
      dispatch({ field: 'defaultModelId', value: defaultModelId });
    }
  }, [defaultModelId, dispatch]);

  useEffect(() => {
    if (defaultTitle) {
      dispatch({ field: 'defaultTitle', value: defaultTitle });
    }
  }, [defaultTitle, dispatch]);

  // ON LOAD --------------------------------------------

  useDeepCompareEffect(() => {
    const settings = getSettings();
    if (settings.theme) {
      dispatch({
        field: 'lightMode',
        value: settings.theme,
      });
    }

    const key = localStorage.getItem('apiKey');
    if (key) {
      dispatch({ field: 'apiKey', value: key });
    }

    const mainElement = document.querySelector('main');
    if (mainElement && mainElement.offsetWidth < 200) {
      dispatch({ field: 'showChatbar', value: false });
      dispatch({ field: 'showPromptbar', value: false });
    }

    const showChatbar = localStorage.getItem('showChatbar');
    if (showChatbar) {
      dispatch({ field: 'showChatbar', value: showChatbar === 'true' });
    }

    const showPromptbar = localStorage.getItem('showPromptbar');
    if (showPromptbar) {
      dispatch({ field: 'showPromptbar', value: showPromptbar === 'true' });
    }

    const fldrs = localStorage.getItem('folders');
    if (fldrs) {
      dispatch({ field: 'folders', value: JSON.parse(fldrs ?? "") });
    }

    const temp = localStorage.getItem('temperature');
    if (temp) {
      dispatch({ field: 'temperature', value: JSON.parse(temp ?? "") });
    }

    const prmpts = localStorage.getItem('prompts');
    if (prmpts) {
      dispatch({ field: 'prompts', value: JSON.parse(prmpts ?? "") });
    }

    const conversationHistory = null; // localStorage.getItem('conversationHistory');
    if (conversationHistory) {
      const parsedConversationHistory: Conversation[] =
        JSON.parse(conversationHistory);
      const cleanedConversationHistory = cleanConversationHistory(
        parsedConversationHistory,
      );

      dispatch({ field: 'conversations', value: cleanedConversationHistory });
    }

    const selectedConv = null; // localStorage.getItem('selectedConversation');
    if (selectedConv) {
      const parsedSelectedConversation: Conversation =
        JSON.parse(selectedConv);
      const cleanedSelectedConversation = cleanSelectedConversation(
        parsedSelectedConversation,
      );
      dispatch({
        field: 'selectedConversation',
        value: cleanedSelectedConversation,
      });
    } else {
      let lastConversation = conversations[conversations.length - 1];
      if (!lastConversation) {
        lastConversation = {
          id: uuidv4(),
          name: t('New Conversation'),
          messages: [],
          model: CogniAIModels[defaultModelId],
          title: defaultTitle,
          prompt: REACT_APP_DEFAULT_SYSTEM_PROMPT,
          temperature: REACT_APP_DEFAULT_TEMPERATURE,
          folderId: null,
        };
        saveConversation();
      }
      dispatch({
        field: 'selectedConversation',
        value: lastConversation,
      });
    }
  }, [
    defaultModelId,
    dispatch,
    conversations,
    // t,
  ]);

  return (
    <ProfessorContext.Provider
      value={{
        ...contextValue,
        handleNewConversation,
        handleCreateFolder,
        handleDeleteFolder,
        handleUpdateFolder,
        handleSelectConversation,
        handleUpdateConversation,
      }}
    >
      {isActive && selectedConversation && (
        <main className={`d-flex flex-column text-sm text-white vh-100 professor-view ${lightMode}`}>
          <div className="position-fixed top-0 w-100 d-sm-none">
            <Navbar
              selectedConversation={selectedConversation}
              onNewConversation={handleNewConversation}
            />
          </div>
          <div className="d-flex h-100 w-100 pt-3 pt-sm-0">
            <div className="d-flex flex-column flex-fill">
              <Chat stopConversationRef={stopConversationRef} />
            </div>
          </div>
        </main>
      )}
    </ProfessorContext.Provider>
  );
};
export default ProfessorView;