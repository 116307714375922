import * as React from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { Dictionary } from 'src/collections/Generics';
import { Log } from 'src/Logger';
import { FontSizes } from 'src/models/AppSession';
import { Book } from 'src/models/Book';
import { ContentSegment, ExtendedHeader, FlattenTocNode, TocNode } from 'src/models/Content';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';

import * as Messages from '../foundation/Messages';
import { BookContext } from '../state/Contextes';
import { ISelectionInfo, SelectionState, TOCNodeItem } from '../views/TOCView';
import { Loading, Switch } from './Controls';

const content_css = require("!!raw-loader!src/assets/css/content.css").default;
interface IPrintModalControlProps {
  maxWords: number;
}

interface IPrintModalControlState {
  isOpen: boolean;
  selectionObject: any;
  firstSpine: number;
  lastSpine: number;
}

export class PrintModalControl extends React.Component<IPrintModalControlProps, IPrintModalControlState> {
  private printForm = React.createRef<PrintModalForm>();
  constructor(props: IPrintModalControlProps) {
    super(props);

    this.state = {
      isOpen: false,
      selectionObject: null,
      firstSpine: -1,
      lastSpine: -1
    };
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.printSelection = this.printSelection.bind(this);
  }

  hide() {
    this.setState({ isOpen: false });
  }
  show(selection: any, startSpine: number, endSpine: number) {
    this.setState({ isOpen: true, selectionObject: selection, firstSpine: startSpine, lastSpine: endSpine });
  }

  async printSelection(selection: any, startSpine: number, endSpine: number, rawContent: string, annotationsInSelection: boolean) {
    await Log.info(startSpine.toString());
    await Log.info(endSpine.toString());
    this.setState({ selectionObject: selection, firstSpine: startSpine, lastSpine: endSpine }, () => {
      if (selection != null && selection && this.printForm.current && this.printForm.current !== null) {
        this.printForm.current.printSelection(this.state.selectionObject.firstDocId as number, this.state.selectionObject.secondDocId as number, rawContent, selection.firstWordCount as number, selection.secondWordCount as number, annotationsInSelection);
      }
    });
  }

  render() {
    if (this.state.isOpen) {
      return (
        <div>
          <div className="unbound-form-modal" onClick={this.hide} />
          <div className="unbound-form-container print-container">
            {this.state.selectionObject && (
              <PrintModalForm
                ref={this.printForm}
                selection={this.state.selectionObject}
                showing={true}
                initialMaxWords={this.props.maxWords}
                firstSpine={this.state.firstSpine}
                lastSpine={this.state.lastSpine}
              />
            )}
          </div>
        </div>
      );
    } else if (this.state.selectionObject) {
      return (
        <PrintModalForm
          ref={this.printForm}
          selection={this.state.selectionObject}
          showing={false}
          initialMaxWords={this.props.maxWords}
          firstSpine={this.state.firstSpine}
          lastSpine={this.state.lastSpine}
        />
      );
    } else {
      return "";
    }
  }
}
interface IPrintModalFormProps {
  selection: any;
  firstSpine: number;
  lastSpine: number;
  showing: boolean;
  initialMaxWords: number;
}
interface IPrintModalFormState {
  selectionInfo: ISelectionInfo;
  maxWords: number;
  words: number;
  loading: boolean;
  addHighlights: boolean;
}

class PrintModalForm extends React.Component<IPrintModalFormProps, IPrintModalFormState> {
  context: Book;
  static contextType = BookContext;
  children: Array<React.RefObject<TOCNodeItem>>;
  constructor(props: IPrintModalFormProps) {
    super(props);
    this.state = {
      selectionInfo: {
        selectedSegmentIds: new Set<number>(),
        partiallySelectedIds: new Map<number, Map<number, SelectionState>>()
      },
      maxWords: props.initialMaxWords,
      words: 0,
      loading: false,
      addHighlights: false
    };
    this.removeFromPartialSelectionMap = this.removeFromPartialSelectionMap.bind(this);
    this.computeSelectionStateOfNode = this.computeSelectionStateOfNode.bind(this);
    this.updateParentSelectionState = this.updateParentSelectionState.bind(this);
    this.updateListOfPartiallySelectedNodes = this.updateListOfPartiallySelectedNodes.bind(this);
    this.setChildDocs = this.setChildDocs.bind(this);
    this.notifyParentOfSelectionChange = this.notifyParentOfSelectionChange.bind(this);
    this.printSegments = this.printSegments.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.changeHighlight = this.changeHighlight.bind(this);
  }
  async componentDidMount() {
    try {
      let targetSpine: number = +this.props.firstSpine;
      while ((this.context.tocSpineIdToHeader.get(targetSpine) == null || !this.context.tocSpineIdToHeader.get(targetSpine)) && targetSpine > 0) {
        targetSpine = targetSpine - 1;
        await Log.info(targetSpine.toString());
        let node = this.context.tocSpineIdToHeader.get(targetSpine);
        if (node) {
          break;
        }
      }
      await Log.error("Final spine was: " + targetSpine.toString(), null);
      let X: TocNode = {
        Children: [],
        Current: this.context.tocSpineIdToHeader.get(targetSpine)!.Current,
        Depth: this.context.tocSpineIdToHeader.get(targetSpine)!.Depth
      };
      this.notifyParentOfSelectionChange(X, SelectionState.Selected);
    } catch (ex) {
      await Log.error("Printing error.", ex);
    }
  }
  /**
   *
   * Removes the given nodes from the partially selected nodes map.
   * This method does not propagate itself to the parent nodes.
   */
  private removeFromPartialSelectionMap(nodeIds: Iterable<number>) {
    for (const nodeId of nodeIds) {
      this.state.selectionInfo.partiallySelectedIds.delete(nodeId);
    }
  }
  private computeSelectionStateOfNode(node: FlattenTocNode): SelectionState {
    // Unselected
    if (!this.state.selectionInfo.partiallySelectedIds.has(node.Current.Id)) {
      return SelectionState.Unselected;
    } else {
      // Check if all children are selected
      let areEveryChildrenSelected = true;
      const selectedChildren = this.state.selectionInfo.partiallySelectedIds.get(node.Current.Id)!;
      for (const selectedChild of selectedChildren.values()) {
        if (selectedChild !== SelectionState.Selected) {
          areEveryChildrenSelected = false;
          break;
        }
      }
      // Full selection
      if (selectedChildren.size === node.Children.length && areEveryChildrenSelected) {
        return SelectionState.Selected;
      }
      // Partial selection
      else {
        return SelectionState.PartialSelected;
      }
    }
  }
  private updateParentSelectionState(child: ExtendedHeader, childState: SelectionState) {
    // There is no need to update the parent if it does not exist.
    if (!child.Parent || !this.context.tocHeaderIdToHeader.has(child.Parent)) {
      return;
    }
    const parentNode = this.context.tocHeaderIdToHeader.get(child.Parent)!;
    // First, we update the list of partially selected nodes for the parent.
    this.updateListOfPartiallySelectedNodes(child, childState);
    // Then, we compute the new state of the parent
    const newParentSelectionState = this.computeSelectionStateOfNode(parentNode);
    // After that, we update the list of selected nodes if need be
    if (newParentSelectionState === SelectionState.Selected) {
      this.state.selectionInfo.selectedSegmentIds.add(child.Parent);
    } else {
      this.state.selectionInfo.selectedSegmentIds.delete(child.Parent);
    }
    // Finally, we propagate the changes to the parent's ancestor.
    this.updateParentSelectionState(parentNode.Current, newParentSelectionState);
  }
  private updateListOfPartiallySelectedNodes(child: ExtendedHeader, childState: SelectionState) {
    // There is no need to update the parent if it does not exist.
    if (!child.Parent || !this.context.tocHeaderIdToHeader.has(child.Parent)) {
      return;
    }
    const parentId = child.Parent;
    // We update the list of partially selected nodes to reflect the new selection
    if (this.state.selectionInfo.partiallySelectedIds.has(parentId)) {
      const childrenSelectionState = this.state.selectionInfo.partiallySelectedIds.get(parentId)!;
      if (childState === SelectionState.Unselected) {
        childrenSelectionState.delete(child.Id);
        if (childrenSelectionState.size === 0) {
          this.state.selectionInfo.partiallySelectedIds.delete(parentId);
        }
      } else {
        childrenSelectionState.set(child.Id, childState);
      }
    } else {
      // If the child has been unselected or partially selected and the parent is in the list of selected nodes,
      // add the parent to the list of partially selected nodes with the selection state of
      // all of the other children.
      if (
        (childState === SelectionState.Unselected || childState === SelectionState.PartialSelected) &&
        this.state.selectionInfo.selectedSegmentIds.has(parentId)
      ) {
        const parentNode = this.context.tocHeaderIdToHeader.get(parentId)!;
        const selectedChildren = parentNode.Children.filter(c => c.Id !== child.Id);
        if (selectedChildren.length > 1) {
          this.state.selectionInfo.partiallySelectedIds.set(parentId, new Map(selectedChildren.map(c => [c.Id, SelectionState.Selected])));
        }
      } else {
        this.state.selectionInfo.partiallySelectedIds.set(parentId, new Map([[child.Id, childState]]));
      }
    }
  }
  setChildDocs(node: TocNode, curr: Set<number>) {
    curr.add(node.Current.Id);
    node.Children.map(it => {
      this.setChildDocs(it, curr);
    });
  }
  notifyParentOfSelectionChange(node: TocNode, newState: SelectionState) {
    // Recursively find document IDs and add or remove them depending on how the node was modified. This constitutes the filter.
    this.setState({ loading: true }, () => void(async () => {
      let affectedDocuments = new Set<number>();
      this.setChildDocs(node, affectedDocuments);
      let changes = [];

      // Once all child have their new selection state, remove them from the partially selected nodes.
      this.removeFromPartialSelectionMap(affectedDocuments);
      // Then, propagate the change to the parent nodes.
      this.updateParentSelectionState(node.Current, newState);
      // After that, we persist the new selection state.
      if (newState === SelectionState.Selected) {
        changes = [...this.state.selectionInfo.selectedSegmentIds, ...affectedDocuments];
      } else {
        changes = [...this.state.selectionInfo.selectedSegmentIds].filter(num => !affectedDocuments.has(num));
      }
      const result = await this.context.printSelectionChanged({ Filter: changes });
      if(result) {
        if (result.valid()) {
          this.setState({ maxWords: result.data.MaxWords, words: result.data.Words, loading: false });
        }
      }
      // Finally, update the state to rerender all children. This is done even though the state already
      // been mutated in order to rerender the affected TocNodes.
      this.setState({
        selectionInfo: {
          selectedSegmentIds: new Set<number>(changes),
          partiallySelectedIds: this.state.selectionInfo.partiallySelectedIds
        }
      });
    })());
  }
  printSegments() {
    if (this.state.words > this.state.maxWords) {
      Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_TOO_MANY_WORDS);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: true }, () => void(async () => {
      const css = `<style>${String(content_css)}</style><style>${this.context.style}</style>`;
      const head = css;
      let result = await this.context.printSegments({
        Filter: Array.from(this.state.selectionInfo.selectedSegmentIds),
        ShowHighlights: this.state.addHighlights,
        HeadContent: head
      });
      // If no segments returned either there were no segments selected or the native printer handled it. Either way don't do anything.
      if (result.errors.length > 0 || result.data.SelectedSegments.length === 0) {
        this.setState({ loading: false });
        return;
      }
      this.executePrint(result.data.SelectedSegments, head);
    })());
  }
  printSelection(firstDoc: number, secondDoc: number, rawContent: string, firstOffset: number, secondOffset: number, annotationsInSelection: boolean) {
    this.setState({ loading: true }, () => void(async () => {
      // Settings up the head and opening the tab
      let css = `<style>${String(content_css)}</style><style>${this.context.style}</style>`;
      if (
        (annotationsInSelection && await Messages.Dialog.confirm(this.context.localization.currentLocale.PrintView.PRINT_HIGHLIGHTS_CONFIRM, this.context.localization.currentLocale.PrintView.PRINT_HIGHLIGHTS_CONFIRM_TITLE, Messages.Dialog.Buttons.YesNo)) === "true"
      ) {
        css = `<style>${String(content_css)}</style><style>${this.context.style + "*{-webkit-print-color-adjust: exact;}"}</style>`;
      }
      const head = css;
      try {
        let result = await this.context.printSelection({
          FirstDocId: firstDoc,
          SecondDocId: secondDoc,
          ShowHighlights: this.state.addHighlights,
          HeadContent: head,
          FirstOffset: firstOffset,
          LastOffset: secondOffset,
          RawContent: rawContent
        });

        if (result.errors.length > 0) {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_TOO_MANY_WORDS);
        }
        // If no segments returned either there were no segments selected or the native printer handled it. Either way don't do anything.
        if (result.errors.length > 0 || result.data.RawPrint === null || result.data.RawPrint.length === 0) {
          this.setState({ loading: false });
          return;
        }
        this.executePrintHtml(result.data.RawPrint, head);
      } catch (e) {
        await Log.error("Inner print failed.", e);
      }
    })());
  }
  executePrint(segments: ContentSegment[], headContent: string) {
    // Getting the selection content
    const selectedHtml = segments.map(x => x.Content).join("");
    const div = document.createElement("div");
    if (selectedHtml) {
      div.innerHTML = selectedHtml;
    }
    const win = ResourcePlatform.openNewTabWithContent(div.outerHTML, "Print Selection", true, headContent);
    // Adding a timeout to let the ContentView window react to the tab opening properly.
    // Without this, the whole browser freezes while the print preview is opened.
    if (win) {
      setTimeout(() => win.print(), 1000);
    }
    this.setState({ loading: false });
  }
  executePrintHtml(rawHtml: string, headContent: string) {
    // Getting the selection content
    const selectedHtml = rawHtml;
    const div = document.createElement("div");
    if (selectedHtml) {
      div.innerHTML = selectedHtml;
    }
    const win = ResourcePlatform.openNewTabWithContent(div.outerHTML, "Print Selection", true, headContent);
    // Adding a timeout to let the ContentView window react to the tab opening properly.
    // Without this, the whole browser freezes while the print preview is opened.
    if (win) {
      setTimeout(() => win.print(), 1000);
    }
    this.setState({ loading: false });
  }
  changeHighlight(isOn: boolean) {
    this.setState({ addHighlights: isOn });
  }
  render() {
    if (!this.props.showing) {
      return "";
    }
    let currentFontSizeClass = "";
    switch (this.context.appSettings.get().TOCFontSize) {
      case FontSizes.Smallest:
        currentFontSizeClass = "font-smallest";
        break;
      case FontSizes.Smaller:
        currentFontSizeClass = "font-smaller";
        break;
      case FontSizes.Normal:
        currentFontSizeClass = "font-medium";
        break;
      case FontSizes.Larger:
        currentFontSizeClass = "font-larger";
        break;
      case FontSizes.Largest:
        currentFontSizeClass = "font-largest";
        break;
    }
    this.children = [];
    let tooManyWords = this.state.words > this.state.maxWords ? "invalid-text" : "";
    return (
      <Loading className="print-loading" isLoading={this.state.loading} status={""}>
        <div className="print-modal-container">
          <div className="print-modal-header">
            <h2>{this.context.localization.currentLocale.PrintView.PRINT_SELECT_SECTIONS}</h2>
            <h3 className={tooManyWords}>
              {Convert.formatString(this.context.localization.currentLocale.PrintView.PRINT_WORDS_MAX, [this.state.words, this.state.maxWords])}
            </h3>
          </div>
          <div className="toc-view print-modal-toc">
            <div>
              {this.context.tocRoot.rows().map(node => {
                let ref = React.createRef<TOCNodeItem>();
                this.children.push(ref);
                return (
                  <TOCNodeItem
                    searchResults={new Dictionary<string, ExtendedHeader>()}
                    onSelectionChanged={this.notifyParentOfSelectionChange}
                    selectionInfo={this.state.selectionInfo}
                    checkboxesActive={true}
                    highlightInfo={null}
                    ref={ref}
                    navigateTOC={() => { }}
                    initialOpen={true}
                    key={node.Current.Id}
                    currentNode={node}
                    fontsizeClass={currentFontSizeClass}
                  />
                );
              })}
            </div>
          </div>
          <div className="print-modal-footer">
            <FormGroup>
              <Label>{this.context.localization.currentLocale.PrintView.PRINT_SHOW_HIGHLIGHT}</Label>
              <Switch
                name="check2"
                on={this.state.addHighlights}
                onChange={e => {
                  this.changeHighlight(e.target.checked as boolean);
                }}
                className="ml-3"
              />
              <Button color="primary" outline block onClick={this.printSegments}>
                {this.context.localization.currentLocale.PrintView.PRINT_DIALOG_CAPTION}
              </Button>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
