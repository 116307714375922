import 'src/assets/css/app.css';

import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { AppSession } from 'src/models/AppSession';
import { AppContext } from 'src/ui/state/Contextes';
import { AppView } from 'src/ui/views/AppView';
import { msalConfig } from 'src/utilities/AuthConfig';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ResourcePlatform } from './utilities/Helpers';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
let msalInstance: PublicClientApplication | undefined;

if (ResourcePlatform.UsesWebSso()) {
  msalInstance = new PublicClientApplication(msalConfig);
  AppSession.current.MSALInstance = msalInstance;
}

const queryClient = new QueryClient();

const App = () => {
  const [app] = useState(AppSession.current);

  if (msalInstance) {
    return (
        <MsalProvider instance={msalInstance}>
          <AppContext.Provider value={app}>
            <Toaster />
            <QueryClientProvider client={queryClient}>
              <AppView />
            </QueryClientProvider>
          </AppContext.Provider>
        </MsalProvider>
    );
  } else {
    return (
        <AppContext.Provider value={app}>
          <Toaster />
          <QueryClientProvider client={queryClient}>
            <AppView />
          </QueryClientProvider>
        </AppContext.Provider>
    );
  }
};

export default App;