import React, { ChangeEvent } from 'react';
import rehypeSanitize from 'rehype-sanitize';
import { Book } from 'src/models/Book';
import { BookContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import MDEditor, { ContextStore } from '@uiw/react-md-editor';

export interface IProLibroMarkdownProps {
  value: string;
  onChange: ((value?: string | undefined, event?: ChangeEvent<HTMLTextAreaElement> | undefined, state?: ContextStore | undefined) => void) | undefined;
  intialPreview: "live" | "edit" | "preview";
  linkClicked?: (href: string) => void;
}

const markdownLinkClicked = async (e: React.MouseEvent, props: IProLibroMarkdownProps, book: Book) => {
  if ((e.target as HTMLElement).tagName === "A") {
    e.preventDefault();
    e.stopPropagation();
    let anchor = e.target as HTMLAnchorElement;
    if (props.linkClicked) {
      props.linkClicked(anchor.href);
    } else {
      let finalLink = Convert.DeepLinkUrlToNative(anchor.href);
      if (finalLink !== null) {
        await book.reportExternalLink({ Url: finalLink });
      } else if (anchor.href.indexOf("http") === 0 || anchor.href.indexOf("https") === 0) {
        await book.openExternalWebsite(anchor.href, anchor.href);
      }
    }
  }
};


export const ProLibroMarkdown = (props: IProLibroMarkdownProps) => {
  const book = React.useContext(BookContext)!;
  return (
    <div onClick={(e) => void markdownLinkClicked(e, props, book)}>
      <MDEditor
        value={props.value}
        onChange={props.onChange}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]],
          skipHtml: true,
        }}
        height={400}
        preview={props.intialPreview}
      />
    </div>
  );
};
