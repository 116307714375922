import classnames from 'classnames';
import * as React from 'react';

import { Frame, IFrameProps } from './Frame';
import { ICogniflowProps, StandaloneCogniflowContainer } from './StandaloneCogniflow';

/**
 * Frame props. They extend the ICogniflowProps and IFrameProps.
 */
interface ICogniflowFrameProps extends ICogniflowProps, IFrameProps {
  /** Event to fire when the frame is blurred. */
  frameBlurred?: () => void;  
  children?: React.ReactElement;
}
/**
 * This is the standalone cogniflow container frame. It is meant to replace the hybrid cogniflow previously existing.
 * This version wraps the regular StandalonCogniflowContainer with an iframe and provides all the same functions as the
 * regular one but abstracted. This allows the scoping of CSS and scripting as well as increasing security by cutting the
 * contents off from the rest of the app.
 */
export class StandaloneCogniflowFrameContainer extends React.PureComponent<ICogniflowFrameProps, unknown> {
  private cogniflow = React.createRef<StandaloneCogniflowContainer>();
  private frameComponent = React.createRef<Frame>();
  initialized = false;
  constructor(props: ICogniflowFrameProps | Readonly<ICogniflowFrameProps>) {
    super(props);
    this.initializeWrapper = this.initializeWrapper.bind(this);
  }

  componentDidMount() {}
  componentDidUpdate() {
    if (this.cogniflow.current && !this.initialized) {
      this.initialized = true;
      this.cogniflow.current.reloadCogniflow();
    }
  }
  /**
   * Getter for the StandaloneCogniflowContainer within this Frame control.
   */
  public flow(): StandaloneCogniflowContainer | null {
    return this.cogniflow.current;
  }
  /**
   * Getter for the direct Document element of the iframe itself.
   */
  public document(): Document | null {
    if (this.frameComponent.current === null) {
      return null;
    }
    let doc = this.frameComponent.current.getDocument();
    if (doc && doc.readyState !== "complete") {
      return null;
    }
    return doc;
  }

  public async loadBodyScripts() {
    await this.frameComponent.current!.createScripts(this.frameComponent.current!.getDocument()!, false);
  }

  docReady() {}

  /** Only set scripts after the flow initializes. This prevents scripts executing on unloaded cogniflow. */
  private async initializeWrapper(initialAnchor?: number): Promise<{ nodes: any[]; targetSpine: number }> {
    if (!this.initialized) {
      await this.frameComponent.current!.installScripts();
      this.forceUpdate();
    }
    if (!this.initialized) {
      return { nodes: [], targetSpine: -1 };
    } else {
      return await this.props.initialize(initialAnchor);
    }
  }

  render() {
    // Set a key if the frame is loaded. This causes the iframe to reload with the new content.
    let key = "";
    return (
      <Frame
        key={key}
        className={classnames(this.props.className, "full-height", "full-width")}
        head={this.props.head}
        scripts={this.props.scripts}
        skipWrapper={true}
        documentProvider={this.docReady}
        ref={this.frameComponent}
      >
        <div className="full-height full-width" tabIndex={1} onBlur={() => this.props.frameBlurred && this.props.frameBlurred()}>
          <StandaloneCogniflowContainer
            secondaryIdInitializeRequested={this.props.secondaryIdInitializeRequested}
            provider={this.props.provider}
            builder={this.props.builder}
            addonBuilder={this.props.addonBuilder}
            segmentsInsertedCallback={this.props.segmentsInsertedCallback}
            navigationDoneCallback={this.props.navigationDoneCallback}
            extraSettings={this.props.extraSettings}
            topMostHasChanged={this.props.topMostHasChanged}
            initialize={this.initializeWrapper}
            ref={this.cogniflow}
          >
            {this.props.children}
          </StandaloneCogniflowContainer>
        </div>
      </Frame>
    );
  }
}
