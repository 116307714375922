import classnames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Tooltip } from 'react-tooltip';
import {
    Button, Card, CardBody, CardText, CardTitle, Collapse, DropdownToggle, Input, ListGroup,
    ListGroupItem, ModalBody, Nav, Navbar, NavItem, NavLink, Progress, TabContent, TabPane,
    UncontrolledDropdown
} from 'reactstrap';
import Gfm from 'remark-gfm';
import { Languages, Locale } from 'src/localization/Locale';
import { Log } from 'src/Logger';
import { AppLoadingSteps, LibrarySession, LoginType } from 'src/models/AppSession';
import { Book } from 'src/models/Book';
import { AvailableTitlesCogniflowMessage } from 'src/models/dto/LibraryRequest';
import { IMigrationSet } from 'src/models/dto/MigrationRequest';
import { ExternalLinkingError, ExternalLinkingPayload } from 'src/models/ExternalLinking';
import {
    AccessResult, IPaidSubscriptionDefinition, IUserUploadedTitle, LibraryCell, LibraryCellType,
    LibraryProductCell, LibrarySorting, LibraryVersion, LibraryViewMode, LibrayToolbarActions,
    MultiSearchMessage, PurchaseResult, Section, SectionState, UnifiedProduct,
    UserUploadedTitleState, VersionStatus, VersionTask, VersionTaskType
} from 'src/models/Library';
import { Resource } from 'src/models/Resource';
import { IError, Status } from 'src/network/Requests';
import { Wire } from 'src/network/Wire';
import { Binding, EmitterEvent, EventHandler } from 'src/utilities/Events';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';
import * as _ from 'underscore';

import { PaymentMethod } from '@stripe/stripe-js';

import { Image } from '../foundation/Assets';
import {
    ActionIcon, Checkbox, CheckboxState, Drawer, DrawerContainer, Expander, Icon, Loading,
    SortDropdownItem, SortDropdownMenu, StaticModal
} from '../foundation/Controls';
import { PurchaseSummary } from '../foundation/Controls/PurchaseSummary';
import { TitleUploadModal } from '../foundation/Controls/TitleUploadModal';
import { RightFlip } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import {
    ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { LibraryContext } from '../state/Contextes';
import { IModelBinding } from '../state/Generics';
import { LoginView } from './LoginView';
import { SubscriptionModal } from './SubscriptionModal';

interface ILibraryViewProps {}
interface ILibraryViewState {
  login: LoginType;
  loading: boolean;
  cells: LibraryCell[] | null;
  moreInfoOpen: boolean;
  currentMoreInfo: LibraryProductCell | LibraryCell | null;
  loginInProgress: boolean;
  openingInProgess: number[];
  currentSort: LibrarySorting;
  isReversed: boolean;
  isMigrating: boolean;
  searchMode: boolean;
  currentQuery: string;
  currentFilter: string;
  showTitleUploadModal: boolean;
  showSubscriptionModal: boolean;
  selectedVersions: number[];
  hasAvailableTitles: boolean;
  filterEnabled: boolean;
  availableTitlesSection: SectionState;
  myTitlesSection: SectionState;
  initialMoreInfoTab: string;
  currentToken: string;
  showTokenNote: boolean;
  libraryViewMode: LibraryViewMode;
}
let currentViewState = LibraryViewMode.Grid;

export class LibraryView extends React.Component<ILibraryViewProps, ILibraryViewState> {
  context: LibrarySession;
  static contextType = LibraryContext;
  defaultMessage = "Refreshing library...";
  migratingMessage = "Loading migration...";
  companionUrlHandler: Resource;
  flowRef = React.createRef<AvailableTitlesFlow>();
  prodFlowRef = React.createRef<ProductsFlow>();
  constructor(props: ILibraryViewProps | Readonly<ILibraryViewProps>) {
    super(props);
    this.update = this.update.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.registerLibrary = this.registerLibrary.bind(this);
    this.refresh = this.refresh.bind(this);
    this.loading = this.loading.bind(this);
    this.booksChanged = this.booksChanged.bind(this);
    this.bookUserClosed = this.bookUserClosed.bind(this);
    this.onToolbarActionClicked = this.onToolbarActionClicked.bind(this);
    this.onLibrarySortSelected = this.onLibrarySortSelected.bind(this);
    this.onLibraryTileActionClicked = this.onLibraryTileActionClicked.bind(this);
    this.onProductTileActionClicked = this.onProductTileActionClicked.bind(this);
    this.onAvailableTitleActionClicked = this.onAvailableTitleActionClicked.bind(this);
    this.moreInfoDrawerCloseClicked = this.moreInfoDrawerCloseClicked.bind(this);
    this.bookAccessError = this.bookAccessError.bind(this);
    this.bookOpenError = this.bookOpenError.bind(this);
    this.onMigrationPrompted = this.onMigrationPrompted.bind(this);
    this.externalLinkDownload = this.externalLinkDownload.bind(this);
    this.externalLinkError = this.externalLinkError.bind(this);
    this.onSearchQueryChanged = this.onSearchQueryChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onTileSelectionChanged = this.onTileSelectionChanged.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.onAvailableTitlesChanged = this.onAvailableTitlesChanged.bind(this);
    this.onLibraryPanelRefreshRequested = this.onLibraryPanelRefreshRequested.bind(this);
    this.bookOpenRequest = this.bookOpenRequest.bind(this);
    this.onLibraryViewModeSelected = this.onLibraryViewModeSelected.bind(this);
    this.state = {
      login: LoginType.None,
      loading: false,
      cells: [],
      moreInfoOpen: false,
      currentMoreInfo: null,
      loginInProgress: false,
      currentSort: LibrarySorting.LastViewed,
      isReversed: false,
      isMigrating: false,
      openingInProgess: [],
      searchMode: false,
      currentQuery: "",
      currentFilter: "",
      selectedVersions: [],
      availableTitlesSection: SectionState.Expanded,
      myTitlesSection: SectionState.Expanded,
      hasAvailableTitles: false,
      filterEnabled: false,
      initialMoreInfoTab: "details",
      currentToken: "",
      showTokenNote: true,
      libraryViewMode: LibraryViewMode.Grid,
      showTitleUploadModal: false,
      showSubscriptionModal: false,
    };
  }

  componentDidMount() {
    this.context.appLoading.stepLoaded.on(AppLoadingSteps.data, this.registerLibrary);
    this.context.libraryLoading.stepLoading.on("login", () => this.loginProgress(true));
    this.context.libraryLoading.stepLoaded.on("login", () => this.loginProgress(false));
    this.context.bookVersionAccessLost.on(this.booksChanged);
    this.context.bookVersionUserClosed.on(this.bookUserClosed);
    this.context.bookVersionClosedByFailure.on(this.bookOpenError);
    this.context.bookVersionAccessError.on(this.bookAccessError);
    this.context.migrationAvailable.on(this.onMigrationPrompted);
    this.context.externalLinkingDownloadRequired.on(this.externalLinkDownload);
    this.context.externalLinkingParseFailed.on(this.externalLinkError);
    this.context.libraryPanelRefreshRequested.on(() => void this.onLibraryPanelRefreshRequested());

    this.setState(
      {
        currentSort: this.context.appSettings.get().LibrarySorting,
        isReversed: this.context.appSettings.get().LibrarySortingReversed,
        libraryViewMode: this.context.appSettings.get().LibraryViewMode,
        availableTitlesSection: SectionState.Collapsed,
        myTitlesSection: this.context.appSettings.get().MyTitlesSectionExpansion,
      },
      () => (currentViewState = this.state.libraryViewMode)
    );

    this.companionUrlHandler = new Resource(Wire.shield(this.context.wire), -1);
    this.context.externalLinkRequiresCompanion.on(
      (url) =>
        void (async () => {
          await this.companionUrlHandler.openExternalWebsite(url);
        })()
    );
    this.context.bookOpenRequested.on((v) => void this.bookOpenRequest(v));
  }

  componentWillUnmount() {
    this.context.loginTypeNotifier.removeListener(this.update);
    this.context.libraryCells.removeListener(this.update);
    this.context.libraryLoading.stepLoaded.off("login");
    this.context.migrationAvailable.off(this.onMigrationPrompted);
    this.context.bookVersionAccessLost.off(this.booksChanged);
    this.context.bookVersionUserClosed.off(this.bookUserClosed);
    this.context.bookVersionAccessError.off(this.bookAccessError);
    this.context.bookVersionClosedByFailure.off(this.bookOpenError);
    this.context.externalLinkingDownloadRequired.off(this.externalLinkDownload);
    this.context.externalLinkingParseFailed.off(this.externalLinkError);
    this.context.externalLinkRequiresCompanion.off(
      (url) =>
        void (async () => {
          await this.companionUrlHandler.openExternalWebsite(url);
        })()
    );
    this.context.libraryPanelRefreshRequested.off(() => void this.onLibraryPanelRefreshRequested());
    this.context.libraryPanelRefreshRequested.off(() => void this.onLibraryPanelRefreshRequested());
    this.setState({ cells: [] });
  }
  private async bookOpenRequest(version: number) {
    let cellId = this.context.getCellId(version);
    if (cellId !== null) {
      await this.context.changeVersion(cellId, version);
      await this.onLibraryTileActionClicked(cellId, LibraryTileActions.launch, 0);
    }
  }

  private async onLibraryPanelRefreshRequested() {
    await this.refresh();
  }

  onProductTileActionClicked(value: LibraryProductCell, action: LibraryTileActions) {
    switch (action) {
      case LibraryTileActions.info:
        if (value) {
          this.setState({
            currentMoreInfo: value,
            moreInfoOpen: true,
            initialMoreInfoTab: "details",
          });
        } else {
          this.setState({
            currentMoreInfo: null,
            moreInfoOpen: false,
            initialMoreInfoTab: "details",
          });
        }
        break;
      case LibraryTileActions.purchase:
        if (value) {
          this.setState({
            currentMoreInfo: value,
            moreInfoOpen: true,
            initialMoreInfoTab: "plans",
          });
        } else {
          this.setState({
            currentMoreInfo: null,
            moreInfoOpen: false,
            initialMoreInfoTab: "details",
          });
        }
        break;
    }
  }

  // App level external link errors handled in the proper view for their context.
  externalLinkError(externalLink: ExternalLinkingPayload) {
    switch (externalLink.ParseError) {
      case ExternalLinkingError.INVALID_TITLE:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_INVALID_TITLE);
        break;
      case ExternalLinkingError.INVALID_VERSION:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_INVALID_VERSION);
        break;
      case ExternalLinkingError.NOT_LOGGED_IN:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_NOT_LOGGED_IN);
        break;
      case ExternalLinkingError.UNKNOWN_TITLE:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_UNKNOWN_TITLE);
        break;
      case ExternalLinkingError.UNKNOWN_VERSION:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_UNKNOWN_VERSION);
        break;
    }
  }

  externalLinkDownload(eventArg: { payload: ExternalLinkingPayload; version: LibraryVersion }) {
    Messages.Dialog.confirm(
      Convert.formatString(this.context.localization.currentLocale.Application.EXTERNAL_LINK_TITLE_NOT_DOWNLOADED, [
        eventArg.version.VersionInfo.Title,
      ]) as string,
      this.context.localization.currentLocale.Application.EXTERNAL_LINK_TITLE_NOT_DOWNLOADED_TITLE,
      Messages.Dialog.Buttons.YesNo
    )
      .then(async (res) => {
        if (res === "true") {
          this.context.pendingExternalLinkOpens.set(eventArg.version.VersionId, eventArg.payload);
          await this.context.downloadById(eventArg.version.VersionId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onMigrationPrompted(set: IMigrationSet) {
    let versionTo = this.context.getVersionInfo(set.VersionTo)!;
    let versionFrom = this.context.getVersionInfo(set.VersionFrom)!;
    Messages.Dialog.confirm(
      Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_MIGRATIONAVAILABLE, [versionFrom.Title, versionTo.Title]) as string,
      this.context.localization.currentLocale.Migration.ALERT_GENERIC_TITLE
    )
      .then(async (res) => {
        if (res === "true") {
          this.setState(
            { loading: true, isMigrating: true, currentMoreInfo: null, moreInfoOpen: false },
            () =>
              void (async () => {
                // Always hold the migration set. It need to be sent in every communication
                let result = await this.context.executeMigration({ MigrationSet: set });
                if (!result.valid()) {
                  Messages.Notify.error(this.context.localization.currentLocale.Migration.ALERT_MIGRATIONERROR_LABEL);
                  this.setState({ loading: false, isMigrating: false });
                  return;
                }
                // If the migration resolved automatically, attempt to open the book.
                if (result.data.AutomaticallyResolved) {
                  await this.context.openBookByID(set.VersionTo, false);
                  Messages.Notify.success(this.context.localization.currentLocale.Migration.ALERT_NO_CONFLICTS);
                } else {
                  this.context.addMigration(result.data, set);
                }
                this.setState({ loading: false, isMigrating: false });
              })()
          );
        } else {
          // Assert open the book as the user doesn't want to migrate now.
          await this.context.openBookByID(set.VersionTo, true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  booksChanged(value: AccessResult) {
    if (value) {
      this.bookAccessError([1, value]);
    }
  }
  bookUserClosed(value: Book) {
    if (value) {
      Messages.Notify.success(Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_BOOKCLOSED, [value.name]) as string);
    }
  }

  loginProgress(inProgress: boolean) {
    this.setState({
      loginInProgress: inProgress,
    });
  }
  bookOpenError() {
    Messages.Notify.error(
      Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_PREFIX, [
        this.context.localization.currentLocale.LibraryView.LABEL_DATA_ERROR,
      ]) as string
    );
  }
  bookAccessError(items: [number, AccessResult]) {
    let err = "";
    switch (items[1]) {
      case AccessResult.AlreadyLoaded:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_ALREADYLOADED;
        break;
      case AccessResult.Bumped:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_BUMPED;
        break;
      case AccessResult.MigrationAvailable:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_MIGRATIONAVAILABLE;
        break;
      case AccessResult.NoAccess:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_NOACCESS;
        break;
      case AccessResult.NotDownloaded:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_NOTDOWNLOADED;
        break;
      case AccessResult.OfflineOpensExceeded:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_OFFLINEOPENSEXCEEDED;
        break;
      case AccessResult.OfflineUsageTimeExceeded:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_OFFLINETIMEEXCEEDED;
        break;
      case AccessResult.UpdateAvailable:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_UPDATEAVAILABLE;
        break;
      case AccessResult.AlreadyMigrating:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_ALREADYMIGRATING;
        break;
      case AccessResult.ConcurrencyAccessRevoked:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_CONCURRENCYACCESSREVOKED;
        break;
      case AccessResult.IpAccessRevoked:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_IPACCESSREVOKED;
        break;
      case AccessResult.ConditionalAccessFailure:
        err = this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_CONDITIONALACCESSFAILURE;
        break;
    }

    Messages.Notify.error(Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_ERRORACCESS_PREFIX, [err]) as string);
  }

  registerLibrary() {
    this.setState({
      login: this.context.loginType,
    });
    this.context.loginTypeNotifier.addListener(this.update);
    this.context.libraryCells.addListener(this.update);
    this.update();
  }

  update(sender?: any, event?: EmitterEvent) {
    // let newVal = this.state.hasAvailableTitles;
    let moreinfo = this.state.currentMoreInfo;
    let moreinfoState = this.state.moreInfoOpen;
    if (this.context.loginType === LoginType.None || event === EmitterEvent.deleteAll) {
      // newVal = false;
      moreinfo = null;
      moreinfoState = false;
    }
    if (event !== EmitterEvent.deleteAll) {
      this.updateSort();
    } else {
      this.setState({ cells: [] });
    }

    this.setState(
      {
        login: this.context.loginType,
        // hasAvailableTitles: newVal,
        currentMoreInfo: moreinfo,
        moreInfoOpen: moreinfoState,
      },
      () => {
        this.loading(false);
      }
    );
  }
  updateSort(sorting?: LibrarySorting, reversed?: boolean, currentViewMode?: LibraryViewMode) {
    let cells = this.context.libraryCells.rows();
    if (typeof sorting === "undefined") {
      sorting = this.state.currentSort;
    }
    if (typeof reversed === "undefined") {
      reversed = this.state.isReversed;
    }
    if (typeof currentViewMode === "undefined") {
      currentViewMode = this.state.libraryViewMode;
    }

    // Filter owned titles.
    cells = this.filterCells(cells);

    // Owned titles
    cells = this.concreteSort(sorting, reversed, cells);

    if (this.state.currentSort !== sorting || this.state.isReversed !== reversed || this.state.libraryViewMode !== currentViewMode) {
      this.setState({ cells: cells, currentSort: sorting, isReversed: reversed, libraryViewMode: currentViewMode }, () => {
        // Persist the change
        this.context.appSettings.set({
          ...this.context.appSettings.get(),
          LibrarySorting: sorting!,
          LibrarySortingReversed: reversed!,
          LibraryViewMode: currentViewMode!,
        });
      });
    } else {
      this.setState({ cells: cells });
    }
  }
  filterCells(cells: LibraryCell[]): LibraryCell[] {
    if (Convert.isEmptyOrSpaces(this.state.currentFilter)) {
      return cells;
    }
    let newCells = [];

    for (let i = 0; i < cells.length; i++) {
      let currInfo = cells[i].CurrentVersion.VersionInfo;
      let compString = this.state.currentFilter.toLowerCase();
      if (
        (currInfo.Contributor && currInfo.Contributor.toLowerCase().includes(compString)) ||
        (currInfo.Coverage && currInfo.Coverage.toLowerCase().includes(compString)) ||
        (currInfo.Creator && currInfo.Creator.toLowerCase().includes(compString)) ||
        (currInfo.Date && Convert.dateToFormattedString(currInfo.Date, this.context.localization.currentCulture).toLowerCase().includes(compString)) ||
        (currInfo.Description && currInfo.Description.toLowerCase().includes(compString)) ||
        (currInfo.Extent && currInfo.Extent.toLowerCase().includes(compString)) ||
        (currInfo.Format && currInfo.Format.toLowerCase().includes(compString)) ||
        (currInfo.Identifier && currInfo.Identifier.toLowerCase().includes(compString)) ||
        (currInfo.Language && currInfo.Language.toLowerCase().includes(compString)) ||
        (currInfo.Publisher && currInfo.Publisher.toLowerCase().includes(compString)) ||
        (currInfo.Rights && currInfo.Rights.toLowerCase().includes(compString)) ||
        (currInfo.Source && currInfo.Source.toLowerCase().includes(compString)) ||
        (currInfo.Subject && currInfo.Subject.toLowerCase().includes(compString)) ||
        (currInfo.Title && currInfo.Title.toLowerCase().includes(compString)) ||
        (currInfo.Type && currInfo.Type.toLowerCase().includes(compString))
      ) {
        newCells.push(cells[i]);
      }
    }
    return newCells;
  }

  concreteSort(sorting: LibrarySorting, reversed: boolean, cells: LibraryCell[]): LibraryCell[] {
    if (sorting === LibrarySorting.LastViewed) {
      cells = cells.sort((x1, x2) => {
        let maxDateX1 = new Date(Math.max(...x1.OtherVersions.map((x) => new Date(Convert.dateToFormattedString(x.LastViewed, Languages.English)).getTime())));
        let maxDateX2 = new Date(Math.max(...x2.OtherVersions.map((x) => new Date(Convert.dateToFormattedString(x.LastViewed, Languages.English)).getTime())));

        if (maxDateX1 > maxDateX2) {
          return -1;
        }
        if (maxDateX1 < maxDateX2) {
          return 1;
        }
        return 0;
      });
    } else if (sorting === LibrarySorting.Title) {
      cells = cells.sort((x1, x2) => {
        if (x1.CurrentVersion.VersionInfo.Title.toLowerCase() > x2.CurrentVersion.VersionInfo.Title.toLowerCase()) {
          return 1;
        }
        if (x1.CurrentVersion.VersionInfo.Title.toLowerCase() < x2.CurrentVersion.VersionInfo.Title.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sorting === LibrarySorting.Publisher) {
      cells = cells.sort((x1, x2) => {
        if (x1.CurrentVersion.VersionInfo.Publisher === null) {
          x1.CurrentVersion.VersionInfo.Publisher = "";
        }
        if (x2.CurrentVersion.VersionInfo.Publisher === null) {
          x2.CurrentVersion.VersionInfo.Publisher = "";
        }

        if (x1.CurrentVersion.VersionInfo.Publisher.toLowerCase() > x2.CurrentVersion.VersionInfo.Publisher.toLowerCase()) {
          return 1;
        }
        if (x1.CurrentVersion.VersionInfo.Publisher.toLowerCase() < x2.CurrentVersion.VersionInfo.Publisher.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      cells = cells.sort((x1, x2) => {
        if (x1.CurrentVersion.VersionInfo.Title.toLowerCase() > x2.CurrentVersion.VersionInfo.Title.toLowerCase()) {
          return 1;
        }
        if (x1.CurrentVersion.VersionInfo.Title.toLowerCase() < x2.CurrentVersion.VersionInfo.Title.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sorting === LibrarySorting.Downloads) {
      cells = cells.sort((x1, x2) => {
        let maxDateX1 = new Date(Math.max(...x1.OtherVersions.map((x) => new Date(Convert.dateToFormattedString(x.LastViewed, Languages.English)).getTime())));
        let maxDateX2 = new Date(Math.max(...x2.OtherVersions.map((x) => new Date(Convert.dateToFormattedString(x.LastViewed, Languages.English)).getTime())));

        if (maxDateX1 > maxDateX2) {
          return -1;
        }
        if (maxDateX1 < maxDateX2) {
          return 1;
        }
        return 0;
      });
      cells = cells.sort((x1, x2) => {
        if (x1.CurrentVersion.IsDownloaded && !x2.CurrentVersion.IsDownloaded) {
          return -1;
        }
        if (!x1.CurrentVersion.IsDownloaded && x2.CurrentVersion.IsDownloaded) {
          return 1;
        }
        if (x1.CurrentVersion.IsDownloading && !x2.CurrentVersion.IsDownloaded && !x2.CurrentVersion.IsDownloading) {
          return -1;
        }
        if (!x1.CurrentVersion.IsDownloading && !x1.CurrentVersion.IsDownloaded && x2.CurrentVersion.IsDownloading) {
          return 1;
        }
        if ((!x1.CurrentVersion.IsDownloaded && !x2.CurrentVersion.IsDownloaded) || (x1.CurrentVersion.IsDownloaded && x2.CurrentVersion.IsDownloaded)) {
          return 0;
        }
        return 0;
      });
    }
    if (reversed) {
      cells = cells.reverse();
    }
    cells = cells.sort((x1, x2) => {
      if (x1.IsFavourite && !x2.IsFavourite) {
        return -1;
      }
      if (!x1.IsFavourite && x2.IsFavourite) {
        return 1;
      }
      return 0;
    });
    return cells;
  }

  componentDidUpdate() {
    if (this.state.login !== this.context.loginType) {
      this.setState({ login: this.context.loginType });
    }
    this.defaultMessage = this.context.localization.currentLocale.LibraryView.ALERT_REFRESHING_LIBRARY;
    this.migratingMessage = this.context.localization.currentLocale.LibraryView.LABEL_LOADINGMIGRATION;
    if (this.context.appLoading.isLoaded(AppLoadingSteps.data)) {
      this.context.setLibraryLoaded();
    }
  }

  async refresh() {
    this.loading(true);
    await this.context.updateLibrary();
    if (this.flowRef.current) {
      this.flowRef.current.reload();
    }
    if (this.prodFlowRef.current) {
      this.prodFlowRef.current.reload();
    }
    this.setState({ showSubscriptionModal: true });
  }

  async onToolbarActionClicked(action: LibrayToolbarActions): Promise<void> {
    switch (action) {
      case LibrayToolbarActions.refresh:
        await this.refresh();
        break;
      case LibrayToolbarActions.multiSearch: {
        let isSearchMode = !this.state.searchMode;
        if (isSearchMode) {
          Messages.Notify.success(this.context.localization.currentLocale.SearchView.LABEL_SEARCH_MODE_ON);
          let FirstDownloaded = this.state.cells!.filter((cell) => {
            if (cell.CurrentVersion.IsDownloaded) {
              return true;
            }
            return false;
          })[0];
          if (FirstDownloaded) {
            this.onTileSelectionChanged(FirstDownloaded.CurrentVersion.VersionId, true);
          }
        } else {
          await this.context.cancelMultiSearch({});
          this.context.cancelSearch.dispatch("", this);
          this.context.mutliSearchLastQuery = "";
          this.context.multiSearchSelectedVersions = [];
        }
        this.setState({ searchMode: isSearchMode, currentQuery: "" });
        break;
      }
      case LibrayToolbarActions.multiSearchExecute:
        this.context.clearSearch.dispatch("", this);
        if (this.state.selectedVersions.length === 0) {
          Messages.Notify.error(this.context.localization.currentLocale.SearchView.LABEL_SEARCH_MULTI_NOTITLES);
          return;
        }
        if (Convert.isEmptyOrSpaces(this.state.currentQuery)) {
          Messages.Notify.error(this.context.localization.currentLocale.SearchView.LABEL_SEARCH_MULTI_NOQUERY);
          return;
        }
        await this.context.multiSearch({ SearchQuery: this.state.currentQuery, SelectedVersions: this.state.selectedVersions });
        break;
      case LibrayToolbarActions.filteringChanged:
        this.setState({ filterEnabled: !this.state.filterEnabled });
        if (this.state.filterEnabled) {
          this.setState({ currentFilter: "" }, () => this.update());
        }
        break;

      case LibrayToolbarActions.uploadTitle:
        if (this.context.config.UserTitlesEnabled === true) {
          this.setState({ showTitleUploadModal: !this.state.showTitleUploadModal });
        }
        break;
    }
  }

  onLibrarySortSelected(sort: LibrarySorting, reversed: boolean) {
    this.updateSort(sort, reversed);
    if (this.flowRef.current) {
      this.flowRef.current.reload();
    }
    if (this.prodFlowRef.current) {
      this.prodFlowRef.current.reload();
    }
  }

  onLibraryViewModeSelected(libraryViewMode: LibraryViewMode) {
    this.updateSort(undefined, undefined, libraryViewMode);
    if (this.flowRef.current) {
      this.flowRef.current.reload();
    }
    if (this.prodFlowRef.current) {
      this.prodFlowRef.current.reload();
    }
  }

  onSearchQueryChanged(newQuery: string) {
    this.setState({ currentQuery: newQuery });
  }

  onFilterChanged(newQuery: string) {
    this.setState({ currentFilter: newQuery }, () => {
      this.update();
    });
  }

  async onLibraryTileActionClicked(id: number, action: LibraryTileActions, currentHits: number) {
    switch (action) {
      case LibraryTileActions.download:
        await this.context.download(id);
        break;
      case LibraryTileActions.launch:
        if (this.state.openingInProgess.indexOf(id) === -1) {
          let arr = this.state.openingInProgess;
          arr.push(id);
          this.setState(
            { openingInProgess: arr },
            () =>
              void (async () => {
                await this.context.openBook(id, false, currentHits);
                let arrInner = this.state.openingInProgess;
                arrInner.splice(this.state.openingInProgess.indexOf(id), 1);
                this.setState({ openingInProgess: arrInner });
              })()
          );
        }
        break;
      case LibraryTileActions.pause:
        await this.context.pauseDownload(id);
        break;
      case LibraryTileActions.delete:
        await this.context.delete(id);
        break;
      case LibraryTileActions.info:
        if (this.context.libraryCells.exists(id)) {
          this.setState({
            currentMoreInfo: this.context.libraryCells.get(id)!,
            moreInfoOpen: true,
          });
        } else {
          this.setState({
            currentMoreInfo: null,
            moreInfoOpen: false,
          });
        }
        break;
      case LibraryTileActions.removeUserTitle:
        if (
          (await Messages.Dialog.confirm(
            this.context.localization.currentLocale.UserBookUploads.LABEL_REMOVE_BOOK_MESSAGE,
            this.context.localization.currentLocale.UserBookUploads.LABEL_REMOVE_BOOK_TITLE,
            Messages.Dialog.Buttons.YesNo
          )) === "true"
        ) {
          await this.context.closeBook({ VersionId: id, RemoveVersion: true });
          let result = await this.context.DeleteUserUploadedBook({ VersionId: id });
          if (result.valid()) {
            Messages.Notify.success(this.context.localization.currentLocale.UserBookUploads.LABEL_BOOK_REMOVED);
            this.setState(
              {
                currentMoreInfo: null,
                moreInfoOpen: false,
              },
              ()=> void this.refresh()
            );
          } else {
            Messages.Notify.error(this.context.localization.currentLocale.UserBookUploads.ERROR_BOOK_REMOVAL_FAILED + result.errors[0].Message);
          }
        }
        break;
    }
  }
  onAvailableTitleActionClicked(cell: LibraryCell, action: LibraryTileActions): void {
    switch (action) {
      case LibraryTileActions.info:
        if (cell) {
          this.setState({
            currentMoreInfo: cell,
            moreInfoOpen: true,
          });
        } else {
          this.setState({
            currentMoreInfo: null,
            moreInfoOpen: false,
          });
        }
        break;
    }
  }

  private moreInfoDrawerCloseClicked() {
    this.setState(
      {
        moreInfoOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({ currentMoreInfo: null });
        }, 500);
      }
    );
  }

  loading(isLoading: boolean) {
    if (this.state.loading !== isLoading) {
      this.setState({
        loading: isLoading,
      });
    }
  }
  onTileSelectionChanged(versionId: number, newVal: boolean) {
    let currentArr = this.state.selectedVersions;
    if (newVal && currentArr.indexOf(versionId) === -1) {
      currentArr.push(versionId);
    } else if (!newVal && currentArr.indexOf(versionId) > -1) {
      currentArr.splice(currentArr.indexOf(versionId), 1);
    }
    this.setState({ selectedVersions: currentArr });
  }

  shouldComponentUpdate(_nextProps: any, nextState: any): boolean {
    if (this.state.loginInProgress || nextState.loginInProgress) {
      return !nextState.loginInProgress;
    }

    return true;
  }

  toggleSection(section: Section) {
    let newState = SectionState.Expanded;
    switch (section) {
      case Section.AvailableTitles:
        if (this.state.availableTitlesSection === SectionState.Expanded) {
          newState = SectionState.Collapsed;
        }
        this.context.appSettings.set({ ...this.context.appSettings.get(), AvailableTitlesSectionExpansion: newState });
        this.setState({ availableTitlesSection: newState });
        break;
      case Section.OwnedTitles:
        if (this.state.myTitlesSection === SectionState.Expanded) {
          newState = SectionState.Collapsed;
        }
        this.context.appSettings.set({ ...this.context.appSettings.get(), MyTitlesSectionExpansion: newState });
        this.setState({ myTitlesSection: newState });
        break;
    }
  }

  onAvailableTitlesChanged(newValue: boolean) {
    this.setState({ hasAvailableTitles: newValue });
  }

  render() {
    let emptyLib = this.context.config.LibraryInternationalizations[this.context.localization.currentCulture - 1].LibraryNoTitlesMessage;
    let tokenNote = null;

    let sectionExpand = " noSections";
    if (this.state.availableTitlesSection === SectionState.Expanded && this.state.myTitlesSection === SectionState.Expanded) {
      sectionExpand = " bothSections";
    } else if (this.state.myTitlesSection === SectionState.Expanded) {
      sectionExpand = " firstSection";
    } else if (this.state.availableTitlesSection === SectionState.Expanded) {
      sectionExpand = " secondSection";
    }
    // Search mode will layout with only owned titles. Can't multi search non-downloaded titles.
    if (this.state.searchMode) {
      sectionExpand = " firstSection";
    }

    if (
      !Convert.isEmptyOrSpaces(this.context.externalLinkingCurrentToken) &&
      this.state.myTitlesSection === SectionState.Expanded &&
      this.state.showTokenNote
    ) {
      tokenNote = (
        <div className="tokenNote">
          <div className="book-tab-close tokenCloseIcon" onClick={() => this.setState({ showTokenNote: false })}>
            <Image.close />
          </div>
          <span className="tokenLabel">
            {Convert.isEmptyOrSpaces(this.context.externalLinkingCurrentTokenName)
              ? this.context.localization.currentLocale.LibraryView.LABEL_TOKEN_SET_NO_NAME
              : Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_TOKEN_SET_NAMED, [this.context.externalLinkingCurrentTokenName])}
          </span>
          <span className="tokenIcon">
            <Icon src={<Image.info />} />
          </span>
        </div>
      );
    }

    let hasTitles = "";
    let noTitles = "";
    let compressed: string = currentViewState === LibraryViewMode.Grid ? " wrap-grid" : " wrap-grid-compressed";
    if (!this.state.hasAvailableTitles || this.state.searchMode) {
      hasTitles = " hide";
      noTitles = " noTitles";
    }

    if (!emptyLib || emptyLib.length === 0) {
      emptyLib = this.context.localization.currentLocale.LibraryView.LABEL_NOTITLES_MESSAGE;
    }
    return (
      <LibraryErrorBoundary>
        <Loading
          className="flex-fill d-flex flex-column"
          isLoading={this.state.loading}
          status={this.state.isMigrating ? this.migratingMessage : this.defaultMessage}
        >
          {this.state.login > LoginType.None && (
            <LibraryToolbar
              onActionClicked={(a) => void this.onToolbarActionClicked(a)}
              onSortSelected={this.onLibrarySortSelected}
              localization={this.context.localization}
              helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
              searchModeEnabled={this.state.searchMode}
              onQueryChanged={this.onSearchQueryChanged}
              onFilterChanged={this.onFilterChanged}
              filterEnabled={this.state.filterEnabled}
              initialSort={this.state.currentSort}
              initialReversed={this.state.isReversed}
              libraryViewMode={this.state.libraryViewMode}
              onLibraryViewModeSelected={this.onLibraryViewModeSelected}
              userTitlesEnabled={
                this.context.config.UserTitlesEnabled /* && !this.context.userUploadedTitles.rows().some(x=>x.UploadState !== UserUploadedTitleState.Complete)*/
              }
              userSubscriptionPlan={this.context.userPaidSubscriptionPlan}
            />
          )}
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column">
            <Drawer isOpen={this.state.moreInfoOpen} backdrop={false} className="details-view">
              {this.state.currentMoreInfo !== null && this.state.currentMoreInfo.CellType === LibraryCellType.OwnedTitle && (
                <MoreInfoView
                  key={(this.state.currentMoreInfo as LibraryCell).TitleId}
                  model={this.context.libraryCells.bind(this.state.currentMoreInfo as LibraryCell) as Binding<LibraryCell | null>}
                  closeButton={<Button close onClick={this.moreInfoDrawerCloseClicked} className="pl-3 pb-1" />}
                  onActionClicked={(id, action, currentHits) => void this.onLibraryTileActionClicked(id, action, currentHits)}
                  defaultSplash={this.context.config.DefaultBookSplash}
                  userUploadedTitle={this.context.userUploadedTitles.get((this.state.currentMoreInfo as LibraryCell).TitleId) || null}
                />
              )}
              {this.state.currentMoreInfo !== null && this.state.currentMoreInfo.CellType === LibraryCellType.AvailableTitle && (
                <MoreInfoView
                  key={(this.state.currentMoreInfo as LibraryCell).TitleId}
                  model={new Binding<LibraryCell>(this.state.currentMoreInfo as LibraryCell) as Binding<LibraryCell | null>}
                  closeButton={<Button close onClick={this.moreInfoDrawerCloseClicked} className="pl-3 pb-1" />}
                  onActionClicked={(id, action, currentHits) => void this.onLibraryTileActionClicked(id, action, currentHits)}
                  defaultSplash={this.context.config.DefaultBookSplash}
                  userUploadedTitle={this.context.userUploadedTitles.get((this.state.currentMoreInfo as LibraryCell).TitleId) || null}
                />
              )}
              {this.state.currentMoreInfo !== null && this.state.currentMoreInfo.CellType === LibraryCellType.Product && (
                <ProductInfoView
                  closeButton={<Button close onClick={this.moreInfoDrawerCloseClicked} className="pl-3 pb-1" />}
                  defaultSplash={this.context.config.DefaultBookSplash}
                  product={this.state.currentMoreInfo as LibraryProductCell}
                  activeTab={this.state.initialMoreInfoTab}
                />
              )}
            </Drawer>
            <StaticModal visible={this.state.login === LoginType.None} centered={true} backdrop={true}>
              <ModalBody className="p-0">
                <LoginView
                  forgotPasswordLink={this.context.config.LibraryInternationalizations[this.context.localization.currentCulture - 1].ResetPasswordUrl}
                  registrationLink={this.context.config.LibraryInternationalizations[this.context.localization.currentCulture - 1].RegistrationUrl}
                />
              </ModalBody>
            </StaticModal>
            <div className={"library-view-container"}>
              {this.state.showTitleUploadModal === true && (
                <TitleUploadModal
                  refreshLibrary={() => void this.onToolbarActionClicked(LibrayToolbarActions.refresh)}
                  dismissModal={() => this.setState({ showTitleUploadModal: false })}
                  showSubscriptionModal={() => this.setState({ showSubscriptionModal: true })}
                />
              )}
              {this.state.showSubscriptionModal === true && !this.context.userPaidSubscriptionPlan && this.context.config.UserTitlesEnabled && (
                <SubscriptionModal
                  refreshLibrary={() => void this.onToolbarActionClicked(LibrayToolbarActions.refresh)}
                  dismissModal={() => this.setState({ showSubscriptionModal: false })}
                />
              )}
              {this.state.login > LoginType.None && this.state.cells && this.state.cells.length > 0 && (
                <div className={"library-view-owned-titles" + sectionExpand + noTitles}>
                  {tokenNote}
                  <div
                    className={"library-section-header"}
                    onClick={() => {
                      this.toggleSection(Section.OwnedTitles);
                    }}
                  >
                    {this.state.myTitlesSection === SectionState.Expanded ? (
                      <span>
                        <Icon src={<Image.TOCminus />} />
                      </span>
                    ) : (
                      <span>
                        <Icon src={<Image.TOCplus />} />
                      </span>
                    )}
                    <span className={"library-section-text"}>{this.context.localization.currentLocale.LibraryView.LABEL_YOUR_TITLES}</span>
                  </div>
                  <div className="cell-container scrollable">
                    <Expander isOpen={this.state.myTitlesSection === SectionState.Expanded}>
                      {this.state.cells !== null && this.state.cells.length > 0 && (
                        <div className={"p-2" + compressed}>
                          {this.state.cells.map((cell) => (
                            <LibraryTile
                              key={cell.CurrentVersion.VersionId}
                              model={this.context.libraryCells.bind(cell)}
                              onActionClicked={(id: number, action: LibraryTileActions, currentHits: number) =>
                                void this.onLibraryTileActionClicked(id, action, currentHits)
                              }
                              onTileSelectionChanged={this.onTileSelectionChanged}
                              taskUpdate={this.context.onLibraryTaskUpdated.handler(cell.CurrentVersion.VersionId)}
                              downloadFailed={this.context.bookDownloadFailed}
                              localization={this.context.localization}
                              defaultSplash={this.context.config.DefaultBookSplash}
                              searchModeActive={this.state.searchMode}
                              hitHandler={this.context.multiSearchMessageReceived.handler(cell.CurrentVersion.VersionId)}
                              multiSearchExecuted={this.context.multiSearchExecuted.handler(cell.CurrentVersion.VersionId)}
                              clearSearch={this.context.clearSearch}
                              cancelSearch={this.context.cancelSearch}
                              isSelected={this.state.selectedVersions.indexOf(cell.CurrentVersion.VersionId) > -1}
                              canManipulateContent={this.context.canManipulateContent}
                              isFavourite={cell.IsFavourite}
                            />
                          ))}
                        </div>
                      )}
                    </Expander>
                  </div>
                </div>
              )}
              {this.state.login > LoginType.None && this.state.cells !== null && this.state.cells.length === 0 && <h3 className="emptyLib">{emptyLib}</h3>}
              {this.state.login > LoginType.None && (
                <div className={"library-view-available-titles" + hasTitles + sectionExpand}>
                  <div
                    className="library-section-header"
                    onClick={() => {
                      this.toggleSection(Section.AvailableTitles);
                    }}
                  >
                    {this.state.availableTitlesSection === SectionState.Expanded ? (
                      <span>
                        <Icon src={<Image.TOCminus />} />
                      </span>
                    ) : (
                      <span>
                        <Icon src={<Image.TOCplus />} />
                      </span>
                    )}
                    <span className={"library-section-text"}>
                      {this.context.config.LibraryInternationalizations.some(
                        (lci) => lci.Language === this.context.localization.currentCulture && lci.AvailableTitlesSectionTitle
                      )
                        ? this.context.config.LibraryInternationalizations.filter((lci) => lci.Language === this.context.localization.currentCulture)[0]
                            .AvailableTitlesSectionTitle
                        : this.context.localization.currentLocale.LibraryView.LABEL_AVAILABLE_TITLES}
                    </span>
                  </div>
                  <div className="cell-container">
                    <ProductsFlow
                      shouldRender={this.context.config.IsIAPEnabled}
                      ref={this.prodFlowRef}
                      currentSort={this.state.currentSort}
                      isReversed={this.state.isReversed}
                      onProductInfoClicked={this.onProductTileActionClicked}
                      searchMode={this.state.searchMode}
                      onTitlesAvailableChange={this.onAvailableTitlesChanged}
                    />
                    <AvailableTitlesFlow
                      shouldRender={!this.context.config.IsIAPEnabled}
                      ref={this.flowRef}
                      currentSort={this.state.currentSort}
                      isReversed={this.state.isReversed}
                      onAvailableTitleInfoClicked={this.onAvailableTitleActionClicked}
                      onTileSelectionChanged={this.onTileSelectionChanged}
                      searchMode={this.state.searchMode}
                      onTitlesAvailableChange={this.onAvailableTitlesChanged}
                    />
                  </div>
                </div>
              )}
            </div>
          </DrawerContainer>
        </Loading>
      </LibraryErrorBoundary>
    );
  }
}

interface IAvailableTitlesProps {
  onAvailableTitleInfoClicked: (value: LibraryCell, action: LibraryTileActions) => void;
  onTileSelectionChanged: (versionId: number, newVal: boolean) => void;
  onTitlesAvailableChange: (newVal: boolean) => void;
  searchMode: boolean;
  currentSort: LibrarySorting;
  isReversed: boolean;
  shouldRender: boolean;
}

interface IAvailableTitlesState {
  isLoading: boolean;
}

export class AvailableTitlesFlow extends React.PureComponent<IAvailableTitlesProps, IAvailableTitlesState> {
  context: LibrarySession;
  loaded = false;
  static contextType = LibraryContext;
  cogniflow = React.createRef<StandaloneCogniflowContainer>();
  constructor(props: IAvailableTitlesProps | Readonly<IAvailableTitlesProps>) {
    super(props);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildLibraryCellItem = this.buildLibraryCellItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.onReady = this.onReady.bind(this);
    this.state = { isLoading: false };
  }

  onReady() {}

  componentDidMount() {}

  componentWillUnmount() {}

  public reload() {
    if (this.cogniflow.current) {
      this.setState({ isLoading: true }, () => {
        this.cogniflow.current!.reloadCogniflow();
      });
    }
  }

  private initializeFlow(): Promise<{ nodes: INode[]; targetSpine: number }> {
    let message = new AvailableTitlesCogniflowMessage();
    return this.context.fetchAvailableTitles(message).then((result) => {
      if (result.valid()) {
        let response: IResponse = Convert.cogniflowMessageToResponse(result.data);
        if (response.Batches.length > 0 && response.Batches[0].Nodes.length > 0) {
          this.props.onTitlesAvailableChange(true);
        }
        return {
          nodes: response.Batches.length > 0 ? response.Batches[0].Nodes : [],
          targetSpine: 0,
        };
      } else {
        throw new Error("Result is not valid");
      }
    });
  }

  private flowProvider(request: IRequest): Promise<IResponse> {
    let message = Convert.cogniflowRequestToMessage(request) as AvailableTitlesCogniflowMessage;
    message.CurrentSort = this.props.currentSort;
    message.IsReversed = this.props.isReversed;
    return this.context.fetchAvailableTitles(message).then((result) => {
      if (result.valid()) {
        return Convert.cogniflowMessageToResponse(result.data);
      } else {
        throw new Error("Result is not valid");
      }
    });
  }

  private buildLibraryCellItem(node: INode): JSX.Element {
    let cell = node as LibraryCell;
    return (
      <LibraryTile
        key={cell.CurrentVersion.VersionId}
        model={new Binding<LibraryCell>(cell)}
        onActionClicked={(id, action) => {
          this.props.onAvailableTitleInfoClicked(cell, action);
        }}
        onTileSelectionChanged={this.props.onTileSelectionChanged}
        taskUpdate={new EventHandler()}
        downloadFailed={this.context.bookDownloadFailed}
        localization={this.context.localization}
        defaultSplash={this.context.config.DefaultBookSplash}
        searchModeActive={this.props.searchMode}
        hitHandler={new EventHandler()}
        multiSearchExecuted={new EventHandler()}
        clearSearch={this.context.clearSearch}
        cancelSearch={this.context.cancelSearch}
        isSelected={false}
        isFavourite={cell.IsFavourite}
        canManipulateContent={this.context.canManipulateContent}
      />
    );
  }

  private navigationDone() {}
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-title-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-title-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    segmentContainerClasses: "wrap-grid p2",
    batchSize: 12,
  };
  render() {
    if (!this.props.shouldRender) {
      return "";
    }
    return (
      <StandaloneCogniflowContainer
        provider={this.flowProvider}
        builder={this.buildLibraryCellItem}
        initialize={this.initializeFlow}
        extraSettings={this.settings}
        ref={this.cogniflow}
        navigationDoneCallback={this.navigationDone}
      />
    );
  }
}
interface IProductsProps {
  onProductInfoClicked: (value: LibraryProductCell, action: LibraryTileActions) => void;
  onTitlesAvailableChange: (newVal: boolean) => void;
  searchMode: boolean;
  currentSort: LibrarySorting;
  isReversed: boolean;
  shouldRender: boolean;
}

interface IProductsState {
  isLoading: boolean;
}

export class ProductsFlow extends React.PureComponent<IProductsProps, IProductsState> {
  context: LibrarySession;
  loaded = false;
  static contextType = LibraryContext;
  cogniflow = React.createRef<StandaloneCogniflowContainer>();
  constructor(props: IProductsProps | Readonly<IProductsProps>) {
    super(props);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildProductCellItem = this.buildProductCellItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.onReady = this.onReady.bind(this);
    this.productsReceived = this.productsReceived.bind(this);
    this.state = { isLoading: false };
  }

  onReady() {}

  componentDidMount() {
    if (ResourcePlatform.IsAppleIAP()) {
      this.context.productsReceived.on((newNodes) => void this.productsReceived(newNodes)); // This will be triggered on Apple IAP when it's enabled.
    }
  }

  componentWillUnmount() {
    if (ResourcePlatform.IsAppleIAP()) {
      this.context.productsReceived.off((newNodes) => void this.productsReceived(newNodes)); // This will be triggered on Apple IAP when it's enabled.
    }
  }
  async productsReceived(newNodes: LibraryProductCell[]) {
    if (newNodes.length > 0) {
      await Log.info("Received " + newNodes.length + " product cells to the frontend");
      this.cogniflow.current!.replaceAllNodes(newNodes, 0);
      this.props.onTitlesAvailableChange(newNodes.length > 0);
    }
    this.setState({ isLoading: false });
  }
  componentDidUpdate() {}

  public reload() {
    if (this.cogniflow.current) {
      this.setState({ isLoading: true }, () => {
        this.cogniflow.current!.reloadCogniflow();
      });
    }
  }

  private async initializeFlow(): Promise<{ nodes: LibraryProductCell[]; targetSpine: number }> {
    return this.context
      .getFirstAvailableTitles({
        CurrentSort: this.props.currentSort,
        IsReversed: this.props.isReversed,
      })
      .then((result) => {
        if (result.valid()) {
          if (!ResourcePlatform.IsAppleIAP()) {
            this.props.onTitlesAvailableChange(result.data.Products.length > 0);
            this.setState({ isLoading: false });
          }
          return {
            nodes: result.data.Products,
            targetSpine: -1,
          };
        } else {
          throw new Error("Result is not valid");
        }
      });
  }

  private flowProvider(): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let response: IResponse = { Batches: [] };
      resolve(response);
    });
  }

  private buildProductCellItem(node: INode): JSX.Element {
    let cell = node as LibraryProductCell;
    return (
      <LibraryProductTile
        onProductInfoClicked={this.props.onProductInfoClicked}
        defaultImage={this.context.config.DefaultBookSplash}
        product={cell}
        localization={this.context.localization}
        localizationCulture={this.context.localization.currentCulture}
      />
    );
  }

  private navigationDone() {}
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-product-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-product-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    segmentContainerClasses: "wrap-grid p2",
    batchSize: 12,
  };
  render() {
    if (!this.props.shouldRender) {
      return "";
    }
    return (
      <Loading
        className={"full-height full-width"}
        isLoading={this.state.isLoading}
        status={this.context.localization.currentLocale.Store.LABLE_FETCHING_PRODUCTS}
      >
        <StandaloneCogniflowContainer
          provider={this.flowProvider}
          builder={this.buildProductCellItem}
          initialize={this.initializeFlow}
          extraSettings={this.settings}
          ref={this.cogniflow}
          navigationDoneCallback={this.navigationDone}
        />
      </Loading>
    );
  }
}
class LibraryErrorBoundaryProps {
  children?: React.ReactNode;
}
class LibraryErrorBoundaryState {
  errorCount: number;
}
class LibraryErrorBoundary extends React.Component<LibraryErrorBoundaryProps, LibraryErrorBoundaryState> {
  context: LibrarySession;
  static contextType = LibraryContext;

  constructor(props: LibraryErrorBoundaryProps | Readonly<LibraryErrorBoundaryProps>) {
    super(props);
    this.state = { errorCount: 0 };
  }

  static getDerivedStateFromError() {}

  async componentDidCatch(error: Error) {
    await Log.fatal("A library with brand key: " + this.context.appBrandKey + " encountered an error.  Error message: " + error.message.toString());
    if (error.stack) {
      await Log.fatal("Error stacktrace: " + error.stack.toString());
    } else {
      await Log.fatal("Error stacktrace: None provided");
    }
    await Log.fatal("Error name: " + error.name.toString());

    Messages.Notify.error(error.message);
    this.setState({ errorCount: this.state.errorCount + 1 });
  }

  render() {
    return <React.Fragment key={this.state.errorCount}>{this.props.children}</React.Fragment>;
  }
}

interface ILibraryToolbarProps {
  onActionClicked: (action: LibrayToolbarActions) => void;
  onSortSelected: (sort: LibrarySorting, reversed: boolean) => void;
  onQueryChanged: (newQuery: string) => void;
  onFilterChanged: (newFilter: string) => void;
  onLibraryViewModeSelected: (libraryViewMode: LibraryViewMode) => void;
  localization: Locale;
  helpEnabled: boolean;
  searchModeEnabled: boolean;
  filterEnabled: boolean;
  initialSort: LibrarySorting;
  initialReversed: boolean;
  userTitlesEnabled: boolean;
  libraryViewMode: LibraryViewMode;
  userSubscriptionPlan: IPaidSubscriptionDefinition;
}

interface ILibraryToolbarState {}
export class LibraryToolbar extends React.Component<ILibraryToolbarProps, ILibraryToolbarState> {
  constructor(props: ILibraryToolbarProps | Readonly<ILibraryToolbarProps>) {
    super(props);
    this.checkKey = this.checkKey.bind(this);
  }
  checkKey(event: React.KeyboardEvent) {
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      this.props.onActionClicked(LibrayToolbarActions.multiSearchExecute);
    }
  }
  changeLibraryViewMode = () => {
    if (currentViewState === LibraryViewMode.Grid) {
      currentViewState = LibraryViewMode.List;
    } else {
      currentViewState = LibraryViewMode.Grid;
    }

    this.props.onLibraryViewModeSelected(currentViewState);
  };
  render() {
    return (
      <Navbar color="light" light={true} expand="xs" className="flex-shrink-0 library-nav-bar">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_LIBRARY_REFRESH}>
              <ActionIcon onClick={() => this.props.onActionClicked(LibrayToolbarActions.refresh)} src={<Image.refresh />} />
            </NavItem>
            <UncontrolledDropdown>
              <DropdownToggle caret color="link" data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_SORT_BUTTON}>
                <Icon src={<Image.sort />} />
              </DropdownToggle>
              <SortDropdownMenu initialReversed={this.props.initialReversed} initial={this.props.initialSort} onSortSelected={this.props.onSortSelected}>
                <SortDropdownItem value={LibrarySorting.LastViewed}>{this.props.localization.currentLocale.LibraryView.LABEL_SORT_LASTVIEWED}</SortDropdownItem>
                <SortDropdownItem value={LibrarySorting.Title}>{this.props.localization.currentLocale.LibraryView.LABEL_SORT_TITLE}</SortDropdownItem>
                <SortDropdownItem value={LibrarySorting.Publisher}>{this.props.localization.currentLocale.LibraryView.LABEL_SORT_PUBLISHER}</SortDropdownItem>
                <SortDropdownItem value={LibrarySorting.Downloads}>{this.props.localization.currentLocale.LibraryView.LABEL_SORT_DOWNLOADS}</SortDropdownItem>
              </SortDropdownMenu>
            </UncontrolledDropdown>
            {currentViewState === LibraryViewMode.Grid ? (
              <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_CHANGE_LIST_VIEW}>
                <ActionIcon onClick={this.changeLibraryViewMode} src={<Image.TOCcollapse />} />
              </NavItem>
            ) : (
              <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_CHANGE_GRID_VIEW}>
                <ActionIcon onClick={this.changeLibraryViewMode} src={<Image.TOCExpand />} />
              </NavItem>
            )}

            <NavItem
              data-tooltip-id="libraryToolbar"
              data-tooltip-content={
                this.props.searchModeEnabled
                  ? this.props.localization.currentLocale.LibraryView.LABEL_MULTI_TITLE_SEARCHING_EXIT
                  : this.props.localization.currentLocale.LibraryView.LABEL_MULTI_TITLE_SEARCHING
              }
            >
              <ActionIcon
                onClick={() => this.props.onActionClicked(LibrayToolbarActions.multiSearch)}
                src={this.props.searchModeEnabled ? <Image.cancel_search /> : <Image.search />}
              />
            </NavItem>
            {this.props.searchModeEnabled && (
              <NavItem className="searchBar" data-tooltip-id="libraryToolbar" data-tooltip-content={"Multi-title search"}>
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onQueryChanged(e.target.value)}
                  placeholder={this.props.localization.currentLocale.SearchView.VALUE_TEMPLATE_TERMSFIELD}
                  onKeyUp={this.checkKey}
                />
              </NavItem>
            )}
            {this.props.searchModeEnabled && (
              <NavItem className="searchButton" data-for="libraryToolbar" style={{ marginLeft: "5px" }}>
                <Button onClick={() => this.props.onActionClicked(LibrayToolbarActions.multiSearchExecute)}>
                  {this.props.localization.currentLocale.SearchView.LABEL_SEARCHVIEW}
                </Button>
              </NavItem>
            )}

            {this.props.userTitlesEnabled && (
              <NavItem className="subscriptionText" data-tooltip-id="libraryToolbar" style={{ marginLeft: "5px" }}>
                <NavLink className={classnames("icon", "action-icon")} style={{ cursor: "default" }}>
                  <i>{!this.props.userSubscriptionPlan ? "proLibro Free" : this.props.userSubscriptionPlan.FriendlyName}</i>
                </NavLink>
              </NavItem>
            )}

            {this.props.helpEnabled && <Tooltip id="libraryToolbar" place="bottom" variant="info" className="primaryColoured" />}
          </Nav>
          <Nav navbar={true} className={"ml-auto"}>
            {!this.props.filterEnabled && this.props.userTitlesEnabled && (
              <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.UserBookUploads.LABEL_UPLOAD_YOUR_OWN}>
                <NavLink
                  className={classnames("icon", "action-icon")}
                  onClick={() => this.props.onActionClicked(LibrayToolbarActions.uploadTitle as LibrayToolbarActions)}
                >
                  <Image.fileUpload />
                  <span className="uploadYourOwnTitleSpan">{this.props.localization.currentLocale.UserBookUploads.LABEL_UPLOAD_YOUR_OWN}</span>
                </NavLink>
              </NavItem>
            )}
            <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_TOGGLE_LIBRARY_FILTERING}>
              <ActionIcon
                onClick={() => this.props.onActionClicked(LibrayToolbarActions.filteringChanged)}
                src={this.props.filterEnabled ? <Image.filteron /> : <Image.filteroff />}
              />
            </NavItem>
            {this.props.filterEnabled && (
              <NavItem className="searchBar" data-for="libraryToolbar">
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onFilterChanged(e.target.value)}
                  placeholder={this.props.localization.currentLocale.AnnotationView.LABEL_NO_FILTER + "..."}
                />
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

enum LibraryTileActions {
  download,
  pause,
  launch,
  info,
  delete,
  purchase,
  favourite,
  removeUserTitle,
}

interface ILibraryTileProps extends IModelBinding<LibraryCell> {
  onActionClicked: (id: number, action: LibraryTileActions, currentHits: number) => void;
  onTileSelectionChanged: (versionId: number, newVal: boolean) => void;
  taskUpdate?: EventHandler<VersionTask>;
  downloadFailed: EventHandler<number>;
  localization: Locale;
  defaultSplash: string;
  searchModeActive: boolean;
  hitHandler: EventHandler<MultiSearchMessage>;
  multiSearchExecuted: EventHandler<any>;
  clearSearch: EventHandler<any>;
  cancelSearch: EventHandler<any>;
  isSelected: boolean;
  canManipulateContent: boolean;
  isFavourite: boolean;
}

interface ILibraryTileState extends IFlattenedCell {
  isPaused: boolean;
  isBlocked: boolean;
  isSearching: number;
  allowPause: boolean;
  hits: number;
  isFavourite: boolean;
  userUploadedTitle: IUserUploadedTitle | null;
}

export class LibraryTile extends React.Component<ILibraryTileProps, ILibraryTileState> {
  context: LibrarySession;
  static contextType = LibraryContext;
  constructor(props: ILibraryTileProps | Readonly<ILibraryTileProps>) {
    super(props);
    this.state = {
      ...LibraryUtils.flatten(this.props.model.current, this.props.localization.currentCulture, null),
      isDownloading: false,
      isPaused: false,
      isBlocked: false,
      allowPause: false,
      isSearching: 0,
      hits: -1,
      isFavourite: this.props.isFavourite,
      userUploadedTitle: null,
    };
    this.onVersionTaskUpdated = this.onVersionTaskUpdated.bind(this);
    this.onActionClicked = this.onActionClicked.bind(this);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.downloadFailed = this.downloadFailed.bind(this);
    this.downloadCancelled = this.downloadCancelled.bind(this);
    this.selectTile = this.selectTile.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.cancelSearch = this.cancelSearch.bind(this);
    this.searchExecuted = this.searchExecuted.bind(this);
    this.hitsReceived = this.hitsReceived.bind(this);
    this.updateBook = this.updateBook.bind(this);
    this.needUpdates = this.needUpdates.bind(this);
  }

  componentDidMount() {
    if (this.props.taskUpdate !== undefined) {
      this.props.taskUpdate.on(this.onVersionTaskUpdated);
    }
    if (this.props.downloadFailed) {
      this.props.downloadFailed.on(this.downloadFailed);
    }
    this.props.clearSearch.on(this.clearSearch);
    this.props.cancelSearch.on(this.cancelSearch);
    this.props.model.on(this.onModelUpdate);
    this.props.hitHandler.on(this.hitsReceived);
    this.props.multiSearchExecuted.on(this.searchExecuted);
    this.context.userUploadedBooksUpdated.on(this.userUploadedBooksUpdated);
    this.setState({
      userUploadedTitle: this.context.userUploadedTitles.get(this.props.model.current.TitleId) || null,
      ...LibraryUtils.flatten(
        this.props.model.current,
        this.props.localization.currentCulture,
        this.context.userUploadedTitles.get(this.props.model.current.TitleId) || null
      ),
    });
  }
  userUploadedBooksUpdated = (updates: IUserUploadedTitle[]) => {
    if (updates.some((x) => x.TableId === this.state.userUploadedTitle?.TableId)) {
      this.setState({
        userUploadedTitle: updates.find((x) => x.TableId === this.state.userUploadedTitle?.TableId) || null,
        ...LibraryUtils.flatten(
          this.props.model.current,
          this.props.localization.currentCulture,
          updates.find((x) => x.TableId === this.state.userUploadedTitle?.TableId) || null
        ),
      });
    }
  };
  searchExecuted() {
    this.setState({ isSearching: this.state.isSearching + 1 });
  }

  clearSearch() {
    if (this.state.isSearching - 1 < 0) {
      this.setState({ hits: -1, isSearching: 0 });
    } else {
      this.setState({ hits: -1, isSearching: this.state.isSearching - 1 });
    }
  }

  cancelSearch() {
    if (this.state.isSearching - 1 < 0) {
      this.setState({ hits: -1, isSearching: 0 });
    } else {
      this.setState({ hits: -1, isSearching: this.state.isSearching - 1 });
    }
  }

  hitsReceived(message: MultiSearchMessage) {
    if (this.state.isSearching - 1 < 0) {
      this.setState({ hits: message.ResultCount, isSearching: 0 });
    } else {
      this.setState({ hits: message.ResultCount, isSearching: this.state.isSearching - 1 });
    }
  }
  downloadFailed(versionId: number, error: IError) {
    if (versionId === this.props.model.current.CurrentVersion.VersionId) {
      if (error.Status === Status.SyncOffline || error.Status === Status.ConnectionFailed || error.Status === Status.AuthorizationFailed) {
        Messages.Notify.warning(this.props.localization.currentLocale.LibraryView.ALERT_DOWNLOADFAILED);
        this.setState({
          isBlocked: false,
          isPaused: true,
          allowPause: false,
          isDownloading: false,
          isDownloaded: false,
        });
      } else {
        Messages.Notify.error(this.props.localization.currentLocale.LibraryView.ALERT_DOWNLOAD_DATA_ERROR);
        this.setState({
          isBlocked: false,
          isDownloading: false,
        });
      }
    }
  }
  downloadCancelled(versionId: number) {
    if (versionId === this.props.model.current.CurrentVersion.VersionId) {
      this.setState({
        isBlocked: false,
        isPaused: true,
        allowPause: false,
        isDownloading: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.downloadFailed) {
      this.props.downloadFailed.off(this.downloadFailed);
    }
    if (this.props.taskUpdate) {
      this.props.taskUpdate.off(this.onVersionTaskUpdated);
    }
    this.props.model.off(this.onModelUpdate);
    this.props.clearSearch.off(this.clearSearch);
    this.props.cancelSearch.off(this.cancelSearch);
    this.props.hitHandler.off(this.hitsReceived);
    this.props.multiSearchExecuted.off(this.searchExecuted);
  }

  onModelUpdate() {
    this.setState({
      ...LibraryUtils.flatten(this.props.model.current, this.props.localization.currentCulture, this.state.userUploadedTitle),
    });
  }

  onVersionTaskUpdated(task: VersionTask) {
    this.updateProgress(task);
  }

  async updateBook(action: LibraryTileActions) {
    this.setState({ isBlocked: true });
    await this.context.changeVersion(this.state.id, this.state.latestVersionId);
    this.props.onActionClicked(this.state.id, action, 0);
  }

  needUpdates() {
    let hasOneVersion = false;
    for (const v of this.state.bookVersions) {
      if (v.IsDownloaded) {
        hasOneVersion = true;
      }
    }
    return hasOneVersion && !this.state.bookVersions[this.state.bookVersions.length - 1].IsDownloaded;
  }

  hasOneDownloadedVersion() {
    let hasOneVersion = false;
    for (const v of this.state.bookVersions) {
      if (v.IsDownloaded) {
        hasOneVersion = true;
      }
    }
    return hasOneVersion;
  }

  onActionClicked(action: LibraryTileActions) {
    this.setState({ isBlocked: true });
    this.props.onActionClicked(this.state.id, action, this.state.hits);
    switch (action) {
      case LibraryTileActions.pause:
      case LibraryTileActions.download:
      case LibraryTileActions.launch:
        break;
      case LibraryTileActions.info:
      case LibraryTileActions.delete:
        this.setState({ isBlocked: false });
        break;
      case LibraryTileActions.favourite:
    }
  }

  private updateProgress(task: VersionTask) {
    let allowPause = false;
    switch (task.Type) {
      case VersionTaskType.downloading:
        allowPause = true;
        if (!this.state.isDownloading) {
          this.setState({ isDownloading: true });
        }
        break;
      case -1:
        allowPause = false;
        if (this.state.isDownloading) {
          this.setState({ isDownloading: false });
        }
        this.downloadCancelled(task.VersionId);
        break;
    }
    if (!task.IsPaused) {
      task.IsPaused = false;
    }

    if (this.state.isPaused !== task.IsPaused || this.state.allowPause !== allowPause || this.state.isBlocked) {
      this.setState({
        isPaused: task.IsPaused,
        allowPause,
        isBlocked: false, // we unblock because we have the latest data
      });
    }
  }

  selectTile() {
    if (this.props.searchModeActive && !this.props.model.current.CurrentVersion.IsDownloaded) {
      Messages.Notify.error(this.props.localization.currentLocale.SearchView.LABEL_SEARCH_MULT_NOTDOWNLOADED);
    } else if (this.props.searchModeActive && !this.state.isSearching) {
      let newStateSelected = !this.props.isSelected;
      this.props.onTileSelectionChanged(this.props.model.current.CurrentVersion.VersionId, newStateSelected);
    }
  }

  toggleFavourite() {
    this.context
      .toggleFavouriteTitle(this.state.isFavourite, this.props.model.current.TitleId)
      .then((value) => {
        let favourite: boolean = value;
        if (favourite !== this.state.isFavourite) {
          this.setState({ isFavourite: favourite });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendlyUploadState = (state: UserUploadedTitleState) => {
    switch (state) {
      case UserUploadedTitleState.Finalizing:
        return "Indexing your book!";
      case UserUploadedTitleState.Complete:
        return "Complete";
      case UserUploadedTitleState.Processing:
        return "Processing your content...";
      case UserUploadedTitleState.Received:
        return "Received your book!";
      case UserUploadedTitleState.Refused:
        return "Refused";
      case UserUploadedTitleState.Uploaded:
        return "Uploaded your file!";
    }
  };

  render() {
    let selection =
      (this.props.isSelected && this.props.searchModeActive && this.props.model.current.CellType === LibraryCellType.OwnedTitle) || this.state.hasUpdates
        ? " selected"
        : "";
    let isFaded =
      !this.props.model.current.CurrentVersion.IsDownloaded && this.props.searchModeActive && this.props.model.current.CellType === LibraryCellType.OwnedTitle
        ? " faded"
        : "";
    let searchModeClass = this.props.searchModeActive || this.state.hasUpdates ? " searchMode" : "";
    let userUploadingClass = (this.state.userUploadedTitle !== null && this.state.userUploadedTitle.UploadState !== UserUploadedTitleState.Complete)?" userUploading":"";
    let isConditional = this.props.model.current.IsConditionallyLicensed === true;
    let viewState: string[] = currentViewState === LibraryViewMode.Grid ? [" book-tile ", ""] : [" book-tile-compressed ", ""];
    return (
      <div className={"book-tile-wrapper p-1" + isFaded} onClick={this.selectTile}>
        <Loading
          isLoading={
            this.state.isSearching > 0 ||
            (this.state.userUploadedTitle !== null && this.state.userUploadedTitle.UploadState !== UserUploadedTitleState.Complete)
          }
          status={
            this.state.userUploadedTitle !== null && this.state.userUploadedTitle.UploadState !== UserUploadedTitleState.Complete
              ? this.getFriendlyUploadState(this.state.userUploadedTitle.UploadState)
              : this.props.localization.currentLocale.SearchView.LABEL_SEARCHING_TITLE
          }
        >
          <Card className={"card-img-left bg-light" + viewState[0] + selection + searchModeClass + userUploadingClass}>
            {this.needUpdates() &&
              this.props.model.current.CurrentVersion.VersionRef === this.state.bookVersions[this.state.bookVersions.length - 1].VersionRef && (
                <span
                  className="text-primary"
                  style={{
                    position: "absolute",
                    paddingLeft: "7px",
                    paddingTop: "7px",
                  }}
                >
                  Update now!
                </span>
              )}
            {isConditional && (
              <div
                className="conditionalFlag"
                onClick={() =>
                  void (async () => {
                    await Messages.Dialog.confirm(
                      this.props.localization.currentLocale.LibraryView.LABEL_CONDITIONAL_ACCESS,
                      this.props.localization.currentLocale.LibraryView.LABEL_CONDITIONAL_OFFER,
                      Messages.Dialog.Buttons.Ok
                    );
                  })()
                }
              />
            )}
            {this.state.isSearching === 0 && this.state.hits !== -1 && (
              <div
                onClick={(e) =>
                  void (async () => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.state.hits > 0 || !this.props.searchModeActive) {
                      if (this.state.isDownloaded) {
                        this.onActionClicked(LibraryTileActions.launch);
                      } else if ((!this.state.isDownloading || (this.state.isPaused && this.state.allowPause)) && this.props.canManipulateContent) {
                        this.onActionClicked(LibraryTileActions.download);
                      } else {
                        await Log.warn("no state");
                      }
                    }
                    if (this.props.searchModeActive && this.state.hits <= 0) {
                      this.selectTile();
                    }
                  })()
                }
                className="hitCount"
              >
                {this.state.hits + " " + this.props.localization.currentLocale.SearchView.LABEL_RESULTS}
              </div>
            )}
            {currentViewState === LibraryViewMode.Grid ? (
              <div
                onClick={(e) =>
                  void (async () => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.props.model.current.CellType !== LibraryCellType.OwnedTitle) {
                      this.onActionClicked(LibraryTileActions.info);
                      return;
                    }
                    if (this.state.hits > 0 || !this.props.searchModeActive) {
                      if (this.state.isDownloaded) {
                        this.onActionClicked(LibraryTileActions.launch);
                      } else if ((!this.state.isDownloading || (this.state.isPaused && this.state.allowPause)) && this.props.canManipulateContent) {
                        this.onActionClicked(LibraryTileActions.download);
                      } else {
                        await Log.warn("no state");
                      }
                    }
                    if (this.props.searchModeActive && this.state.hits <= 0) {
                      this.selectTile();
                    }
                  })()
                }
                className="cover card-img"
                style={{
                  backgroundImage: `url(data:image/png;base64,${
                    !this.state.cover || this.state.cover === null || this.state.cover.length === 0 ? this.props.defaultSplash : String(this.state.cover)
                  })`,
                }}
              />
            ) : (
              <div />
            )}

            <CardBody>
              <div className="book-tile-info">
                <div className="fadeout" />
                <CardTitle
                  onClick={(e: React.MouseEvent) => {
                    if (!this.props.searchModeActive) {
                      e.preventDefault();
                      e.stopPropagation();
                      this.onActionClicked(LibraryTileActions.info);
                    }
                  }}
                >
                  {this.state.title}
                </CardTitle>
                <CardText
                  onClick={(e: React.MouseEvent) => {
                    if (!this.props.searchModeActive) {
                      e.preventDefault();
                      e.stopPropagation();
                      this.onActionClicked(LibraryTileActions.info);
                    }
                  }}
                >
                  {this.state.author}
                </CardText>
              </div>
              <div className="book-tile-actions">
                <Nav>
                  {(this.props.model.current.CurrentVersion.IsDownloaded || this.hasOneDownloadedVersion()) &&
                    this.props.model.current.CellType === LibraryCellType.OwnedTitle && (
                      <React.Fragment>
                        <NavItem>
                          <ActionIcon
                            src={<Image.book />}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (this.state.hits > 0 || !this.props.searchModeActive) {
                                this.onActionClicked(LibraryTileActions.launch);
                              }
                              if (this.props.searchModeActive && this.state.hits <= 0) {
                                this.selectTile();
                              }
                            }}
                          />
                        </NavItem>
                      </React.Fragment>
                    )}
                  {/* {this.state.isDownloaded && this.state.hasUpdates && (
                    <React.Fragment>
                      <NavItem>
                        <ActionIcon
                          src={<Image.download />}
                          enabled={!this.state.isBlocked}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.updateBook(LibraryTileActions.download);
                          }}
                        />
                      </NavItem>
                    </React.Fragment>
                  )} */}
                  {!this.props.model.current.CurrentVersion.IsDownloaded && this.props.model.current.CellType === LibraryCellType.OwnedTitle && (
                    <NavItem>
                      {(!this.state.isDownloading || (this.state.isPaused && this.state.allowPause)) && this.props.canManipulateContent && (
                        <ActionIcon
                          src={<Image.download />}
                          enabled={!this.state.isBlocked}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.onActionClicked(LibraryTileActions.download);
                          }}
                        />
                      )}
                      {this.state.isDownloading && (
                        <React.Fragment>
                          {this.state.allowPause && !this.state.isPaused && (
                            <ActionIcon
                              src={<Image.pause />}
                              enabled={!this.state.isBlocked}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.onActionClicked(LibraryTileActions.pause);
                              }}
                            />
                          )}
                          {false && !this.state.allowPause && !this.state.isPaused && <ActionIcon src={<Image.empty />} enabled={false} onClick={() => {}} />}
                        </React.Fragment>
                      )}
                    </NavItem>
                  )}
                  <NavItem>
                    <ActionIcon
                      src={<Image.info />}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onActionClicked(LibraryTileActions.info);
                      }}
                    />
                  </NavItem>
                  {this.state.isFavourite && (
                    <NavItem>
                      <ActionIcon
                        src={<Image.star_full />}
                        className="fav"
                        onClick={() => {
                          this.toggleFavourite();
                        }}
                      />
                    </NavItem>
                  )}
                  {!this.state.isFavourite && (
                    <NavItem>
                      <ActionIcon
                        src={<Image.star_empty />}
                        onClick={() => {
                          this.toggleFavourite();
                        }}
                      />
                    </NavItem>
                  )}
                </Nav>
                <div className="book-tile-status">
                  {this.props.taskUpdate !== undefined && <LibraryProgress update={this.props.taskUpdate} localization={this.props.localization} />}
                </div>
              </div>
            </CardBody>
          </Card>
        </Loading>
      </div>
    );
  }
}

interface ILibraryProductTileProps {
  product: LibraryProductCell;
  defaultImage: string;
  localization: Locale;
  localizationCulture: Languages;
  onProductInfoClicked: (value: LibraryProductCell, action: LibraryTileActions) => void;
}

export class LibraryProductTile extends React.PureComponent<ILibraryProductTileProps, unknown> {
  constructor(props: ILibraryProductTileProps) {
    super(props);
  }
  render() {
    let lowestPlan = this.props.product.ProductPlans.sort((x, y) => {
      if (x.Price < y.Price) {
        return -1;
      } else if (x.Price === y.Price) {
        return 0;
      } else {
        return 1;
      }
    })[0];

    return (
      <div
        className={"book-tile-wrapper container p-1"}
        onClick={() => {
          this.props.onProductInfoClicked(this.props.product, LibraryTileActions.info);
        }}
      >
        <Loading isLoading={false}>
          <Card className={"card-img-left book-tile-compressed bg-light"}>
            <div
              className="cover card-img"
              style={{
                backgroundImage: `url(data:image/png;base64,${
                  !this.props.product.ImageThumbnail || this.props.product.ImageThumbnail === null || this.props.product.ImageThumbnail.length === 0
                    ? this.props.defaultImage
                    : String(this.props.product.ImageThumbnail)
                })`,
              }}
            />
            <CardBody>
              <div className="book-tile-info">
                <div className="fadeout" />
                <CardTitle>{this.props.product.ProductName}</CardTitle>
                <CardText>{this.props.localization.currentLocale.LibraryView.LABEL_PURCHASE_STARTING_PRICE + lowestPlan.FormattedPrice}</CardText>
              </div>
              <div className="book-tile-actions">
                <Nav>
                  <NavItem>
                    <ActionIcon
                      src={<Image.info />}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.onProductInfoClicked(this.props.product, LibraryTileActions.info);
                      }}
                    />
                  </NavItem>
                  <NavItem>
                    <ActionIcon
                      src={<Image.cart />}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.onProductInfoClicked(this.props.product, LibraryTileActions.purchase);
                      }}
                    />
                  </NavItem>
                </Nav>
              </div>
            </CardBody>
          </Card>
        </Loading>
      </div>
    );
  }
}

interface ILibraryProgressProps {
  update: EventHandler<VersionTask>;
  localization: Locale;
}

interface ILibraryProgressState {
  showProgress: boolean;
  progress: number;
  status: string;
  isIndeterminate: boolean;
}

export class LibraryProgress extends React.Component<ILibraryProgressProps, ILibraryProgressState> {
  constructor(props: ILibraryProgressProps | Readonly<ILibraryProgressProps>) {
    super(props);
    this.state = {
      showProgress: false,
      progress: 0,
      status: "",
      isIndeterminate: false,
    };

    this.updateProgress = this.updateProgress.bind(this);
  }
  componentDidMount() {
    this.props.update.on(this.updateProgress);
  }

  componentWillUnmount() {
    this.props.update.off(this.updateProgress);
  }

  private updateProgress(task: VersionTask) {
    let status = "";
    let isIndeterminate = false;
    switch (task.Type) {
      case VersionTaskType.downloading:
        status = task.IsPaused
          ? this.props.localization.currentLocale.LibraryView.LABEL_DOWNLOAD_PAUSED
          : this.props.localization.currentLocale.LibraryView.LABEL_DownloadNotification;
        isIndeterminate = false;
        break;
      case VersionTaskType.installing:
        status = this.props.localization.currentLocale.LibraryView.LABEL_DOWNLOAD_INDEXING;
        isIndeterminate = true;
        break;
      case VersionTaskType.community:
        status = this.props.localization.currentLocale.CommunityView.LABEL_UPDATING_COMMUNITIES;
        isIndeterminate = false;
        break;
      case VersionTaskType.syncing:
        status = this.props.localization.currentLocale.LibraryView.LABEL_LAUNCHING_STEP_UCSYNC;
        isIndeterminate = false;
        break;
      case VersionTaskType.launching:
        status = this.props.localization.currentLocale.LibraryView.LABEL_LAUNCHING_OPENING_TITLE;
        isIndeterminate = true;
        break;
    }

    if (
      this.state.showProgress !== !task.IsCompleted ||
      this.state.progress !== Math.min(100, task.Progress * 100) ||
      this.state.status !== status ||
      this.state.isIndeterminate !== isIndeterminate
    ) {
      this.setState({
        showProgress: !task.IsCompleted,
        progress: Math.min(100, task.Progress * 100),
        status,
        isIndeterminate,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showProgress && (
          <div>
            {this.state.status}
            <Progress
              value={this.state.progress}
              className={classnames({
                "progress-indeterminate": this.state.isIndeterminate,
              })}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

interface IProductInfoViewProps {
  product: LibraryProductCell;
  closeButton?: React.ReactNode;
  defaultSplash: string;
  activeTab?: string;
}
interface IProductInfoViewState {
  activeTab: string;
  selectedPlan: UnifiedProduct | null;
  purchaseMode: PurchaseMode;
  transactionRef: string;
  purchaseUrl: string;
  transactionErrorMsg: string;
}
enum PurchaseMode {
  None,
  PaymentDetails,
  Processing,
  Complete,
  Error,
}
export class ProductInfoView extends React.PureComponent<IProductInfoViewProps, IProductInfoViewState> {
  context: LibrarySession;
  static contextType = LibraryContext;
  moreInfoResource: Resource;
  subscribeButton = React.createRef<HTMLDivElement>();
  constructor(props: IProductInfoViewProps) {
    super(props);
    this.markdownLinkOverride = this.markdownLinkOverride.bind(this);
    this.purchaseRequested = this.purchaseRequested.bind(this);
    this.initiatePurchase = this.initiatePurchase.bind(this);
    this.initiateApplePurchase = this.initiateApplePurchase.bind(this);
    this.cancelPurchase = this.cancelPurchase.bind(this);
    this.purchaseFailed = this.purchaseFailed.bind(this);
    this.purchaseSuccess = this.purchaseSuccess.bind(this);
    this.onPlanSelected = this.onPlanSelected.bind(this);
    this.state = {
      activeTab: props.activeTab ? props.activeTab : "details",
      selectedPlan: null,
      purchaseMode: PurchaseMode.None,
      purchaseUrl: "",
      transactionRef: "",
      transactionErrorMsg: "",
    };
  }
  componentDidMount() {
    this.moreInfoResource = new Resource(Wire.shield(this.context.wire), -1);
    if (ResourcePlatform.IsAppleIAP()) {
      this.context.purchaseFailed.on(this.purchaseFailed); // This will be triggered on Apple IAP when a purchase is detected as failing.
      this.context.purchaseSuccess.on(this.purchaseSuccess); // This will be triggered on Apple IAP when a purchase is detected as failing.
    }
  }
  componentWillUnmount() {
    if (ResourcePlatform.IsAppleIAP()) {
      this.context.purchaseFailed.off(this.purchaseFailed); // This will be triggered on Apple IAP when a purchase is detected as failing.
      this.context.purchaseSuccess.off(this.purchaseSuccess); // This will be triggered on Apple IAP when a purchase is detected as failing.
    }
  }
  purchaseFailed(msg: string) {
    this.setState({ purchaseMode: PurchaseMode.Error, transactionErrorMsg: msg, selectedPlan: null, transactionRef: "" });
  }
  purchaseSuccess() {
    this.setState({ purchaseMode: PurchaseMode.Complete, selectedPlan: null, transactionRef: "" }, () =>
      setTimeout(() => {
        this.context.libraryPanelRefreshRequested.dispatch(null, this);
      }, 3000)
    );
  }
  initiatePurchase(method: PaymentMethod) {
    this.setState(
      { purchaseMode: PurchaseMode.Processing },
      () =>
        void (async () => {
          const res = await this.context.executePurchase({
            PaymentMethod: JSON.stringify(method),
            ProductIdentifier: this.state.selectedPlan!.Identifier,
            PurchaseUrl: this.state.purchaseUrl,
            TransactionRef: this.state.transactionRef,
          });
          if (res) {
            if (!res.valid() || res.data.Result !== PurchaseResult.Success) {
              this.setState({ purchaseMode: PurchaseMode.Error, selectedPlan: null, transactionRef: "", transactionErrorMsg: res.data.Message });
            } else {
              this.setState({ purchaseMode: PurchaseMode.Complete, selectedPlan: null, transactionRef: "" }, () =>
                setTimeout(() => {
                  this.context.libraryPanelRefreshRequested.dispatch(null, this);
                }, 3000)
              );
            }
          }
        })()
    );
  }

  initiateApplePurchase() {
    this.setState(
      { purchaseMode: PurchaseMode.Processing },
      () =>
        void (async () => {
          await this.context.executePurchase({
            PaymentMethod: "",
            ProductIdentifier: this.state.selectedPlan!.Identifier,
            PurchaseUrl: this.state.purchaseUrl,
            TransactionRef: this.state.transactionRef,
          });
        })()
    );
  }
  componentDidUpdate(prevProps: IProductInfoViewProps, prevState: IProductInfoViewState) {
    if (prevProps.activeTab !== this.props.activeTab && prevState.activeTab === this.state.activeTab) {
      this.setState({ activeTab: this.props.activeTab ? this.props.activeTab : "details" });
    }
  }

  async cancelPurchase() {
    await this.context.cancelPurchaseIntent({ TransactionRef: this.state.transactionRef });
    this.setState({ purchaseMode: PurchaseMode.None, selectedPlan: null });
  }

  async purchaseRequested() {
    if (this.context.loginType === LoginType.Anonymous) {
      let result = await Messages.Dialog.confirm(
        Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_ANONYMOUS_LOGIN, [this.context.userName]) as string,
        this.context.localization.currentLocale.LibraryView.LABEL_ANONYMOUS_LOGIN_TITLE,
        Messages.Dialog.Buttons.YesNo
      );
      if (result === "true") {
        await this.context.logout();
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.LibraryView.LABEL_PURCHASE_REGISTRATION_REQUIRED);
      }
    }
    if (this.context.loginType === LoginType.User && this.state.selectedPlan !== null) {
      // Create an intent with SS.
      // Begin the purchasing process.
      let res = await this.context.createPurchaseIntent({ ProductId: this.state.selectedPlan.Identifier });
      if (res.valid()) {
        // Show the purchase frame
        this.setState({ purchaseMode: PurchaseMode.PaymentDetails, transactionRef: res.data.TransactionRef, purchaseUrl: res.data.PurchaseUrl });
      }
    }
    if (this.state.selectedPlan === null) {
      Messages.Notify.error(this.context.localization.currentLocale.LibraryView.ALERT_PURCHASE_PROCESSING_ERROR);
    }
  }

  markdownLinkClicked() {
    return "webMedia";
  }
  async markdownLinkOverride(e: React.MouseEvent) {
    if ((e.target as HTMLAnchorElement).target === "webMedia") {
      e.preventDefault();
      e.stopPropagation();
      if (this.moreInfoResource) {
        await this.moreInfoResource.openExternalWebsite((e.target as HTMLAnchorElement).href);
      }
    }
  }
  onPlanSelected(prod: UnifiedProduct) {
    this.setState({ selectedPlan: prod });
    this.subscribeButton.current!.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    let node = (
      <React.Fragment>
        <ListGroup>
          {this.props.product.ProductPlans.map((plan) => (
            <ListGroupItem tag="a" tabIndex={0} key={plan.Identifier} onClick={() => this.onPlanSelected(plan)}>
              <Checkbox
                state={
                  this.state.selectedPlan !== null && plan.Identifier === this.state.selectedPlan.Identifier ? CheckboxState.checked : CheckboxState.unchecked
                }
                onChange={() => {}}
                className="mr-2"
              />
              <label>{plan.Title + " - " + plan.FormattedPrice}</label>
              <div className="blackText">
                <i>{ResourcePlatform.toMarkdown(plan.Description, (e) => void this.markdownLinkOverride(e), this.markdownLinkClicked)}</i>
              </div>
            </ListGroupItem>
          ))}
          <div ref={this.subscribeButton}>
            <Button
              onClick={() => void this.purchaseRequested()}
              style={{ marginTop: "15px", marginBottom: "15px" }}
              color="primary"
              outline
              block
              disabled={this.state.selectedPlan === null}
            >
              {this.context.localization.currentLocale.Store.LABEL_SUBSCRIBE}
            </Button>
          </div>
        </ListGroup>
      </React.Fragment>
    );

    switch (this.state.purchaseMode) {
      case PurchaseMode.PaymentDetails:
        node = (
          <PurchaseSummary
            cancelPurchase={() => void this.cancelPurchase()}
            initiateApplePurchase={this.initiateApplePurchase}
            initiatePurchase={this.initiatePurchase}
            selectedPlan={this.state.selectedPlan!}
            product={this.props.product}
            defaultSplash={this.props.defaultSplash}
            moreInfoResource={this.moreInfoResource}
          />
        );
        break;
      case PurchaseMode.Complete:
        node = (
          <div className="transactionSuccess">
            <div className="glowBox glow">
              <Image.warningResolved />
            </div>
            <label>{this.context.localization.currentLocale.Store.ALERT_STORE_SUBSCRIPTION_SUCCESS}</label>
          </div>
        );
        break;
      case PurchaseMode.Error:
        node = (
          <div className="transactionError">
            <div className="">
              <Image.warning />
            </div>
            <label>{this.context.localization.currentLocale.Store.ALERT_STORE_CARD_ERROR_OTHER + ": " + this.state.transactionErrorMsg}</label>
            <Button onClick={() => void this.cancelPurchase()} style={{ marginTop: "15px" }} color="primary" outline block>
              {this.context.localization.currentLocale.Store.LABEL_STORE_CANCEL}
            </Button>
          </div>
        );
        break;
      case PurchaseMode.Processing:
        node = <Loading className="full-height" status="Processing payment" isLoading={true} />;
        break;
    }

    return (
      <div className="d-flex flex-fill flex-column full-height">
        {this.state.purchaseMode === PurchaseMode.None && (
          <React.Fragment>
            {" "}
            <div className="details-view-header">
              <div className="d-flex flex-row pb-3">
                <div>
                  <div
                    className="cover"
                    style={{
                      backgroundImage: `url(data:image/png;base64,${
                        !this.props.product.ImageThumbnail || this.props.product.ImageThumbnail === null || this.props.product.ImageThumbnail.length === 0
                          ? this.props.defaultSplash
                          : String(this.props.product.ImageThumbnail)
                      })`,
                    }}
                  />
                </div>
                <div className="flex-fill pl-3 d-flex flex-column">
                  <h2 className="flex-shrink-0 mb-0 pb-0 header-with-close">
                    <span>{this.props.product.ProductName} </span>
                    {this.props.closeButton}
                  </h2>
                </div>
              </div>
              <Nav pills className="nav-fill">
                <NavItem>
                  <NavLink active={this.state.activeTab === "details"} tabIndex={0} onClick={() => this.setState({ activeTab: "details" })}>
                    <Icon src={<Image.tag />} /> <span className="header-span">{this.context.localization.currentLocale.LibraryView.LABEL_DETAILS}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active={this.state.activeTab === "plans"} tabIndex={0} onClick={() => this.setState({ activeTab: "plans" })}>
                    <Icon src={<Image.tag />} />{" "}
                    <span className="header-span">{this.context.localization.currentLocale.LibraryView.LABEL_PURCHASE_SUBSCRIPTION_PLANS}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={this.state.activeTab} className="flex-fill scrollable details-view-body d-flex">
              <TabPane tabId="details" className="flex-fill">
                {ResourcePlatform.toMarkdown(
                  this.props.product.ProductDescription,
                  (e) => void this.markdownLinkOverride(e),
                  this.markdownLinkClicked,
                  "Description"
                )}
              </TabPane>
              <TabPane tabId="plans" className="flex-fill">
                {node}
              </TabPane>
            </TabContent>
          </React.Fragment>
        )}
        {this.state.purchaseMode !== PurchaseMode.None && (
          <div style={this.state.purchaseMode !== PurchaseMode.Processing ? { padding: "15px" } : { padding: "0px" }} className="full-height full-width">
            {node}
          </div>
        )}
      </div>
    );
  }
}

interface IMoreInfoViewProps extends IModelBinding<LibraryCell | null> {
  closeButton?: React.ReactNode;
  defaultSplash: string;
  userUploadedTitle: IUserUploadedTitle | null;
  onActionClicked: (id: number, action: LibraryTileActions, currentHits: number) => void;
}

interface IMoreInfoViewState extends IFlattenedCell {
  pageOpen: boolean;
  activeTab: "details" | "pages" | "versions";
  aboutPages: IMoreInfoItem[];
  loadingPage: boolean;
  currentPageTitle: string;
  versions: IOtherVersionItem[];
  taskUpdate?: EventHandler<VersionTask>;
  isCancellable: boolean;
}
export class MoreInfoView extends React.Component<IMoreInfoViewProps, IMoreInfoViewState> {
  static pageBackId = -1;
  context: LibrarySession;
  static contextType = LibraryContext;
  pageFrameRef = React.createRef<HTMLIFrameElement>();
  moreInfoResource: Resource;
  infoKeys: any;
  constructor(props: IMoreInfoViewProps | Readonly<IMoreInfoViewProps>) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.state = {
      ...LibraryUtils.flatten(this.props.model.current, Languages.English, this.props.userUploadedTitle),
      activeTab: "details",
      pageOpen: false,
      aboutPages: new Array<IMoreInfoItem>(),
      loadingPage: true,
      currentPageTitle: "",
      versions: new Array<IOtherVersionItem>(),
      taskUpdate: undefined,
      isCancellable: false,
    };
    this.onTabChanged = this.onTabChanged.bind(this);
    this.onActionClicked = this.onActionClicked.bind(this);
    this.onAboutPageLinkClicked = this.onAboutPageLinkClicked.bind(this);
    this.onVersionSelected = this.onVersionSelected.bind(this);
    this.taskUpdated = this.taskUpdated.bind(this);
    this.downloadFailed = this.downloadFailed.bind(this);
    this.markdownLinkOverride = this.markdownLinkOverride.bind(this);
  }

  componentDidMount() {
    this.props.model.on(this.onModelUpdate);
    this.moreInfoResource = new Resource(Wire.shield(this.context.wire), -1);
    if (this.context.bookDownloadFailed) {
      this.context.bookDownloadFailed.on(this.downloadFailed);
    }
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  downloadFailed() {
    this.setState({ isCancellable: false, isDownloading: false, isDownloaded: false, taskUpdate: undefined });
  }

  onActionClicked(action: LibraryTileActions) {
    this.props.onActionClicked(this.state.id, action, 0);
  }

  async onAboutPageLinkClicked(pageId: number) {
    let title = "";
    if (pageId !== MoreInfoView.pageBackId) {
      const result = await this.context.getAboutPage(this.state.id, pageId);
      for (const p of this.state.aboutPages) {
        if (p.id === pageId) {
          title = p.name;
          break;
        }
      }

      this.loadFrameWithContent(result.data);
    }

    this.setState({
      pageOpen: pageId !== MoreInfoView.pageBackId,
      currentPageTitle: title,
    });
  }

  private loadFrameWithContent(content: string) {
    const iframe = this.pageFrameRef.current;
    if (iframe) {
      const document = iframe.contentDocument;
      if (document && document.documentElement != null) {
        document.documentElement.innerHTML = content;
      }
    }
  }

  async onVersionSelected(versionId: number) {
    await this.context.changeVersion(this.state.id, versionId);
  }
  onModelUpdate() {
    if (this.props.model.current !== null) {
      let taskUpdate = this.context.onLibraryTaskUpdated.handler(this.props.model.current.CurrentVersion.VersionId);
      taskUpdate.on(this.taskUpdated);
      let stateVar = Languages.English;
      if (this.context.appSettings && this.context.appSettings.get()) {
        stateVar = this.context.appSettings.get().LanguageOverride;
      }
      this.setState({
        ...LibraryUtils.flatten(this.props.model.current, stateVar, this.props.userUploadedTitle),
        aboutPages: LibraryUtils.parseMoreInfo(this.props.model.current),
        versions: LibraryUtils.getOtherVersions(this.props.model.current),
        taskUpdate: taskUpdate,
      });
    } else {
      if (this.state.taskUpdate) {
        this.state.taskUpdate.off(this.taskUpdated);
      }
      this.setState({
        ...LibraryUtils.flatten(null, Languages.English, null),
        aboutPages: LibraryUtils.parseMoreInfo(null),
        versions: LibraryUtils.getOtherVersions(null),
        taskUpdate: undefined,
      });
    }
  }
  taskUpdated(versionTask: VersionTask) {
    if (versionTask.Type === VersionTaskType.downloading) {
      this.setState({ isDownloading: true });
    }
    if (
      versionTask.Type === VersionTaskType.syncing ||
      versionTask.Type === VersionTaskType.launching ||
      versionTask.Type === VersionTaskType.installing ||
      versionTask.Type === VersionTaskType.community
    ) {
      if (this.state.isCancellable !== false) {
        this.setState({ isCancellable: false });
      }
    }
    if (versionTask.Type === -1) {
      this.setState({ isDownloading: false, isDownloaded: false, isCancellable: false });
    } else {
      if (this.state.isCancellable !== true) {
        this.setState({ isCancellable: true });
      }
    }
  }

  onTabChanged(tab: "details" | "pages" | "versions") {
    this.setState({
      activeTab: tab,
    });
  }

  markdownLinkClicked() {
    return "webMedia";
  }
  async markdownLinkOverride(e: React.MouseEvent) {
    if ((e.target as HTMLAnchorElement).target === "webMedia") {
      e.preventDefault();
      e.stopPropagation();
      if (this.moreInfoResource) {
        await this.moreInfoResource.openExternalWebsite((e.target as HTMLAnchorElement).href);
      }
    }
  }

  toMarkdown(value: any) {
    let markdowns: JSX.Element[] = [];
    let index = 0;
    _.mapObject(this.infoKeys, (label: any, key: any) => {
      index = index + 1;
      if (value[key] !== undefined && value[key] !== null && value[key] !== "") {
        markdowns.push(
          <div key={index}>
            <dl>
              <dt>{label}</dt>
              <React.Fragment key={index}>
                <dd className="markdownContainer">
                  <ReactMarkdown remarkPlugins={[Gfm]} linkTarget={this.markdownLinkClicked}>
                    {value[key]}
                  </ReactMarkdown>
                </dd>
              </React.Fragment>
            </dl>
          </div>
        );
      }
    });
    return <dl onClick={(e) => void this.markdownLinkOverride(e)}>{markdowns}</dl>;
  }

  render() {
    this.infoKeys = {
      description: this.context.localization.currentLocale.LibraryView.LABEL_DETAIL_DESCRIPTION,
      author: this.context.localization.currentLocale.LibraryView.LABEL_DETAIL_AUTHOR,
      publisher: this.context.localization.currentLocale.LibraryView.LABEL_DETAIL_PUBLISHER,
      date: this.context.localization.currentLocale.LibraryView.LABEL_CreateDate,
      isbn: "ISBN",
      subject: this.context.localization.currentLocale.LibraryView.LABEL_DETAIL_SUBJECT,
      language: this.context.localization.currentLocale.LibraryView.LABEL_DETAIL_LANGUAGE,
      rights: this.context.localization.currentLocale.LibraryView.LABEL_DETAIL_RIGHTS,
    };
    let companion = this.props.model.current!.CurrentVersion.VersionInfo.URL;
    if (this.state.index !== this.props.model.current!.Index || this.state.cellType !== this.props.model.current!.CellType) {
      // this.onModelUpdate();
    }
    return (
      <div className="d-flex flex-fill flex-column full-height">
        <div className="details-view-header">
          <div className="d-flex flex-row pb-3">
            <div>
              <div
                className="cover"
                style={{
                  backgroundImage: `url(data:image/png;base64,${
                    !this.state.cover || this.state.cover === null || this.state.cover.length === 0 ? this.props.defaultSplash : String(this.state.cover)
                  })`,
                }}
              />
            </div>
            <div className="flex-fill pl-3 d-flex flex-column">
              <h2 className="flex-shrink-0 mb-0 pb-0 header-with-close">
                <span>{this.state.title}</span>
                {this.props.closeButton}
              </h2>
              <div className="flex-fill flex-shrink-0 d-flex justify-content-center align-items-stretch flex-column">
                {this.state.isDownloaded && this.props.model.current!.CellType === LibraryCellType.OwnedTitle && (
                  <React.Fragment>
                    <Button color="dark" outline block onClick={() => this.onActionClicked(LibraryTileActions.launch)}>
                      <Icon src={<Image.book />} /> {this.context.localization.currentLocale.LibraryView.LABEL_READ}
                    </Button>
                    {this.context.canManipulateContent && (
                      <Button style={{ marginTop: "15px" }} color="danger" outline block onClick={() => this.onActionClicked(LibraryTileActions.delete)}>
                        <Icon src={<Image.delete_item />} /> {this.context.localization.currentLocale.LibraryView.LABEL_LIBRARY_DELETE_TITLE}
                      </Button>
                    )}
                  </React.Fragment>
                )}
                {(this.props.model.current!.CellType === LibraryCellType.AvailableTitle || this.props.model.current!.CellType === LibraryCellType.OwnedTitle) &&
                  !Convert.isEmptyOrSpaces(companion) && (
                    <React.Fragment>
                      <Button
                        color="dark"
                        outline
                        block
                        style={{ marginTop: "15pt" }}
                        onClick={() =>
                          void (async () => {
                            await this.moreInfoResource.openExternalWebsite(companion);
                          })()
                        }
                      >
                        <Icon src={<Image.info />} /> {this.context.localization.currentLocale.LibraryView.LABEL_VISIT_COMPANION}
                      </Button>
                    </React.Fragment>
                  )}
                {!this.state.isDownloaded && this.props.model.current!.CellType === LibraryCellType.OwnedTitle && (
                  <React.Fragment>
                    {!this.state.isDownloading && this.context.canManipulateContent && (
                      <Button color="dark" outline block onClick={() => this.onActionClicked(LibraryTileActions.download)}>
                        <Icon src={<Image.download />} /> {this.context.localization.currentLocale.LibraryView.LABEL_DOWNLOAD}
                      </Button>
                    )}
                    {this.state.isDownloading && this.state.taskUpdate !== undefined && this.context.canManipulateContent && (
                      <React.Fragment>
                        <LibraryProgress update={this.state.taskUpdate} localization={this.context.localization} />
                        {this.state.isCancellable && (
                          <a className="text-danger" tabIndex={0} onClick={() => this.onActionClicked(LibraryTileActions.delete)}>
                            {this.context.localization.currentLocale.LibraryView.LABEL_CANCELDOWNLOAD}
                          </a>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {this.props.userUploadedTitle && (
                  <React.Fragment>
                    <Button style={{ marginTop: "15px" }} color="danger" outline block onClick={() => this.onActionClicked(LibraryTileActions.removeUserTitle)}>
                      <Icon src={<Image.delete_item />} /> {this.context.localization.currentLocale.UserBookUploads.LABEL_REMOVE_BOOK}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <Nav pills className="nav-fill">
            <NavItem>
              <NavLink onClick={() => this.onTabChanged("details")} active={this.state.activeTab === "details"} tabIndex={0}>
                <Icon src={<Image.tag />} /> <span className="header-span">{this.context.localization.currentLocale.LibraryView.LABEL_DETAILS}</span>
              </NavLink>
            </NavItem>
            {this.props.model.current!.CellType === LibraryCellType.OwnedTitle && this.props.userUploadedTitle === null && (
              <NavItem>
                <NavLink
                  onClick={() => this.onTabChanged("pages")}
                  active={this.state.activeTab === "pages"}
                  disabled={this.state.aboutPages.length === 0}
                  tabIndex={0}
                >
                  <Icon src={<Image.info />} /> <span className="header-span">{this.context.localization.currentLocale.LibraryView.LABEL_ABOUT}</span>
                </NavLink>
              </NavItem>
            )}
            {this.props.model.current!.CellType === LibraryCellType.OwnedTitle && this.props.userUploadedTitle === null && (
              <NavItem>
                <NavLink
                  onClick={() => this.onTabChanged("versions")}
                  active={this.state.activeTab === "versions"}
                  disabled={this.state.versions.length === 0}
                  tabIndex={0}
                >
                  <Icon src={<Image.versions />} />
                  <span className="header-span">
                    {this.context.localization.currentLocale.LibraryView.LABEL_AVAILABLE_VERSIONS} ({this.state.versions.length})
                  </span>
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </div>
        <TabContent activeTab={this.state.activeTab} className="flex-fill scrollable details-view-body d-flex">
          <TabPane tabId="details" className="flex-fill">
            {this.toMarkdown(this.state)}
          </TabPane>
          <TabPane tabId="pages" className="flex-fill d-flex">
            <RightFlip
              breakpoint={768}
              className="flex-fill"
              isOpen={this.state.pageOpen}
              panelClassName={{
                left: "flex-fill",
                right: "bg-white flex-fill d-flex flex-row align-items-stretch",
              }}
            >
              {{
                left: (
                  <ListGroup>
                    {this.state.aboutPages.map((info) => (
                      <ListGroupItem tag="a" tabIndex={0} key={info.id} onClick={() => void this.onAboutPageLinkClicked(info.id)}>
                        {info.name}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                ),
                right: (
                  <React.Fragment>
                    <div className="flex-shrink-0">
                      <ActionIcon src={<Image.arrowback />} className="p-2" onClick={() => void this.onAboutPageLinkClicked(MoreInfoView.pageBackId)} />
                    </div>
                    <iframe title="rightFrame" ref={this.pageFrameRef} className="flex-fill" />
                  </React.Fragment>
                ),
              }}
            </RightFlip>
          </TabPane>
          <TabPane tabId="versions" className="flex-fill">
            <ListGroup>
              {this.state.versions.map((version) => (
                <ListGroupItem tag="a" tabIndex={0} key={version.versionId} onClick={() => void this.onVersionSelected(version.versionId)}>
                  <Checkbox
                    state={version.versionId === this.state.versionId ? CheckboxState.checked : CheckboxState.unchecked}
                    onChange={() => {}}
                    className="mr-2"
                  />
                  <span> {version.title} </span>
                  {version.state && version.state.toLowerCase() !== "undefined" && version.state !== "Release" && (
                    <span style={{ color: "red" }}> {version.state} </span>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

interface IFlattenedCell {
  id: number;
  index: number;
  cellType: LibraryCellType;
  title: string;
  publisher: string;
  author: string;
  cover: number[];
  isDownloaded: boolean;
  isDownloading: boolean;
  hasUpdates: boolean;
  date: string;
  isbn: string;
  subject: string;
  language: string;
  description: string;
  rights: string;
  versionId: number;
  latestVersionId: number;
  bookVersions: LibraryVersion[];
}

interface IMoreInfoItem {
  id: number;
  lang: string;
  name: string;
}

interface IOtherVersionItem {
  versionId: number;
  isDownloaded: boolean;
  title: string;
  state: string;
}
class LibraryUtils {
  static flatten(cell: LibraryCell | undefined | null, language: Languages, userUploadedTitle: IUserUploadedTitle | null): IFlattenedCell {
    if (cell !== undefined && cell !== null) {
      if (cell.OtherVersions === null) {
        cell.OtherVersions = [];
      }
      return {
        id: cell.TitleId,
        index: cell.Index,
        cellType: cell.CellType,
        title: userUploadedTitle !== null ? userUploadedTitle.BookName : cell.CurrentVersion.VersionInfo.Title,
        publisher: cell.CurrentVersion.VersionInfo.Publisher,
        author: cell.CurrentVersion.VersionInfo.Creator,
        cover: cell.CurrentVersion.VersionInfo.CoverImage,
        isDownloaded: cell.CurrentVersion.IsDownloaded,
        isDownloading: cell.CurrentVersion.IsDownloading,
        hasUpdates: cell.OtherVersions.length > 0 ? cell.CurrentVersion.VersionId !== cell.OtherVersions[cell.OtherVersions.length - 1].VersionId : false,
        date: Convert.dateToFormattedString(cell.CurrentVersion.VersionInfo.Date, language),
        isbn: cell.CurrentVersion.VersionInfo.Identifier,
        subject: cell.CurrentVersion.VersionInfo.Subject,
        language: cell.CurrentVersion.VersionInfo.Language,
        description: cell.CurrentVersion.VersionInfo.Description,
        rights: cell.CurrentVersion.VersionInfo.Rights,
        versionId: cell.CurrentVersion.VersionId,
        latestVersionId: cell.OtherVersions.length > 0 ? cell.OtherVersions[cell.OtherVersions.length - 1].VersionId : 0,
        bookVersions: cell.OtherVersions,
      };
    } else {
      return {
        id: 0,
        index: 0,
        cellType: LibraryCellType.OwnedTitle,
        title: "",
        publisher: "",
        author: "",
        cover: [],
        isDownloaded: false,
        isDownloading: false,
        hasUpdates: false,
        date: "",
        isbn: "",
        subject: "",
        language: "",
        description: "",
        rights: "",
        versionId: 0,
        latestVersionId: 0,
        bookVersions: [],
      };
    }
  }

  static getTheVersionStatus(value: VersionStatus): string {
    switch (value) {
      case VersionStatus.New:
        return "New";
      case VersionStatus.Review:
        return "Review";
      case VersionStatus.Release:
        return "Release";
      case VersionStatus.Rescinded:
        return "Rescinded";
      case VersionStatus.Inactive:
        return "Inactive";
      case VersionStatus.Publishing:
        return "Publishing";
      case VersionStatus.Releasing:
        return "Releasing";
      default:
        return "Undefined";
    }
  }

  static getOtherVersions(cell: LibraryCell | undefined | null): IOtherVersionItem[] {
    if (cell !== undefined && cell !== null && cell.OtherVersions) {
      let versions = new Array<IOtherVersionItem>();
      for (const v of cell.OtherVersions) {
        versions.push({
          versionId: v.VersionId,
          isDownloaded: v.IsDownloaded,
          title: v.VersionInfo.Title,
          state: this.getTheVersionStatus(v.VersionStatus),
        });
      }
      versions.sort((a, b) => b.versionId - a.versionId);
      return versions;
    } else {
      return new Array<IOtherVersionItem>();
    }
  }

  static parseMoreInfo(cell: LibraryCell | undefined | null): IMoreInfoItem[] {
    if (cell !== undefined && cell !== null && cell.CurrentVersion.MoreInfo) {
      let pages = new Array<IMoreInfoItem>();
      let moreInfo: any;
      try {
        moreInfo = JSON.parse(cell.CurrentVersion.MoreInfo);
      } catch (e) {
        return pages;
      }
      if (moreInfo.items) {
        for (const p of moreInfo.items) {
          if (p.id !== undefined && p.name !== undefined && p.lang !== undefined) {
            pages.push({
              id: p.id,
              name: p.name,
              lang: p.lang,
            });
          }
        }
      }
      return pages;
    } else {
      return new Array<IMoreInfoItem>();
    }
  }
}
