import { Plugin, PluginID } from 'src/chatbot/types/plugin';

export const getEndpoint = (plugin: Plugin | null) => {
  if (!plugin) {
    return 'http://localhost:3000/api/professor/chat/';
  }

  if (plugin.id === PluginID.GOOGLE_SEARCH) {
    return 'api/google';
  }

  return 'http://localhost:3000/api/professor/chat/';
};
