import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'src/chatbot/types/error';

const useErrorService = () => {
  const { t } = useTranslation('chat');

  return {
    getModelsError: useMemo(
      () => (error: any) =>
        !error
        ? null
        : ({
            title: t('Error fetching models.'),
            code: error.status || 'unknown',
            messageLines: error.statusText
              ? [error.statusText]
              : [ 
                  t('Make sure your CogniAI API key is set in the bottom left of the sidebar.'),
                  t('If you completed this step, CogniAI may be experiencing issues.'),
                ],
          } as ErrorMessage),
      [t],
    ),
    getTitlesError: useMemo(
      () => (error: any) =>
        !error
        ? null
        : ({
            title: t('Error fetching titles.'),
            code: error.status || 'unknown',
            messageLines: error.statusText
              ? [error.statusText]
              : [
                  t('Make sure your CogniAI API key is set in the bottom left of the sidebar.'),
                  t('If you completed this step, CogniAI may be experiencing issues.'),
                ],
          } as ErrorMessage),
      [t],
    ),
  };
};

export default useErrorService;
