import classnames from 'classnames';
import * as React from 'react';
import * as Autosuggest from 'react-autosuggest';
import ReactDOMClient from 'react-dom/client';
import { Tooltip } from 'react-tooltip';
import {
    Badge, Collapse, DropdownToggle, Form, FormFeedback, FormGroup, Input, Label, Nav, Navbar,
    NavItem, NavLink, UncontrolledDropdown
} from 'reactstrap';
import { Book, BookLoadingSteps, NavigationInitiator } from 'src/models/Book';
import { ContentPopupModel, ContentSegment, NavigationRequest } from 'src/models/Content';
import { IGetUCPreviewR } from 'src/models/dto/UCRequest';
import { ActionResult } from 'src/models/Result';
import { Favourite, FavouriteGrouping, FavouriteSorting } from 'src/models/UserContent';
import { Binding, EmitterEvent } from 'src/utilities/Events';
import { FavouritePrint } from 'src/utilities/FavouritePrint';
import { FormState } from 'src/utilities/FormState';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';
import { PrintBox } from 'src/utilities/Printbox';
import { Validator } from 'src/utilities/Validator';

import { Languages, Locale } from '../../localization/Locale';
import { Image } from '../foundation/Assets';
import {
    ActionIcon, ConcreteContentSegment, Expander, ExpanderClose, FilteringItem, FilteringMenu,
    FormValue, Icon, ItemCard, PreviewHandler, SortDropdownItem, SortDropdownMenu, Switch
} from '../foundation/Controls';
import { RightFlip } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import {
    Action, ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { StandaloneCogniflowFrameContainer } from '../foundation/StandaloneCogniflowFrame';
import { BookContext } from '../state/Contextes';
import { IModelBinding } from '../state/Generics';

const content_css = require("!!raw-loader!src/assets/css/content.css").default;
const content_jquery = require("!!raw-loader!jquery").default;
const content_api = require("!!raw-loader!src/assets/js/ContentAPI.js").default;
const content_mathJax = require("!!raw-loader!src/assets/js/tex-mml-svg.js").default;
const content_place = require("!!raw-loader!src/assets/js/Place.js").default;
const printStyle_css = require("!!raw-loader!src/assets/css/annotationPrint.css").default;
export interface IFavouriteViewState {
  formOpen: boolean;
  currentFavourite: Favourite | null;
  sorting: FavouriteSorting;
  grouping: FavouriteGrouping;
  folderViewOpen: boolean;
  selectFavourite: boolean;
  reversedSort: boolean;
  folderFilterCount: false | number;
  favourites: Favourite[][];
  selectedFavouriteIDs: number[];
  selectedFavourite: number | null;
  currentFilter: string[];
}
interface IFavouriteViewProps {
  isActive: boolean;
}
export class FavouriteView extends React.Component<IFavouriteViewProps, IFavouriteViewState> {
  context: Book;
  static contextType = BookContext;
  private formRef = React.createRef<FavouriteForm>();
  cogniflow = React.createRef<StandaloneCogniflowContainer>();

  constructor(props: IFavouriteViewProps | Readonly<IFavouriteViewProps>) {
    super(props);
    this.onFavouriteClicked = this.onFavouriteClicked.bind(this);
    this.state = {
      formOpen: false,
      currentFavourite: null,
      sorting: FavouriteSorting.Recent,
      reversedSort: false,
      folderViewOpen: false,
      selectFavourite: false,
      grouping: FavouriteGrouping.Folder,
      folderFilterCount: false,
      favourites: [],
      selectedFavouriteIDs: [],
      selectedFavourite: null,
      currentFilter: [],
    };

    this.onFavouriteClicked = this.onFavouriteClicked.bind(this);
    this.onFormToolbarAction = this.onFormToolbarAction.bind(this);
    this.onFolderViewToggle = this.onFolderViewToggle.bind(this);
    this.onCloseFolderView = this.onCloseFolderView.bind(this);
    this.onFolderFilterChanged = this.onFolderFilterChanged.bind(this);
    this.onGroupingChanged = this.onGroupingChanged.bind(this);
    this.onFavouriteContentClick = this.onFavouriteContentClick.bind(this);
    this.update = this.update.bind(this);
    this.sortFaves = this.sortFaves.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onReady = this.onReady.bind(this);
    this.checkChanges = this.checkChanges.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildFavouriteItem = this.buildFavouriteItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.groupSelectionClicked = this.groupSelectionClicked.bind(this);
  }
  async onFavouriteContentClick(ids: number[]) {
    if (ids.length > 1) {
      return;
    }
    let id = ids[0];
    let fave = this.context.favourites.get(id);
    if (fave) {
      await this.readFavourite(fave);
    }
  }
  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.favourites, this);
    await this.context.initFavourites();
    this.context.favourites.addListener((affectedItem?: any, event?: EmitterEvent | undefined) => void this.update(affectedItem, event));
    await this.update();
    this.context.loading.setLoaded(BookLoadingSteps.favourites);
  }
  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.favourites, () => void this.onReady());
    this.context.favouriteContentClicked.on((ids) => void this.onFavouriteContentClick(ids));
    this.setState({
      sorting: this.context.appSettings.get().FavouriteSorting,
      reversedSort: this.context.appSettings.get().AnnotationSortingReversed,
      grouping: this.context.appSettings.get().FavouriteGrouping,
    });
  }

  componentWillUnmount() {
    this.context.favourites.removeListener((affectedItem?: any, event?: EmitterEvent | undefined) => void this.update(affectedItem, event));
    this.context.favouriteContentClicked.off((ids) => void this.onFavouriteContentClick(ids));
  }
  sortFaves(faves: Favourite[]): Favourite[] {
    if (this.state.sorting === FavouriteSorting.ReadingOrder) {
      faves = faves.sort((x1, x2) => {
        if (x1.Spine > x2.Spine) {
          return 1;
        }
        if (x1.Spine < x2.Spine) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.sorting === FavouriteSorting.Recent) {
      faves = faves.sort((x1, x2) => {
        if (x1.LastUpdate > x2.LastUpdate) {
          return 1;
        }
        if (x1.LastUpdate < x2.LastUpdate) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.sorting === FavouriteSorting.Title) {
      faves = faves.sort((x1, x2) => {
        if (x1.Value > x2.Value) {
          return 1;
        }
        if (x1.Value < x2.Value) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.reversedSort) {
      faves = faves.reverse();
    }
    let count = 0;
    faves.map((x) => {
      x.Index = count;
      count++;
      x.IsFirst = false;
      x.IsLast = false;
    });
    if (faves.length > 0) {
      faves[0].IsFirst = true;
      faves[faves.length - 1].IsLast = true;
    }
    return faves;
  }

  async update(affectedItem?: any, event?: EmitterEvent) {
    if (event === EmitterEvent.delete || (event === EmitterEvent.action && affectedItem === undefined)) {
      this.setState({ currentFavourite: null });
      await this.closeFavouriteForm();
    }
    if (event === EmitterEvent.update) {
      /* this.closeFavouriteForm(); */
    }

    let finalFaves: Favourite[][] = [];

    // All the faves, sorted by folder.
    let holder: Favourite[] = this.context.favourites.rows().sort((x1, x2) => {
      x1.Folder = Convert.isEmptyOrSpaces(x1.Folder) ? "" : x1.Folder;
      x2.Folder = Convert.isEmptyOrSpaces(x2.Folder) ? "" : x2.Folder;

      if (x1.Folder > x2.Folder) {
        return 1;
      }
      if (x1.Folder < x2.Folder) {
        return -1;
      }
      return 0;
    });

    // If there's no items in the collection don't bother doing anything.
    if (holder.length === 0) {
      this.setState({ favourites: finalFaves });
      return;
    }

    // Strip away the ones not in the filter if it's set.
    if ((this.state.folderFilterCount as number) > 0) {
      let grouping: Favourite[] = [];
      for (let i = 0; i < holder.length; i++) {
        if (this.state.currentFilter.indexOf(holder[i].Folder) > -1) {
          grouping.push(holder[i]);
        }
      }
      holder = grouping;
    }
    if (this.state.folderFilterCount === 0) {
      this.setState({ favourites: [] });
      return;
    }

    // If we're grouping the faves, we'll separate them here so the render can render grouped lists.
    // Sub groups will also be sorted by their current sort param.
    if (this.state.grouping === FavouriteGrouping.Folder) {
      let currentFolder: string = Convert.isEmptyOrSpaces(holder[0].Folder) ? "" : holder[0].Folder;
      let group: Favourite[] = [];
      for (let i = 0; i < holder.length; i++) {
        holder[i].Folder = Convert.isEmptyOrSpaces(holder[i].Folder) ? "" : holder[i].Folder;
        if (holder[i].Folder === currentFolder) {
          group.push(holder[i]);
        } else {
          group = this.sortFaves(group);
          finalFaves.push(group);
          group = [];
          group.push(holder[i]);
          currentFolder = holder[i].Folder;
        }
      }
      group = this.sortFaves(group);
      finalFaves.push(group);
    } else {
      holder = this.sortFaves(holder);
      finalFaves.push(holder);
    }

    this.setState({ favourites: finalFaves }, () => {
      if (this.cogniflow.current) {
        this.cogniflow.current.reloadCogniflow();
      }
    });
  }

  async onFavouriteClicked(favourite: Favourite, action: ItemAction) {
    if (this.state.selectFavourite === true) {
      // add Id of Favourite to list of selectedFavouriteIDs
      let selectedFavoList = this.state.selectedFavouriteIDs; // current state variable
      if (!selectedFavoList?.includes(favourite.Id)) {
        selectedFavoList?.push(favourite.Id); // add ID
        this.setState({ selectedFavouriteIDs: selectedFavoList }, () => {
          this.cogniflow.current?.updateNodes();
        });
      } else {
        let filteredList = selectedFavoList?.filter((i) => i !== favourite.Id);
        this.setState({ selectedFavouriteIDs: filteredList }, () => {
          this.cogniflow.current?.updateNodes();
        });
      }
    } else {
      switch (action) {
        case ItemAction.item:
          this.setState({ selectedFavourite: favourite.Id }, () => void(async() => {
            await this.context.contentNavigation(NavigationRequest.toFavourite(favourite.Id), NavigationInitiator.favourite);
            this.cogniflow.current?.updateNodes();
          })());
          await this.readFavourite(favourite);
          break;
        case ItemAction.showMore:
          await this.readFavourite(favourite); 
          break;
      }
    }
  }

  async readFavourite(favourite: Favourite) {
    await this.checkChanges();
    this.setState({
      formOpen: true,
      currentFavourite: favourite,
    });
  }

  async onFormToolbarAction(action: FavouriteFormActions) {
    switch (action) {
      case FavouriteFormActions.back:
        await this.closeFavouriteForm();
        break;
      case FavouriteFormActions.save:
        if (this.formRef.current != null) {
          if (action === FavouriteFormActions.save && this.formRef.current.valid()) {
            await this.formRef.current.submit(undefined);
          }
        }
        break;
      case FavouriteFormActions.delete:
        if (this.formRef.current != null) {
          await this.formRef.current.deleteFavourite();
        }
        break;
      case FavouriteFormActions.goto:
        if (this.formRef.current != null) {
          if (this.state.currentFavourite && this.state.currentFavourite !== null) {
            await this.onFavouriteClicked(this.state.currentFavourite, ItemAction.item);
          }
        }
        break;
    }
  }

  onFolderViewToggle() {
    this.setState({
      folderViewOpen: !this.state.folderViewOpen,
    });
  }

  onSelectFavouriteViewToggle = () => {
    this.setState(
      {
        selectFavourite: !this.state.selectFavourite,
        selectedFavouriteIDs: [],
      },
      () => {
        this.cogniflow.current?.updateNodes();
      }
    );
  };

  onClearSelectedFavourites = () => {
    this.setState(
      {
        selectedFavouriteIDs: [],
        currentFavourite: null,
        selectedFavourite: null,
      },
      () => {
        this.cogniflow.current?.updateNodes();
      }
    );
  };

  onPrintSelectedFavourites = async () => {
    let favos: JSX.Element[] = [];
    if (this.state.selectedFavouriteIDs.length === 0) {
      Messages.Notify.error(this.context.localization.currentLocale.FavouriteView.LABEL_ONE_OR_MORE_FAVOURITES);
    } else {
      let previewResult = await this.context.requestFavouritePrintPreviews({
        FavouriteIDs: this.state.selectedFavouriteIDs,
      });
      console.log(previewResult);
      if (!previewResult.valid()) {
        Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
        return;
      }
      previewResult.data.FormattedFavourites.map((favo) => {
        favos.push(<FavouritePrint model={favo} context={this.context} />);
      });

      const div = document.createElement("div");

      const printRoot = ReactDOMClient.createRoot(div);
      printRoot.render(
        <PrintBox headScript="" headStyle={printStyle_css}>
          {favos}
        </PrintBox>
      );
      
      let tries = 0;
      while( div.innerHTML === "" || tries >= 10) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        tries++;
      }

      let result = await this.context.printFavourites({
        FavouriteIDs: this.state.selectedFavouriteIDs,
        FormattedPrintPayload: div.innerHTML,
      });

      if (result.valid() && !Convert.isEmptyOrSpaces(result.data.RawContent)) {
        const win = ResourcePlatform.openNewTabWithContent(result.data.RawContent, "Print Selection", false);
        if (win) {
          setTimeout(() => win.print(), 1000);
        }
      } else if (!result.valid()) {
        Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
      }
    }
  };

  onDeleteSelectedFavourites = async () => {
    if (this.state.selectedFavouriteIDs.length > 0) {
      let resp = await Messages.Dialog.confirm(this.context.localization.currentLocale.FavouriteView.DIALOG_DELETE_SELECTED);
      if (resp === "true") {
        let response = await this.context.deleteFavourites({
          FavouriteIDs: this.state.selectedFavouriteIDs,
        });
        if (response.valid()) {
          this.setState({ selectFavourite: false, selectedFavouriteIDs: [] });
          Messages.Notify.success(this.context.localization.currentLocale.FavouriteView.LABEL_FAVOURITE_DELETED);
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.FavouriteView.ERROR_COULD_NOT_DELETE_SELECTED);
        }
      }
    } else {
      Messages.Notify.error(this.context.localization.currentLocale.FavouriteView.LABEL_ONE_OR_MORE_FAVOURITES);
    }
  };

  onCloseFolderView() {
    this.setState({
      folderViewOpen: false,
    });
  }

  onFolderFilterChanged(filter: { [key: string]: boolean }) {
    let selectedFolders = 0;
    let totalCount = 0;
    let selectedFilter: string[] = [];
    for (let folder in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, folder)) {
        totalCount++;
        if (filter[folder] === true) {
          selectedFolders++;
          selectedFilter.push(folder);
        }
      }
    }

    this.setState(
      {
        folderFilterCount: selectedFolders >= totalCount ? false : selectedFolders,
        currentFilter: selectedFilter,
      },
      (affectedItem?: any, event?: EmitterEvent | undefined) => void this.update(affectedItem, event)
    );
  }

  onGroupingChanged(grouping: FavouriteGrouping) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), FavouriteGrouping: grouping });
    this.setState(
      {
        grouping,
      },
      (affectedItem?: any, event?: EmitterEvent | undefined) => void this.update(affectedItem, event)
    );
  }

  onSortingChanged(sort: FavouriteSorting, reversed: boolean) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), FavouriteSorting: sort, FavouriteSortingReversed: reversed });
    this.setState(
      {
        sorting: sort,
        reversedSort: reversed,
      },
      () => void this.update(undefined, EmitterEvent.update)
    );
  }

  async closeFavouriteForm() {
    await this.checkChanges();
    this.setState({
      formOpen: false,
    });
  }
  async checkChanges() {
    if (this.formRef.current && this.formRef.current.state.edited && this.formRef.current.state.form.formValid()) {
      let result = await Messages.Dialog.confirm(
        this.context.localization.currentLocale.FavouriteView.ALERT_FAVOURITESAVE_PROMPT,
        this.context.localization.currentLocale.FavouriteView.ALERT_FAVOURITESAVE_HEADING
      );
      if (result === "true") {
        await this.formRef.current.submit(undefined);
      } else {
        this.formRef.current.setState({ edited: false });
      }
    }
  }

  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-fave-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-fave-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    batchSize: 20,
  };
  private navigationDone() {}

  groupSelectionClicked(items: Favourite[]) {
    let groupIDs = items.map((groupItem) => groupItem.Id);
    const filteredArray = groupIDs.filter((value) => this.state.selectedFavouriteIDs.includes(value));
    let newSet = this.state.selectedFavouriteIDs;
    if (filteredArray.length > 0) {
      newSet = newSet.filter((it) => !groupIDs.includes(it));
    } else {
      newSet = this.state.selectedFavouriteIDs.concat(groupIDs);
    }
    this.setState({ selectedFavouriteIDs: newSet });
  }

  private flowProvider(request: IRequest): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let message = request;
      let items: Favourite[] = [];
      if (message.Batches[0].Action === Action.append) {
        let nextId = message.Batches[0].AnchorMainId + 1;
        items = this.state.favourites[0].slice(nextId, nextId + message.Batches[0].BatchSize);
      }
      if (message.Batches[0].Action === Action.prepend) {
        items = this.state.favourites[0].slice(
          message.Batches[0].AnchorMainId - message.Batches[0].BatchSize < 0 ? 0 : message.Batches[0].AnchorMainId - message.Batches[0].BatchSize,
          message.Batches[0].AnchorMainId
        );
      }
      message.Batches[0].Nodes = [];
      message.Batches[0].Nodes = items;
      resolve(message);
    });
  }
  private buildFavouriteItem(node: INode): any {
    let item = node as Favourite;
    item = this.context.favourites.get(item.Id)!;
    return (
      <FavouriteItem
        groupedMode={false}
        key={item.Id}
        item={item}
        onClick={(fav, action) => void this.onFavouriteClicked(fav, action)} 
        language={this.context.localization.currentCulture}
        selected={this.state.selectedFavouriteIDs.includes(item.Id) || this.state.selectedFavourite === item.Id}
      />
    );
  }
  private initializeFlow(anchor?: number): Promise<{ nodes: any[]; targetSpine: number }> {
    return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let result: Favourite[] = this.state.favourites[0].slice(0, 80);
      if (anchor) {
        result = this.state.favourites[0].slice(anchor, anchor + 80);
      }
      resolve({
        nodes: result,
        targetSpine: result.length > 0 ? result[0].Index! : 0,
      });
    });
  }
  render() {
    let lists: JSX.Element;
    // More than one grouping means the
    if (this.state.grouping === FavouriteGrouping.Folder && this.state.favourites.length > 0) {
      let groupHolder = [];
      for (let i = 0; i < this.state.favourites.length; i++) {
        groupHolder.push(
          <GroupedFavouriteList
            key={this.state.favourites[i]![0].Folder}
            items={this.state.favourites[i]!}
            onItemClicked={(fav, action) => void this.onFavouriteClicked(fav, action)}
            language={this.context.appSettings.get().LanguageOverride}
            localization={this.context.localization}
            selectedFavouriteIDs={this.state.selectedFavouriteIDs}
            groupSelectionClicked={this.groupSelectionClicked}
            selectionModeOn={this.state.selectFavourite}
            selectedFavouriteID={this.state.selectedFavourite!}
          />
        );
      }
      lists = (
        <div className="d-flex flex-column full-height full-width">
          <div className="flex-fill scrollable">{groupHolder}</div>
        </div>
      );
    } else if (this.state.favourites.length > 0) {
      lists = (
        <StandaloneCogniflowContainer
          provider={this.flowProvider}
          builder={this.buildFavouriteItem}
          initialize={this.initializeFlow}
          extraSettings={this.settings}
          ref={this.cogniflow}
          key={0}
        />
      );
    } else {
      lists = (
        <span key={0} className="noUcItems">
          {this.context.localization.currentLocale.FavouriteView.LABEL_NO_FAVOURITES}
        </span>
      );
    }

    return (
      <div className="favourite-view full-height d-flex flex-column position-relative">
        <RightFlip
          breakpoint={768}
          className="flex-fill"
          isOpen={this.state.formOpen}
          panelClassName={{
            left: "d-flex flex-column",
            right: "d-flex flex-column border-left bg-body",
          }}
        >
          {{
            left: (
              <React.Fragment>
                <FavouriteToolbar
                  folderFilterCount={this.state.folderFilterCount}
                  onFolderViewToggle={this.onFolderViewToggle}
                  onSelectFavouriteViewToggle={this.onSelectFavouriteViewToggle}
                  onClearSelectedFavourites={this.onClearSelectedFavourites}
                  onPrintSelectedFavourites={() => void this.onPrintSelectedFavourites()}
                  onDeleteSelectedFavourites={() => void this.onDeleteSelectedFavourites()}
                  onSortingChange={this.onSortingChanged}
                  folderViewOpen={this.state.folderViewOpen}
                  selectFavourite={this.state.selectFavourite}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                  initialSort={this.state.sorting}
                  initialReversed={this.state.reversedSort}
                />
                <Expander isOpen={this.state.folderViewOpen} className="bg-light border-top border-bottom">
                  <FavouriteFolderView
                    onFolderFilterChanged={this.onFolderFilterChanged}
                    onGroupingChanged={this.onGroupingChanged}
                    grouping={this.state.grouping}
                  />
                </Expander>
                {lists}
              </React.Fragment>
            ),
            right: (
              <React.Fragment>
                <FavouriteFormToolbar
                  onActionClicked={(a) => void this.onFormToolbarAction(a)}
                  key={this.state.currentFavourite && this.state.currentFavourite !== null ? this.state.currentFavourite.Id : -1}
                  model={
                    (this.state.currentFavourite && this.state.currentFavourite !== null
                      ? this.context.favourites.bind(this.state.currentFavourite)
                      : new Binding<Favourite | undefined>(undefined)) as Binding<Favourite | undefined>
                  }
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                />
                <div className="flex-fill scrollable  listContainer">
                  <FavouriteForm
                    ref={this.formRef}
                    model={
                      (this.state.currentFavourite && this.state.currentFavourite !== null
                        ? this.context.favourites.bind(this.state.currentFavourite)
                        : new Binding<Favourite | undefined>(undefined)) as Binding<Favourite | undefined>
                    }
                  />
                </div>
              </React.Fragment>
            ),
          }}
        </RightFlip>
      </div>
    );
  }
}

interface IFavouriteFolderViewProps {
  grouping: FavouriteGrouping;
  onFolderFilterChanged: (filter: { [key: string]: any })=> void;
  onGroupingChanged: (grouping: FavouriteGrouping)=> void;
}

interface IFavouriteFolderViewState {
  folders: string[];
}
class FavouriteFolderView extends React.Component<IFavouriteFolderViewProps, IFavouriteFolderViewState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IFavouriteFolderViewProps | Readonly<IFavouriteFolderViewProps>) {
    super(props);
    this.state = { folders: new Array<string>() };
    this.onFolderGroupingSwitchChanged = this.onFolderGroupingSwitchChanged.bind(this);
    this.updateFolders = this.updateFolders.bind(this);
  }

  componentDidMount() {
    this.context.favouriteFolders.addListener(this.updateFolders);
    this.updateFolders();
  }

  componentWillUnmount() {
    this.context.favouriteFolders.removeListener(this.updateFolders);
  }

  updateFolders() {
    this.setState({
      folders: this.context.favouriteFolders.get(),
    });
  }

  onFolderGroupingSwitchChanged(event: any) {
    this.props.onGroupingChanged(event.target.checked ? FavouriteGrouping.Folder : FavouriteGrouping.None);
  }

  render() {
    return (
      <div>
        <div className="p-3">
          <label>{this.context.localization.currentLocale.FavouriteView.LABEL_GROUPFAVOURITES}</label>
          <Switch on={this.props.grouping === FavouriteGrouping.Folder} onChange={this.onFolderGroupingSwitchChanged} className="ml-3" />
        </div>
        <FilteringMenu onFilterChanged={this.props.onFolderFilterChanged}>
          {this.state.folders &&
            this.state.folders.map((folder: string) => (
              <FilteringItem key={folder} value={folder}>
                {folder}
              </FilteringItem>
            ))}
        </FilteringMenu>
      </div>
    );
  }
}

interface IFavouriteToolbarState {}

interface IFavouriteToolbarProps {
  onFolderViewToggle: ()=> void;
  onSelectFavouriteViewToggle: ()=> void;
  onClearSelectedFavourites: ()=> void;
  onPrintSelectedFavourites: ()=> void;
  onDeleteSelectedFavourites: ()=> void;
  onSortingChange: (sort: FavouriteSorting, reversed: boolean)=> void;
  folderViewOpen: boolean;
  selectFavourite: boolean;
  folderFilterCount: false | number;
  localization: Locale;
  helpEnabled: boolean;
  initialSort: FavouriteSorting;
  initialReversed: boolean;
}
class FavouriteToolbar extends React.Component<IFavouriteToolbarProps, IFavouriteToolbarState> {
  constructor(props: IFavouriteToolbarProps | Readonly<IFavouriteToolbarProps>) {
    super(props);
  }

  render() {
    let filterColor = this.props.folderFilterCount === 0 ? "danger" : "primary";
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <UncontrolledDropdown>
              <DropdownToggle caret color="link" data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_SORT_BUTTON}>
                <Icon src={<Image.sort />} />
              </DropdownToggle>
              <SortDropdownMenu initial={this.props.initialSort} initialReversed={this.props.initialReversed} onSortSelected={this.props.onSortingChange}>
                <SortDropdownItem onClick={this.props.onSortingChange} value={FavouriteSorting.Recent}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={FavouriteSorting.ReadingOrder}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_READING_ORDER}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={FavouriteSorting.Title}>
                  {this.props.localization.currentLocale.FavouriteView.LABEL_TITLE}
                </SortDropdownItem>
              </SortDropdownMenu>
            </UncontrolledDropdown>
            <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.AnnotationView.LABEL_TOGGLE_FOLDER}>
              <ActionIcon onClick={this.props.onFolderViewToggle} src={<Image.folder />} active={this.props.folderViewOpen} />
            </NavItem>
            <NavItem>
              {this.props.folderFilterCount !== false && (
                <Badge color={filterColor}>
                  <Icon src={<Image.filter />} /> {this.props.folderFilterCount}{" "}
                </Badge>
              )}
            </NavItem>
            {
              <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.FavouriteView.LABEL_FAVOURITE_PRINT_SHARE}>
                <ActionIcon src={<Image.select2 />} active={this.props.selectFavourite} onClick={this.props.onSelectFavouriteViewToggle} />
              </NavItem>
            }
            {this.props.helpEnabled && <Tooltip id="favouriteToolbar" place="bottom" variant="info" className="primaryColoured" />}
          </Nav>
        </Collapse>

        <Nav navbar={true}>
          <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.FavouriteView.LABEL_CLEAR_SELECTED}>
            {this.props.selectFavourite === true && <ActionIcon src={<Image.stack_cancel />} onClick={this.props.onClearSelectedFavourites} />}
          </NavItem>
          {ResourcePlatform.CanPrint() && (
            <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.FavouriteView.LABEL_PRINT_FAVOURITES}>
              {this.props.selectFavourite === true && <ActionIcon src={<Image.print />} onClick={this.props.onPrintSelectedFavourites} />}
            </NavItem>
          )}
          <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.FavouriteView.LABEL_DELETE_SELECTED}>
            {this.props.selectFavourite === true && <ActionIcon src={<Image.delete_item />} onClick={this.props.onDeleteSelectedFavourites} />}
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

enum FavouriteFormActions {
  back,
  delete,
  save,
  goto,
}

interface IFavouriteFormToolbarProps extends IModelBinding<Favourite | undefined> {
  onActionClicked: (action: FavouriteFormActions)=> void;
  localization: Locale;
  helpEnabled: boolean;
}

interface IFavouriteFormToolbarState {
  actionsEnabled: boolean;
}
class FavouriteFormToolbar extends React.Component<IFavouriteFormToolbarProps, IFavouriteFormToolbarState> {
  constructor(props: IFavouriteFormToolbarProps | Readonly<IFavouriteFormToolbarProps>) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.state = { actionsEnabled: false };
  }

  onModelUpdate() {
    if (this.props.model.current && this.props.model.current !== null) {
      this.setState({
        actionsEnabled: true,
      });
    } else {
      this.setState({
        actionsEnabled: false,
      });
    }
  }

  componentDidMount() {
    this.props.model.on(this.onModelUpdate);
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_BACK}>
              <NavLink className="flip-back" onClick={() => this.props.onActionClicked(FavouriteFormActions.back)}>
                <Icon src={<Image.arrowback />} /> <small>{this.props.localization.currentLocale.FavouriteView.LABEL_FAVORITEVIEW}</small>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav navbar={true}>
          <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_GOTO}>
            <ActionIcon src={<Image.target />} enabled={this.state.actionsEnabled} onClick={() => this.props.onActionClicked(FavouriteFormActions.goto)} />
          </NavItem>
          <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_DELETE}>
            <ActionIcon
              src={<Image.delete_item />}
              enabled={this.state.actionsEnabled}
              onClick={() => this.props.onActionClicked(FavouriteFormActions.delete)}
            />
          </NavItem>
          <NavItem data-tooltip-id="favouriteToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.ALERT_SAVE}>
            <ActionIcon src={<Image.save />} enabled={this.state.actionsEnabled} onClick={() => this.props.onActionClicked(FavouriteFormActions.save)} />
          </NavItem>
          {this.props.helpEnabled && <Tooltip id="favouriteToolbar" place="bottom" variant="info" className="primaryColoured" />}
        </Nav>
      </Navbar>
    );
  }
}

export interface IFavouriteListProps {
  items: Favourite[];
  onItemClicked: (item: Favourite, action: ItemAction)=> void;
  selectedFavouriteIDs: number[];
  localization: Locale;
}
interface IFvaouriteGroupedListProps {
  items: Favourite[];
  language: Languages;
  onItemClicked: (item: Favourite, action: ItemAction)=> void;
  selectedFavouriteIDs: number[];
  selectedFavouriteID: number;
  selectionModeOn: boolean;
  groupSelectionClicked: (items: Favourite[]) => void;
  localization: Locale;
}

export class FavouriteList extends React.Component<IFavouriteListProps, unknown> {
  render() {
    return (
      <div>
        {this.props.items &&
          this.props.items.map((item: Favourite) => (
            <FavouriteItem
              groupedMode={false}
              key={item.Id}
              item={item}
              onClick={this.props.onItemClicked}
              language={this.props.localization.currentCulture}
              selected={this.props.selectedFavouriteIDs.includes(item.Id)}
            />
          ))}
      </div>
    );
  }
}

export interface IGroupedFavouriteListState {
  isOpen: boolean;
}
class GroupedFavouriteList extends React.Component<IFvaouriteGroupedListProps, IGroupedFavouriteListState> {
  constructor(props: IFvaouriteGroupedListProps) {
    super(props);
    this.onOpenToggle = this.onOpenToggle.bind(this);
    this.conditionalOnOpenToggle = this.conditionalOnOpenToggle.bind(this);
    this.state = { isOpen: false };
  }

  onOpenToggle(e: React.MouseEvent) {
    e.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }
  conditionalOnOpenToggle(e: React.MouseEvent) {
    console.log(this.props.selectionModeOn);
    if (this.props.selectionModeOn) {
      this.props.groupSelectionClicked(this.props.items);
    } else {
      this.onOpenToggle(e);
    }
  }

  render() {
    let groupIDs = this.props.items.map((groupItem) => groupItem.Id);
    let allSelected = "";
    if (groupIDs.filter((value) => this.props.selectedFavouriteIDs.includes(value)).length === this.props.items.length) {
      allSelected = "allSelected";
    }

    return (
      <div className={classnames("grouped-container", allSelected)}>
        <div className="d-flex align-items-center justify-content-between" onClick={this.conditionalOnOpenToggle}>
          <label className="grouped-header-label">
            {Convert.isEmptyOrSpaces(this.props.items[0].Folder)
              ? this.props.localization.currentLocale.FavouriteView.LABEL_NOFOLDER
              : this.props.items[0].Folder}
          </label>
          <ExpanderClose isOpen={this.state.isOpen} onClick={this.onOpenToggle} />
        </div>
        <Expander isOpen={this.state.isOpen}>
          {this.props.items &&
            this.state.isOpen &&
            this.props.items.map((item: Favourite) => (
              <FavouriteItem
                groupedMode={true}
                key={item.Id}
                item={item}
                onClick={this.props.onItemClicked}
                language={this.props.localization.currentCulture}
                selected={this.props.selectedFavouriteIDs.includes(item.Id) || this.props.selectedFavouriteID === item.Id}
              />
            ))}
        </Expander>
      </div>
    );
  }
}

interface IFavouriteItemProps {
  item: Favourite;
  language: Languages;
  groupedMode: boolean;
  onClick: (item: Favourite, action: ItemAction)=> void;
  selected?: boolean;
}

export interface IFavouriteItemState {
  isSelected: boolean;
}

enum ItemAction {
  item,
  showMore,
}
class FavouriteItem extends React.Component<IFavouriteItemProps, IFavouriteItemState> {
  constructor(props: IFavouriteItemProps | Readonly<IFavouriteItemProps>) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onShowMoreClick = this.onShowMoreClick.bind(this);
    this.state = { isSelected: false };
  }

  private onClick() {
    this.props.onClick(this.props.item, ItemAction.item);
  }

  private onShowMoreClick() {
    this.props.onClick(this.props.item, ItemAction.showMore);
  }

  render() {
    let section: string | undefined = this.props.item.Section;
    if (this.props.item.Value === section) {
      if (!Convert.isEmptyOrSpaces(this.props.item.Folder) || this.props.groupedMode) {
        section = Convert.dateToFormattedString(this.props.item.LastUpdate, this.props.language);
      } else {
        section = undefined;
      }
    }
    let folderGrouping: any = "";
    if (this.props.item.Folder && !this.props.groupedMode) {
      folderGrouping = (
        <span>
          <Icon src={<Image.folder />} className="text-muted valign-text-bottom" /> {this.props.item.Folder}
        </span>
      );
    } else if (this.props.item.LastUpdate) {
      folderGrouping = <span>{Convert.dateToFormattedString(this.props.item.LastUpdate, this.props.language)}</span>;
    }
    return (
      <ItemCard onClick={this.onClick} showMore={true} onShowMoreClick={this.onShowMoreClick} selected={this.props.selected}>
        {{
          title: (
            <span>
              <Icon src={<Image.favourite />} className="text-muted valign-text-bottom" /> {this.props.item.Value}
            </span>
          ),
          subtitle: section,
          description: folderGrouping,
        }}
      </ItemCard>
    );
  }
}

interface IFavouriteFormProps extends IModelBinding<Favourite | undefined> {}

interface IFavouriteFormState {
  form: FormState;
  edited: boolean;
  suggestions: string[];
}
class FavouriteForm extends React.Component<IFavouriteFormProps, IFavouriteFormState> {
  context: Book;
  static contextType = BookContext;
  content: PreviewHandler;
  previewRef: React.RefObject<StandaloneCogniflowFrameContainer>;
  constructor(props: IFavouriteFormProps | Readonly<IFavouriteFormProps>) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.deleteFavourite = this.deleteFavourite.bind(this);
    this.extendedGenerateSegment = this.extendedGenerateSegment.bind(this);
    this.buildPopup = this.buildPopup.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.scrollToPreview = this.scrollToPreview.bind(this);
    this.insertedSegmentCallback = this.insertedSegmentCallback.bind(this);
    this.handleFolderInput = this.handleFolderInput.bind(this);
    this.handleFolderInputBlurred = this.handleFolderInputBlurred.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
    this.previewRef = React.createRef<StandaloneCogniflowFrameContainer>();
    let form = new FormState({
      id: 0,
      title: "",
      folder: "",
      lastUpdate: new Date(),
      location: "",
    });
    this.submit = this.submit.bind(this);
    this.state = {
      form,
      edited: false,
      suggestions: [],
    };
  }

  onModelUpdate() {
    if (this.props.model.current) {
      const favourite = this.props.model.current;
      this.setState({
        form: this.state.form.update({
          id: favourite.Id,
          title: favourite.Value,
          folder: favourite.Folder,
          lastUpdate: Convert.dateToFormattedString(favourite.LastUpdate, this.context.appSettings.get().LanguageOverride),
          location: favourite.Location,
        }),
        edited: false,
      });
    } else {
      this.setState({
        form: this.state.form.reset(),
        edited: false,
      });
    }
  }

  handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      form: this.state.form.change(event.target),
      edited: true,
    });
  }

  componentDidMount() {
    this.setState({ suggestions: this.context.favouriteFolders.get() });
  }

  componentDidUpdate() {
    if (this.props.model.current && this.state.form.values.id !== this.props.model.current.Id) {
      this.props.model.off(this.onModelUpdate);
      this.props.model.on(this.onModelUpdate);
      if (this.props.model.current) {
        this.previewRef.current!.flow()!.reloadCogniflow();
      }
      this.state.form.addValidator(
        "title",
        Validator.notNullOrWhitespace,
        this.context.localization.currentLocale.FavouriteView.ALERT_FAVORITENAMEREQUIRED_PROMPT
      );
    }
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  async submit(event?: any) {
    if (event) {
      event.preventDefault();
    }
    let form = this.state.form;
    if (this.state.edited) {
      if (form.formValid()) {
        let result = await this.context.updateFavourite({
          Id: form.values.id,
          Title: form.values.title,
          Folder: form.values.folder,
        });
        if (result.valid()) {
          Messages.Notify.success(this.context.localization.currentLocale.FavouriteView.LABEL_FAVOURITE_UPDATED);
          this.setState({ form: this.state.form.reset(), edited: false });
        } else {
          Messages.Notify.error(result.errors[0].Message);
        }
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.FavouriteView.LABEL_FAVOURITEERROR);
      }
    }
  }
  async deleteFavourite(): Promise<boolean> {
    if (
      (await Messages.Dialog.confirm(
        this.context.localization.currentLocale.FavouriteView.ALERT_CONFIRM_DELETE_PROMPT,
        this.context.localization.currentLocale.FavouriteView.LABEL_DELETE_FAVOURITE
      )) === "true"
    ) {
      let form = this.state.form;
      let result = await this.context.deleteFavourite({
        Id: form.values.id,
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.FavouriteView.LABEL_FAVOURITE_DELETED);
        this.setState({ form: this.state.form.reset(), edited: false });
        return true;
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.FavouriteView.LABEL_FAVOURITEERROR);
      }
    }
    return false;
  }

  valid(): boolean {
    return this.state.form.formValid();
  }
  private async flowProvider(): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let response: IResponse = { Batches: [] };
      resolve(response);
    });
  }
  private async initializeFlow(anchor?: number, searchQuery?: string, recurse?: boolean): Promise<{ nodes: any[]; targetSpine: number }> {
    if (!this.props.model.current) {
      return new Promise<{ nodes: any[]; targetSpine: number }>(() => {});
    }
    if (!this.content || recurse) {
      this.content = new PreviewHandler(this.previewRef.current!.flow()!, this.previewRef.current!.document()!, this.context, this.props.model.current);
      if (this.content.apiServer.isInitialized() === true) {
        await this.previewRef.current!.loadBodyScripts();
      } else {
        return this.delay(100).then(async () => this.initializeFlow(anchor, "", true));
      }
    }
   
    let result: ActionResult<IGetUCPreviewR> = await this.context.getUCPreview({
      FavouriteId: this.props.model.current ? this.props.model.current.Id : -1,
      AnnotationId: -1,
    });

    if (result.valid()) {
      this.content.updateInstance(
        this.previewRef.current!.flow()!,
        this.previewRef.current!.document()!,
        result.data.PreviewPopup,
        result.data.PreviewPopupParent,
        this.context,
        this.props.model.current
      );
      return {
        nodes: result.data.Preview,
        targetSpine: result.data.Preview[0].SpineId,
      }
    } else {
      const msg = result.errors && result.errors.length > 0 ? result.errors[0].Message : "Error getting preview.";
      throw new Error(msg);
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  private extendedGenerateSegment(node: INode, attributes: any, key: number | undefined): JSX.Element {
    return (
      <ConcreteContentSegment
        classes={attributes["className"]}
        headAttr={this.settings.segmentDataDescriptor.secondaryIdDataAttribute}
        head={attributes[this.settings.segmentDataDescriptor.secondaryIdDataAttribute]}
        spineAttr={this.settings.segmentDataDescriptor.mainIdDataAttribute}
        spine={attributes[this.settings.segmentDataDescriptor.mainIdDataAttribute]}
        key={key}
        originalModel={this.props.model.current}
        item={node as ContentSegment}
        segmentAdded={this.content.apiServer ? this.content.apiServer.triggerSegmentAdded : () => {}}
      />
    );
  }
  private insertedSegmentCallback() {
    this.scrollToPreview();
  }
  private scrollToPreview() {
    this.content.adjustFontSize();
    setTimeout(() => {
      this.content.scrollToPreview();
    }, 100);
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = (value: any) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value: any) => {
    const inputValue = value.value.trim().toLowerCase();
    const inputLength = inputValue.length as number;
    if (Convert.isEmptyOrSpaces(inputValue as string)) {
      return this.context.favouriteFolders.get();
    }
    return this.context.favouriteFolders
      .get()
      .filter((folder) => folder.toLowerCase().slice(0, inputLength) === inputValue)
      .sort((a, b) => a.localeCompare(b));
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(folder: any) {
    return folder;
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion: string) {
    return <div className="suggestionItem">{suggestion}</div>;
  }
  handleFolderInput(event: any, arg: any) {
    this.state.form.update({ folder: arg.newValue });
    this.setState({
      form: this.state.form.update({ folder: arg.newValue }),
      edited: true,
    });
  }
  handleFolderInputBlurred() {
    this.setState({
      suggestions: [],
    });
  }
  shouldRenderSuggestions(): boolean {
    return true;
  }
  settings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "SpineId",
      mainIdDataAttribute: "data-cogniflow--spine",
      secondaryIdDataAttribute: "data-cogniflow--headid",
      secondaryIdNodeAttribute: "HeadId",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      contentAttribute: "ContentSegment",
      applyDirectlyToSegment: true,
    },
    segmentContainerClasses: "html body",
    scrollingClasses: "BookView tex2jax_ignore",
    batchSize: 3,
  };
  buildPopup(pop: INode) {
    return this.content.buildPopup(pop as ContentPopupModel);
  }
  render() {
    let form = this.state.form;
    // Autosuggest will pass through all these props to the input.
    let value = form.values.folder;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.handleFolderInput,
      onBlur: this.handleFolderInputBlurred,
    };
    if (this.context.style !== undefined && this.context.javascript !== undefined) {
      return (
        <Form
          className="p-2 form-condensed"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FormGroup>
            <Label for="title">{this.context.localization.currentLocale.FavouriteView.LABEL_TITLE}</Label>
            <Input type="text" name="title" id="title" value={form.values.title} invalid={form.invalid("title")} onChange={this.handleInput} />
            <FormFeedback tooltip={true}>{form.getErrors("title")}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="folder">{this.context.localization.currentLocale.FavouriteView.LABEL_FOLDER}</Label>
            <div className="autoSuggestContainerDiv">
              <Autosuggest
                focusInputOnSuggestionClick={true}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
              />
            </div>{" "}
            <FormFeedback tooltip={true}>{form.getErrors("folder")}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{this.context.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}</Label>
            <FormValue>{form.values.lastUpdate && form.values.lastUpdate.toString()}</FormValue>
          </FormGroup>
          <FormGroup>
            <Label>{this.context.localization.currentLocale.FavouriteView.LABEL_FAVORITELOCATION}</Label>
            <FormValue>{form.values.location}</FormValue>
          </FormGroup>
          <FormGroup className="form-group-grow">
            <Label>{this.context.localization.currentLocale.FavouriteView.LABEL_FAVORITEPREVIEW}</Label>
            <StandaloneCogniflowFrameContainer
              ref={this.previewRef}
              className="content-reader"
              provider={this.flowProvider}
              initialize={this.initializeFlow}
              builder={this.extendedGenerateSegment}
              addonBuilder={this.buildPopup}
              segmentsInsertedCallback={this.insertedSegmentCallback}
              navigationDoneCallback={this.scrollToPreview}
              extraSettings={this.settings}
              head={
                <React.Fragment>
                  <style>{content_css}</style>
                  <style>{this.context.style}</style>
                </React.Fragment>
              }
              scripts={[
                {
                  src: content_jquery,
                  head: true,
                },
                {
                  src: content_place,
                  head: true,
                },
                {
                  src: content_api,
                  head: true,
                },
                {
                  src: this.context.javascript,
                  head: false,
                },
                { src: content_mathJax, head: true },
              ]}
            />
          </FormGroup>
        </Form>
      );
    } else {
      return "";
    }
  }
}
