import classnames from 'classnames';
import { saveAs } from 'file-saver';
import mime from 'mime';
import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import {
    Collapse, DropdownToggle, Input, Nav, Navbar, NavItem, NavLink, UncontrolledDropdown
} from 'reactstrap';
import { Languages, Locale } from 'src/localization/Locale';
import { Book, BookLoadingSteps, NavigationInitiator } from 'src/models/Book';
import { NavigationRequest } from 'src/models/Content';
import { ActionResult } from 'src/models/Result';
import {
    CommunitySorting, FormType, UserFormSubmission, UserFormSubmissionAttachment,
    UserFormSubmissionViewModel
} from 'src/models/UserContent';
import { Loading } from 'src/ui/foundation/Controls';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';

import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';

import * as Messages from '../../ui/foundation/Messages';
import { Image } from '../foundation/Assets';
import {
    ActionIcon, Icon, ItemCard, SortDropdownItem, SortDropdownMenu
} from '../foundation/Controls';
import iframeControlTester from '../foundation/Controls/iframeControlTester';
import RichContentControl from '../foundation/Controls/RichContentControl';
import richContentControlTester from '../foundation/Controls/richContentControlTester';
import TableIdWithOffset from '../foundation/Controls/TableIdWithOffset';
import tableIdWithOffsetTester from '../foundation/Controls/tableIdWithOffsetTester';
import { RightFlip } from '../foundation/Layout';
import {
    Action, ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { BookContext } from '../state/Contextes';

interface ICommunityViewProps {
  isActive: boolean;
}

enum UserFormSubmissionItemAction {
  item,
  showMore,
}
export interface ICommunityViewState {
  formOpen: boolean;
  currentSubmission: UserFormSubmissionViewModel | null;
  sortedSubmissions: UserFormSubmissionViewModel[];
  currentFilter: string;
  sorting: CommunitySorting;
  sortingReversed: boolean;
}

export class CommunityView extends React.Component<ICommunityViewProps, ICommunityViewState> {
  context: Book;
  static contextType = BookContext;
  cogniflow = React.createRef<StandaloneCogniflowContainer>();
  divRef: React.RefObject<HTMLDivElement>;

  constructor(props: ICommunityViewProps | Readonly<ICommunityViewProps>) {
    super(props);
    this.state = {
      formOpen: false,
      currentSubmission: null,
      sortedSubmissions: [],
      currentFilter: "",
      sorting: CommunitySorting.Recent,
      sortingReversed: true,
    };

    this.onReady = this.onReady.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.onCommunityContentClick = this.onCommunityContentClick.bind(this);
    this.onSubmissionClicked = this.onSubmissionClicked.bind(this);
    this.update = this.update.bind(this);
    this.buildTipItem = this.buildTipItem.bind(this);
    this.divRef = React.createRef<HTMLDivElement>();
  }

  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.community, this);
    await this.context.initUserFormSubmissions();
    this.context.userFormSubmissions.addListener(this.update);
    this.update();
    this.context.loading.setLoaded(BookLoadingSteps.community);
  }
  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.community, () => void this.onReady());
    this.context.userFormSubmissionRequested.on((id) => void this.onCommunityContentClick(id));
    this.setState({
      currentSubmission: null,
      formOpen: false,
    });
  }

  componentWillUnmount() {
    this.context.userFormSubmissions.removeListener(this.update);
    this.context.userFormSubmissionRequested.off((id) => void this.onCommunityContentClick(id));
  }

  async onCommunityContentClick(id: number) {
    let sub = this.context.userFormSubmissions.get(id);
    if (sub) {
      this.setState(
        { currentSubmission: sub },
        () =>
          void (async () => {
            await this.readSubmission(sub!);
          })()
      );
      return;
    }

    let result = await this.context.getUserFormSubmission({ UserFormSubmissionId: id });
    if (result.valid() && result.data.UserForm) {
      this.setState(
        { currentSubmission: result.data.UserForm },
        () =>
          void (async () => {
            await this.readSubmission(result.data.UserForm);
          })()
      );
    }
  }

  private pullTitle = (title: string, fallback: string) => {
    try {
      let submission = JSON.parse(title);
      title = submission["title"].trim().toLowerCase();
      if (title === null || title === "") {
        title = submission["Title"];
      }
    } catch (ex) {
      title = "";
    }
    if (title === null || title === "") {
      title = fallback;
    }
    return title;
  };

  update = () => {
    if (this.context.userFormSubmissions.rows().length === 0) {
      this.setState(
        {
          sortedSubmissions: [],
        },
        () => {
          if (this.cogniflow.current) {
            this.cogniflow.current.reloadCogniflow();
          }
        }
      );
      return;
    }

    let holder: UserFormSubmissionViewModel[] = [];
    if (!Convert.isEmptyOrSpaces(this.state.currentFilter)) {
      holder = this.context.userFormSubmissions
        .rows()
        .filter(
          (x) =>
            x.Submission.Submission.toLowerCase().includes(this.state.currentFilter.toLowerCase()) ||
            x.Submission.TableGuid.toLowerCase().includes(this.state.currentFilter.toLowerCase())
        );
    } else {
      holder = this.context.userFormSubmissions.rows();
    }

    switch (this.state.sorting) {
      case CommunitySorting.Recent:
        holder = holder.sort((x1, x2) => {
          if (x1.Submission.LastModificationDate! < x2.Submission.LastModificationDate!) {
            return 1;
          }
          if (x1.Submission.LastModificationDate! > x2.Submission.LastModificationDate!) {
            return -1;
          }
          return 0;
        });
        if (!this.state.sortingReversed) {
          holder = holder.reverse();
        }
        break;
      case CommunitySorting.CreationDate:
        holder = holder.sort((x1, x2) => {
          if (x1.Submission.CreationDate! < x2.Submission.CreationDate!) {
            return 1;
          }
          if (x1.Submission.CreationDate! > x2.Submission.CreationDate!) {
            return -1;
          }
          return 0;
        });
        if (!this.state.sortingReversed) {
          holder = holder.reverse();
        }
        break;
      case CommunitySorting.Title:
        holder = holder.sort((x1, x2) => {
          let titlex1 = this.pullTitle(x1.Submission.Submission, x1.Submission.TableGuid);
          let titlex2 = this.pullTitle(x2.Submission.Submission, x2.Submission.TableGuid);
          return titlex1.localeCompare(titlex2);
        });
        if (!this.state.sortingReversed) {
          holder = holder.reverse();
        }
        break;
    }

    if (holder.length === 0) {
      this.setState(
        {
          sortedSubmissions: [],
        },
        () => {
          if (this.cogniflow.current) {
            this.cogniflow.current.reloadCogniflow();
          }
        }
      );
      return;
    }

    // Set indices and first/last
    let count = 0;
    holder.map((x) => {
      x.Index = count;
      count++;
      x.IsFirst = false;
      x.IsLast = false;
    });
    holder[0].IsFirst = true;
    holder[holder.length - 1].IsLast = true;

    this.setState(
      {
        sortedSubmissions: holder,
      },
      () => {
        if (this.cogniflow.current) {
          this.cogniflow.current.reloadCogniflow();
        }
      }
    );
  };
  private flowProvider(request: IRequest): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let message = request;
      let items: UserFormSubmissionViewModel[] = [];
      if (message.Batches[0].Action === Action.append) {
        let nextId = message.Batches[0].AnchorMainId + 1;
        items = this.state.sortedSubmissions.slice(nextId, nextId + message.Batches[0].BatchSize);
      }
      if (message.Batches[0].Action === Action.prepend) {
        items = this.state.sortedSubmissions.slice(
          message.Batches[0].AnchorMainId - message.Batches[0].BatchSize < 0 ? 0 : message.Batches[0].AnchorMainId - message.Batches[0].BatchSize,
          message.Batches[0].AnchorMainId
        );
      }
      message.Batches[0].Nodes = [];
      message.Batches[0].Nodes = items;
      resolve(message);
    });
  }
  readSubmission = async (submission: UserFormSubmissionViewModel) => {
    await this.checkChanges();
    this.setState(
      {
        formOpen: true,
        currentSubmission: submission,
      },
      () => {
        this.cogniflow.current!.updateNodes();
      }
    );
  };
  async checkChanges() {
    // Change handling for the submission form. But it may not be needed as the form is not editable here.
    /* if (this.formRef.current && this.formRef.current.state.edited && this.formRef.current.state.form.formValid()) {
      let result = await Messages.Dialog.confirm(
        this.context.localization.currentLocale.FavouriteView.ALERT_FAVOURITESAVE_PROMPT,
        this.context.localization.currentLocale.FavouriteView.ALERT_FAVOURITESAVE_HEADING
      );
      if (result === "true") {
        await this.formRef.current.submit(undefined);
      } else {
        this.formRef.current.setState({ edited: false });
      }
    }*/
  }
  onSubmissionClicked = async (submission: UserFormSubmissionViewModel, action: UserFormSubmissionItemAction) => {
    switch (action) {
      case UserFormSubmissionItemAction.item:
        if (submission.Submission.SegmentId !== null) {
          await this.context.contentNavigation(NavigationRequest.toHeader(submission.Submission.SegmentId), NavigationInitiator.toc);
        } else {
          await this.readSubmission(submission);
        }
        break;
      case UserFormSubmissionItemAction.showMore:
        await this.readSubmission(submission);
        break;
    }
  };
  buildTipItem = (node: INode): any => {
    let item = node as UserFormSubmissionViewModel;
    item = this.context.userFormSubmissions.get(item.Submission.TableGuid)!;
    return (
      <UserFormSubmissionItem
        groupedMode={false}
        key={item.Submission.TableId}
        item={item}
        onClick={(submission, action) => void this.onSubmissionClicked(submission, action)}
        language={this.context.localization.currentCulture}
        selectedSubmission={this.state.currentSubmission}
      />
    );
  };

  private initializeFlow(anchor?: number): Promise<{ nodes: any[]; targetSpine: number }> {
    return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let result: UserFormSubmissionViewModel[] = this.state.sortedSubmissions.slice(0, 80);
      if (anchor) {
        result = this.state.sortedSubmissions.slice(anchor, anchor + 80);
      }
      resolve({
        nodes: result,
        targetSpine: result.length > 0 ? result[0].Index! : 0,
      });
    });
  }
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-fave-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-fave-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    batchSize: 20,
  };
  onSortingChanged = (value: CommunitySorting, reversed: boolean) => {
    this.setState({ sorting: value, sortingReversed: reversed }, () => this.update());
  };
  onFilterChanged = (filter: string) => {
    this.setState({ currentFilter: filter }, () => this.update());
  };
  printCurrentTip = async ()  => {
    if (this.state.currentSubmission === null || !this.divRef || !this.divRef.current) {
      return;
    }
    // Getting the selection content
    const selectedHtml = this.divRef.current.innerHTML;
    const div = document.createElement("div");
    if (selectedHtml) {
      div.innerHTML = selectedHtml;
      let iframes = div.querySelectorAll('iframe');
      [].forEach.call(iframes, (iframe) => {
        (iframe as any).style.width = "100%";
      });
    }
    
    // Piggyback to print arbitrary markup.
    let result = await this.context.printFavourites({
      FavouriteIDs: [],
      FormattedPrintPayload: div.innerHTML,
    });

    if (result.valid() && !Convert.isEmptyOrSpaces(result.data.RawContent)) {
      const win = ResourcePlatform.openNewTabWithContent(result.data.RawContent,  "Print Tip", true);
      if (win) {
        setTimeout(() => win.print(), 1000);
      }
    } else if (!result.valid()) {
      Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
    }
  };
  render() {
    let list: JSX.Element;
    list = (
      <StandaloneCogniflowContainer
        provider={this.flowProvider}
        builder={this.buildTipItem}
        initialize={this.initializeFlow}
        extraSettings={this.settings}
        ref={this.cogniflow}
        key={0}
      />
    );
    let detail: JSX.Element;
    detail = (
      <React.Fragment>
        <CommunityFormToolbar
          backCalled={() => {
            this.setState({ currentSubmission: null, formOpen: false });
            return Promise.resolve();
          }}
          helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
          localization={this.context.localization}
          printCalled={this.printCurrentTip}
        />
        <div ref={this.divRef} className="innerCommunity flex-fill scrollable">
          <div className="communityFormContainer" style={{ display: "flex" }}>
            <CommunityForm initialNode={this.state.currentSubmission} localization={this.context.localization} />
          </div>
          <br />
        </div>
      </React.Fragment>
    );
    if (this.state.sortedSubmissions.length === 0) {
      list = <span className="noUcItems">{this.context.localization.currentLocale.CommunityView.LABEL_NO_SUBMISSIONS}</span>;
    }
    return (
      <div className="community-view full-height d-flex flex-column position-relative">
        <RightFlip
          breakpoint={768}
          className="flex-fill"
          isOpen={this.state.formOpen}
          panelClassName={{
            left: "d-flex flex-column",
            right: "d-flex flex-column border-left bg-body",
          }}
        >
          {{
            left: (
              <React.Fragment>
                <CommunityToolbar
                  onFilterChange={this.onFilterChanged}
                  onSortingChange={this.onSortingChanged}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                  initialSort={this.state.sorting}
                  initialReversed={this.state.sortingReversed}
                />
                {list}
              </React.Fragment>
            ),
            right: detail,
          }}
        </RightFlip>
      </div>
    );
  }
}
interface ISubmissionItemProps {
  item: UserFormSubmissionViewModel;
  language: Languages;
  groupedMode: boolean;
  onClick: (item: UserFormSubmissionViewModel, action: UserFormSubmissionItemAction) => void;
  selectedSubmission: UserFormSubmissionViewModel | null;
}

class UserFormSubmissionItem extends React.Component<ISubmissionItemProps, unknown> {
  context: Book;
  static contextType = BookContext;
  constructor(props: ISubmissionItemProps | Readonly<ISubmissionItemProps>) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onShowMoreClick = this.onShowMoreClick.bind(this);
  }

  private onClick() {
    this.props.onClick(this.props.item, UserFormSubmissionItemAction.item);
  }

  private onShowMoreClick() {
    this.props.onClick(this.props.item, UserFormSubmissionItemAction.showMore);
  }

  private pullTitle = () => {
    let title: string | null;

    try {
      let submission = JSON.parse(this.props.item.Submission.Submission);
      title = submission["title"];

      if (title === null || title === "") {
        title = submission["Title"];
      }
    } catch (ex) {
      title = null;
    }

    if (title === null || title === "") {
      title = this.props.item.Submission.TableGuid;
    }

    return title;
  };
  private pullFormTypeFriendly = () => {
    switch (this.props.item.Definition.FormType) {
      case FormType.Bulletin: {
        return this.context.localization.currentLocale.CommunityView.LABEL_BULLETIN;
      }
      case FormType.Tip: {
        return this.context.localization.currentLocale.CommunityView.LABEL_TIP;
      }
      default: {
        return "Unknown";
      }
    }
  };
  private pullFormIconFriendly = () => {
    switch (this.props.item.Definition.FormType) {
      case FormType.Bulletin: {
        return <Image.bulletin />;
      }
      default: {
        return <Image.tip />;
      }
    }
  };

  render() {
    let title = this.pullTitle();
    const currentDate = new Date();
    const date30DaysAgo = new Date();
    date30DaysAgo.setDate(currentDate.getDate() - 30);
    const lastModificationDate = new Date(this.props.item.Submission.LastModificationDate!);
    const formattedLastModificationDate = Convert.dateToFormattedString(lastModificationDate, this.props.language);
    const finalFormattedLastModifiedDate =
      lastModificationDate > date30DaysAgo ? <b>{formattedLastModificationDate}</b> : <span>{formattedLastModificationDate}</span>;
    return (
      <ItemCard
        onClick={this.onClick}
        showMore={true}
        onShowMoreClick={this.onShowMoreClick}
        selected={this.props.selectedSubmission?.Submission.TableId === this.props.item.Submission.TableId}
      >
        {{
          title: (
            <span>
              <Icon src={this.pullFormIconFriendly()} className="text-muted valign-text-bottom" /> {title}
            </span>
          ),
          subtitle: this.pullFormTypeFriendly(),
          description: (
            <span>
              {this.context.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED + " "}
              {finalFormattedLastModifiedDate}
            </span>
          ),
        }}
      </ItemCard>
    );
  }
}

export interface ICommunityFormProps {
  initialNode: UserFormSubmissionViewModel | null;
  localization: Locale;
  deleteRequested?: (node: UserFormSubmission) => void;
  saveRequested?: (node: UserFormSubmission) => void;
  reloadCommunityForms?: () => void;
}

export interface ICommunityFormState {
  loading: boolean;
  wholeFormLoading: boolean;
  attachments: UserFormSubmissionAttachment[];
}

export class CommunityForm extends React.Component<ICommunityFormProps, ICommunityFormState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: ICommunityFormProps | Readonly<ICommunityFormProps>) {
    super(props);
    this.state = {
      loading: false,
      wholeFormLoading: false,
      attachments: [],
    };
  }
  componentDidUpdate(prevProps: ICommunityFormProps) {
    if (
      (this.props.initialNode !== null && prevProps.initialNode === null) ||
      (prevProps.initialNode !== null &&
        this.props.initialNode !== null &&
        prevProps.initialNode.Submission.TableGuid !== this.props.initialNode.Submission.TableGuid)
    ) {
      this.setState(
        { loading: true },
        () => void (async () => this.setState({ attachments: await this.getAttachments() }, () => this.setState({ loading: false })))()
      );
    }
    if (this.props.initialNode === null && this.state.attachments.length > 0) {
      this.setState({ attachments: [] });
    }
  }
  private getAttachments = async () => {
    let result: ActionResult<UserFormSubmissionAttachment[]> = await this.context.getAttachments({
      UserFormSubmissionRef: this.props.initialNode!.Submission.TableGuid,
    });
    if (result.valid()) {
      return result.data;
    } else {
      return [];
    }
  };
  handleContextNavigate = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      { wholeFormLoading: true },
      () =>
        void (async () => {
          await this.context.reportExternalLink({ Url: Convert.DeepLinkUrlToNative(this.props.initialNode!.Submission.DeepLink)! });
          setTimeout(() => {
            this.setState({ wholeFormLoading: false });
          }, 500);
        })()
    );
  };
  IsResourceUnknown = (attachment: UserFormSubmissionAttachment): boolean => {
    if (attachment.AttachmentExtension === ".zip" || attachment.AttachmentExtension === ".tif" || attachment.AttachmentExtension === ".xlsx") {
      return true;
    } else {
      return false;
    }
  };

  private downloadAttachment = (attachment: UserFormSubmissionAttachment) => {
    let binaryString = window.atob(attachment.AttachmentData.toString());
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    let mimeType = mime.getType(attachment.AttachmentExtension);
    let mimeTypeString = mimeType as string;

    let blob = new Blob([bytes], { type: mimeTypeString });
    saveAs(blob, attachment.Name + attachment.AttachmentExtension);
  };

  private saveAttachment = async (attachment: UserFormSubmissionAttachment) => {
    if (ResourcePlatform.CanDownloadAttachmentsNatively()) {
      let result = await this.context.downloadAttachmentNative({
        UserFormAttachmentRef: attachment.TableGuid,
      });
      if (!result.valid()) {
        this.downloadAttachment(attachment);
      }
    } else {
      this.downloadAttachment(attachment);
    }
  };

  render() {
    const renderers = [
      ...materialRenderers,
      // register custom renderers
      { tester: iframeControlTester, renderer: RichContentControl },
      { tester: tableIdWithOffsetTester, renderer: TableIdWithOffset },
      { tester: richContentControlTester, renderer: RichContentControl },
    ];
    if (this.props.initialNode === null) {
      return (
        <Loading
          className="full-width full-height"
          isLoading={this.state.loading}
          theme="opaque"
          status={this.context.localization.currentLocale.CommunityView.LABEL_LOADING_TIP}
        >
          <div className="form-container">
            <div className="submissionInfo">
              <h1>{this.context.localization.currentLocale.CommunityView.LABEL_SUBMISSION_INFORMATION}</h1>
              <span>{this.context.localization.currentLocale.CommunityView.LABEL_CREATED_ON}</span>
              <span>{this.context.localization.currentLocale.CommunityView.LABEL_MODIFIED_ON}</span>
              <span>{this.context.localization.currentLocale.CommunityView.LABEL_SUBMISSION_REF}</span>
            </div>
          </div>
        </Loading>
      );
    }
    let style = {};
    if (this.state.loading) {
      style = { minHeight: "100pt" };
    }
    const currentDate = new Date();
    const date30DaysAgo = new Date();
    date30DaysAgo.setDate(currentDate.getDate() - 30);
    const lastModificationDate = new Date(this.props.initialNode.Submission.LastModificationDate!);
    const formattedLastModificationDate = Convert.dateToFormattedString(lastModificationDate, this.props.localization.currentCulture);
    const creationDate = new Date(this.props.initialNode.Submission.CreationDate!);
    const formattedCreationDate = Convert.dateToFormattedString(creationDate, this.props.localization.currentCulture);
    return (
      <Loading
        className="full-width full-height"
        isLoading={this.state.wholeFormLoading}
        theme="opaque"
        status={this.context.localization.currentLocale.CommunityView.LABEL_LOADING_TIP}
      >
        <div className="form-container">
          <div className="submissionInfo">
            <h1>{this.context.localization.currentLocale.CommunityView.LABEL_SUBMISSION_INFORMATION}</h1>
            <span>
              {this.context.localization.currentLocale.CommunityView.LABEL_CREATED_ON}
              {": "}
              {creationDate > date30DaysAgo ? <b>{formattedCreationDate}</b> : formattedCreationDate}
            </span>
            <span>
              {this.context.localization.currentLocale.CommunityView.LABEL_MODIFIED_ON}
              {": "}
              {lastModificationDate > date30DaysAgo ? <b>{formattedLastModificationDate}</b> : formattedLastModificationDate}
            </span>
            <span>
              {this.context.localization.currentLocale.CommunityView.LABEL_SUBMISSION_REF}
              {": "} {this.props.initialNode.Submission.TableGuid}
            </span>
            {!Convert.isEmptyOrSpaces(this.props.initialNode.Submission.DeepLink) && (
              <span>
                {this.context.localization.currentLocale.CommunityView.LABEL_LOCATION_PROVIDED}
                {": "}{" "}
                <a href="" onClick={this.handleContextNavigate}>
                  {this.context.localization.currentLocale.CommunityView.LABEL_CLICK_NAVIGATE}
                </a>
              </span>
            )}
          </div>
          <div>
            <Loading style={style} isLoading={this.state.loading} status={this.context.localization.currentLocale.CommunityView.LABEL_LOADING_ATTACHMENTS}>
              {this.state.attachments.length > 0 && <h6>{this.context.localization.currentLocale.CommunityView.LABEL_ATTACHMENTS}</h6>}
              <div className="attachmentContainer">
                {this.state.attachments.length > 0 &&
                  this.state.attachments.map((x, i) => (
                    <div key={i} className="attachment">
                      <div className="attachmentIcon">
                        <Image.attachment />
                      </div>
                      <a href="#" onClick={() => void this.saveAttachment(x)} key={x.Name + x.AttachmentExtension + i}>
                        {x.Name + x.AttachmentExtension}
                      </a>
                    </div>
                  ))}
              </div>
            </Loading>
          </div>
          <JsonForms
            schema={JSON.parse(this.props.initialNode.Definition.DataSchema)}
            uischema={JSON.parse(this.props.initialNode.Definition.ReaderUiSchema)}
            data={JSON.parse(this.props.initialNode.Submission.Submission)}
            renderers={renderers}
            cells={materialCells}
            readonly={true}
          />
        </div>
      </Loading>
    );
  }
}

interface ICommunityToolbarState {
  currentFilter: string;
  filterEnabled: boolean;
}

interface ICommunityToolbarProps {
  onSortingChange: (sort: CommunitySorting, reversed: boolean) => void;
  onFilterChange: (filter: string) => void;
  localization: Locale;
  helpEnabled: boolean;
  initialSort: CommunitySorting;
  initialReversed: boolean;
}
class CommunityToolbar extends React.Component<ICommunityToolbarProps, ICommunityToolbarState> {
  constructor(props: ICommunityToolbarProps | Readonly<ICommunityToolbarProps>) {
    super(props);
    this.state = { currentFilter: "", filterEnabled: false };
  }

  handleSearch = (e: React.SyntheticEvent) => {
    let value = (e.target as HTMLInputElement).value;
    this.setState({ currentFilter: value }, () => this.props.onFilterChange(value));
  };
  clearSearch = () => {
    this.setState({ currentFilter: "" }, () => this.props.onFilterChange(""));
  };

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <UncontrolledDropdown>
              <DropdownToggle caret color="link" data-tooltip-id="communityToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_SORT_BUTTON}>
                <Icon src={<Image.sort />} />
              </DropdownToggle>
              <SortDropdownMenu initial={this.props.initialSort} initialReversed={this.props.initialReversed} onSortSelected={this.props.onSortingChange}>
                <SortDropdownItem onClick={this.props.onSortingChange} value={CommunitySorting.Recent}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={CommunitySorting.CreationDate}>
                  {this.props.localization.currentLocale.CommunityView.LABEL_DATE_CREATED}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={CommunitySorting.Title}>
                  {this.props.localization.currentLocale.FavouriteView.LABEL_TITLE}
                </SortDropdownItem>
              </SortDropdownMenu>
            </UncontrolledDropdown>
            {this.props.helpEnabled && <Tooltip id="communityToolbar" place="bottom" variant="info" className="primaryColoured" />}
          </Nav>
          <Nav navbar={true} className={"ml-auto"}>
            <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_TOGGLE_LIBRARY_FILTERING}>
              <ActionIcon
                onClick={() =>
                  this.setState({ filterEnabled: !this.state.filterEnabled }, () => {
                    if (!this.state.filterEnabled && this.state.currentFilter !== "") {
                      this.clearSearch();
                    }
                  })
                }
                src={this.state.filterEnabled ? <Image.filteron /> : <Image.filteroff />}
              />
            </NavItem>
            {this.state.filterEnabled && (
              <NavItem className="searchBar" data-for="libraryToolbar">
                <Input onChange={this.handleSearch} placeholder={this.props.localization.currentLocale.AnnotationView.LABEL_NO_FILTER + "..."} />
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

interface ICommunityFormToolbarProps {
  localization: Locale;
  helpEnabled: boolean;
  backCalled(): Promise<void>;
  printCalled(): Promise<void>;
}

interface ICommunityFormToolbarState {}
class CommunityFormToolbar extends React.Component<ICommunityFormToolbarProps, ICommunityFormToolbarState> {
  constructor(props: ICommunityFormToolbarProps | Readonly<ICommunityFormToolbarProps>) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.state = { actionsEnabled: false };
  }

  onModelUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}


  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav>
            <NavItem data-tooltip-id="annotationToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_BACK}>
              <NavLink className="flip-back" onClick={() => this.props.backCalled()}>
                <Icon src={<Image.arrowback />} /> <small>{this.props.localization.currentLocale.Application.LABEL_BACK}</small>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar={true} className={"ml-auto"}>
            <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.props.localization.currentLocale.UserBookUploads.LABEL_UPLOAD_YOUR_OWN}>
              <NavLink className={classnames("icon", "action-icon")} onClick={this.props.printCalled}>
                <Image.print />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
