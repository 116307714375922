export interface ICogniAIModel {
  id: string;
  name: string;
  maxLength: number; // maximum length of a message
  tokenLimit: number;
}

export class CogniAIModel implements ICogniAIModel {
  id: string;
  name: string;
  maxLength: number;
  tokenLimit: number;
}

export enum CogniAIModelID {
  LLAMA2_7B_CHAT_GGML_Q4_0 = 'llama-2-7b-chat-ggml-q4_0',
  LLAMA2_13B_CHAT_GGML_Q4_0 = 'llama-2-13b-chat-ggml-q4_0',
}

// in case the `REACT_APP_DEFAULT_MODEL` environment variable is not set or set to an unsupported model
export const fallbackModelID = CogniAIModelID.LLAMA2_7B_CHAT_GGML_Q4_0;

export const CogniAIModels: Record<CogniAIModelID, CogniAIModel> & Record<string, CogniAIModel> = {
  [CogniAIModelID.LLAMA2_7B_CHAT_GGML_Q4_0]: {
    id: CogniAIModelID.LLAMA2_7B_CHAT_GGML_Q4_0,
    name: 'llama-2-7b-chat-ggml-q4_0',
    maxLength: 4096,
    tokenLimit: 2048,
  },
  [CogniAIModelID.LLAMA2_13B_CHAT_GGML_Q4_0]: {
    id: CogniAIModelID.LLAMA2_13B_CHAT_GGML_Q4_0,
    name: 'llama-2-13b-chat-ggml-q4_0',
    maxLength: 4096,
    tokenLimit: 2048,
  },
};

export interface ICogniAITitle {
  versionId: string; // Version Guid of book
  title: string; // Title of book
}

export class CogniAITitle implements ICogniAITitle {
  versionId: string;
  title: string;
}

export const fallbackTitle: CogniAITitle = { versionId: 'None', title: 'None' }; // for general queries when no title is selected