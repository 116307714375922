import * as React from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { LoginType } from './models/AppSession';
import { LogType } from './models/dto/AppRequest';
import { LibraryContext } from './ui/state/Contextes';
import { loginRequest } from './utilities/AuthConfig';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SSOHookMiddleWare = ({ loginNeeded }: any) => {
  const { instance, accounts } = useMsal();
  const context = React.useContext(LibraryContext)!;
  const isAuthenticated = useIsAuthenticated();
  const logoutRedirect = async () => {
    context.ssoIdToken = "loading...";
    await instance.logoutRedirect();
  }
  if (context.ssoLogout === false && context.ssoIdToken === "" && context.loginType === LoginType.None && isAuthenticated) {
    context.ssoIdToken = "loading...";
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(
        (response) => {
          if (context.ssoIdToken === "loading...") {
            context.ssoIdToken = response.idToken;
            loginNeeded(response.account.username, context.ssoIdToken);
            context.ssoLogout = false;
          }
        },
        async (reason) => {
          await context.logInfo({ Info: reason.toString(), Type: LogType.Info });
        }
      );
  }
  if (context.ssoLogout === true && context.ssoIdToken === "") {
    context.ssoIdToken = "loading...";
    const asyncLogoutRedirect = async () => {
      try {
        await logoutRedirect();
      } catch (error) {
        // Handle error here
        console.error(error);
      }
    };
    asyncLogoutRedirect().catch((error) => {
      console.error('Error during logout redirect:', error);
    });
  }
  return null;
};
