import * as React from 'react';
import { Button, Collapse, FormGroup, Input, Nav, Navbar, NavItem } from 'reactstrap';
import { Locale } from 'src/localization/Locale';
import { AppSession, LibraryConfig } from 'src/models/AppSession';
import { Convert } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import { ActionIcon, Loading } from '../foundation/Controls';
import * as Messages from '../foundation/Messages';
import { AppContext } from '../state/Contextes';

interface IAddLibraryViewProps { }
interface IAddLibraryViewState {
  addableConfs: LibraryConfig[];
  loading: boolean;
}
export class AddLibraryView extends React.Component<IAddLibraryViewProps, IAddLibraryViewState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IAddLibraryViewProps) {
    super(props);
    this.addLibrary = this.addLibrary.bind(this);
    this.update = this.update.bind(this);
    this.state = { addableConfs: [], loading: false};
  }
  componentDidMount() {
    this.context.appLoading.loaded.on(this.update);
    this.update();
  }
  update() {
    let userConfigs = this.context.userConfigs.rows().map((item) => item.BrandKey);
    this.setState({ addableConfs: this.context.installedConfigs.filter((item) => !userConfigs.includes(item.BrandKey)) });
  }
  async addLibrary(brandKey: string) {
    this.setState({loading: true});
    let result = await this.context.addLibConfig({ BrandKey: brandKey });
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.AddLibraryView.LIBRARY_ADD_SUCCESS);
    }
    if (!result.valid()) {
      if (result.errors[0].Message.indexOf(this.context.localization.currentLocale.AddLibraryView.LIBRARY_ALREADY_ADDED) > -1) {
        this.context.selectLibrary(brandKey);
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.Application.ALERT_IDENTIFIER_INCORRECT);
      }
    }
    this.setState({loading: false});
  }
  render() {
    return (
      <Loading
         isLoading={this.state.loading}
         theme="opaque"
         className="full-height">
      <div className="add-library-view-container">
        <Navbar color="light" light={true} expand="xs" className="flex-shrink-0">
          <Collapse isOpen={true} navbar={true}>
            <Nav navbar={true}>
              <NavItem data-tooltip-id="libraryToolbar" data-tooltip-content={this.context.localization.currentLocale.LibraryView.LABEL_LIBRARY_REFRESH}>
                <ActionIcon onClick={() => { }} src={<Image.empty />} />
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <AddByBrandKeySection brandKeyLibrarySubmitted={(brandKey) => void this.addLibrary(brandKey)} localization={this.context.localization} />
        {this.state.addableConfs.length > 0 && (
          <h2 className="preinstall-header">{this.context.localization.currentLocale.Application.LABEL_ADD_BY_PREINSTALL}</h2>
        )}
        {this.state.addableConfs.length > 0 && (
          <AddByInstalledBrandSection addableConfigs={this.state.addableConfs} preInstalledLibraryClicked={(brandKey) => void this.addLibrary(brandKey)} />
        )}
      </div>
      </Loading>
    );
  }
}
interface IAddByBrandKeySectionProps {
  brandKeyLibrarySubmitted: (brandKey: string) => void;
  localization: Locale;
}
interface IAddByBrandKeySectionState {
  currentText: string;
}
export class AddByBrandKeySection extends React.Component<IAddByBrandKeySectionProps, IAddByBrandKeySectionState> {
  constructor(props: IAddByBrandKeySectionProps) {
    super(props);
    this.state = { currentText: "" };
    this.handleInput = this.handleInput.bind(this);
  }
  handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ currentText: event.target.value });
  }
  render() {
    return (
      <div className="add-by-brand-key-container">
        <FormGroup>
          <h1>{this.props.localization.currentLocale.Application.LABEL_ADD_BY_BRAND}</h1>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder={this.props.localization.currentLocale.Application.LABEL_IDENTIFIER_OR_DOMAIN}
            value={this.state.currentText}
            onChange={this.handleInput}
          />
          <Button
            className="add-library-button"
            name="submit"
            id="submit"
            onClick={() => this.props.brandKeyLibrarySubmitted(this.state.currentText)}
            color="success"
            disabled={Convert.isEmptyOrSpaces(this.state.currentText)}
          >
            {this.props.localization.currentLocale.Application.LABEL_ADD_LIBRARY}
          </Button>
        </FormGroup>
      </div>
    );
  }
}
interface IAddByInstalledBrandSectionProps {
  preInstalledLibraryClicked: (brandKey: string) => void;
  addableConfigs: LibraryConfig[];
}
interface IAddByInstalledBrandSectionState { }
export class AddByInstalledBrandSection extends React.Component<IAddByInstalledBrandSectionProps, IAddByInstalledBrandSectionState> {
  constructor(props: IAddByInstalledBrandSectionProps) {
    super(props);
  }
  render() {
    return (
      <div className="add-by-installed-brand">
        {this.props.addableConfigs.map((item) => (
            <div key={item.Id} className="img-container" onClick={() => this.props.preInstalledLibraryClicked(item.BrandKey)}>
              <img title="loginImg" key={item.Id} src={"data:image/png;base64," + item.LoginLogo} />
            </div>
          ))}
      </div>
    );
  }
}
