import {
    AccessResult, IPaidSubscriptionDefinition, IUserUploadedTitle, LibraryCell, LibraryProductCell,
    LibrarySorting, PurchaseResult, UserPermission
} from 'src/models/Library';

import { IContentPermissions } from '../Book';
import { CogniflowBatch } from './CogniflowRequest';
import { IMigrationSet } from './MigrationRequest';

export interface ILibraryUpdate {
  FullRefresh: boolean;
  Token: string;
}

export interface ILibraryUpdateR {
  LibraryCells: LibraryCell[];
  AvailableTitles: LibraryCell[];
  UserUploadedTitles: IUserUploadedTitle[];
  UserUploadedTitlesSocket: string|null;
  UserPaidSubscriptionPlan: IPaidSubscriptionDefinition;
}

export interface ILibraryDownloadVersion {
  VersionId: number;
}

export interface ILibraryDownloadVersionR {
  VersionId: number;
  DownloadId: string;
}

export interface IDownloadStateInfoLocal {
  VersionId: number;
  RowCTR: number;
  RowTotal: number;
  IsPaused: boolean;
  DownloadType: DownloadType;
}

export enum DownloadType {
  contentDownload = 1,
  installing = 2,
  userContentSyncing = 3,
  completed = 4,
  cancelled = 5,
  community = 6
}

export interface ILibraryPauseDownload {
  VersionId: number;
}

export interface ILibraryPauseDownloadR {
  State: IDownloadStateInfoLocal;
}

export interface ILibraryDeleteVersion {
  VersionId: number;
}

export interface ILibraryDeleteVersionR {
  Success: boolean;
}

export interface ILibraryGetInfoPage {
  VersionId: number;
  ResourceId: number;
}

export interface ILibraryGetInfoPageR {
  PageData: string;
}

export interface ILibraryOpenVersion {
  VersionId: number;
  AssertOpen: boolean;
}

export interface ILibraryOpenVersionR {
  VersionId: number;
  Result: AccessResult;
  DownloadId: string;
  CheckAccessId: string;
  Migration: IMigrationSet;
  Permissions: IContentPermissions;
  UserPermissions: UserPermission[];
}
export interface ILibraryCloseVersion {
  VersionId: number;
  RemoveVersion: boolean;
}
export interface ILibraryCloseVersionR {}
export interface ILoginLogout {
  InvalidateUser: boolean;
}

export interface ILoginLogoutR {}

export interface ILibraryGetSplashPage {
  VersionId: number;
}

export interface ILibraryGetSplashPageR {
  SplashPagePortrait: number[];
  SplashPageLandscape: number[];
}

export interface ILibrarySyncUserContent {
  VersionId: number;
}
export interface ILibrarySyncUserContentR {
  Result: AccessResult;
  DownloadId: string;
}

export interface ILibraryMultiSearch {
  SelectedVersions: number[];
  SearchQuery: string;
}
export interface ILibraryMultiSearchR {
  MultiSearchSocketId: string;
}
export interface ILibraryCreatePurchaseIntent {
  ProductId: string;
}
export interface ILibraryCreatePurchaseIntentR {
  TransactionRef: string;
  PurchaseUrl: string;
}
export interface ILibraryCancelPurchaseIntent {
  TransactionRef: string;
}
export interface ILibraryCancelPurchaseIntentR {}
export interface ILibraryExecutePurchase {
  TransactionRef: string;
  PurchaseUrl: string;
  PaymentMethod: string;
  ProductIdentifier: string;
}
export interface ILibraryExecutePurchaseR {
  Result: PurchaseResult;
  Message: string;
}

export interface ILibraryCancelMultiSearch {}
export interface ILibraryCancelMultiSearchR {}

export interface ILibraryGetFirstAvailableTitles {
  CurrentSort: LibrarySorting;
  IsReversed: boolean;
}
export interface ILibraryGetFirstAvailableTitlesR {
  Cells: LibraryCell[];
  Products: LibraryProductCell[];
}

export interface IInstallOfflinePackage {
  PackagePath: string;
  Password: string;
  BrandKey: string;
  SocketId: string;
}

export interface IInstallOfflinePackageR {
  status: AccessResult;
  OfflinePackageInstallSocketId: string;
}
export class AvailableTitlesCogniflowMessage<T = any> {
  CurrentSort: LibrarySorting;
  IsReversed: boolean;
  constructor() {
    this.Batches = new Array<CogniflowBatch<T>>();
    this.CurrentSort = LibrarySorting.LastViewed;
    this.IsReversed = false;
  }
  Batches: Array<CogniflowBatch<T>>;
}

export interface ILibraryCreateFavouriteTitle {
  TitleId: number;
  IsFavourite: boolean;
}

export interface IGetUserRefR {
  UserRef: string;
}