import {
    memo, MutableRefObject, useCallback, useContext, useEffect, useRef, useState
} from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useApiService } from 'src/chatbot/services/useApiService';
import { ChatBody, Message } from 'src/chatbot/types/chat';
import { Plugin } from 'src/chatbot/types/plugin';
import { getEndpoint } from 'src/chatbot/utils/app/api';
import { throttle } from 'src/chatbot/utils/data/throttle';
import { Status } from 'src/network/Requests';
import { BookContext, LibraryContext } from 'src/ui/state/Contextes';
import { ProfessorContext, ProfessorContextProps } from 'src/ui/state/professor.context';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { IconClearAll } from '@tabler/icons-react';

import { ChatPayload } from '../../../models/Chat';
import Spinner from '../Spinner';
import { ChatInput } from './ChatInput';
import { ChatLoader } from './ChatLoader';
import { ErrorMessageDiv } from './ErrorMessageDiv';
import { MemoizedChatMessage } from './MemoizedChatMessage';
import { SystemPrompt } from './SystemPrompt';
import { TemperatureSlider } from './Temperature';

interface Props {
  stopConversationRef: MutableRefObject<boolean>;
}

export const Chat = memo(({ stopConversationRef }: Props) => {
  const { t } = useTranslation('chat');
  const library = useContext(LibraryContext)!;
  const book = useContext(BookContext)!;
  const { chat } = useApiService(library, book);

  const {
    state: {
      selectedConversation,
      conversations,
      apiKey,
      pluginKeys,
      loading,
      prompts,
      titles,
      titleError,
      titleRefetch,
    },
    handleUpdateConversation,
    dispatch: homeDispatch,
  } = useContext(ProfessorContext) as ProfessorContextProps;

  const [currentMessage, setCurrentMessage] = useState<Message>();
  const [autoScrollEnabled, setAutoScrollEnabled] = useState<boolean>(true);
  const [showScrollDownButton, setShowScrollDownButton] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const versionRef = library.getVersion(book.id)!.VersionRef;
  const [controller, setController] = useState<AbortController>(new AbortController());

  useDeepCompareEffect(() => {
    const fetchTitles = async () => {
      if (versionRef && !titles.find(title => title.versionId === versionRef)) {
        const reponse = await library.processBookForChat({ versionRef: versionRef });
        await new Promise(resolve => setTimeout(resolve, 30000));
        if (reponse?.status === Status.Success) {
          await titleRefetch();
        }
      }
    };
    void fetchTitles();
  }, [versionRef, titles, titleRefetch, library]);

  useEffect(() => {
    book?.chatResponseReceived.on(chatResponseMessageReceived);
    return () => {
      book?.chatResponseReceived.off(chatResponseMessageReceived);
    }
  });

  const handleChat = async (key: string, request: ChatBody, signal: AbortSignal) => {
    let chatResponse = chat({
      key: key,
      chatRequest: request,
    },
      signal);
    return await chatResponse;
  }

  const handleSend = useCallback(
    async (message: Message, deleteCount: number, plugin: Plugin | null) => {
      if (selectedConversation) {
        if (deleteCount) {
          const updatedMessages = [...selectedConversation.messages];
          for (let i = 0; i < deleteCount; i++) {
            updatedMessages.pop();
          }
          book.updatedConversation = {
            ...selectedConversation,
            messages: [...updatedMessages, message],
          };
        } else {
          book.updatedConversation = {
            ...selectedConversation,
            messages: [...selectedConversation.messages, message],
          };
        }
        homeDispatch({
          field: 'selectedConversation',
          value: book.updatedConversation,
        });
        homeDispatch({ field: 'loading', value: true });
        homeDispatch({ field: 'messageIsStreaming', value: true });

        const chatBody: ChatBody = {
          id: book.updatedConversation.id,
          model: book.updatedConversation.model,
          title: {
            versionId: library.getVersion(book.id)?.VersionRef ?? "None",
            title: book.versionInfo?.Title ?? "None"
          },
          messages: book.updatedConversation.messages.map((msg) => ({ ...msg, sources: null })), // remove sources from messages for post request
          prompt: book.updatedConversation.prompt,
          temperature: book.updatedConversation.temperature,
          maxTokens: 2048,
          SocketId: ""
        };

        const endpoint = getEndpoint(plugin);
        setController(new AbortController());
        
        try {
          book.isFirst = true;
          book.text = '';
          book.chunkValue = '';

          await handleChat(apiKey, chatBody, controller.signal);
        }
        catch (error: any) {
          homeDispatch({ field: 'loading', value: false });
          homeDispatch({ field: 'messageIsStreaming', value: false });
          const msg = error?.message as string;
          toast.error(msg);
          console.log(`Chat post to ${endpoint} failed with error: ${msg}`);
          return;
        }
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      apiKey,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(conversations),
      pluginKeys,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(selectedConversation),
      stopConversationRef,
      homeDispatch,
    ],
  );

  const chatResponseMessageReceived = (payload: ChatPayload) => {
    if (!payload || payload.EndOfStream) {
      homeDispatch({ field: 'loading', value: false });
      homeDispatch({ field: 'messageIsStreaming', value: false });
      return;
    }

    if (stopConversationRef.current === true) {
      controller.abort();
      homeDispatch({ field: 'loading', value: false });
      homeDispatch({ field: 'messageIsStreaming', value: false });
      return;
    }
    // if (updatedConversation.messages.length === 1) {
    //   const { content } = message;
    //   const customName =
    //     content.length > 30 ? content.substring(0, 30) + '...' : content;
    //   updatedConversation = {
    //     ...updatedConversation,
    //     name: customName,
    //   };
    // }
    homeDispatch({ field: 'loading', value: false });
    const binaryData = new Uint8Array(Array.from(atob(payload.Message), (c) => c.charCodeAt(0)));

    // let text = book.text;
    // let chunkValue = book.chunkValue;
    const decoder = new TextDecoder();
    book.chunkValue += decoder.decode(binaryData);
    console.log(book.chunkValue);
    let endPos = book.chunkValue.lastIndexOf('\r\n\r\n');
    if (endPos !== -1) {
      let processChunk = book.chunkValue.substring(0, endPos + 2);
      book.chunkValue = book.chunkValue.substring(endPos + 2); // keep the characters after '\r\n\r\n'
      const regex = /data:\s?({.*?})\r\n/g;
      const matches = [...processChunk.matchAll(regex)];
      const jsonStrings = matches.map((match) => match[1]);
      if (jsonStrings.length > 0) {
        book.text += jsonStrings.map((jsonString) => {
          if (typeof jsonString === 'string' && jsonString.startsWith('{"token":')) {
            const jsonObject = JSON.parse(jsonString);
            const tokenValue = jsonObject.token;
            return tokenValue;
          } else if (typeof jsonString === 'string' && jsonString.startsWith('{"source_documents":')) {
            const jsonObject = JSON.parse(jsonString);
            const sourceDocs = jsonObject.source_documents;
            book.docSources = sourceDocs.map((sourceDoc: { page_content: string, metadata: { source: string; }; }) =>
              ({ url: sourceDoc.metadata.source, page_content: sourceDoc.page_content })
            );
          }
          else {
            return jsonString;
          }
        }).join('');
      }

      if (book.isFirst) {
        book.isFirst = false;
        const updatedMessages: Message[] = [
          ...book.updatedConversation.messages,
          { role: 'assistant', content: book.text, sources: book.docSources },
        ];
        book.updatedConversation = {
          ...book.updatedConversation,
          messages: updatedMessages,
        };
        homeDispatch({
          field: 'selectedConversation',
          value: book.updatedConversation,
        });
      } else {
        const updatedMessages: Message[] =
          book.updatedConversation.messages.map((msg, index) => {
            if (index === book.updatedConversation.messages.length - 1) {
              return {
                ...msg,
                content: book.text,
                sources: book.docSources,
              };
            }
            else {
              return msg;
            }
          });
        book.updatedConversation = {
          ...book.updatedConversation,
          messages: updatedMessages,
        };
        homeDispatch({
          field: 'selectedConversation',
          value: book.updatedConversation,
        });
      }
    }
    // }
  }

  // const scrollToBottom = useCallback(() => {
  //   if (autoScrollEnabled) {
  //     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  //     textareaRef.current?.focus();
  //   }
  // }, [autoScrollEnabled]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const bottomTolerance = 30;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        setAutoScrollEnabled(false);
        setShowScrollDownButton(true);
      } else {
        setAutoScrollEnabled(true);
        setShowScrollDownButton(false);
      }
    }
  };

  const handleScrollDown = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };


  const onClearAll = () => {
    if (
      confirm(t('Are you sure you want to clear all messages?').toString()) &&
      selectedConversation
    ) {
      handleUpdateConversation(selectedConversation, {
        key: 'messages',
        value: [],
      });
    }
  };

  const scrollDown = () => {
    if (autoScrollEnabled) {
      messagesEndRef.current?.scrollIntoView(false);
    }
  };
  const throttledScrollDown = throttle(scrollDown, 250);

  // useEffect(() => {
  //   console.log('currentMessage', currentMessage);
  //   if (currentMessage) {
  //     handleSend(currentMessage);
  //     homeDispatch({ field: 'currentMessage', value: undefined });
  //   }
  // }, [currentMessage]);

  useDeepCompareEffect(() => {
    throttledScrollDown();
    if (selectedConversation) {
      setCurrentMessage(selectedConversation.messages[selectedConversation.messages.length - 2]);
    }
  }, [selectedConversation]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAutoScrollEnabled(entry.isIntersecting);
        if (entry.isIntersecting) {
          textareaRef.current?.focus();
        }
      },
      {
        root: null,
        threshold: 0.5,
      },
    );
    const messagesEndElement = messagesEndRef.current;
    if (messagesEndElement) {
      observer.observe(messagesEndElement);
    }
    return () => {
      if (messagesEndElement) {
        observer.unobserve(messagesEndElement);
      }
    };
  }, [messagesEndRef]);

  return (
    <>
      <div className={selectedConversation?.messages.length === 0 ?
        "d-flex flex-column justify-content-center position-relative flex-grow-1 overflow-auto bg-white dark:bg chat"
        : "position-relative flex-grow-1 overflow-auto bg-white dark:bg chat"}>
        {
          titleError ?
            <ErrorMessageDiv error={titleError} />
            :
            <>
              <div
                className="max-h-100 overflow-x-hidden overflow-y-auto"
                ref={chatContainerRef}
                onScroll={handleScroll}
                style={{ overflow: 'auto' }}
              >
                {selectedConversation?.messages.length === 0 ? (
                  <>
                    <div className="mx-auto d-flex flex-column space-2 md-space-2 px-3 pt-1 md-pt-1 max-w-[600px] pb-32">
                      {!titles.find(title => title.versionId === library.getVersion(book.id)?.VersionRef) && (
                        <div className="text-center">
                          <div className="text-secondary">
                            {t('Processing Book Content for the Professor Chatbot ...')}
                          </div>
                          <Spinner className="loading-modal-spinner" />
                        </div>
                      )}
                      {(
                        <div className="d-flex h-100 flex-column space-2 rounded-lg border border-secondary p-4">
                          <SystemPrompt
                            conversation={selectedConversation}
                            prompts={prompts}
                            onChangePrompt={(prompt) =>
                              handleUpdateConversation(selectedConversation, {
                                key: 'prompt',
                                value: prompt,
                              })
                            }
                          />
                          <TemperatureSlider
                            label={t('Temperature')}
                            onChangeTemperature={(temperature) =>
                              handleUpdateConversation(selectedConversation, {
                                key: 'temperature',
                                value: temperature,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="position-sticky top-0 z-10 d-flex justify-content-center border border-b-neutral-300 bg-neutral-100 py-2 text-sm text-secondary">
                      <button
                        title={t('Clear All')}
                        className="ml-2 cursor-pointer hover-opacity-50"
                        onClick={onClearAll}
                      >
                        <IconClearAll size={18} />
                      </button>
                    </div>
                    {selectedConversation?.messages.map((message, index) => (
                      <MemoizedChatMessage
                        key={index}
                        message={message}
                        messageIndex={index}
                        onEdit={(editedMessage) => void (async () => {
                          setCurrentMessage(editedMessage);
                          // discard edited message and the ones that come after then resend
                          await handleSend(
                            editedMessage,
                            selectedConversation?.messages.length - index,
                            null
                          );
                        })()}
                      />
                    ))}

                    { loading && <ChatLoader />}

                    <div
                      className="bg-white"
                      style={{
                        height: '1px'
                      }}
                      ref={messagesEndRef}
                    />
                  </>
                )}
              </div>
            </>
        }
      </div>
      <ChatInput
        stopConversationRef={stopConversationRef}
        textareaRef={textareaRef}
        onSend={(message, plugin) => void (async () => {
          stopConversationRef.current = false;
          setCurrentMessage(message);
          await handleSend(message, 0, plugin);
        })()}
        onScrollDownClick={handleScrollDown}
        onRegenerate={() => void (async () => {
          stopConversationRef.current = false;
          if (currentMessage) {
            await handleSend(currentMessage, 2, null);
          }
        })()}
        showScrollDownButton={showScrollDownButton}
      />
    </>
  );
});
Chat.displayName = 'Chat';
