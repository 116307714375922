import classnames from 'classnames';
import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import { TabContent, TabPane } from 'reactstrap';
import { Single } from 'src/collections/Observable';
import { Locale } from 'src/localization/Locale';
import { Log } from 'src/Logger';
import { LibrarySession } from 'src/models/AppSession';
import { Book, BookLoadingSteps } from 'src/models/Book';
import { IGlobalNoteViewModel } from 'src/models/dto/UCRequest';
import { ExternalLinkingError, ExternalLinkingPayload } from 'src/models/ExternalLinking';
import { VersionTask, VersionTaskType } from 'src/models/Library';
import { UserFormSubmissionAttachment } from 'src/models/UserContent';
import { EventHandler } from 'src/utilities/Events';

import { Image } from '../foundation/Assets';
import { CircleActionIcon, IHtmlElementProps } from '../foundation/Controls';
import { MediaQuery, SplitPanel, VerticalSplitGroup } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import { Screen } from '../foundation/Screen';
import { BookContext, LibraryContext } from '../state/Contextes';
import { ActionView } from './ActionView';
import { AnnotationTypeView } from './AnnotationTypeView';
import { AnnotationView } from './AnnotationView';
import { CommunityView } from './CommunityView';
import { ContentReader, ContentView } from './ContentView';
import { CriticalSubmissionModal } from './CriticalSubmissionModal';
import { FavouriteView } from './FavouriteView';
import { GlobalNoteModal } from './GlobalNoteModal';
import { BookNavBar } from './Navigation';
import { ProfessorView } from './ProfessorView';
import { SearchView } from './SearchView';
import { TOCView } from './TOCView';

export interface IBookContainerProperties {
  book: Book;
}

export class BookView extends React.Component<IBookContainerProperties, unknown> {
  public static panels: Single<{ source: number; size: number[] }> = new Single<{ source: number; size: number[] }>({
    source: -1,
    size: [30, 70],
  });
  render() {
    return (
      <BookContext.Provider value={this.props.book}>
        <InnerBookView layout={BookView.panels} />
      </BookContext.Provider>
    );
  }
}

class BookErrorBoundaryState {
  errorCount: number;
}
class BookErrorBoundaryProps {
  versionId: number;
  children?: React.ReactNode;
}
class BookErrorBoundary extends React.Component<BookErrorBoundaryProps, BookErrorBoundaryState> {
  context: LibrarySession;
  static contextType = LibraryContext;

  constructor(props: BookErrorBoundaryProps | Readonly<BookErrorBoundaryProps>) {
    super(props);
    this.state = { errorCount: 0 };
  }

  static getDerivedStateFromError() {}

  async componentDidCatch(error: Error) {
    if (this.state.errorCount === 0) {
      Messages.Notify.error(error.message);
      await this.context.closeBook({ VersionId: this.props.versionId, RemoveVersion: true });
      await Log.fatal("The book with local version " + this.props.versionId + " encountered a book level error! Error message: " + error.message.toString());
      if (error.stack) {
        await Log.fatal("Error stacktrace: " + error.stack.toString());
      } else {
        await Log.fatal("Error stacktrace: None provided");
      }
      await Log.fatal("Error name: " + error.name.toString());
      this.setState({ errorCount: this.state.errorCount + 1 });
    }
  }

  render() {
    if (this.state.errorCount > 0) {
      return "";
    }
    return <React.Fragment key={this.state.errorCount}>{this.props.children}</React.Fragment>;
  }
}

interface IInnerBookViewState {
  activeTab: Single<Screen>;
  panelSizes: number[];
  leftPanelOpen: boolean;
  showSplash: boolean;
  currentCriticalSubmission: any;
  currentGlobalNote: IGlobalNoteViewModel | null;
  modalLoading: boolean;
  attachments: UserFormSubmissionAttachment[];
  showModal: boolean;
}

interface IInnerBookViewProps {
  layout: Single<{ source: number; size: number[] }>;
}
class InnerBookView extends React.Component<IInnerBookViewProps, IInnerBookViewState> {
  context: Book;
  content: ContentReader;
  static contextType = BookContext;
  constructor(props: IInnerBookViewProps | Readonly<IInnerBookViewProps>) {
    super(props);
    this.state = {
      activeTab: new Single<Screen>(Screen.toc),
      panelSizes: this.props.layout.get().size,
      leftPanelOpen: true,
      showSplash: true,
      currentCriticalSubmission: null,
      showModal: true,
      modalLoading: false,
      attachments: [],
      currentGlobalNote: null,
    };
    this.navigationHandler = this.navigationHandler.bind(this);
    this.panelSizeChanged = this.panelSizeChanged.bind(this);
    this.updatePanelSize = this.updatePanelSize.bind(this);
    this.leftPanelToggled = this.leftPanelToggled.bind(this);
    this.onBookLoaded = this.onBookLoaded.bind(this);
    this.doubleClickHandle = this.doubleClickHandle.bind(this);
    this.dismissPanel = this.dismissPanel.bind(this);
    this.externalLinkError = this.externalLinkError.bind(this);
    this.checkKeys = this.checkKeys.bind(this);
    this.dismissCriticalBulletin = this.dismissCriticalBulletin.bind(this);
    this.getNextCriticalBulletin = this.getNextCriticalBulletin.bind(this);
  }

  navigationHandler(screen: Screen) {
    let acttab = this.state.activeTab;
    acttab.set(screen);
    this.setState({ activeTab: acttab, leftPanelOpen: true });
  }

  panelSizeChanged(s: number[]) {
    this.props.layout.set({
      source: this.context.id,
      size: s,
    });
  }

  updatePanelSize() {
    if (this.props.layout.get().source !== this.context.id) {
      this.setState({
        panelSizes: this.props.layout.get().size,
      });
    }
  }

  componentDidMount() {
    this.props.layout.addListener(this.updatePanelSize);
    this.context.loading.loaded.on(this.onBookLoaded);
    this.context.contentNavigationRequested.on(this.dismissPanel);
    this.context.externalLinkingParseFailed.on(this.externalLinkError);
    document.removeEventListener("keydown", this.checkKeys, false);
    document.addEventListener("keydown", this.checkKeys, false);
    this.setState({ leftPanelOpen: this.context.appSettings.get().LeftPanelDefaultOpen });
    this.context.globalNoteContentClicked.on(this.globalNoteClicked);
  }
  shouldComponentUpdate(): boolean {
    return !this.context.shouldLimitRendering && this.context.isActiveTab;
  }
  componentWillUnmount() {
    this.props.layout.removeListener(this.updatePanelSize);
    this.context.loading.loaded.off(this.onBookLoaded);
    this.context.contentNavigationRequested.off(this.dismissPanel);
    this.context.externalLinkingParseFailed.off(this.externalLinkError);
    document.removeEventListener("keydown", this.checkKeys, false);
    this.context.globalNoteContentClicked.off(this.globalNoteClicked);
  }

  globalNoteClicked = (ids: number[]) => {
    if (ids.length > 1) {
      return;
    }
    let id = ids[0];
    let globalNote = this.context.globalNotes.get(id);
    if (globalNote) {
      this.setState({ currentGlobalNote: globalNote });
    }
  };
  // App level external link errors handled in the proper view for their context.
  externalLinkError(externalLink: ExternalLinkingPayload) {
    switch (externalLink.ParseError) {
      case ExternalLinkingError.BAD_DESTINATION:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_BAD_DESTINATION);
        break;
      case ExternalLinkingError.INVALID_ACTION:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_INVALID_ACTION);
        break;
      case ExternalLinkingError.INVALID_ACTION_PARAMETERS:
      case ExternalLinkingError.NO_PERSISTENT_ID:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_INVALID_PARAMETERS);
        break;
    }
  }

  onBookLoaded() {
    this.setState(
      {
        showSplash: false,
      },
      () =>
        void (async () => {
          this.context.registerChat();
          await this.context.setCommunityValues();
          this.context.communityEstablished.dispatch(null, this);
          this.getNextCriticalBulletin("00000000-0000-0000-0000-000000000000");
        })()
    );
  }

  getNextCriticalBulletin(prevBul: string) {
    this.setState({ modalLoading: true }, () => void(async () => {
      let criticalSub = await this.context.getNextCriticalSubmission(prevBul);
      if (!criticalSub.valid() || criticalSub.data.Submission === null) {
        this.setState({
          currentCriticalSubmission: null,
          showModal: false,
          attachments: [],
          modalLoading: false,
        });
        return;
      }
      let attach = await this.getAttachments(criticalSub.data.Submission.Submission.TableGuid);
      if (criticalSub.data.Submission) {
        this.setState({
          currentCriticalSubmission: criticalSub.data.Submission,
          showModal: false,
          attachments: attach,
          modalLoading: false,
        });
      }
    })());
  }

  dismissGlobalNoteModal = () => {
    this.setState({
      showModal: false,
      currentGlobalNote: null,
    });
  };

  dismissCriticalBulletin() {
    this.setState(
      {
        showModal: true,
      },
      () => this.getNextCriticalBulletin(this.state.currentCriticalSubmission.Submission.TableGuid as string)
    );
  }

  dismissPanel() {
    if (this.state.leftPanelOpen && window.matchMedia("(max-width: " + (MediaQuery.lg - 1) + "px)").matches) {
      this.leftPanelToggled();
    }
  }
  leftPanelToggled() {
    this.setState({
      leftPanelOpen: !this.state.leftPanelOpen,
    });
  }

  doubleClickHandle(e: any) {
    if (e.target.classList.contains("gutter")) {
      this.leftPanelToggled();
    }
  }
  private checkKeys(event: any) {
    if (event.ctrlKey || event.metaKey) {
      if (event.keyCode === 70) {
        event.preventDefault();
        event.stopPropagation();
        if (this.context.isActiveTab) {
          this.context.searchRemotelyExecuted.dispatch("", this);
        }
        return false;
      }
      if (event.keyCode === 80) {
        event.preventDefault();
        event.stopPropagation();
        if (this.context.isActiveTab && this.context.contentPermissions.CanPrint) {
          this.context.printRemotelyExecuted.dispatch("", this);
        }
        return false;
      }
      if (event.keyCode === 67 && !this.context.contentPermissions.CanCopy) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
    return true;
  }

  private getAttachments = async (tableGuid: any) => {
    let result = await this.context.getAttachments({
      UserFormSubmissionRef: tableGuid,
    });
    if (result.valid()) {
      return result.data;
    } else {
      return [];
    }
  };

  render(): any {
    let leftPanel = !this.state.leftPanelOpen ? "collapsed" : "";
    return (
      <BookErrorBoundary versionId={this.context.id}>
        <div className="flex-fill full-height" style={{ position: "relative" }}>
          {this.state.currentCriticalSubmission && (
            <CriticalSubmissionModal
              show={this.state.showModal}
              handleClose={this.dismissCriticalBulletin}
              currentSubmission={this.state.currentCriticalSubmission}
              localization={this.context.localization}
              attachments={this.state.attachments}
              loading={this.state.modalLoading}
            />
          )}
          {this.state.currentGlobalNote && (
            <GlobalNoteModal
              show={this.state.showModal}
              handleClose={this.dismissGlobalNoteModal}
              currentGlobalNote={this.state.currentGlobalNote}
              localization={this.context.localization}
              loading={this.state.modalLoading}
            />
          )}
          <SplashPage
            className="d-flex bookview"
            style={{ position: "absolute", height: "100%", width: "100%" }}
            show={this.state.showSplash}
            src={this.context.splash}
            taskUpdate={this.context.taskUpdate}
            loadingUpdate={this.context.stepLoading}
            localization={this.context.localization}
            defaultSplash={this.context.libraryConfig.DefaultBookSplash}
          >
            {!this.state.currentCriticalSubmission && (
              <BookViewToggle
                helpEnabled={this.context.appSettings.get().HelpEnabled}
                localization={this.context.localization}
                active={this.state.leftPanelOpen}
                onToggle={this.leftPanelToggled}
              />
            )}

            <VerticalSplitGroup
              sizes={this.state.panelSizes}
              gutterSize={6}
              onDoubleClick={this.doubleClickHandle}
              onDragEnd={this.panelSizeChanged}
              elementStyle={(dimension: any, size: any, gutterSize: any) => ({ width: "calc(" + size + "% - " + gutterSize + "px)", "min-width": "300px" })}
            >
              <SplitPanel className={`collapsible ${leftPanel}`}>
                <div className="d-flex flex-fill flex-row full-height">
                  <BookNavBar navigation={this.navigationHandler} />
                  <BookViewTabs localization={this.context.localization} activeTab={this.state.activeTab} />
                </div>
                {this.state.currentCriticalSubmission && <div className="unbound-form-modal" />}
              </SplitPanel>
              <SplitPanel>
                <ContentView />
              </SplitPanel>
            </VerticalSplitGroup>
          </SplashPage>
        </div>
      </BookErrorBoundary>
    );
  }
}

interface IBookViewToggleProps {
  active: boolean;
  helpEnabled: boolean;
  isPlaceholder?: boolean;
  localization: Locale;
  onToggle: (active: boolean) => void;
}

interface IBookViewToggleState {
  active: boolean;
}

export class BookViewToggle extends React.Component<IBookViewToggleProps, IBookViewToggleState> {
  constructor(props: IBookViewToggleProps | Readonly<IBookViewToggleProps>) {
    super(props);
    this.state = { active: this.props.active };
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    let otherActive = !this.state.active;
    this.setState({
      active: otherActive,
    });
    this.props.onToggle(otherActive);
  }

  render() {
    return (
      <div className="bookview-toggle-container" data-tooltip-id="toggleToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_TOGGLE_TOOLS_PANEL}>
        <CircleActionIcon src={<Image.menu />} active={this.state.active} onClick={this.clickHandler} />
        {this.props.helpEnabled && !this.props.isPlaceholder && <Tooltip id="toggleToolbar" place="right" variant="info" className="primaryColoured" />}
      </div>
    );
  }
}

interface IBookViewTabsProps {
  activeTab: Single<Screen>;
  localization: Locale;
}

interface IBookViewTabsState {
  tab: string;
}

class BookViewTabs extends React.Component<IBookViewTabsProps, IBookViewTabsState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IBookViewTabsProps | Readonly<IBookViewTabsProps>) {
    super(props);
    this.handleTabChanged = this.handleTabChanged.bind(this);
    this.state = {
      tab: this.props.activeTab.get(),
    };
  }

  handleTabChanged(screen: Screen | undefined) {
    if (screen === undefined) {
      return;
    }
    this.setState({ tab: screen });
  }

  componentWillUnmount() {
    this.props.activeTab.removeListener(this.handleTabChanged);
  }

  componentDidMount() {
    this.props.activeTab.addListener(this.handleTabChanged);
  }

  render() {
    return (
      <div className="bookview-tabs">
        <TabContent activeTab={this.state.tab}>
          <TabPane tabId="toc">
            <TOCView isActive={this.state.tab === "toc"} />
          </TabPane>
          <TabPane tabId="results">
            <SearchView isActive={this.state.tab === "results"} />
          </TabPane>
          {this.context.parentContext.config.UserTitlesEnabled === true && <TabPane tabId="professor">
            <ProfessorView isActive={this.state.tab === "professor"} />
          </TabPane>}
          <TabPane tabId="annotations">
            <AnnotationView isActive={this.state.tab === "annotations"} />
          </TabPane>
          <TabPane tabId="annotationTypes">
            <AnnotationTypeView isActive={this.state.tab === "annotationTypes"} />
          </TabPane>
          <TabPane tabId="favourites">
            <FavouriteView isActive={this.state.tab === "favourites"} />
          </TabPane>
          <TabPane tabId="community">
            <CommunityView isActive={this.state.tab === "community"} />
          </TabPane>          
          <TabPane tabId="actions">
            <ActionView isActive={this.state.tab === "actions"} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

interface ISplashPageProps extends IHtmlElementProps {
  src: number[];
  show: boolean;
  taskUpdate?: EventHandler<VersionTask>;
  loadingUpdate: EventHandler<BookLoadingSteps>;
  localization: Locale;
  defaultSplash: string;
  children?: React.ReactNode;
}

interface ISplashPageState {
  status: string;
}
export class SplashPage extends React.Component<ISplashPageProps, ISplashPageState> {
  constructor(props: ISplashPageProps | Readonly<ISplashPageProps>) {
    super(props);
    this.state = { status: "" };
    this.updateStatus = this.updateStatus.bind(this);
    this.updateLoading = this.updateLoading.bind(this);
  }

  componentDidMount() {
    this.setState({ status: this.props.localization.currentLocale.LibraryView.LABEL_LAUNCHING_STEP_VALIDATION });
    if (this.props.taskUpdate !== undefined) {
      this.props.loadingUpdate.on(this.updateLoading);
      this.props.taskUpdate.on(this.updateStatus);
    }
  }

  componentWillUnmount() {
    if (this.props.taskUpdate !== undefined) {
      this.props.loadingUpdate.off(this.updateLoading);
      this.props.taskUpdate.off(this.updateStatus);
    }
  }

  private updateStatus(task: VersionTask) {
    let status = "";
    switch (task.Type) {
      case VersionTaskType.launching:
        status = this.props.localization.currentLocale.LibraryView.LABEL_LAUNCHING_STEP_LOADINGBOOK;
        break;
      case VersionTaskType.syncing:
        status = this.props.localization.currentLocale.LibraryView.LABEL_LAUNCHING_STEP_UCSYNC;
        break;
      case VersionTaskType.community:
        status = this.props.localization.currentLocale.CommunityView.LABEL_UPDATING_COMMUNITIES;
        break;
    }
    if (task.IsCompleted) {
      status = this.props.localization.currentLocale.LibraryView.LABEL_LAUNCHING_OPENING_TITLE;
    }
    this.setState({
      status,
    });
  }
  private updateLoading() {}

  render() {
    return (
      <div className={classnames("splash-page-context", this.props.className)} id={this.props.id} style={this.props.style}>
        {this.props.show && (
          <div
            className="splash-page"
            style={{
              backgroundImage: `url(data:image/png;base64,${
                !this.props.src || this.props.src === null || this.props.src.length === 0 ? this.props.defaultSplash : String(this.props.src)
              })`,
            }}
          >
            <div className="splash-page-status">{this.state.status}</div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}
