export const B_INST = "[INST]";
export const E_INST = "[/INST]";
export const B_SYS = "<<SYS>>\n";
export const E_SYS = "\n<</SYS>>\n\n"

export const REACT_APP_DEFAULT_SYSTEM_PROMPT =
  process.env.REACT_APP_DEFAULT_SYSTEM_PROMPT ||
  "You are helpful AI known as Professor, built by CogniLore. " +
  "You are an expert at answering questions about e-book contents. " +
  "Use the following Context and Chat History to answer the " +
  "question at the end with a helpful and detailed answer. " +
  "Do not rely solely on the Context and Chat History however, " +
  "as they may not contain all the information needed to answer. " +
  "If you don't know the answer, just say " +
  "'I don't know'; don't try to make up an answer. \n" +
  "Context: {context} \n" +
  "Chat History: {chat_history} \n" +
  "Question: {question} \n" +
  "Helpful Answer:\n";

export const REACT_APP_COGNIAI_API_HOST =
  process.env.REACT_APP_COGNIAI_API_HOST || 'http://localhost:5333';

export const REACT_APP_DEFAULT_TEMPERATURE = 
  parseFloat(process.env.REACT_APP_DEFAULT_TEMPERATURE || "0.0");

export const REACT_APP_COGNIAI_API_TYPE =
  process.env.REACT_APP_COGNIAI_API_TYPE || 'cogniai';

export const REACT_APP_COGNIAI_API_VERSION =
  process.env.REACT_APP_COGNIAI_API_VERSION || '2023-08-30';

export const REACT_APP_COGNIAI_ORGANIZATION =
  process.env.REACT_APP_COGNIAI_ORGANIZATION || '';

export const REACT_APP_AZURE_DEPLOYMENT_ID =
  process.env.REACT_APP_AZURE_DEPLOYMENT_ID || '';
