import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REACT_APP_DEFAULT_TEMPERATURE } from 'src/chatbot/utils/app/const';
import { ProfessorContext, ProfessorContextProps } from 'src/ui/state/professor.context';

interface Props {
  label: string;
  onChangeTemperature: (temperature: number) => void;
}

export const TemperatureSlider: FC<Props> = ({
  label,
  onChangeTemperature,
}) => {
  const {
    state: { conversations },
  } = useContext(ProfessorContext) as ProfessorContextProps;
  const lastConversation = conversations[conversations.length - 1];
  const [temperature, setTemperature] = useState(
    lastConversation?.temperature ?? REACT_APP_DEFAULT_TEMPERATURE,
  );
  const { t } = useTranslation('chat');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setTemperature(newValue);
    onChangeTemperature(newValue);
  };

  return (
    <div className="d-flex flex-column">
      <label className="mb-2 text-start text-secondary">
        {label}
      </label>
      <span className="text-[12px] text-black-50 dark-text-white-50 text-sm">
        {t(
          'Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
        )}
      </span>
      <span className="mt-2 mb-1 text-center text-secondary">
        {temperature.toFixed(1)}
      </span>
      <input
        title={t('temperature')}
        placeholder="0.0"
        className="cursor-pointer"
        type="range"
        min={0}
        max={1}
        step={0.1}
        value={temperature}
        onChange={handleChange}
      />
      <ul className="w mt-2 pb-8 d-flex justify-content-between px-[24px] text-secondary">
        <li className="d-flex justify-content-center">
          <span>{t('Precise')}</span>
        </li>
        <li className="d-flex justify-content-center">
          <span>{t('Neutral')}</span>
        </li>
        <li className="d-flex justify-content-center">
          <span>{t('Creative')}</span>
        </li>
      </ul>
    </div>
  );
};
