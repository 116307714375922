import React, { useContext, useState } from 'react';
import { Source } from 'src/chatbot/types/chat';
import { Log } from 'src/Logger';
import { NavigationInitiator } from 'src/models/Book';
import { NavigationRequest } from 'src/models/Content';
import { BookContext } from 'src/ui/state/Contextes';

interface Props {
  sources: Source[] | null | undefined;
}

const ChatSources: React.FC<Props> = ({ sources }) => {
    const book = useContext(BookContext);
    const [enabledSource, setEnabledSource] = useState<boolean>(false);
    const handleSourceClick = async (source: Source) => await navigateToContentSegment(source.url);
    const handleEnabledSource = () => setEnabledSource(!enabledSource);
    const navigateToContentSegment = async (url: string) => {
        try {
            const urlObj = new URL(url);
            const pathParts = urlObj.pathname.split('/');
            const lastPartWithExtension = pathParts[pathParts.length - 1];
            const contentSegmentId = parseInt(lastPartWithExtension.split('.')[0]);
            console.log("Content segment id:  " + contentSegmentId); // Outputs the last part of the path without the extension
            await book.contentNavigation(NavigationRequest.toHeader(contentSegmentId), NavigationInitiator.toc);
        } catch (error) {
            const msg = "Error parsing or navigating to content segment: " + error;
            await Log.error(msg, error);
            console.log(msg);
        }
    }
    return (
        <div>
            {sources && sources.length > 0 && ( 
                <div>
                    <a className='text-primary' onClick={handleEnabledSource}>Sources</a> 
                    {enabledSource && (
                        <ol>
                            {sources.map((source, index) => (
                                <li key={index}>
                                    <a className='text-primary' onClick={() => void handleSourceClick(source)}> {source.page_content.substring(0, 100) + "..."} </a>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatSources;