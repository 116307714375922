export enum Screen {
  community = "community",
  content = "content",
  actions = "actions",
  annotations = "annotations",
  annotationTypes = "annotationTypes",
  toc = "toc",
  favourite = "favourites",
  results = "results",
  resource = "resource",
  receiptDialog = "receiptDialog",
  professor = "professor",
}
