import { ContentExcerpt } from './Content';
import { IUserFormSubmissionAttachment } from './dto/ReaderModels';

export class AnnotationType {
  Id: number;
  Value: string;
  Color: string;
  LastUpdate: Date;

  // Frontend only props for cogniflow:--
  Index?: number;
  IsFirst?: boolean;
  IsLast?: boolean;
  constructor() {
    this.Id = 0;
    this.Value = "";
    this.Color = "#FFFFFF";
    this.LastUpdate = new Date();
  }
}
export class UserFormSubmissionViewModel {
  Submission: UserFormSubmission;
  Definition: UserFormDefinition;
  AssociatedTitles: string[];
  Attachments: IUserFormSubmissionAttachment[];
  // Frontend only props for cogniflow:--
  Index?: number;
  IsFirst?: boolean;
  IsLast?: boolean;
}

export class UserFormSubmission {
  TableId: number;
  TableGuid: string;
  UserId: number;
  VersionId: number | null;
  ApproverUserId: number | null;
  SegmentId: number | null;
  UserReadReceiptNeeded: boolean | null;
  CreationDate: Date | null;
  LastModificationDate: Date | null;
  SubmissionState: SubmissionState;
  Submission: string;
  UserFormDefinitionId: number;
  DeepLink: string;
  BreadCrumb: string;
}

export class UserFormDefinition {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  FormType: FormType;
  CreationDate: Date | null;
  IsActive: boolean;
  DataSchema: string;
  ReaderUiSchema: string;
  DashboardUiSchema: string;
}

export class UserFormSubmissionAttachment {
  TableId: number;
  UserFormSubmissionId: number;
  AttachmentExtension: string;
  AttachmentData: number[];
  CreationDate: Date;
  LastModificationDate: Date;
  TableGuid: string;
  Name: string;
}

export enum FormType {
  Tip,
  Bulletin,
  Feedback,
}

export enum SubmissionState {
  New,
  UnderReview,
  Published,
  Rejected,
  Completed,
}
export class Annotation {
  Id: number;
  Note: string;
  LastUpdate: Date;
  AnnotationTypeId: number;
  Ranges: AnnotationRange[];
  Preview: string;

  // Frontend only props for cogniflow:--
  Index?: number;
  IsFirst?: boolean;
  IsLast?: boolean;
}
export class AnnotationRange {
  Id: number;
  AnnoId: number;
  DocId: number;
  Spine: number;
  StartTermIndex: number;
  StartCharacterIndex: number;
  EndTermIndex: number;
  EndCharacterIndex: number;
}

export class AnnotationPrintModel {
  Annotation: Annotation;
  AnnotationType: AnnotationType;
  Excerpts: ContentExcerpt[];
  BreadCrumbs: string;
}

export class FavouritePrintModel {
  Favourite: Favourite;
  Excerpts: ContentExcerpt[];
  BreadCrumbs: string;
}

export class SearchPrintModel {
  Index: number;
  ItemId: number;
  Relevancy: number;
  HitTermCount: number;
  IsFirst: boolean;
  IsLast: boolean;
  Path: string;
  Rank: number;
  Title: string;
  MoreCount: number;
  Excerpts: ContentExcerpt[];
  IsUserFormSubmission: boolean;
}

export class Favourite {
  Id: number;
  Value: string;
  Folder: string;
  Section: string;
  Location: string;
  DocumentId: number;
  Spine: number;
  LastUpdate: Date;
  YDocHeight: number;
  YOffset: number;

  // Frontend only props for cogniflow:--
  Index?: number;
  IsFirst?: boolean;
  IsLast?: boolean;
}
export class Action {
  Index: number;
  Id: number;
  ObjectId: number;
  SupplementaryText: string;
  SecondaryText: string;
  CreationDate: Date;
  Type: HistoryType;
  IsFirst: boolean;
  IsLast: boolean;
  RawData: string;
}
export enum HistoryType {
  Unspecified = 0,
  Search = 1, // A search was performed
  LinkNavigation = 2, // a navigation within content was performed
  SearchResultNavigation = 3, // a navigation to the first hit within a document was performed
  TOCNavigation = 4, // the table of contents set the location in the content pane
  ScrubberNavigation = 5, // the scrubber was used to jump to a location in content.
  AppClosure = 6, // The ap was closed/ minimised and this was the last location
  ResourceViewing = 7, // an embeded content was selected and the resouce was loaded
  ExternalWebsite = 8, // a link to an external webpage was selected in content.
}
export enum FavouriteSorting {
  ReadingOrder,
  Title,
  Recent,
}
export enum FavouriteGrouping {
  None,
  Folder,
}
export enum AnnotationSorting {
  ReadingOrder,
  Note,
  Recent,
}
export enum AnnotationGrouping {
  None,
  Type,
}
export enum AnnotationTypeSorting {
  Title,
  Recent,
}
export enum CommunitySorting {
  Title,
  Recent,
  CreationDate
}
