import { Dictionary, IKeyValuePair } from 'src/collections/Generics';
import { Single, Table } from 'src/collections/Observable';
import { Config, Platform } from 'src/Config';
import { Log } from 'src/Logger';
import { IId } from 'src/models/Interfaces';
import {
    AccessResult, IDeleteUserUploadedBookRequest, IDeleteUserUploadedBookRequestR,
    IPaidSubscriptionDefinition, IUploadBookRequest, IUploadBookRequestR, IUserUploadedTitle,
    LibraryCell, LibraryProductCell, LibrarySorting, LibraryVersion, LibraryViewMode,
    MultiSearchMessage, SectionState, UserFormSubmissionType, UserPermission, VersionMeta,
    VersionTask, VersionTaskType
} from 'src/models/Library';
import {
    AjaxRequestor, AndroidRequestor, AppleRequestor, CefRequestor, IRequestor
} from 'src/network/Requestor';
import { IError, IResponse, Status } from 'src/network/Requests';
import { Middleware, Wire } from 'src/network/Wire';
import { Dialog, IDialogItemProps, INotification, Notify } from 'src/ui/foundation/Messages';
import { IEmptyValue } from 'src/ui/state/Generics';
import { EventHandler, LoadingReport, MapEventHandler } from 'src/utilities/Events';
import { Communication, Constants, Convert, ResourcePlatform } from 'src/utilities/Helpers';
import { v4 as uuidv4 } from 'uuid';

import { PublicClientApplication } from '@azure/msal-browser';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { ChatRequestProps } from '../chatbot/services/useApiService';
import { Languages, Locale } from '../localization/Locale';
import * as Messages from '../ui/foundation/Messages';
import { Book, IContentPermissions } from './Book';
import { ChatPayload } from './Chat';
import { SearchSorting, SearchViewMode } from './Content';
import * as AppRequest from './dto/AppRequest';
import * as LibraryRequest from './dto/LibraryRequest';
import * as MigrationRequest from './dto/MigrationRequest';
import * as ProfessorRequest from './dto/ProfessorRequest';
import { IFlowTitlesRequest } from './dto/TitlesRequest';
import {
    ExternalLinkingError, ExternalLinkingPayload, ExternalLinkingSection, IQueryCompanionUrl,
    IQueryCompanionUrlR, IReportExternalLink, IReportExternalLinkR, StoreMessagePayload,
    StoreMessageType
} from './ExternalLinking';
import { Migration } from './Migration';
import { ActionResult } from './Result';
import {
    AnnotationGrouping, AnnotationSorting, AnnotationTypeSorting, CommunitySorting,
    FavouriteGrouping, FavouriteSorting, HistoryType
} from './UserContent';

export class OfflinePackageInstallPayload {
  Message: string;
}
export class LibraryConfig {
  Id: number;
  PublisherId: number;
  PrintMaxWordCount: number;
  CopyMaxWordCount: number;
  BrandKey: string;
  Theme: string;
  Version: string;
  ProductIdPrefix: string;
  HideRegistrationLink: boolean;
  IsIAPEnabled: boolean;
  IsAnonymousEnabled: boolean;
  IsLoginEmailBased: boolean;
  IsServerUrlOverrideable: boolean;
  IsRememberMeAllowed: boolean;
  AuthorizationId: number; // Not needed by frontend. Kept for consistency.
  AuthorizationMethod: number; // Not needed by frontend. Kept for consistency.
  URLPrefix: string;
  LibraryInternationalizations: LibraryConfigInternationalization[];
  StripePublicKey: string;
  StripePublicKeyTest: string;
  StripeStoreUrl: string;
  StripeStoreUrlTest: string;
  AnonymousRequiresToken: boolean;
  LoginLogo: string; ///
  DefaultBookSplash: string; ///
  LibraryIcon: string; ///
  LastModified: Date;
  BuiltInLinkingOverride: string;
  BuiltInServerOverride: string;
  IsMicrosoftSsoEnabled: boolean;
  MicrosoftTenantOverride: string;
  Position: number;
  UserTitlesEnabled: boolean;
}
export class LibraryConfigInternationalization {
  Id: number;
  LibraryConfigId: number;
  Name: string;
  // BrandId: number;
  Language: number;
  ResetPasswordUrl: string;
  RegistrationUrl: string;
  // StringAboutJson: string;
  // StringAboutHtml: string;
  AccountNotActiveMessage: string;
  LoginCustomPlaceholder: string;
  LibraryNoTitlesMessage: string;
  LicenseAgreementLink: string;
  PrivacyPolicyLink: string;
  // RegistrationInvitationHtml: string;
  // RegistrationInvitationCss: string;
  LoginFlavourText: string;
  AvailableTitlesSectionTitle: string; // to remove
  AboutInfos: AboutInfo[];
}

export class AboutInfo {
  Id: number;
  LibraryInternationalizationId: number;
  Title: string;
  Html: string;
}

export enum LoginType {
  None = 0,
  Anonymous = 1,
  User = 2,
}

export class AppSettings {
  DeviceId: string;
  Build: string;
  Version: string;
  ReaderFontSize: FontSizes;
  TOCFontSize: FontSizes;
  SearchEngine: SearchEngines;
  LibraryViewMode: LibraryViewMode;
  LanguageOverride: Languages;
  HelpEnabled: boolean;
  SearchFilteringEnabled: boolean;
  TOCLinking: boolean;
  ContentViewGuttersEnabled: boolean;
  LeftPanelDefaultOpen: boolean;
  FullScreenOpen: boolean;
  LibrarySorting: LibrarySorting;
  LibrarySortingReversed: boolean;
  AvailableTitlesSectionExpansion: SectionState;
  AvailableTitlesSectionDefaultsCollapsed: boolean;
  MyTitlesSectionExpansion: SectionState;
  HistoryFilter: HistoryType;
  AnnotationSorting: AnnotationSorting;
  AnnotationSortingReversed: boolean;
  AnnotationGrouping: AnnotationGrouping;
  AnnotationTypeSorting: AnnotationTypeSorting;
  AnnotationTypeSortingReversed: boolean;
  FavouriteSorting: FavouriteSorting;
  FavouriteSortingReversed: boolean;
  FavouriteGrouping: FavouriteGrouping;
  UserLibraries: number[];
  CanAddLibraries: boolean;
  CanDragAndDropLibraries: boolean;
  SearchSorting: SearchSorting;
  SearchViewMode: SearchViewMode;
  ReopenTitles: boolean;
  AppCanOverrideUrls: boolean;
  CurrentLinkingUrlOverride: string;
  CurrentServerUrlOverride: string;
  CommunitySorting: CommunitySorting;
  CommunitySortingReversed: boolean;
}

class DownloadStore {
  private tasks: Dictionary<number, VersionTask>;
  public taskUpdated: MapEventHandler<number, VersionTask>;
  public versionDownloading: EventHandler<number>;
  public versionDownloaded: EventHandler<number>;
  public versionDownloadRemoved: EventHandler<number>;

  constructor() {
    this.tasks = new Dictionary<number, VersionTask>();
    this.taskUpdated = new MapEventHandler<number, VersionTask>();
    this.versionDownloaded = new EventHandler<number>();
    this.versionDownloading = new EventHandler<number>();
    this.versionDownloadRemoved = new EventHandler<number>();
  }
  public containsKey(versionId: number): boolean {
    return this.tasks.containsKey(versionId);
  }
  public startDownload(versionId: number) {
    if (!this.tasks.containsKey(versionId)) {
      const task = new VersionTask(versionId);
      task.Type = VersionTaskType.downloading;
      this.tasks.set(task.VersionId, task);
      this.versionDownloading.dispatch(task.VersionId, this);
      this.taskUpdated.dispatch(task.VersionId, task, this);
    }
  }

  public launch(versionId: number) {
    const task = new VersionTask(versionId);
    task.Type = VersionTaskType.launching;
    this.tasks.set(task.VersionId, task);
    this.taskUpdated.dispatch(task.VersionId, task, this);
  }

  public update(downloadState: LibraryRequest.IDownloadStateInfoLocal) {
    if (downloadState == null) {
      return;
    }
    if (!this.tasks.containsKey(downloadState.VersionId)) {
      this.tasks.set(downloadState.VersionId, new VersionTask(downloadState.VersionId));
      this.versionDownloading.dispatch(downloadState.VersionId, this);
    }

    const task = this.tasks.get(downloadState.VersionId)!;
    task.Progress = downloadState.RowTotal > 0 ? downloadState.RowCTR / downloadState.RowTotal : 0;
    task.IsPaused = downloadState.IsPaused;

    switch (downloadState.DownloadType) {
      case LibraryRequest.DownloadType.contentDownload:
        task.Type = VersionTaskType.downloading;
        break;
      case LibraryRequest.DownloadType.installing:
        task.Type = VersionTaskType.installing;
        break;
      case LibraryRequest.DownloadType.community:
        task.Type = VersionTaskType.community;
        break;
      case LibraryRequest.DownloadType.userContentSyncing:
        task.Type = VersionTaskType.syncing;
        break;
      case LibraryRequest.DownloadType.completed:
        task.IsCompleted = true;
        task.Progress = 100;
        break;
      case LibraryRequest.DownloadType.cancelled:
        task.IsCompleted = true;
        task.Type = -1;
        task.Progress = 100;
        break;
    }

    this.tasks.set(task.VersionId, task);
    this.taskUpdated.dispatch(task.VersionId, task, this);

    if (task.IsCompleted && downloadState.DownloadType !== LibraryRequest.DownloadType.cancelled) {
      this.versionDownloaded.dispatch(task.VersionId, this);
    }
  }

  public remove(versionId: number) {
    this.tasks.remove(versionId);
    this.versionDownloadRemoved.dispatch(versionId, this);
  }
}

export class LibrarySession implements IId {
  id: string;
  config: LibraryConfig;
  books: Table<Book>;
  migrations: Table<Migration>;
  session: string | undefined;
  canManipulateContent: boolean;
  wire: Wire;
  loginTypeNotifier: Single<LoginType>;
  userName: string;
  libraryCells: Table<LibraryCell>;
  userUploadedTitles: Table<IUserUploadedTitle>;
  userPermissions: Table<UserPermission>;
  downloadStore: DownloadStore;
  appLoading: LoadingReport;
  libraryLoading: LoadingReport;
  pendingBookOpen: number[];
  bookVersionOpened: EventHandler<number>;
  migrationOpened: EventHandler<number>;
  libraryPanelRequested: EventHandler<any>;
  libraryPanelRefreshRequested: EventHandler<any>;
  loginTokenSet: EventHandler<any>;
  purchaseFailed: EventHandler<string>;
  purchaseSuccess: EventHandler<any>;
  productsReceived: EventHandler<LibraryProductCell[]>;
  bookOpenRequested: EventHandler<number>;
  bookVersionUserClosed: EventHandler<Book>;
  bookVersionClosedByFailure: EventHandler<Book>;
  bookVersionAccessLost: EventHandler<AccessResult>;
  bookVersionAccessError: EventHandler<[number, AccessResult]>;
  bookDownloadFailed: EventHandler<number>;
  migrationAvailable: EventHandler<MigrationRequest.IMigrationSet>;
  externalLinkingDownloadRequired: EventHandler<{ payload: ExternalLinkingPayload; version: LibraryVersion }>;
  externalLinkingParseFailed: EventHandler<ExternalLinkingPayload>;
  pendingExternalLinkOpens: Dictionary<number, ExternalLinkingPayload>;
  externalLinkRequiresCompanion: EventHandler<string>;
  externalLinkingCurrentToken: string;
  externalLinkingCurrentTokenName: string;
  ssoIdToken: string;
  ssoLogout: boolean;
  // multi search
  multiSearchMessageReceived: MapEventHandler<number, MultiSearchMessage>;
  multiSearchExecuted: MapEventHandler<number, string>;
  multiSearchCompleted: EventHandler<any>;
  multiSearchSelectedVersions: number[];
  mutliSearchLastQuery: string;
  clearSearch: EventHandler<any>;
  cancelSearch: EventHandler<any>;
  // ----
  localization: Locale;
  appSettings: Single<AppSettings>;
  currentConfig: Single<SettingsTarget>;
  originalConfig: Single<SettingsTarget>;
  shouldLimitRendering: boolean; // Can be listened to in books/library to know if it's not the active lib. Use this to diminish rendering load when not the active lib.
  appBrandKey: string;
  stripe: Stripe | null;
  position: number;
  chatResponseReceived: EventHandler<ChatPayload>;


  // User Subscription
  userPaidSubscriptionPlan: IPaidSubscriptionDefinition;

  userUploadedBooksUpdated: EventHandler<IUserUploadedTitle[]>;

  constructor(
    library: LibraryConfig,
    wire: Wire,
    downloadStore: DownloadStore,
    appLoading: LoadingReport,
    localization: Locale,
    appSettings: Single<AppSettings>,
    brandKey: string,
    currentConfig: Single<SettingsTarget>,
    originalConfig: Single<SettingsTarget>
  ) {
    if (library.IsIAPEnabled === true || library.IsIAPEnabled === undefined) {
      loadStripe(ResourcePlatform.GetStripeStorePublicKey(library, currentConfig.get()))
      .then((res) => (this.stripe = res))
      .catch((error) => {
        console.error('Error loading Stripe:', error);
      });
    }
    this.ssoLogout = false;
    this.appBrandKey = brandKey;
    this.id = library.BrandKey;
    this.books = new Table<Book>();
    this.migrations = new Table<Migration>((i: Migration) => i.migrationSet.VersionTo);
    this.config = library;
    this.wire = wire;
    this.localization = localization;
    this.appSettings = appSettings;
    this.currentConfig = currentConfig;
    this.originalConfig = originalConfig;
    this.wire.addRequestTransform((request) => {
      request.Session = this.getSession();
      request.brandKey = this.config.BrandKey;
      return request;
    });
    this.wire.addMiddleware(
      Middleware.create(async (response: IResponse) => {
        if (response.Error && response.Error.Status === Status.DataError && response.Error.Parameter) {
          this.books.deleteById(+response.Error.Parameter);
        }
        if (response.Error === null && response.Status === Status.NotAllowed) {
          await Log.error("Illegal request made.", null);
        }
      }, this)
    );
    this.loginTypeNotifier = new Single<LoginType>(LoginType.None);
    this.libraryCells = new Table<LibraryCell>((i: LibraryCell) => i.TitleId);
    this.userUploadedTitles = new Table<IUserUploadedTitle>((i: IUserUploadedTitle) => i.TitleId);
    this.userPermissions = new Table<UserPermission>((i: UserPermission) => i.TableId);
    this.downloadStore = downloadStore;
    this.downloadStore.versionDownloaded.on(this.onVersionDownloaded.bind(this));
    this.downloadStore.versionDownloading.on(this.onVersionDownloading.bind(this));
    this.appLoading = appLoading;
    this.libraryLoading = new LoadingReport();
    this.pendingBookOpen = new Array<number>();
    this.bookVersionOpened = new EventHandler<number>();
    this.migrationOpened = new EventHandler<number>();
    this.libraryPanelRequested = new EventHandler<any>();
    this.libraryPanelRefreshRequested = new EventHandler<any>();
    this.loginTokenSet = new EventHandler<any>();
    this.purchaseFailed = new EventHandler<string>();
    this.purchaseSuccess = new EventHandler<any>();
    this.productsReceived = new EventHandler<LibraryProductCell[]>();
    this.bookVersionUserClosed = new EventHandler<Book>();
    this.bookVersionClosedByFailure = new EventHandler<Book>();
    this.bookOpenRequested = new EventHandler<number>();
    this.bookVersionAccessLost = new EventHandler<AccessResult>();
    this.bookVersionAccessError = new EventHandler<[number, AccessResult]>();
    this.bookDownloadFailed = new EventHandler<number>();
    this.migrationAvailable = new EventHandler<MigrationRequest.IMigrationSet>();
    this.externalLinkingParseFailed = new EventHandler<ExternalLinkingPayload>();
    this.externalLinkingDownloadRequired = new EventHandler<{ payload: ExternalLinkingPayload; version: LibraryVersion }>();
    this.externalLinkRequiresCompanion = new EventHandler<string>();
    this.pendingExternalLinkOpens = new Dictionary<number, ExternalLinkingPayload>();
    this.multiSearchMessageReceived = new MapEventHandler<number, MultiSearchMessage>();
    this.multiSearchExecuted = new MapEventHandler<number, any>();
    this.multiSearchCompleted = new EventHandler<any>();
    this.multiSearchSelectedVersions = [];
    this.mutliSearchLastQuery = "";
    this.clearSearch = new EventHandler<any>();
    this.cancelSearch = new EventHandler<any>();
    this.userName = "";
    this.syncUserContent = this.syncUserContent.bind(this);
    this.checkLinks = this.checkLinks.bind(this);
    this.doLink = this.doLink.bind(this);
    this.bookVersionOpened.on(this.checkLinks);
    this.ssoIdToken = "";
    this.chatResponseReceived = new EventHandler<ChatPayload>();
    this.userUploadedBooksUpdated = new EventHandler<IUserUploadedTitle[]>();
  }

  // region External Link Checking
  private checkLinks(version: number) {
    let concreteBook = this.books.get(version);
    if (concreteBook) {
      concreteBook.loading.loaded.on(() => void(async () => {
        await this.doLink(version, concreteBook!);
      })());
    }
  }
  private async checkPostDownloadLink(versionId: number) {
    let pendingLink = this.pendingExternalLinkOpens.get(versionId);
    if (!pendingLink) {
      return;
    }
    let title = this.libraryCells.rows().filter((x) => x.TitleRef === pendingLink!.PayloadDict.get(ExternalLinkingSection.TITLE_REF))[0];
    let version = title.OtherVersions.filter((x) => x.VersionRef === pendingLink!.PayloadDict.get(ExternalLinkingSection.VERSION_REF))[0];
    let concreteBook = this.books.get(versionId);
    if (!concreteBook && pendingLink && version.IsDownloaded) {
      await this.openBookByID(versionId);
    }
  }
  private async doLink(version: number, concreteBook: Book) {
    let pendingLink = this.pendingExternalLinkOpens.get(version);
    if (concreteBook && pendingLink) {
      await concreteBook.handleExternalLinkPayload(pendingLink);
      this.pendingExternalLinkOpens.remove(version);
    }
  }
  // --- end region

  // region Accessors

  public getSession(): string | undefined {
    return this.session;
  }

  get key() {
    if (this.config === undefined) {
      return "";
    }
    return this.config.BrandKey;
  }

  get name() {
    try {
      return this.config.LibraryInternationalizations[this.localization.currentCulture - 1].Name;
    } catch (e) {
      return "";
    }
  }

  get icon() {
    return this.config.LibraryIcon;
  }
  // endregion

  async handleExternalLinkPayload(payload: ExternalLinkingPayload) {
    // Check for a possible login url
    if (payload.PayloadDict.containsKey(ExternalLinkingSection.PASSWORD) && payload.PayloadDict.get(ExternalLinkingSection.BRAND) === this.config.BrandKey) {
      let tokenPieces = payload.PayloadDict.get(ExternalLinkingSection.PASSWORD)!.split(":");
      let tokenName = "";
      let tokenPayload = "";
      if (tokenPieces.length > 1) {
        tokenName = tokenPieces[0];
        tokenPayload = tokenPieces[1];
      } else {
        tokenPayload = tokenPieces[0];
      }

      if (tokenPayload !== this.externalLinkingCurrentToken) {
        if (this.loginType > LoginType.None) {
          Messages.Notify.success(tokenName + " " + this.localization.currentLocale.LoginView.ALERT_TOKEN_REFRESHING);
          this.externalLinkingCurrentToken = tokenPayload;
          this.externalLinkingCurrentTokenName = tokenName;
          this.libraryPanelRefreshRequested.dispatch(null, this);
        } else {
          Messages.Notify.success(tokenName + " " + this.localization.currentLocale.LoginView.ALERT_LOGIN_TOKEN_DETECTED);
          this.externalLinkingCurrentToken = tokenPayload;
          this.externalLinkingCurrentTokenName = tokenName;
          this.loginTokenSet.dispatch(null, this);
        }
      }
    }

    // If no title was provided, the library is simply shown and nothing else is done.
    if (!payload.PayloadDict.get(ExternalLinkingSection.TITLE_REF)) {
      return;
    }

    if (payload.ParseError === ExternalLinkingError.INVALID_TITLE || payload.ParseError === ExternalLinkingError.INVALID_VERSION) {
      this.externalLinkingParseFailed.dispatch(payload, this);
      return;
    }

    // Check if logged in
    if (!this.session) {
      payload.ParseError = ExternalLinkingError.NOT_LOGGED_IN;
      this.externalLinkingParseFailed.dispatch(payload, this);
      return;
    }
    // Check for title in link
    let title = this.libraryCells.rows().filter((x) => x.TitleRef === payload.PayloadDict.get(ExternalLinkingSection.TITLE_REF))[0];
    if (!title) {
      payload.ParseError = ExternalLinkingError.UNKNOWN_TITLE;
      let result = (
        await this.queryCompanionUrl({
          BrandKey: payload.PayloadDict.get(ExternalLinkingSection.BRAND)!,
          TitleRef: payload.PayloadDict.get(ExternalLinkingSection.TITLE_REF)!,
        })
      ).data as IQueryCompanionUrlR;
      if (!Convert.isEmptyOrSpaces(result.Url)) {
        await Log.info("WE GOT A URL BACK OF " + result.Url);
        this.externalLinkRequiresCompanion.dispatch(result.Url, this);
      } else {
        this.externalLinkingParseFailed.dispatch(payload, this);
      }
      return;
    }
    // Check for version in link. If none, defaulting to current version of the cell.
    let version: LibraryVersion;
    if (!payload.PayloadDict.get(ExternalLinkingSection.VERSION_REF)) {
      version = title.CurrentVersion;
    } else {
      version = title.OtherVersions.filter((x) => x.VersionRef === payload.PayloadDict.get(ExternalLinkingSection.VERSION_REF))[0];
    }
    // Still no version, it is unknown.
    if (!version) {
      payload.ParseError = ExternalLinkingError.UNKNOWN_VERSION;
      this.externalLinkingParseFailed.dispatch(payload, this);
      return;
    }
    // Version is not downloaded.
    if (!version.IsDownloaded) {
      // Activate the library tab of this lib.
      this.libraryPanelRequested.dispatch("", this);
      this.externalLinkingDownloadRequired.dispatch({ payload: payload, version: version }, this);
      return;
    }
    // Set the cell to the right version if it isn't already.
    if (title.CurrentVersion.VersionId !== version.VersionId) {
      await this.changeVersion(title.TitleId, version.VersionId);
    }
    // Get the book if it's open already, otherwise open.
    let book = this.books.get(version.VersionId);
    if (!book) {
      // Activate the library tab of this lib.
      this.libraryPanelRequested.dispatch("", this);
      this.pendingExternalLinkOpens.set(version.VersionId, payload);
      await this.openBookByID(version.VersionId, true);
    } else {
      this.bookVersionOpened.dispatch(book.id, this);
      await book.handleExternalLinkPayload(payload);
    }
  }

  setLibraryLoaded() {
    if (!this.appLoading.isLoaded(AppLoadingSteps.library + this.id)) {
      this.appLoading.setLoaded(AppLoadingSteps.library + this.id);
    }
  }

  initSession(session: string, result: AppRequest.LoginResult, isContentManipulationAllowed: boolean, isLibraryDragAllowed: boolean, storeSocket: string) {
    this.session = session;
    if (result === AppRequest.LoginResult.OfflineAnonymous || result === AppRequest.LoginResult.OnlineAnonymous) {
      this.loginType = LoginType.Anonymous;
    } else {
      this.loginType = LoginType.User;
    }
    if (ResourcePlatform.IsAppleIAP()) {
      this.registerStoreNotificationSocket(storeSocket);
    }
    this.canManipulateContent = isContentManipulationAllowed;
    if (ResourcePlatform.IsWebPlatform()) {
      this.appSettings.set({ ...this.appSettings.get(), CanDragAndDropLibraries: isLibraryDragAllowed });
    } else {
      this.appSettings.set({ ...this.appSettings.get(), CanDragAndDropLibraries: true });
    }
  }

  terminateSession() {
    this.libraryCells.deleteAll(false);
    this.userUploadedTitles.deleteAll(false);
    this.migrations.deleteAll(true);
    this.books.deleteAll(true);
    this.userPermissions.deleteAll(false);
    this.pendingExternalLinkOpens = new Dictionary<number, ExternalLinkingPayload>();
    this.pendingBookOpen = [];
    this.canManipulateContent = false;
    this.loginType = LoginType.None;
    this.session = undefined;
    if (this.ssoIdToken !== "") {
      this.ssoLogout = true;
    }
    this.userName = "";
    this.ssoIdToken = "";
    this.externalLinkingCurrentToken = "";
    this.externalLinkingCurrentTokenName = "";
    this.loginTokenSet.dispatch(null, this);
  }

  get loginType() {
    return this.loginTypeNotifier.get();
  }

  set loginType(v: LoginType) {
    this.loginTypeNotifier.set(v);
  }
  async logInfo(request: AppRequest.ILogInfo): Promise<ActionResult> {
    return await Communication.handleResponse<AppRequest.ILogInfoR>(this.wire, "application/logInfo", request, () => {});
  }
  async login(username: string, password: string, rememberMe: boolean, width: number, height: number, isAnonymous: boolean) {
    return await Communication.handleResponse<AppRequest.ILoginR>(
      this.wire,
      "login/login",
      {
        Username: username,
        Password: password,
        RememberMe: rememberMe,
        LibraryKey: this.id,
        Height: height,
        Width: width,
        IsAnonymous: isAnonymous,
        Token: this.externalLinkingCurrentToken,
      } as AppRequest.ILogin,
      (data, result) => void(async () => {
        if (
          data.Result === AppRequest.LoginResult.Online ||
          data.Result === AppRequest.LoginResult.Offline ||
          data.Result === AppRequest.LoginResult.OnlineAnonymous ||
          data.Result === AppRequest.LoginResult.OfflineAnonymous
        ) {
          this.libraryLoading.setLoading("login");
          this.initSession(data.Session, data.Result, data.IsContentManipulationAllowed, data.IsLibraryDragAllowed, data.StoreSocketId);
          this.userPermissions.insertOrUpdateAll(data.UserPermissions);
          await this.updateLibrary();
          this.libraryLoading.setLoaded("login");
          this.userName = data.Username;
        } else {
          if (data.Result === AppRequest.LoginResult.InvalidCredentials) {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_LOGIN_INVALID_PROMPT });
          } else if (data.Result === AppRequest.LoginResult.AccountNotActive) {
            result.addError({ Message: this.config.LibraryInternationalizations[this.localization.currentCulture - 1].AccountNotActiveMessage });
          } else if (data.Result === AppRequest.LoginResult.ConnectionFailed) {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_CONNECTION_FAILED });
          } else {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_LOGIN_ERROR });
          }
        }
      })()
    );
  }

  async ssoLogin(username: string, password: string, rememberMe: boolean, width: number, height: number, isAnonymous: boolean) {
    return await Communication.handleResponse<AppRequest.ILoginR>(
      this.wire,
      "login/ssoLogin",
      {
        Username: username,
        Password: password,
        RememberMe: rememberMe,
        LibraryKey: this.id,
        Height: height,
        Width: width,
        IsAnonymous: isAnonymous,
        Token: this.externalLinkingCurrentToken,
      } as AppRequest.ILogin,
      (data, result) => void(async () => {
        if (
          data.Result === AppRequest.LoginResult.Online ||
          data.Result === AppRequest.LoginResult.Offline ||
          data.Result === AppRequest.LoginResult.OnlineAnonymous ||
          data.Result === AppRequest.LoginResult.OfflineAnonymous
        ) {
          this.libraryLoading.setLoading("login");
          this.initSession(data.Session, data.Result, data.IsContentManipulationAllowed, data.IsLibraryDragAllowed, data.StoreSocketId);
          this.userPermissions.insertOrUpdateAll(data.UserPermissions);
          await this.updateLibrary();
          this.libraryLoading.setLoaded("login");
          this.userName = data.Username;
        } else {
          if (data.Result === AppRequest.LoginResult.InvalidCredentials) {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_LOGIN_INVALID_PROMPT });
          } else if (data.Result === AppRequest.LoginResult.AccountNotActive) {
            result.addError({ Message: this.config.LibraryInternationalizations[this.localization.currentCulture - 1].AccountNotActiveMessage });
          } else if (data.Result === AppRequest.LoginResult.ConnectionFailed) {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_CONNECTION_FAILED });
          } else {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_LOGIN_ERROR });
          }
        }
      })()
    );
  }

  async UploadBookRequest(request: IUploadBookRequest): Promise<ActionResult> {
    return Communication.handleResponse<IUploadBookRequestR>(this.wire, "userBookUpload/uploadBookRequest", request, (data, result) => {
      result.data = data;
    });
  }
  async DeleteUserUploadedBook(request: IDeleteUserUploadedBookRequest): Promise<ActionResult> {
    return Communication.handleResponse<IDeleteUserUploadedBookRequestR>(this.wire, "userBookUpload/deleteUserUploadedBook", request, (data, result) => {
      result.data = data;
    });
  }

  async queryCompanionUrl(request: IQueryCompanionUrl): Promise<ActionResult> {
    return await Communication.handleResponse<IQueryCompanionUrlR>(this.wire, "application/queryCompanionUrl", request, (data, result) => {
      result.data = data;
    });
  }
  async getUserRef(request: IEmptyValue): Promise<ActionResult<LibraryRequest.IGetUserRefR>> {
    return Communication.handleResponse<LibraryRequest.IGetUserRefR>(this.wire, "library/getUserRef", request, (data, result) => {
      result.data = data;
    });
  }
  async updateLibrary() {
    return await Communication.handleResponse<LibraryRequest.ILibraryUpdateR>(
      this.wire,
      "library/update",
      {
        FullRefresh: true,
        Token: this.externalLinkingCurrentToken,
      } as LibraryRequest.ILibraryUpdate,
      (data) => {
        this.libraryCells.deleteAll(true);
        this.userUploadedTitles.deleteAll(true);
        this.libraryCells.insertOrUpdateAll(data.LibraryCells);
        this.userUploadedTitles.insertOrUpdateAll(data.UserUploadedTitles);
        this.userPaidSubscriptionPlan = data.UserPaidSubscriptionPlan;
        if(data.UserUploadedTitlesSocket !== null){
          this.registerUserUploadedTitleLoop(data.UserUploadedTitlesSocket);
        }
      }
    );
  }

  registerUserUploadedTitleLoop = (userUploadedTitlesSocket: string) => {
    Communication.handleSocket<IUserUploadedTitle[]>(
      this.wire,
      userUploadedTitlesSocket,
      (message) => {
        this.userUploadedTitles.insertOrUpdateAll(message);
        this.userUploadedBooksUpdated.dispatch(message, this);
      }, // Disconnect
      () => {
        
      }, // Error
      () => {
        
      },
      1000
    );
  }

  async deleteOutdatedVersions() {
    return await Communication.handleResponse<LibraryRequest.ILibraryUpdateR>(this.wire, "library/deleteOutdatedVersions", {}, (data, result) => {
      result.data = data;
    });
  }
  async getFirstAvailableTitles(
    request: LibraryRequest.ILibraryGetFirstAvailableTitles
  ): Promise<ActionResult<LibraryRequest.ILibraryGetFirstAvailableTitlesR>> {
    return await Communication.handleResponse<LibraryRequest.ILibraryGetFirstAvailableTitlesR>(
      this.wire,
      "library/getFirstAvailableTitles",
      request,
      (data, result) => {
        result.data = data;
      },
      (result) => {
        result.data = [];
      }
    );
  }
  async fetchAvailableTitles(
    flowRequest: LibraryRequest.AvailableTitlesCogniflowMessage
  ): Promise<ActionResult<LibraryRequest.AvailableTitlesCogniflowMessage<LibraryCell>>> {
    return await Communication.handleResponse<LibraryRequest.AvailableTitlesCogniflowMessage<LibraryCell>>(
      this.wire,
      "library/flowAvailableTitles",
      {
        ...flowRequest,
      },
      (data, result) => {
        result.data = data;
      }
    );
  }

  async flowTitles(request: IFlowTitlesRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return await Communication.handleResponse<AppRequest.IFlowR>(this.wire, "library/flowTitles", request, (data, result) => {
      result.data = data;
    });
  }

  async multiSearch(request: LibraryRequest.ILibraryMultiSearch) {
    // Notify selected version cells that they have a search to execute.
    request.SelectedVersions.forEach((element) => {
      this.multiSearchExecuted.dispatch(element, "", this);
    });
    return await Communication.handleResponse<LibraryRequest.ILibraryMultiSearchR>(
      this.wire,
      "library/multiSearch",
      request,
      (data) => {
        this.registerMultiSearch(data.MultiSearchSocketId);
        this.multiSearchSelectedVersions = request.SelectedVersions;
        this.mutliSearchLastQuery = request.SearchQuery;
      },
      () => {
        this.multiSearchSelectedVersions = [];
        this.mutliSearchLastQuery = "";
      }
    );
  }

  async cancelMultiSearch(request: LibraryRequest.ILibraryCancelMultiSearch) {
    return await Communication.handleResponse<LibraryRequest.ILibraryMultiSearchR>(
      this.wire,
      "library/cancelMultiSearch",
      request,
      () => {},
      () => {}
    );
  }

  registerMultiSearch(multiSearchSocketId: string) {
    Communication.handleSocket<MultiSearchMessage>(
      this.wire,
      multiSearchSocketId,
      (message) => void(async () => {
        await Log.info("Got a returned result count. ID: " + message.VersionId + " Result Count: " + message.ResultCount);
        this.multiSearchMessageReceived.dispatch(message.VersionId, message, this);
      })(), // Disconnect
      () => {
        this.multiSearchCompleted.dispatch("", this);
      }, // Error
      () => {
        this.multiSearchCompleted.dispatch("", this);
      },
      1000
    );
  }

  async getAboutPage(id: number, pageId: number): Promise<ActionResult<string>> {
    if (!this.libraryCells.exists(id)) {
      let result = ActionResult.create();
      result.status = Status.NotFound;
      result.addError({
        Message: "Version not found.",
      });
      return result.promise();
    }

    return await Communication.handleResponse<LibraryRequest.ILibraryGetInfoPageR>(
      this.wire,
      "library/getInfoPage",
      {
        VersionId: this.libraryCells.get(id)!.CurrentVersion.VersionId,
        ResourceId: pageId,
      } as LibraryRequest.ILibraryGetInfoPage,
      (data, result) => {
        result.data = data.PageData;
      },
      (result) => {
        result.data = "";
      }
    );
  }

  // region Versions
  async changeVersion(id: number, toVersionId: number) {
    let result = ActionResult.create();

    if (!this.libraryCells.exists(id)) {
      result.status = Status.NotFound;
      result.addError({
        Message: "Version not found.",
      });
      return result.promise();
    }

    const currentVersionCell = this.libraryCells.get(id)!;
    for (const version of currentVersionCell.OtherVersions) {
      if (version.VersionId === toVersionId) {
        currentVersionCell.CurrentVersion = version;
        this.libraryCells.insertOrUpdate(currentVersionCell);
        return result.promise();
      }
    }

    result.status = Status.NotFound;
    result.addError({
      Message: "Couldn't find version update.",
    });
    return result.promise();
  }
  // endregion

  // region Download
  private onVersionDownloading(versionId: number) {
    this.mapCellsWithMatchingVersion(versionId, (cell, versions) => {
      let changed = false;
      for (const version of versions) {
        if (!version.IsDownloading) {
          version.IsDownloading = true;
          changed = true;
        }

        if (version.IsDownloaded) {
          version.IsDownloaded = false;
          changed = true;
        }
      }

      if (changed) {
        this.libraryCells.insertOrUpdate(cell);
      }
    });
  }

  private onVersionDownloaded(versionId: number) {
    if (this.pendingBookOpen.indexOf(versionId) > -1) {
      this.loadBook(versionId);
    }

    this.mapCellsWithMatchingVersion(versionId, (cell, versions) => {
      let changed = false;
      for (const version of versions) {
        if (!version.IsDownloaded) {
          version.IsDownloaded = true;
          changed = true;
        }

        if (version.IsDownloading) {
          version.IsDownloading = false;
          changed = true;
        }
      }

      if (changed) {
        this.libraryCells.insertOrUpdate(cell);
      }
    });
  }

  private onVersionDownloadRemoved(versionId: number) {
    this.mapCellsWithMatchingVersion(versionId, (cell, versions) => {
      let changed = false;
      for (const version of versions) {
        if (version.IsDownloading !== false) {
          version.IsDownloading = false;
          changed = true;
        }
      }
      if (changed) {
        this.libraryCells.insertOrUpdate(cell);
      }
    });
  }

  async download(id: number) {
    if (!this.libraryCells.exists(id)) {
      let result = ActionResult.create();
      result.status = Status.NotFound;
      result.addError({
        Message: "Version not found.",
      });
      return result.promise();
    }
    const downloadVersion = this.libraryCells.get(id)!;
    return this.downloadById(downloadVersion.CurrentVersion.VersionId);
  }
  async downloadById(versionId: number) {
    return await Communication.handleResponse<LibraryRequest.ILibraryDownloadVersionR>(
      this.wire,
      "library/downloadVersion",
      {
        VersionId: versionId,
      } as LibraryRequest.ILibraryDownloadVersion,
      (data) => {
        this.downloadStore.startDownload(data.VersionId);
        this.registerDownload(data.VersionId, data.DownloadId);
      },
      (result) => {
        result.status = Status.SyncOffline;
        result.addError({
          Message: "Download Failed",
        });
        this.bookDownloadFailed.dispatch(versionId, { Message: result.errors[0].Message, Status: result.status } as IError);
      }
    );
  }

  registerDownload(versionId: number, downloadId: string) {
    Communication.handleSocket<LibraryRequest.IDownloadStateInfoLocal>(
      this.wire,
      downloadId,
      (message) => {
        this.downloadStore.update(message);
      },
      () => void(async () => {
        this.downloadStore.remove(versionId);
        await this.checkPostDownloadLink(versionId);
      })(),
      (result) => {
        this.bookDownloadFailed.dispatch(versionId, { Message: result.Error!.Message, Status: result.Error!.Status } as IError);
        if (
          result.Error!.Status !== Status.SyncOffline &&
          result.Error!.Status !== Status.ConnectionFailed &&
          result.Error!.Status !== Status.AuthorizationFailed
        ) {
          this.downloadStore.update({ DownloadType: LibraryRequest.DownloadType.cancelled, IsPaused: false, RowCTR: 0, RowTotal: 1, VersionId: versionId });
          this.downloadStore.remove(versionId);
        }
      },
      1000
    );
  }

  async pauseDownload(id: number) {
    if (!this.libraryCells.exists(id)) {
      let result = ActionResult.create();
      result.status = Status.NotFound;
      result.addError({
        Message: "Version not found.",
      });
      return result.promise();
    }

    return await Communication.handleResponse<LibraryRequest.ILibraryPauseDownloadR>(
      this.wire,
      "library/pauseDownload",
      {
        VersionId: this.libraryCells.get(id)!.CurrentVersion.VersionId,
      } as LibraryRequest.ILibraryPauseDownload,
      (data) => {
        if (data.State !== undefined) {
          this.downloadStore.update(data.State);
        }
      }
    );
  }

  async deleteById(versionId: number) {
    let ind = -1;
    this.libraryCells.rows().filter((x, i) => {
      if (x.CurrentVersion.VersionId === versionId) {
        ind = i;
        return true;
      } else {
        return false;
      }
    });
    const cellId = this.libraryCells.keys()[ind];
    return await Communication.handleResponse<LibraryRequest.ILibraryDeleteVersion>(
      this.wire,
      "library/deleteVersion",
      {
        VersionId: versionId,
      } as LibraryRequest.ILibraryDeleteVersion,
      () => {
        let cell = this.libraryCells.get(cellId)!;
        if (cell.CurrentVersion.VersionId === versionId) {
          cell.CurrentVersion.IsDownloaded = false;
          cell.CurrentVersion.IsDownloading = false;
        }
        cell.OtherVersions.filter((x) => x.VersionId === versionId)[0].IsDownloaded = false;
        cell.OtherVersions.filter((x) => x.VersionId === versionId)[0].IsDownloading = false;
        if (this.downloadStore.containsKey(versionId)) {
          // Download in progress was cancelled.
          this.downloadStore.update({ VersionId: versionId, DownloadType: LibraryRequest.DownloadType.cancelled, IsPaused: true, RowCTR: 1, RowTotal: 1 });
          this.downloadStore.remove(versionId);
        }
        this.libraryCells.insertOrUpdate(cell);
      }
    );
  }

  async delete(id: number) {
    if (!this.libraryCells.exists(id)) {
      let result = ActionResult.create();
      result.status = Status.NotFound;
      result.addError({
        Message: "Version not found.",
      });
      return result.promise();
    }
    const versionId = this.libraryCells.get(id)!.CurrentVersion.VersionId;
    return this.deleteById(versionId);
  }

  async toggleFavouriteTitle(isFavourite: boolean, titleId: number) {
    await Communication.handleResponse<boolean>(
      this.wire,
      "library/UserFavouriteTitles",
      {
        TitleId: titleId,
        IsFavourite: isFavourite,
      } as LibraryRequest.ILibraryCreateFavouriteTitle,
      () => {
        isFavourite = !isFavourite;
      }
    );
    return isFavourite;
  }

  private mapCellsWithMatchingVersion(versionId: number, f: (cell: LibraryCell, version: LibraryVersion[])=> void) {
    return this.libraryCells.rows().map((cell) => {
      const versions = new Array<LibraryVersion>();
      if (cell.CurrentVersion.VersionId === versionId) {
        versions.push(cell.CurrentVersion);
      }

      for (const version of cell.OtherVersions) {
        if (version.VersionId === versionId) {
          versions.push(version);
        }
      }

      return f(cell, versions);
    });
  }

  getVersion(versionId: number): LibraryVersion | null {
    for (const cell of this.libraryCells.rows()) {
      if (cell.CurrentVersion.VersionId === versionId) {
        return cell.CurrentVersion;
      }

      for (const version of cell.OtherVersions) {
        if (version.VersionId === versionId) {
          return version;
        }
      }
    }
    return null;
  }

  getVersionInfo(versionId: number): VersionMeta | null {
    for (const cell of this.libraryCells.rows()) {
      if (cell.CurrentVersion.VersionId === versionId) {
        return cell.CurrentVersion.VersionInfo;
      }

      for (const version of cell.OtherVersions) {
        if (version.VersionId === versionId) {
          return version.VersionInfo;
        }
      }
    }
    return null;
  }

  getCellId(versionId: number) {
    for (const cell of this.libraryCells.rows()) {
      if (cell.CurrentVersion.VersionId === versionId) {
        return cell.TitleId;
      }

      for (const version of cell.OtherVersions) {
        if (version.VersionId === versionId) {
          return cell.TitleId;
        }
      }
    }
    return null;
  }

  // endregion

  async openBookByID(versionId: number, assertOpen = false, currentHits = 0) {
    if (!Convert.isEmptyOrSpaces(this.mutliSearchLastQuery) && currentHits > 0) {
      let payload = new ExternalLinkingPayload();
      payload.PayloadDict = new Dictionary<ExternalLinkingSection, string>();
      payload.PayloadDict.set(ExternalLinkingSection.ACTION, "search");
      payload.PayloadDict.set(ExternalLinkingSection.SEARCH_QUERY, this.mutliSearchLastQuery);
      payload.ParseError = ExternalLinkingError.NONE;
      this.pendingExternalLinkOpens.set(versionId, payload);
    }
    if (this.books.exists(versionId)) {
      // already open
      let result = ActionResult.create();
      result.data = AccessResult.Success;
      this.bookVersionOpened.dispatch(versionId, this);
      return result;
    }
    this.downloadStore.launch(versionId);

    let resp = await Communication.handleResponse<LibraryRequest.ILibraryOpenVersionR>(
      this.wire,
      "library/versionAccessCheck",
      {
        VersionId: versionId,
        AssertOpen: assertOpen,
      } as LibraryRequest.ILibraryOpenVersion,
      (data, result) => void(async () => {
        result.data = data.Result;
        const openVersionId = data.VersionId;
        if (data.Result === AccessResult.Success || data.Result === AccessResult.OfflineSuccess) {
          let splashes = await this.getBookSplash(versionId);
          this.addBook(versionId, data.Permissions, window.innerWidth > window.innerHeight ? splashes[1] : splashes[0]);
          this.bookVersionOpened.dispatch(versionId, this);
          await Communication.handleResponse<LibraryRequest.ILibraryOpenVersionR>(
            this.wire,
            "library/openVersion",
            {
              VersionId: versionId,
              AssertOpen: assertOpen,
            } as LibraryRequest.ILibraryOpenVersion,
            (innerData) => {
              this.userPermissions.insertOrUpdateAll(innerData.UserPermissions);
              if (innerData.DownloadId) {
                this.registerDownload(openVersionId, innerData.DownloadId);
                this.registerAccessLoop(innerData.CheckAccessId, innerData.VersionId);
              }
            },
            () => void(async () => {
              this.downloadStore.update({ DownloadType: LibraryRequest.DownloadType.cancelled, IsPaused: false, RowCTR: 0, RowTotal: 1, VersionId: versionId });
              this.downloadStore.remove(versionId);
              await this.closeBook({ RemoveVersion: true, VersionId: versionId });
            })()
          );
        } else if (
          result.data === AccessResult.Bumped ||
          result.data === AccessResult.NoAccess ||
          result.data === AccessResult.NotDownloaded ||
          result.data === AccessResult.OfflineOpensExceeded ||
          result.data === AccessResult.AlreadyLoaded ||
          result.data === AccessResult.OfflineUsageTimeExceeded ||
          result.data === AccessResult.AlreadyMigrating ||
          result.data === AccessResult.ConcurrencyAccessRevoked ||
          result.data === AccessResult.IpAccessRevoked ||
          result.data === AccessResult.ConditionalAccessFailure
        ) {
          this.downloadStore.update({ DownloadType: LibraryRequest.DownloadType.cancelled, IsPaused: false, RowCTR: 100, RowTotal: 100, VersionId: versionId });
          this.downloadStore.remove(versionId);
          this.bookVersionAccessError.dispatch([versionId, result.data as AccessResult], this);
        } else if (result.data === AccessResult.MigrationAvailable) {
          this.downloadStore.update({ DownloadType: LibraryRequest.DownloadType.cancelled, IsPaused: false, RowCTR: 100, RowTotal: 100, VersionId: versionId });
          // A migration is available! Dispatch an event so the user can decide if they wanna migrate.
          this.migrationAvailable.dispatch(data.Migration, this);
        } else {
          this.downloadStore.remove(openVersionId);
        }
      })(),
      () => {
        this.downloadStore.remove(versionId);
      }
    );
    return resp.promise();
  }

  async openBook(id: number, assertOpen = false, currentHits = 0): Promise<ActionResult<AccessResult>> {
    if (!this.libraryCells.exists(id)) {
      let result = ActionResult.create();
      result.status = Status.NotFound;
      result.addError({
        Message: "Version not found.",
      });
      return result.promise();
    }
    const titleCell = this.libraryCells.get(id)!;

    if (!titleCell.CurrentVersion.IsDownloaded && titleCell.OtherVersions.some((x) => x.IsDownloaded)) {
      return this.openBookByID(
        titleCell.OtherVersions.filter((x) => x.IsDownloaded)
          .sort((x) => x.VersionId)
          .reverse()[0].VersionId,
        assertOpen,
        currentHits
      );
    } else {
      return this.openBookByID(titleCell.CurrentVersion.VersionId, assertOpen, currentHits);
    }
  }

  addBook(versionId: number, permissions: IContentPermissions, splash?: number[]) {
    if (!this.books.exists(versionId)) {
      // not already open
      this.pendingBookOpen.push(versionId);
      let info = this.getVersionInfo(versionId);
      let book: Book = new Book(
        versionId,
        Wire.shield(this.wire),
        this.downloadStore.taskUpdated.handler(versionId),
        this.localization,
        this.appSettings,
        this.config,
        this.appBrandKey,
        this.loginTypeNotifier,
        permissions,
        info,
        this
      );
      book.onUCSyncRequested.on((id) => void this.syncUserContent(id));
      book.onBookInternalClosureRequested.on((r) => void this.closeBookInternalFailure(r));
      if (splash !== undefined) {
        book.splash = splash;
      }
      this.books.insertOrUpdate(book);
    }
  }
  // region Migration (only open/close of migration)
  addMigration(initialConflict: MigrationRequest.IExecuteMigrationR, set: MigrationRequest.IMigrationSet) {
    let mig: Migration = new Migration(initialConflict, set, Wire.shield(this.wire), this.localization);
    mig.onMigrationExited.on(() => void(async () => {
      await this.exitMigration({ MigrationSet: mig.migrationSet });
    })());
    this.migrations.insertOrUpdate(mig);
    this.migrationOpened.dispatch(set.VersionTo, this);
  }
  async executeMigration(request: MigrationRequest.IExecuteMigration): Promise<ActionResult<MigrationRequest.IExecuteMigrationR>> {
    return await Communication.handleResponse<MigrationRequest.IExecuteMigrationR>(this.wire, "migration/executeMigration", request, (data, result) => {
      result.data = data;
    });
  }
  async exitMigration(request: MigrationRequest.IExitMigration): Promise<ActionResult> {
    return await Communication.handleResponse<MigrationRequest.IExitMigration>(this.wire, "migration/exitMigration", request, () => void(async () => {
      this.migrations.deleteById(request.MigrationSet.VersionTo);
      await this.openBookByID(request.MigrationSet.VersionTo, true);
    })());
  }

  async syncUserContent(id: number): Promise<ActionResult> {
    return await Communication.handleResponse<LibraryRequest.ILibrarySyncUserContentR>(
      this.wire,
      "library/syncUserContent",
      { VersionId: id } as LibraryRequest.ILibrarySyncUserContent,
      (data) => {
        if (data.Result === AccessResult.Success) {
          this.registerDownload(id, data.DownloadId);
        }
      },
      () => {}
    );
  }

  // end region migration
  closeBookInternalFailure = async (request: LibraryRequest.ILibraryCloseVersion) => {
    await Communication.handleResponse<LibraryRequest.ILibraryCloseVersion>(this.wire, "library/closeVersion", request, () => {
      this.bookVersionClosedByFailure.dispatch(this.books.get(request.VersionId)!, this);
      this.books.deleteById(request.VersionId);
    });
  };
  async closeBook(request: LibraryRequest.ILibraryCloseVersion) {
    // Saving the appClosure location first since the library/closeVersion call will remove the book
    // from the book pool, which prevent us from saving the appClosure afterwards.
    const bookToClose = this.books.get(request.VersionId);
    if (bookToClose) {
      await bookToClose.saveBookLocation();
    }

    return await Communication.handleResponse<LibraryRequest.ILibraryCloseVersion>(this.wire, "library/closeVersion", request, () => {
      this.bookVersionUserClosed.dispatch(this.books.get(request.VersionId)!, this);
      this.books.deleteById(request.VersionId);
    });
  }

  loadBook(versionId: number) {
    if (this.books.exists(versionId)) {
      // not already open
      this.books.get(versionId)!.load();
      let pending: number = this.pendingBookOpen.indexOf(versionId);
      if (pending > -1) {
        this.pendingBookOpen.splice(pending, 1);
      }
    }
  }

  registerAccessLoop(accessId: string, versionId: number) {
    Communication.handleSocket<AccessResult>(
      this.wire,
      accessId,
      (message) => void(async() => {
        if (message === AccessResult.NativeShutdown) {
          await this.closeBook({ VersionId: versionId, RemoveVersion: false });
          return;
        }
        if (message === AccessResult.NativeSaveLocation) {
          const bookToClose = this.books.get(versionId);
          if (bookToClose) {
            await bookToClose.saveBookLocation();
          }
        }
        if (
          message === AccessResult.Bumped ||
          message === AccessResult.MigrationAvailable ||
          message === AccessResult.NoAccess ||
          message === AccessResult.NotDownloaded ||
          message === AccessResult.OfflineOpensExceeded ||
          message === AccessResult.AlreadyLoaded ||
          message === AccessResult.OfflineUsageTimeExceeded ||
          message === AccessResult.ConcurrencyAccessRevoked ||
          message === AccessResult.IpAccessRevoked ||
          message === AccessResult.ConditionalAccessFailure
        ) {
          this.bookVersionAccessLost.dispatch(message, this);
          this.books.deleteById(+versionId);
        }
      })(),

      () => {},
      () => {},
      20000
    );
  }

  async getBookSplash(versionId: number): Promise<number[][]> {
    let splashPage: number[][] = [];
    await Communication.handleResponse<LibraryRequest.ILibraryGetSplashPageR>(
      this.wire,
      "library/getSplashPage",
      {
        VersionId: versionId,
      } as LibraryRequest.ILibraryGetSplashPage,
      (innerData) => {
        splashPage.push(innerData.SplashPagePortrait);
        splashPage.push(innerData.SplashPageLandscape);
      }
    );
    return splashPage;
  }

  get onLibraryTaskUpdated(): MapEventHandler<number, VersionTask> {
    return this.downloadStore.taskUpdated;
  }
  async restorePurchases() {
    // "library/createPurchaseIntent"
    return await Communication.handleResponse<Record<string, unknown>>(this.wire, "library/restorePurchases", {}, () => {});
  }
  async createPurchaseIntent(request: LibraryRequest.ILibraryCreatePurchaseIntent): Promise<ActionResult<LibraryRequest.ILibraryCreatePurchaseIntentR>> {
    // "library/createPurchaseIntent"
    return await Communication.handleResponse<LibraryRequest.ILibraryCreatePurchaseIntentR>(this.wire, "library/createPurchaseIntent", request, (data, result) => {
      result.data = data;
    });
  }
  async cancelPurchaseIntent(request: LibraryRequest.ILibraryCancelPurchaseIntent): Promise<ActionResult<LibraryRequest.ILibraryCancelPurchaseIntentR>> {
    // "library/createPurchaseIntent"
    return await Communication.handleResponse<LibraryRequest.ILibraryCancelPurchaseIntent>(this.wire, "library/cancelPurchaseIntent", request, (data, result) => {
      result.data = data;
    });
  }
  async executePurchase(request: LibraryRequest.ILibraryExecutePurchase): Promise<ActionResult<LibraryRequest.ILibraryExecutePurchaseR>> {
    // "library/createPurchaseIntent"
    return await Communication.handleResponse<LibraryRequest.ILibraryExecutePurchaseR>(this.wire, "library/executePurchase", request, (data, result) => {
      result.data = data;
    });
  }
  registerStoreNotificationSocket(storeSocketId: string) {
    Communication.handleSocket<StoreMessagePayload>(
      this.wire,
      storeSocketId,
      (message) => {
        switch (message.StoreMessageType) {
          case StoreMessageType.ProductsLoaded:
            this.productsReceived.dispatch(message.ProductCells, this);
            break;
          case StoreMessageType.PurchaseCompleted:
            this.purchaseSuccess.dispatch(null, this);
            break;
          case StoreMessageType.PurchaseError:
            this.purchaseFailed.dispatch(message.FailureMessage, this);
            break;
          case StoreMessageType.PurchasesRestored:
            this.libraryPanelRefreshRequested.dispatch(null, this);
            break;
        }
      },
      () => {},
      () => {},
      1000
    );
  }
  async logout() {
    if (this.session !== undefined) {
      if (ResourcePlatform.IsWebPlatform()) {
        this.appSettings.set({ ...this.appSettings.get(), CanDragAndDropLibraries: false });
      }
      return await Communication.handleResponse<LibraryRequest.ILoginLogoutR>(
        this.wire,
        "login/logout",
        {
          InvalidateUser: false,
        } as LibraryRequest.ILoginLogout,
        () => {
          this.terminateSession();
        }
      );
    }
    return ActionResult.create().promise();
  }canManageUserForms(formType: UserFormSubmissionType, publisherId: number) {
    if (this.canManageSystem()) {
      return true;
    }
    if (this.canManagePublisher()) {
      return true;
    }
    switch (formType) {
      case UserFormSubmissionType.Bulletin:
        return this.userPermissions.rows().some((x) => (x.ManageBulletins && x.PublisherId === publisherId) || (x.ManageBulletins && x.PublisherId === null));
      case UserFormSubmissionType.Tip:
        return this.userPermissions.rows().some((x) => (x.ManageTips && x.PublisherId === publisherId) || (x.ManageTips && x.PublisherId === null));
      case UserFormSubmissionType.Feedback:
        return this.userPermissions.rows().some((x) => (x.ManageFeedback && x.PublisherId === publisherId) || (x.ManageFeedback && x.PublisherId === null));
    }
  }
 async getChatTitles(
    request: ProfessorRequest.IProfessorTitles
  ): Promise<ActionResult<ProfessorRequest.IProfessorTitlesR>> {
    return await Communication.handleResponse<ProfessorRequest.IProfessorTitlesR>(
      this.wire,
      "professor/titles",
      request,
      (data, result) => {
        result.data = data;
      },
      (result) => {
        result.data = [];
      }
    );
  }
  canManageSystem = () => this.userPermissions.rows().some((x) => x.ManageSystem);
  canManageAccounts = () => this.canManageSystem() || this.userPermissions.rows().some((x) => x.ManageAccounts);
  canManageOfflinePackages = () => this.canManageAccounts() || this.userPermissions.rows().some((x) => x.ManageOfflinePackages);

  canManageProducts = (publisherId: number) => {
    if (this.canManagePublisher()) {
      return true;
    }
    return this.userPermissions.rows().some((x) => (x.ManageProducts && x.PublisherId === null) || (x.ManageProducts && x.PublisherId === publisherId));
  };
  canManageTitles = (publisherId: number) => {
    if (this.canManagePublisher()) {
      return true;
    }
    return this.userPermissions.rows().some((x) => (x.ManageTitles && x.PublisherId === null) || (x.ManageTitles && x.PublisherId === publisherId));
  };
  canManageSubscriptions = (publisherId: number) => {
    if (this.canManagePublisher()) {
      return true;
    }
    return this.userPermissions.rows().some((x) => (x.ManageSubscription && x.PublisherId === null) || (x.ManageSubscription && x.PublisherId === publisherId));
  };
  canCurateSubscription = (publisherId: number, subscriptionId: number) => {
    if (this.canManageSubscriptions(publisherId)) {
      return true;
    }
    return this.userPermissions.rows().some((x) => x.ManageSubscription && x.SubscriptionId === subscriptionId);
  };
  canCurateAnySubscription = () => this.userPermissions.rows().some((x) => x.ManageSubscription && x.SubscriptionId !== null && x.PublisherId !== null);
  canManagePublisher = () => {
    if (this.canManageSystem()) {
      return true;
    }
    return this.userPermissions.rows().some((x) => x.ManagePublishers);
  };
  canManageReporting = () => {
    if (this.canManagePublisher()) {
      return true;
    }
    return this.userPermissions.rows().some((x) => x.ManageReporting);
  };
  canManageContentReview = () => {
    if (this.canManagePublisher()) {
      return true;
    }
    return this.userPermissions.rows().some((x) => x.ManageContentReview);
  };
  async getChatModels(
    request: ProfessorRequest.IProfessorModels
  ): Promise<ActionResult<ProfessorRequest.IProfessorModelsR>> {
    return await Communication.handleResponse<ProfessorRequest.IProfessorModelsR>(
      this.wire,
      "professor/models",
      request,
      (data, result) => {
        result.data = data;
      },
      (result) => {
        result.data = [];
      }
    );
  }

  async getChat(params: ChatRequestProps
  ): Promise<ActionResult<ProfessorRequest.IProfessorChatR>> {
    const chatRequest: ProfessorRequest.IProfessorChat = params.chatRequest;
    return await Communication.handleResponse<ProfessorRequest.IProfessorChatR>(
      this.wire,
      "professor/chat",
      chatRequest,
      (data, result) => {
        result.data = data;
      },
      (result) => {
        result.data = [];
      }
    );
  }

  async processBookForChat(request: ProfessorRequest.IProfessorProcessBook
    ): Promise<ActionResult<ProfessorRequest.IProfessorProcessBook>> {
      return await Communication.handleResponse<ProfessorRequest.IProfessorProcessBookR>(
        this.wire,
        "professor/processBook",
        request,
        (data, result) => {
          result.data = data;
        },
        (result) => {
          result.data = [];
        }
      );
    }
  
  registerChat(
    socketId: string
    ) {
      Communication.handleSocket<ChatPayload>(
        this.wire,
        socketId,
    (message) => {
      this.chatResponseReceived.dispatch(message, this);
    },
    () => {
      console.log("disconnect");
    },
    () => {
      console.log("error");
    },
    1000
  );
}}

export enum AppLoadingSteps {
  data = "data",
  library = "lib_",
}
export class AppSession {
  libraries: Table<LibrarySession>;
  notifications: Table<INotification>;
  dialog: Single<IDialogItemProps | undefined>;
  currentLibraryNotifier: Single<LibrarySession>;
  downloadStore: DownloadStore;
  wire: Wire;
  appLoading: LoadingReport;
  localization: Locale;
  appSettings: Single<AppSettings>;
  currentConfig: Single<SettingsTarget>;
  originalConfig: Single<SettingsTarget>;
  userConfigs: Table<LibraryConfig>;
  installedConfigs: LibraryConfig[];
  externalLinkingParseFailed: EventHandler<ExternalLinkingPayload>;
  appUpdateAvailable: EventHandler<AppRequest.IUpdateCheckR>;
  appBrandKey: string;
  MSALInstance: PublicClientApplication;
  // Offline import
  offlineImportInstallMessageReceived: EventHandler<OfflinePackageInstallPayload>;

  constructor() {
    let requestor: IRequestor;

    this.localization = new Locale();
    switch (Config.platform) {
      case Platform.windows:
        requestor = new CefRequestor();
        break;
      case Platform.android:
        requestor = new AndroidRequestor();
        break;
      case Platform.mac:
      case Platform.ios:
        requestor = new AppleRequestor();
        break;
      default: {
        let location = window.location.href;
        let parts = location.split("/");
        let apiUrl = parts[0] + "//" + parts[2] + "/api/";
        requestor = new AjaxRequestor(apiUrl);
        break;
      }
    }
    this.appSettings = new Single<AppSettings>(new AppSettings());
    this.appSettings.addListener(() => void(async () => {
      await this.saveSettings();
    })());
    this.currentConfig = new Single<SettingsTarget>(0);
    this.originalConfig = new Single<SettingsTarget>(0);
    this.wire = Wire.create(requestor); // TODO: make this platform specific
    this.downloadStore = new DownloadStore();

    this.appLoading = new LoadingReport();
    this.libraries = new Table<LibrarySession>((i: LibrarySession) => i.id);
    this.installedConfigs = [];
    this.userConfigs = new Table<LibraryConfig>((i: LibraryConfig) => i.BrandKey);
    this.currentLibraryNotifier = new Single<LibrarySession>();
    this.externalLinkingParseFailed = new EventHandler<ExternalLinkingPayload>();
    this.appUpdateAvailable = new EventHandler<AppRequest.IUpdateCheckR>();
    this.notifications = Notify.notificationList.notifications;
    this.dialog = Dialog.currentDialog;
    this.offlineImportInstallMessageReceived = new EventHandler<OfflinePackageInstallPayload>();
    
  }

  public addLibrary(config: LibraryConfig) {
    let head = document.head || document.getElementsByTagName("head")[0];
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(config.Theme));
    head.appendChild(style);
    const newLibrary = new LibrarySession(
      config,
      Wire.shield(this.wire),
      this.downloadStore,
      this.appLoading,
      this.localization,
      this.appSettings,
      this.appBrandKey,
      this.currentConfig,
      this.originalConfig
    );

    this.libraries.insertOrUpdate(newLibrary);
    return newLibrary;
  }

  public deleteLibrary(config: LibraryConfig) {
    this.userConfigs.delete(config);
    const librarySession = this.libraries.get(config.BrandKey);
    this.libraries.delete(librarySession!);
  }

  public selectLibrary(key: string) {
    const l = this.getLibrary(key);
    if (l) {
      if (l === this.currentLibraryNotifier.get()) {
        l.libraryPanelRequested.dispatch("", null);
      }
      this.currentLibraryNotifier.set(l);
    }
  }

  public refreshSelectedLibrary() {
    let l = this.currentLibraryNotifier.get();
    if (l && (l.loginType === LoginType.User || l.loginType === LoginType.Anonymous)) {
      l.libraryPanelRefreshRequested.dispatch("", null);
    }
  }

  private getLibrary(key: string) {
    return this.libraries.get(key);
  }

  async logout(key?: string) {
    if (key === undefined) {
      for (const library of this.libraries.rows()) {
        let result = await library.logout();
        if (!result.valid()) {
          return result;
        }
      }

      return ActionResult.create().promise();
    } else {
      this.appLoading.setLoading("data");
      const library = this.getLibrary(key);
      if (library) {
        await library.logout();
        this.appLoading.setLoaded("data");
        return;
      }

      let faultyResult = ActionResult.create();
      faultyResult.status = Status.NotFound;
      this.appLoading.setLoaded("data");
      return faultyResult;
    }
  }

  async saveSettings(): Promise<ActionResult> {
    return await Communication.handleResponse<AppRequest.ISaveSettingsR>(this.wire, "options/saveSettings", { NewSettings: this.appSettings.get() }, () => {});
  }

  async setConfigOverride(request: AppRequest.ISetConfigurationOverride): Promise<ActionResult> {
    return await Communication.handleResponse<AppRequest.ISetConfigurationOverrideR>(this.wire, "options/setConfigurationOverride", request, (data, result) => {
      result.data = data;
      this.currentConfig.set(request.NewValue);
      let currSettings = this.appSettings.get();
      currSettings.CurrentLinkingUrlOverride = request.LinkingSiteUrlOverride;
      currSettings.CurrentServerUrlOverride = request.ServerUrlOverride;
      this.appSettings.set(currSettings);
    });
  }
  async getLibConfigs(request: AppRequest.IGetLibraryConfigs): Promise<ActionResult<AppRequest.IGetLibraryConfigsR>> {
    return await Communication.handleResponse<AppRequest.IGetLibraryConfigsR>(this.wire, "application/getLibraryConfigs", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteLibConfig(request: AppRequest.IDeleteLibraryConfig): Promise<ActionResult<AppRequest.IDeleteLibraryConfigR>> {
    return await Communication.handleResponse<AppRequest.IDeleteLibraryConfigR>(this.wire, "application/deleteLibraryConfig", request, (data, result) => {
      result.data = data;
      if (data) {
        let lib = this.userConfigs.get(request.BrandKey);
        if (lib) {
          this.deleteLibrary(lib);
        }
      }
    });
  }
  async addLibConfig(request: AppRequest.IAddLibraryConfig): Promise<ActionResult<AppRequest.IAddLibraryConfigR>> {
    return await Communication.handleResponse<AppRequest.IAddLibraryConfigR>(this.wire, "application/addLibraryConfig", request, (data, result) => {
      if (data) {
        this.addLibrary(data.Config);
        this.selectLibrary(data.Config.BrandKey);
        this.userConfigs.insertOrUpdate(data.Config);
        result.data = data;
      }
    });
  }

  async swapLibraryOrdering(request: AppRequest.ISwapLibraryOrdering) {
    return await Communication.handleResponse<AppRequest.ISwapLibraryOrderingR>(this.wire, "application/swapLibraryOrdering", request, (data, result) => {
      result.data = data;
    });
  }

  async sendAppLogs(request: AppRequest.IAppSendLogs): Promise<ActionResult> {
    return await Communication.handleResponse<AppRequest.IAppSendLogsR>(this.wire, "application/sendAppLogs", request, () => {});
  }
  async logInfo(request: AppRequest.ILogInfo): Promise<ActionResult> {
    return await Communication.handleResponse<AppRequest.ILogInfoR>(this.wire, "application/logInfo", request, () => {});
  }
  async getFileContent(request: any): Promise<ActionResult> {
    return await Communication.handleResponse<any>(this.wire, "options/getAboutFile", request, () => {});
  }

  async restorePurchases(activeLibrary: string) {
    await this.libraries.get(activeLibrary)!.restorePurchases();
  }

  public async initialize(height: number, width: number) {
    if (this.appLoading.isLoading(AppLoadingSteps.data) || this.appLoading.isLoaded(AppLoadingSteps.data)) {
      return ActionResult.create().promise();
    }
    this.appLoading.setLoading(AppLoadingSteps.data);

    // Get the library configs...
    // let res = await this.getLibConfigs({});
    // if (res.errors && res.errors.length > 0) {
    //   return;
    // }
    // --- Lib get done

    return await Communication.handleResponse<AppRequest.IInitializeR>(
      this.wire,
      "application/initialize",
      { Height: height, Width: width } as AppRequest.IInitialize,
      (data) => void(async () => {
        this.localization.changeLanguage(data.Settings.LanguageOverride);
        this.appSettings.set(data.Settings);
        this.currentConfig.set(data.CurrentConfig);
        this.originalConfig.set(data.OriginalConfig);
        if (data.UpdateCheck.IsAvailable) {
          if (data.UpdateCheck.IsRequired) {
            this.appLoading.setLoaded(AppLoadingSteps.data);
          }
          this.appUpdateAvailable.dispatch(data.UpdateCheck, this);
          if (data.UpdateCheck.IsRequired) {
            return;
          }
        }
        this.appBrandKey = data.AppBrandKey;
        let res = await this.getLibConfigs({ BrandKey: this.appBrandKey });

        res.data.UserConfigs.sort((a, b) => a.Position - b.Position);

        // Set libs
        let first = true;
        res.data.UserConfigs.forEach((element) => {
          let sess = this.addLibrary(element);
          if (first) {
            this.currentLibraryNotifier.set(sess);
            first = !first;
          }
          this.userConfigs.insertOrUpdate(element);
        });
        if (this.appSettings.get().CanAddLibraries === true && res.data.InstalledConfigs.length === 0) {
          let addConfig = {
            BrandKey: Constants.getAddNewConstant(),
            Id: res.data.InstalledConfigs.length + 1,
            Icon: "",
            LibraryInternationalizations: [],
          } as unknown as LibraryConfig;
          this.addLibrary(addConfig);
          if (this.libraries.rows().length === 1) {
            this.selectLibrary(Constants.getAddNewConstant());
          }
        }
        this.installedConfigs = res.data.InstalledConfigs;
        // Done Set Libs

        for (const lib of data.Libraries) {
          const login = lib.Login;
          if (!login) {
            continue;
          }
          const library = this.getLibrary(login.LibraryKey)!;
          if (!library) {
            continue;
          }
          library.appSettings = this.appSettings;

          if (
            login.Result === AppRequest.LoginResult.Online ||
            login.Result === AppRequest.LoginResult.Offline ||
            login.Result === AppRequest.LoginResult.OnlineAnonymous ||
            login.Result === AppRequest.LoginResult.OfflineAnonymous
          ) {
            this.appLoading.setLoading(AppLoadingSteps.library + library.id);

            library.initSession(
              login.Session,
              login.Result,
              login.IsContentManipulationAllowed,
              this.appSettings.get().CanDragAndDropLibraries,
              login.StoreSocketId
            );
            library.userName = login.Username;
            library.userPermissions.insertOrUpdateAll(login.UserPermissions);
            let tokenPieces = login.UsedToken.split(":");
            let tokenName = "";
            let tokenPayload = "";

            if (tokenPieces.length > 1) {
              tokenName = tokenPieces[0];
              tokenPayload = tokenPieces[1];
            } else {
              tokenPayload = tokenPieces[0];
            }
            if (!Convert.isEmptyOrSpaces(tokenPayload)) {
              Messages.Notify.success(tokenName + " " + this.localization.currentLocale.LoginView.ALERT_TOKEN_REFRESHING);
            }
            library.externalLinkingCurrentToken = tokenPayload;
            library.externalLinkingCurrentTokenName = tokenName;

            await library.updateLibrary();

            for (const currentMigration of lib.CurrentMigrations) {
              library.addMigration(currentMigration, currentMigration.MigrationSet);
            }
            for (const download of lib.RunningDownloads) {
              this.downloadStore.startDownload(download.VersionId);
              library.registerDownload(download.VersionId, download.DownloadId);
            }
          }
        }
        this.registerExternalLinkSocket(data.ExternalLinkSocketId);
        this.appLoading.setLoaded(AppLoadingSteps.data);
        // Reopen books after load so that the user gets the UI prompts they would expect.
        if (this.appSettings.get().ReopenTitles) {
          for (const lib of data.Libraries) {
            const library = this.getLibrary(lib.Login.LibraryKey)!;
            if (!library) {
              continue;
            }
            for (const openVersion of lib.OpenVersions) {
              library.bookOpenRequested.dispatch(openVersion.VersionId, this);
            }
          }
        }
        if (!Convert.isEmptyOrSpaces(data.HeldArgUrl)) {
          await this.reportExternalLink({ Url: data.HeldArgUrl });
        }
      })(),
      () => {}
    );
  }
  async shutdownApp(request: AppRequest.IApplicationShutdown): Promise<ActionResult> {
    return await Communication.handleResponse<AppRequest.IApplicationShutdownR>(this.wire, "application/shutdown", request, () => {
      /* We're not expecting a return here... */
    });
  }
  async reportExternalLink(request: IReportExternalLink): Promise<ActionResult> {
    return await Communication.handleResponse<IReportExternalLinkR>(this.wire, "application/reportExternalLink", request, () => {});
  }
  registerExternalLinkSocket(externalLinkId: string) {
    Communication.handleSocket<ExternalLinkingPayload>(
      this.wire,
      externalLinkId,
      (message) => void(async () => {
        message.PayloadDict = new Dictionary<ExternalLinkingSection, string>(
          message.PayloadDict as unknown as Array<IKeyValuePair<ExternalLinkingSection, string>>
        );
        if (message.ParseError === ExternalLinkingError.UNKNOWN_BRAND) {
          this.externalLinkingParseFailed.dispatch(message, this);
        } else {
          this.selectLibrary(message.PayloadDict.get(ExternalLinkingSection.BRAND)!);
        }
        // Top level error handling logic.
        if (message.ParseError === ExternalLinkingError.INVALID) {
          this.externalLinkingParseFailed.dispatch(message, this);
        } else {
          // The app had no error to report. Push the link down to the referenced library.
          await this.libraries.get(message.PayloadDict.get(ExternalLinkingSection.BRAND)!)!.handleExternalLinkPayload(message);
        }
      })(),
      () => {},
      () => {
        let msg = new ExternalLinkingPayload();
        msg.ParseError = ExternalLinkingError.INVALID;
        this.externalLinkingParseFailed.dispatch(msg, this);
      },
      3000
    );
  }

  async submitOfflinePackagePassword(packagePath: string, password: string, brandKey: string): Promise<ActionResult<any>> {
    let socketId = uuidv4();
    this.registerOfflinePackageInstall(socketId);
    return await Communication.handleResponse<LibraryRequest.IInstallOfflinePackageR>(
      this.wire,
      "application/installOfflinePackage",
      {
        PackagePath: packagePath,
        Password: password,
        BrandKey: brandKey,
        SocketId: socketId,
      } as LibraryRequest.IInstallOfflinePackage,
      (data, result) => {
        result.data = data;
      }
    );
  }
  registerOfflinePackageInstall(socketId: string) {
    Communication.handleSocket<OfflinePackageInstallPayload>(
      this.wire,
      socketId,
      (message) => {
        this.offlineImportInstallMessageReceived.dispatch(message, this);
      }, // Disconnect
      () => {}, // Error
      () => {},
      1000
    );
  }
  async getPasswordFromLicenseKey(licenseKey: string) {
    return await Communication.handleResponse<AppRequest.IGetPasswordFromLicenseKeyR>(
      this.wire,
      "application/getPasswordFromLicenseKey",
      {
        LicenseKey: licenseKey,
      } as AppRequest.IGetPasswordFromLicenseKey,
      (data, result) => {
        result.data = data;
      }
    );
  }

  async getInternetConnectionStatus(): Promise<ActionResult<AppRequest.IGetInternetConnectionStatusR>> {
    return await Communication.handleResponse<AppRequest.IGetInternetConnectionStatusR>(
      this.wire,
      "application/getInternetConnectionStatus",
      {} as AppRequest.IGetInternetConnectionStatus,
      (data, result) => {
        result.data = data;
      }
    );
  }

  async triggerNativeFileSelect(arg: AppRequest.ITriggerNativeFileSelect): Promise<ActionResult<AppRequest.ITriggerNativeFileSelectR>> {
    return await Communication.handleResponse<AppRequest.ITriggerNativeFileSelectR>(this.wire, "application/triggerNativeFileSelect", arg, (data, result) => {
      result.data = data;
    });
  }

  static current: AppSession = new AppSession();
}
export enum FontSizes {
  Smallest = 1,
  Smaller = 2,
  Normal = 3,
  Larger = 4,
  Largest = 5,
}
export enum SearchEngines {
  Google = 1,
  Bing = 2,
  Yahoo = 3,
  Wikipedia = 4,
}
export enum LibraryViewModes {
  Cards = 1,
  Tiles = 2,
  List = 3,
}
export enum SettingsTarget {
  Release,
  Debug,
  Staging,
  Testing,
  LocalStack,
}
