import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import { Nav, NavItem } from 'reactstrap';
import { LoginType } from 'src/models/AppSession';
import { Book } from 'src/models/Book';

import { Image } from '../foundation/Assets';
import { ActionIcon, VerticalNavBar } from '../foundation/Controls';
import { Screen } from '../foundation/Screen';
import { BookContext } from '../state/Contextes';

export interface IBookNavBarState {
  activeScreen: Screen;
}

export interface IBookNavBarProps {
  navigation: (screen: Screen)=> void;
}

export class BookNavBar extends React.Component<IBookNavBarProps, IBookNavBarState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IBookNavBarProps | Readonly<IBookNavBarProps>) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleSearchResults = this.toggleSearchResults.bind(this);
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this.toggleAnnotations = this.toggleAnnotations.bind(this);
    this.toggleActions = this.toggleActions.bind(this);
    this.state = {
      activeScreen: Screen.toc,
    };
  }

  componentDidMount() {
    this.context.searchRemotelyExecuted.on(this.toggleSearchResults);
    this.context.favouriteContentClicked.on(this.toggleFavourite);
    this.context.annotationContentClicked.on(this.toggleAnnotations);
    this.context.historyPanelRequested.on(this.toggleActions);
    this.context.communityEstablished.on(this.communityEstablished);
    this.context.userFormSubmissionRequested.on(() => this.toggle(Screen.community));
  }
  componentWillUnmount() {
    this.context.searchRemotelyExecuted.off(this.toggleSearchResults);
    this.context.favouriteContentClicked.off(this.toggleFavourite);
    this.context.annotationContentClicked.off(this.toggleAnnotations);
    this.context.historyPanelRequested.off(this.toggleActions);
    this.context.communityEstablished.off(this.communityEstablished);
    this.context.userFormSubmissionRequested.on(this.toggleCommunity);
  }
  communityEstablished = () => {
    this.forceUpdate();
  };
  toggle(screen: Screen) {
    this.props.navigation(screen);
    this.setState({
      activeScreen: screen,
    });
  }
  private toggleSearchResults() {
    this.toggle(Screen.results);
  }
  private toggleCommunity = () => {
    this.toggle(Screen.community);
  }

  private toggleFavourite(IDs: number[]) {
    if (IDs.length === 1) {
      this.toggle(Screen.favourite);
    }
  }
  private toggleActions() {
    this.toggle(Screen.actions);
  }

  private toggleAnnotations(IDs: number[]) {
    if (IDs.length === 1) {
      this.toggle(Screen.annotations);
    }
  }

  render() {
    return (
      <VerticalNavBar level="second">
        <div className="innerNav">
          <Nav vertical={true}>
            <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.TableofContentsView.LABEL_TOCVIEW}>
              <ActionIcon
                onClick={() => {
                  this.toggle(Screen.toc);
                }}
                active={this.state.activeScreen === Screen.toc}
                src={<Image.toc />}
              />
            </NavItem>
            <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.SearchView.LABEL_SEARCHVIEW}>
              <ActionIcon
                onClick={() => {
                  this.toggle(Screen.results);
                }}
                active={this.state.activeScreen === Screen.results}
                src={<Image.search />}
              />
            </NavItem>
            {this.context.loginType === LoginType.User && this.context.parentContext.config.UserTitlesEnabled === true && (
              <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.ProfessorView.LABEL_PROFESSORVIEW}>
                  <ActionIcon
                    onClick={() => {
                      this.toggle(Screen.professor);
                    }}
                    active={this.state.activeScreen === Screen.professor}
                    src={<Image.professor />}
                  />
              </NavItem>
            )}
            {this.context.loginType === LoginType.User && (
              <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATIONVIEW}>
                <ActionIcon
                  onClick={() => {
                    this.toggle(Screen.annotations);
                  }}
                  active={this.state.activeScreen === Screen.annotations}
                  src={<Image.annotations />}
                />
              </NavItem>
            )}
            {this.context.loginType === LoginType.User && (
              <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW}>
                <ActionIcon
                  onClick={() => {
                    this.toggle(Screen.annotationTypes);
                  }}
                  active={this.state.activeScreen === Screen.annotationTypes}
                  src={<Image.bookmark />}
                />
              </NavItem>
            )}
            {this.context.loginType === LoginType.User && (
              <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.FavouriteView.LABEL_FAVORITEVIEW}>
                <ActionIcon
                  onClick={() => {
                    this.toggle(Screen.favourite);
                  }}
                  active={this.state.activeScreen === Screen.favourite}
                  src={<Image.favourite />}
                />
              </NavItem>
            )}
            {this.context.loginType === LoginType.User && (this.context.community.IsBulletinsAvailable || this.context.community.IsTipsAvailable) && (
              <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.CommunityView.LABEL_COMMUNITYVIEW}>
                  <ActionIcon
                    onClick={() => {
                      this.toggle(Screen.community);
                    }}
                    active={this.state.activeScreen === Screen.community}
                    src={<Image.community />}
                  />
              </NavItem>
            )}
            <NavItem data-tooltip-id="navigation" data-tooltip-content={this.context.localization.currentLocale.HistoryView.LABEL_HISTORYVIEW}>
              <ActionIcon
                onClick={() => {
                  this.toggle(Screen.actions);
                }}
                active={this.state.activeScreen === Screen.actions}
                src={<Image.history />}
              />
            </NavItem>

            {this.context.appSettings && this.context.appSettings.get().HelpEnabled && (
              <Tooltip id="navigation" place="right" variant="info" className="primaryColoured" />
            )}
          </Nav>
        </div>
      </VerticalNavBar>
    );
  }
}
