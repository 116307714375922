import { Wire } from 'src/network/Wire';
import { Arrays, Communication, ResourcePlatform } from 'src/utilities/Helpers';

import { ResourceType } from './Content';
import * as ContentRequest from './dto/ContentRequest';
import { ActionResult } from './Result';

/** This model includes resources and external websites management */
export class Resource {
  private wire: Wire;
  private id: number;

  public some: string;
  constructor(wire: Wire, id: number) {
    this.wire = wire;
    this.id = id;
    this.wire.addRequestTransform((request) => {
      request.bookId = this.id;
      return request;
    });
  }

  async openExternalWebsite(externalWebsiteUrl: string): Promise<void> {
    await this.setwebResource(externalWebsiteUrl);
  }

  async openResource(data: string): Promise<string> {
    return await this.setResource(data);
  }

  private downloadFile(rawResource: number[], resourceType: ResourceType, title: string) {
    let arr = Arrays.base64ToArrayBuffer(rawResource.toString());
    let byteArray = new Uint8Array(arr);
    let a = window.document.createElement("a");
    if (resourceType === ResourceType.DOC) {
      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: "application/msword" }));
    } else {
      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: "application/vnd.ms-excel" }));
    }
    a.download = title;
    // Append anchor to body.
    document.body.appendChild(a);
    a.click();
    // Remove anchor from body
    document.body.removeChild(a);
  }

  private async setResource(data: string) : Promise<string> {
    let id = 0;
    let args = "";
    if (data.indexOf(",") > -1) {
      let parts = data.split(/,(.+)/);
      id = +parts[0];
      args = parts[1];
    } else {
      id = +data;
    }
    // Thick clients usually
    if (ResourcePlatform.CanOpenDocs()) {
      await this.nativeResourceRequested({ StringData: data, Args: args, IsRawUrl: false });
      return "";
    }
    // Web platform
    else {
      let result = await this.getResource({ Id: id, Args: args });
      if (result.data.ResourceType === ResourceType.DOC || result.data.ResourceType === ResourceType.XLS || result.data.ResourceType === ResourceType.ZIP) {
        this.downloadFile(result.data.RawResource, result.data.ResourceType, result.data.Title);
      } 
      else {
        return ResourcePlatform.getFormattedResource(result.data);
      }
      return ResourcePlatform.getFormattedResource(result.data);
    }
  }

  private async setwebResource(data: string) {
    if (ResourcePlatform.CanOpenWebLinks()) {
      await ResourcePlatform.openNewWindowInOtherDomain(data);
    } else {
      await this.nativeResourceRequested({ StringData: data, Args: "", IsRawUrl: true });
    }
  }

  async getBookAssets(): Promise<ActionResult<{ stylesheet: string; javascript: string }>> {
    return await Communication.handleResponse<ContentRequest.IGetBookAssetsR>(
      this.wire,
      "resource/getBookAssets",
      {},
      (data, result) => {
        result.data = {
          stylesheet: data.Stylesheet,
          javascript: data.Javascript,
        };
      },
      (result) => {
        result.data = {
          stylesheet: "",
          javascript: "",
        };
      }
    );
  }
  async getResource(request: ContentRequest.IGetResource): Promise<ActionResult<ContentRequest.IGetResourceR>> {
    return await Communication.handleResponse<ContentRequest.IGetResourceR>(
      this.wire,
      "resource/getResource",
      request,
      (data, result) => {
        result.data = data;
      },
      () => {
        // TODO: handle resource fetch errors.
      }
    );
  }
  private async nativeResourceRequested(request: ContentRequest.INativeResourceRequest): Promise<ActionResult> {
    return await Communication.handleResponse<ContentRequest.INativeResourceRequestR>(this.wire, "resource/nativeResourceRequest", request, (data, result) => {
      result.data = data;
    });
  }
}
