import * as React from 'react';
import { Button, Label, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import { Locale } from 'src/localization/Locale';
import { ContentPopupModel, ContentSegment } from 'src/models/Content';
import {
    ConflictNavigation, ConflictType, ManipulateType, MigratingBook, Migration, MigrationConflict
} from 'src/models/Migration';
import { ActionResult } from 'src/models/Result';
import { Convert } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import {
    ActionIcon, AnnotationTypeComponent, ConcreteContentSegment, Loading, PreviewHandler
} from '../foundation/Controls';
import * as Messages from '../foundation/Messages';
import { SelectionHandler } from '../foundation/Selection';
import {
    ICogniflowOptionalSettings, INode, IRequest, IResponse
} from '../foundation/StandaloneCogniflow';
import { StandaloneCogniflowFrameContainer } from '../foundation/StandaloneCogniflowFrame';
import { MigrationContext } from '../state/Contextes';

const content_css = require("!!raw-loader!src/assets/css/content.css").default;
const content_jquery = require("!!raw-loader!jquery").default;
const content_api = require("!!raw-loader!src/assets/js/ContentAPI.js").default;
const content_mathJax = require("!!raw-loader!src/assets/js/tex-mml-svg.js").default;
const content_place = require("!!raw-loader!src/assets/js/Place.js").default;
export interface IMigrationContainerProperties {
  migration: Migration;
}

export class MigrationView extends React.Component<IMigrationContainerProperties, unknown> {
  render() {
    return (
      <MigrationContext.Provider value={this.props.migration}>
        <InnerMigrationView />
      </MigrationContext.Provider>
    );
  }
}

interface IInnerMigrationViewState {
  currentConflict: MigrationConflict | null;
  pagerInput: string;
  lastSelection: any;
  fromLoaded: boolean;
  toLoaded: boolean;
  isLoading: boolean;
  loadingStatus: string;
  isDisplayingOld: boolean;
}

interface IInnerMigrationViewProps {}

class InnerMigrationView extends React.Component<IInnerMigrationViewProps, IInnerMigrationViewState> {
  context: Migration;
  static contextType = MigrationContext;
  fromFrame = React.createRef<StandaloneCogniflowFrameContainer>();
  toFrame = React.createRef<StandaloneCogniflowFrameContainer>();
  fromContent: PreviewHandler;
  toContent: PreviewHandler;
  selection: SelectionHandler;
  constructor(props: IInnerMigrationViewProps | Readonly<IInnerMigrationViewProps>) {
    super(props);
    this.state = {
      currentConflict: null,
      pagerInput: "",
      lastSelection: null,
      fromLoaded: false,
      toLoaded: false,
      isLoading: false,
      isDisplayingOld: false,
      loadingStatus: "",
    };
    this.onPagerChanged = this.onPagerChanged.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.checkKey = this.checkKey.bind(this);
    this.onManipulate = this.onManipulate.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.fromInitializeFlow = this.fromInitializeFlow.bind(this);
    this.toInitializeFlow = this.toInitializeFlow.bind(this);
    this.extendedGenerateSegment = this.extendedGenerateSegment.bind(this);
    this.buildFromPopup = this.buildFromPopup.bind(this);
    this.buildToPopup = this.buildToPopup.bind(this);
    this.toInsertedSegmentCallback = this.toInsertedSegmentCallback.bind(this);
    this.fromInsertedSegmentCallback = this.fromInsertedSegmentCallback.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.onPagerKeyUp = this.onPagerKeyUp.bind(this);
    this.reapplyModel = this.reapplyModel.bind(this);
    this.onExit = this.onExit.bind(this);
    this.onVersionToggle = this.onVersionToggle.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.decideForMe = this.decideForMe.bind(this);
    this.setScrollEvents = this.setScrollEvents.bind(this);
    this.setKeyEvents = this.setKeyEvents.bind(this);
  }

  componentDidMount() {
    this.setState({ currentConflict: this.context.initialConflict, pagerInput: this.context.initialConflict.CurrentPage.toString() });
  }

  componentWillUnmount() {}

  onNavigate(navigation: ConflictNavigation, page?: number) {
    // Skip pointless navs
    if (navigation === ConflictNavigation.NextConflict && this.state.currentConflict!.CurrentPage === this.state.currentConflict!.TotalConflicts) {
      return;
    } else if (navigation === ConflictNavigation.PreviousConflict && this.state.currentConflict!.CurrentPage === 1) {
      return;
    } else if (navigation === ConflictNavigation.SpecificConflict && (!page || page < 0 || page > this.state.currentConflict!.TotalConflicts)) {
      return;
    }
    this.setState({ isLoading: true, loadingStatus: this.context.localization.currentLocale.Migration.LABEL_NAVIGATING_TO_CONFLICT }, () => void(async () => {
      this.setCurrent((await this.context.navigateConflict({ MigrationSet: this.context.migrationSet, Navigation: navigation, Page: page })).data);
    })());
  }
  onManipulate(action: ManipulateType): void {
    this.setState({ isLoading: true, loadingStatus: this.context.localization.currentLocale.Migration.LABEL_MIGRATING_USER_CONTENT }, () => void(async () => {
      this.setCurrent((await this.context.manipulateModel({ MigrationSet: this.context.migrationSet, Manipulation: action })).data);
    })());
  }
  onExit() {
    this.setState({ isLoading: true, loadingStatus: this.context.localization.currentLocale.Migration.LABEL_FINALIZING_MIGRATION }, () => {
      setTimeout(() => {
        this.context.exitMigration({ MigrationSet: this.context.migrationSet });
        Messages.Notify.success(this.context.localization.currentLocale.Migration.LABEL_MIGRATION_EXITED);
      }, 500);
    });
  }
  onVersionToggle(isOldActive: boolean) {
    this.setState({ isDisplayingOld: isOldActive }, () => {
      if (this.state.currentConflict) {
        this.toContent.scrollToConflict(this.state.currentConflict, this.state.currentConflict.AnnotationModel ? true : false);
        this.fromContent.scrollToConflict(this.state.currentConflict, this.state.currentConflict.AnnotationModel ? true : false);
      }
    });
  }
  setCurrent(conf: MigrationConflict) {
    this.setState({ currentConflict: conf, pagerInput: conf.CurrentPage.toString(), lastSelection: null, fromLoaded: false, toLoaded: false }, () => {
      this.fromFrame.current!.flow()!.reloadCogniflow();
      this.toFrame.current!.flow()!.reloadCogniflow();
    });
  }
  checkKey(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === 38) {
        // up arrow
        this.onNavigate(ConflictNavigation.FirstConflict);
      } else if (e.keyCode === 40) {
        // down arrow
        this.onNavigate(ConflictNavigation.LastConflict);
      } else if (e.keyCode === 65) {
        // "a" button
        this.onManipulate(ManipulateType.Save);
      } else if (e.keyCode === 68) {
        // "d" button
        this.onManipulate(ManipulateType.Discard);
      } else if (e.keyCode === 82) {
        // "r" button
        this.onManipulate(ManipulateType.Cancel);
      } else if (e.keyCode === 37) {
        // left arrow + ctrl
        this.onNavigate(ConflictNavigation.PreviousConflict);
      } else if (e.keyCode === 39) {
        // right arrow + ctrl
        this.onNavigate(ConflictNavigation.NextConflict);
      }
    }
  }
  disruptKey(e: React.KeyboardEvent<HTMLDivElement>) {
    this.disruptKeyNative(e.nativeEvent);
  }
  disruptKeyNative(e: KeyboardEvent) {
    if (e.ctrlKey && [39, 37, 82, 68, 65, 40, 38].includes(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }  
  onPagerChanged(e: any) {
    this.setState({ pagerInput: e.target.value }, () => {
      this.onNavigate(ConflictNavigation.SpecificConflict, +this.state.pagerInput);
    });
  }

  private fromInsertedSegmentCallback() {
    this.navigationDone(true);
  }
  private toInsertedSegmentCallback() {
    this.navigationDone(false);
  }
  // Create the offset height div to perfectly align both highlights/faves
  adjustHeights() {
    let fromTarget;
    let toTarget;

    // Remove old
    this.fromContent.contentDocument.querySelectorAll(".DIV-MIGRATION-ADJUSTMENT").forEach((a) => {
      a.remove();
    });
    this.toContent.contentDocument.querySelectorAll(".DIV-MIGRATION-ADJUSTMENT").forEach((a) => {
      a.remove();
    });

    // If there's a popup, we'll adjust to the anchor instead. Easier for the user to navigate.
    if (this.fromContent.initialPopup || this.toContent.initialPopup) {
      fromTarget = this.fromContent.cogniflow
        .getSegmentByMain((this.fromContent.cogniflow.getNodeBySecondary(this.state.currentConflict!.FromAnchor) as ContentSegment).SpineId)!
        .getBoundingClientRect();
      toTarget = this.toContent.cogniflow
        .getSegmentByMain((this.toContent.cogniflow.getNodeBySecondary(this.state.currentConflict!.ToAnchor) as ContentSegment).SpineId)!
        .getBoundingClientRect();
    }
    // Find annotation target
    else if (this.state.currentConflict!.AnnotationModel) {
      let elem = this.fromContent.contentDocument.querySelectorAll("span[data-anno]")[0];
      if (elem) {
        fromTarget = elem.getBoundingClientRect();
      }
      elem = this.toContent.contentDocument.querySelectorAll("span[data-anno]")[0];
      if (elem) {
        toTarget = elem.getBoundingClientRect();
      }
    }
    // Find favourite target
    else {
      let elem = this.fromContent.contentDocument.querySelectorAll("div[data-containing='FavNOT']")[0];
      if (elem) {
        fromTarget = elem.getBoundingClientRect();
      }
      elem = this.toContent.contentDocument.querySelectorAll("div[data-containing='FavNOT']")[0];
      if (elem) {
        toTarget = elem.getBoundingClientRect();
      }
    }

    let adjustedDiv = 0;
    let appendedToOld = false;
    let fromDocRect = this.fromContent.contentDocument.querySelectorAll(".cogniflow-segments")[0].getBoundingClientRect();
    let toDocRect = this.toContent.contentDocument.querySelectorAll(".cogniflow-segments")[0].getBoundingClientRect();
    // If we've found our targets we can create the right offset. If not, one or both are in a popup. No need to do this.
    if (fromTarget && toTarget) {
      let div = this.toContent.contentDocument.createElement("div");
      div.className = "DIV-MIGRATION-ADJUSTMENT";
      div.style.width = "100%";
      div.style.backgroundColor = "lightgray";
      if (fromTarget.top >= toTarget.top) {
        div.style.height = fromTarget.top - toTarget.top + "px";
        adjustedDiv = fromTarget.top - toTarget.top;
        this.toContent.contentDocument
          .querySelectorAll(".cogniflow-segments")[0]
          .insertBefore(div, this.toContent.contentDocument.querySelectorAll(".cogniflow-segments")[0].childNodes[0]);
      } else {
        div.style.height = toTarget.top - fromTarget.top + "px";
        adjustedDiv = fromTarget.top - toTarget.top;
        appendedToOld = true;
        this.fromContent.contentDocument
          .querySelectorAll(".cogniflow-segments")[0]
          .insertBefore(div, this.fromContent.contentDocument.querySelectorAll(".cogniflow-segments")[0].childNodes[0]);
      }
    }
    // Do total doc heights now.
    this.innerAdjust(adjustedDiv, appendedToOld, fromDocRect, toDocRect);
  }

  private innerAdjust(adjustedDiv: number, appendedToOld: boolean, fromDocRect: DOMRect | ClientRect, toDocRect: DOMRect | ClientRect) {
    let bottomDiv = this.toContent.contentDocument.createElement("div");
    bottomDiv.style.width = "100%";
    bottomDiv.style.backgroundColor = "lightgray";
    bottomDiv.className = "DIV-MIGRATION-ADJUSTMENT";
    let frmDocHeight = fromDocRect.height + (appendedToOld ? 0 : adjustedDiv);
    let toDocHeight = toDocRect.height + (appendedToOld ? adjustedDiv : 0);
    if (frmDocHeight > toDocHeight) {
      bottomDiv.style.height = frmDocHeight - toDocHeight + "px";
      this.toContent.contentDocument.querySelectorAll(".cogniflow-segments")[0].appendChild(bottomDiv);
    } else {
      bottomDiv.style.height = toDocHeight - frmDocHeight + "px";
      this.fromContent.contentDocument.querySelectorAll(".cogniflow-segments")[0].appendChild(bottomDiv);
    }
  }

  private navigationDone(isOld: boolean) {
    if (isOld) {
      this.setState({ fromLoaded: true }, this.continueNavigation);
    } else {
      this.setState({ toLoaded: true }, this.continueNavigation);
    }
  }
  continueNavigation() {
    if (!this.state.toLoaded || !this.state.fromLoaded) {
      return;
    }
    this.adjustHeights();
    this.selection = new SelectionHandler(this.toContent.contentDocument.documentElement.ownerDocument);
    if (!this.toContent.contentDocument.documentElement.ownerDocument.onselectionchange) {
      this.toContent.contentDocument.documentElement.ownerDocument.onselectionchange = async () => {
        let selObj = await this.selection.getSelectionText();
        if (selObj.selTxt.trim().length > 0) {
          try {
            if (this.state.currentConflict!.AnnotationModel) {
              this.setState({ lastSelection: this.selection.getSelectionObject(selObj.sel) });
            } else {
              this.setState({ lastSelection: this.selection.getFavourite() });
            }
          } catch (e) {
            this.setState({ lastSelection: null });
          }
        } else {
          this.setState({ lastSelection: null });
        }
      };
      this.setScrollEvents();
      this.setKeyEvents();
    }
    // Only need to scroll one of the two bc they are scroll linked.
    if (this.state.currentConflict) {
      this.toContent.scrollToConflict(this.state.currentConflict, this.state.currentConflict.AnnotationModel ? true : false);
      this.fromContent.scrollToConflict(this.state.currentConflict, this.state.currentConflict.AnnotationModel ? true : false);
    }
    this.setState({ isLoading: false });
  }
  setScrollEvents() {
    if (!(this.toContent.contentDocument.documentElement.getElementsByClassName("BookView")[0]! as HTMLElement).onscroll) {
      (this.toContent.contentDocument.documentElement.getElementsByClassName("BookView")[0]! as HTMLElement).onscroll = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        (this.fromContent.contentDocument.documentElement.getElementsByClassName(
          "BookView"
        )[0]! as HTMLElement).scrollTop = (this.toContent.contentDocument.documentElement.getElementsByClassName("BookView")[0]! as HTMLElement).scrollTop;
      };
    }
    if (!(this.fromContent.contentDocument.documentElement.getElementsByClassName("BookView")[0]! as HTMLElement).onscroll) {
      (this.fromContent.contentDocument.documentElement.getElementsByClassName("BookView")[0]! as HTMLElement).onscroll = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        (this.toContent.contentDocument.documentElement.getElementsByClassName(
          "BookView"
        )[0]! as HTMLElement).scrollTop = (this.fromContent.contentDocument.documentElement.getElementsByClassName("BookView")[0]! as HTMLElement).scrollTop;
      };
    }
  }
  setKeyEvents() {
    if (!this.toContent.contentDocument.documentElement.onkeyup) {
      this.toContent.contentDocument.documentElement.onkeyup = (e: any) => {
        this.checkKey(e);
      };
    }
    if (!this.fromContent.contentDocument.documentElement.onkeyup) {
      this.fromContent.contentDocument.documentElement.onkeyup = (e: any) => {
        this.checkKey(e);
      };
    }
    if (!this.toContent.contentDocument.documentElement.onkeydown) {
      this.toContent.contentDocument.documentElement.onkeydown = (e: KeyboardEvent) => {
        this.disruptKeyNative(e);
      };
    }
    if (!this.fromContent.contentDocument.documentElement.onkeydown) {
      this.fromContent.contentDocument.documentElement.onkeydown = (e: KeyboardEvent) => {
        this.disruptKeyNative(e);
      };
    }
  }

  private async fromInitializeFlow(anchor?: number, recurse?: boolean): Promise<{ nodes: any[]; targetSpine: number }> {
    if (this.state.currentConflict) {
      if (!this.fromContent || recurse) {
        this.fromContent = new PreviewHandler(this.fromFrame.current!.flow()!, this.fromFrame.current!.document()!, this.context, undefined, MigratingBook.Old);
        if (this.fromContent.apiServer.isInitialized() === true) {
          await this.fromFrame.current!.loadBodyScripts();
        } else {
          return this.delay(100).then(async () => this.fromInitializeFlow(anchor, true));
        }
      } else {
        this.fromContent.updateInstance(
          this.fromFrame.current!.flow()!,
          this.fromFrame.current!.document()!,
          this.state.currentConflict.FromPopup,
          undefined,
          this.context,
          undefined,
          MigratingBook.Old
        );
      }
      this.setState((prevState) => {
        prevState.currentConflict!.FromPreview[0].IsFirst = true;
        prevState.currentConflict!.FromPreview[prevState.currentConflict!.FromPreview.length - 1].IsLast = true;
        return prevState;
      });
      return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
        resolve({
          nodes: this.state.currentConflict!.FromPreview,
          targetSpine: this.state.currentConflict!.FromPreview[0].SpineId,
        });
      });
    } else {
      return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
        resolve({
          nodes: [],
          targetSpine: 1,
        });
      });
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  private async toInitializeFlow(anchor?: number, recurse?: boolean): Promise<{ nodes: any[]; targetSpine: number }> {
    if (this.state.currentConflict) {
      if (!this.toContent || recurse) {
        this.toContent = new PreviewHandler(this.toFrame.current!.flow()!, this.toFrame.current!.document()!, this.context, undefined, MigratingBook.New);
        if (this.toContent.apiServer.isInitialized() === true) {
          await this.toFrame.current!.loadBodyScripts();
        } else {
          return this.delay(100).then(async () => this.toInitializeFlow(anchor, true));
        }
      } else {
        this.toContent.updateInstance(
          this.toFrame.current!.flow()!,
          this.toFrame.current!.document()!,
          this.state.currentConflict.ToPopup,
          undefined,
          this.context,
          undefined,
          MigratingBook.New
        );
      }
      this.setState((prevState) => {
        prevState.currentConflict!.ToPreview[0].IsFirst = true;
        prevState.currentConflict!.ToPreview[prevState.currentConflict!.ToPreview.length - 1].IsLast = true;
        return prevState;
      });
      return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
        resolve({
          nodes: this.state.currentConflict!.ToPreview,
          targetSpine: this.state.currentConflict!.ToPreview[0].SpineId,
        });
      });
    } else {
      return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
        resolve({
          nodes: [],
          targetSpine: 1,
        });
      });
    }
  }
  private flowProvider(): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let response: IResponse = { Batches: [] };
      resolve(response);
    });
  }
  reapplyModel() {
    let sel = this.state.lastSelection;
    let res: ActionResult<MigrationConflict>;
    this.setState({ isLoading: true, loadingStatus: this.context.localization.currentLocale.Migration.LABEL_REAPPLYING_CONFLICT }, () => void(async () => {
      if (this.state.currentConflict!.AnnotationModel) {
        res = await this.context.reapplyAnnotation({
          FirstDocId: sel.firstDocId,
          FirstOffset: sel.firstOffset,
          FirstWordCount: sel.firstWordCount,
          SecondDocId: sel.secondDocId,
          SecondOffset: sel.secondOffset,
          SecondWordCount: sel.secondWordCount,
          MigrationSet: this.context.migrationSet,
        });
      } else {
        res = await this.context.reapplyFavourite({
          DocId: sel.DocId,
          YDocHeight: sel.YDocHeight,
          YOffset: sel.YOffset,
          MigrationSet: this.context.migrationSet,
        });
      }
      this.setCurrent(res.data);
    })());
  }

  private extendedGenerateSegment(node: INode, attributes: any, key: number, isOld: boolean): JSX.Element {
    let originalModel;
    if (isOld) {
      originalModel = this.state.currentConflict!.AnnotationModel ? this.state.currentConflict!.AnnotationModel : this.state.currentConflict!.FavouriteModel;
    }
    let segAdd: any = () => {};
    if (isOld && this.fromContent.apiServer) {
      segAdd = this.fromContent.apiServer.triggerSegmentAdded;
    } else if (!isOld && this.toContent.apiServer) {
      segAdd = this.toContent.apiServer.triggerSegmentAdded;
    }
    return (
      <ConcreteContentSegment
        classes={attributes["className"]}
        headAttr={this.settings.segmentDataDescriptor.secondaryIdDataAttribute}
        head={attributes[this.settings.segmentDataDescriptor.secondaryIdDataAttribute]}
        spineAttr={this.settings.segmentDataDescriptor.mainIdDataAttribute}
        spine={attributes[this.settings.segmentDataDescriptor.mainIdDataAttribute]}
        key={key}
        item={node as ContentSegment}
        originalModel={originalModel}
        segmentAdded={segAdd}
      />
    );
  }
  settings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "SpineId",
      mainIdDataAttribute: "data-cogniflow--spine",
      secondaryIdDataAttribute: "data-cogniflow--headid",
      secondaryIdNodeAttribute: "HeadId",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      contentAttribute: "ContentSegment",
      applyDirectlyToSegment: true,
    },
    segmentContainerClasses: "html body",
    scrollingClasses: "BookView tex2jax_ignore",
    batchSize: 3,
  };
  onPagerKeyUp(e: any) {
    if (e.keyCode === 13) {
      // Cancel the default action, if needed
      e.preventDefault();
    }
  }
  decideForMe() {
    Messages.Dialog.confirm(
      this.context.localization.currentLocale.Migration.ALERT_DECIDEFORMEPROMPT,
      this.context.localization.currentLocale.Migration.ALERT_DECIDEFORMETITLE
    ).then((res) => {
      if (res === "true") {
        this.setState({ isLoading: true, loadingStatus: this.context.localization.currentLocale.Migration.LABEL_DECIDING_CONFLICT }, () => void(async () => {
          await this.context.decideForMe({ MigrationSet: this.context.migrationSet });
          this.context.exitMigration({ MigrationSet: this.context.migrationSet });
          this.setState({ isLoading: false });
        })());
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  buildFromPopup(pop: INode) {
    return this.fromContent.buildPopup(pop as ContentPopupModel);
  }
  buildToPopup(pop: INode) {
    return this.toContent.buildPopup(pop as ContentPopupModel);
  }
  render(): any {
    if (!this.state.currentConflict) {
      return "";
    }

    const versionToggleClass = this.state.isDisplayingOld ? " displayOld" : "";
    return (
      <div tabIndex={0} onKeyDown={this.disruptKey} onKeyUp={this.checkKey} className="full-height full-width migration-view">
        <MigrationToolbar
          onDecideForMe={this.decideForMe}
          onExitMigration={this.onExit}
          onVersionToggle={this.onVersionToggle}
          localization={this.context.localization}
        />
        <Loading isLoading={this.state.isLoading} status={this.state.loadingStatus}>
          <div className="full-height full-width gridContainer bg-void">
            <div className="centerGrid">
              <div className="headerRow">
                <ConflictDetails currentConflict={this.state.currentConflict} localization={this.context.localization} />
              </div>
              <div className={"previewRow" + versionToggleClass}>
                <div className="versionFromLabel">
                  <h2 className="text-center">
                    {this.context.localization.currentLocale.Migration.LABEL_OLD_CONTENT + ": " + this.state.currentConflict.FromTitle}
                  </h2>
                </div>
                <div className="fromPreview">
                  <MigrationPreview
                    flowProvider={this.flowProvider}
                    frameRef={this.fromFrame}
                    extendedGenerateSegment={this.extendedGenerateSegment}
                    popupBuilder={this.buildFromPopup}
                    initializeFlow={this.fromInitializeFlow}
                    insertedSegmentCallback={this.fromInsertedSegmentCallback}
                    navigationDone={this.navigationDone}
                    isOld={true}
                    settings={this.settings}
                  />
                </div>
                <div className="versionToLabel">
                  <h2 className="text-center">
                    {this.context.localization.currentLocale.Migration.LABEL_NEW_CONTENT + ": " + this.state.currentConflict.ToTitle}
                  </h2>
                </div>
                <div className="toPreview">
                  {this.state.lastSelection && (
                    <MigrationReapplyButton
                      contentHandler={this.toContent}
                      lastSelection={this.state.lastSelection}
                      reapplyModel={this.reapplyModel}
                      localization={this.context.localization}
                    />
                  )}
                  <MigrationPreview
                    flowProvider={this.flowProvider}
                    frameRef={this.toFrame}
                    extendedGenerateSegment={this.extendedGenerateSegment}
                    popupBuilder={this.buildToPopup}
                    initializeFlow={this.toInitializeFlow}
                    insertedSegmentCallback={this.toInsertedSegmentCallback}
                    navigationDone={this.navigationDone}
                    isOld={false}
                    settings={this.settings}
                  />
                </div>
              </div>
              <div className="footerRow">
                <MigrationPager
                  currentConflict={this.state.currentConflict}
                  onNavigate={this.onNavigate}
                  onPagerChanged={this.onPagerChanged}
                  onPagerKeyUp={this.onPagerKeyUp}
                  pagerInput={this.state.pagerInput}
                  localization={this.context.localization}
                />
                <MigrationFooterControls onManipulate={this.onManipulate} localization={this.context.localization} />
              </div>
            </div>
          </div>
        </Loading>
      </div>
    );
  }
}

interface IMigrationReapplyButtonProps {
  reapplyModel: () => void;
  lastSelection: any;
  contentHandler: PreviewHandler;
  localization: Locale;
}

class MigrationReapplyButton extends React.Component<IMigrationReapplyButtonProps, unknown> {
  constructor(props: IMigrationReapplyButtonProps) {
    super(props);
  }
  render() {
    let controlLocation;
    if (this.props.lastSelection) {
      let rs = this.props.contentHandler.contentDocument.documentElement.ownerDocument.getSelection()!;
      let r = rs.getRangeAt(rs.rangeCount - 1).getBoundingClientRect();
      controlLocation = {
        top: r.top + r.height + 10 + "px",
        left: r.left + r.width / 2 + "px",
      };
    }
    return (
      <Button color="info" onClick={this.props.reapplyModel} className="glow reapplyButton" style={controlLocation}>
        {this.props.localization.currentLocale.Migration.LABEL_REAPPLY}
      </Button>
    );
  }
}

interface IMigrationPreviewProps {
  frameRef: React.Ref<StandaloneCogniflowFrameContainer>;
  flowProvider: (req: IRequest) => Promise<IResponse>;
  initializeFlow: (anchor?: number) => Promise<{ nodes: any[]; targetSpine: number }>;
  extendedGenerateSegment: (node: INode, attributes: any, key: number, isOld: boolean) => JSX.Element;
  popupBuilder: (node: INode) => JSX.Element;
  insertedSegmentCallback: (segments: HTMLDivElement[], node: INode[])=> void;
  navigationDone: (arg: boolean) => void;
  isOld: boolean;
  settings: ICogniflowOptionalSettings;
}

class MigrationPreview extends React.Component<IMigrationPreviewProps, unknown> {
  context: Migration;
  static contextType = MigrationContext;
  render() {
    return (
      <StandaloneCogniflowFrameContainer
        ref={this.props.frameRef}
        className="content-reader"
        provider={this.props.flowProvider}
        initialize={this.props.initializeFlow}
        builder={(n: INode, attr, key) => this.props.extendedGenerateSegment(n, attr, key!, this.props.isOld)}
        addonBuilder={this.props.popupBuilder}
        segmentsInsertedCallback={this.props.insertedSegmentCallback}
        navigationDoneCallback={() => this.props.navigationDone(this.props.isOld)}
        extraSettings={this.props.settings}
        head={
          <React.Fragment>
            <style>{content_css}</style>
            <style>{this.context.toStyle}</style>
          </React.Fragment>
        }
        scripts={[
          {
            src: content_jquery,
            head: true,
          },
          {
            src: content_place,
            head: true,
          },
          {
            src: content_api,
            head: true,
          },
          {
            src: this.context.toJavascript,
            head: false,
          },
          { src: content_mathJax, head: true },
        ]}
      />
    );
  }
}

interface IMigrationFooterControlsProps {
  onManipulate: (nav: ManipulateType)=> void;
  localization: Locale;
}

class MigrationFooterControls extends React.Component<IMigrationFooterControlsProps, unknown> {
  constructor(props: IMigrationFooterControlsProps) {
    super(props);
  }
  render() {
    return (
      <div className="bottomControls">
        <Button
          outline
          color="success"
          onClick={() => {
            this.props.onManipulate(ManipulateType.Save);
          }}
          className="acceptBtn"
        >
          {this.props.localization.currentLocale.Migration.LABEL_ACCEPT}
        </Button>
        <Button
          outline
          color="danger"
          onClick={() => {
            this.props.onManipulate(ManipulateType.Discard);
          }}
          className="discardBtn"
        >
          {this.props.localization.currentLocale.Migration.LABEL_IGNORE}
        </Button>
        <Button
          outline
          color="warning"
          onClick={() => {
            this.props.onManipulate(ManipulateType.Cancel);
          }}
          className="resetBtn"
        >
          {this.props.localization.currentLocale.Migration.LABEL_RESET}
        </Button>
      </div>
    );
  }
}

interface IMigrationPagerProps {
  onNavigate: (nav: ConflictNavigation)=> void;
  onPagerKeyUp: (e: any)=> void;
  onPagerChanged: (e: any)=> void;
  currentConflict: MigrationConflict;
  pagerInput: string;
  localization: Locale;
}

class MigrationPager extends React.Component<IMigrationPagerProps, unknown> {
  constructor(props: IMigrationPagerProps) {
    super(props);
  }
  render() {
    return (
      <div className="pager">
        <div className="prev">
          <ActionIcon
            src={<Image.prev />}
            onClick={() => {
              this.props.onNavigate(ConflictNavigation.PreviousConflict);
            }}
          />
        </div>
        <div className="pagerCenter d-flex justify-content-center align-items-center">
          <div className="pagerInput">
            <input title="pagerInput" type="text" onKeyUp={this.props.onPagerKeyUp} className="pageSelector" value={this.props.pagerInput} onChange={this.props.onPagerChanged} />
          </div>
          <div className="pagerText">
            <span className="concretePagerText">
              {this.props.localization.currentLocale.Migration.LABEL_PAGE_OF + " " + this.props.currentConflict.TotalConflicts} (
              {this.props.currentConflict.UnresolvedConflicts + " " + this.props.localization.currentLocale.Migration.LABEL_UNRESOLVED_CONFLICTS})
            </span>
          </div>
        </div>
        <div className="next">
          <ActionIcon
            src={<Image.next />}
            onClick={() => {
              this.props.onNavigate(ConflictNavigation.NextConflict);
            }}
          />
        </div>
      </div>
    );
  }
}

interface IMigrationToolbarProps {
  onDecideForMe: ()=> void;
  onExitMigration: ()=> void;
  onVersionToggle: (isOld: boolean)=> void;
  localization: Locale;
}
interface IMigrationToolbarState {
  isDisplayingOldVersion: boolean;
}
class MigrationToolbar extends React.Component<IMigrationToolbarProps, IMigrationToolbarState> {
  constructor(props: IMigrationToolbarProps) {
    super(props);
    this.handleVersionToggle = this.handleVersionToggle.bind(this);
    this.state = { isDisplayingOldVersion: false };
  }

  private handleVersionToggle() {
    const isDisplayingOldVersion = !this.state.isDisplayingOldVersion;
    this.setState({ isDisplayingOldVersion });
    this.props.onVersionToggle(isDisplayingOldVersion);
  }

  render() {
    const toggleVersionButtonText = this.state.isDisplayingOldVersion
      ? this.props.localization.currentLocale.Migration.LABEL_TO_NEW_CONTENT
      : this.props.localization.currentLocale.Migration.LABEL_TO_OLD_CONTENT;
    return (
      <Navbar color="light" light={true} expand="xs">
        <Button className="toggleVersionButton" color="info" outline onClick={this.handleVersionToggle}>
          {toggleVersionButtonText}
        </Button>
        <Nav className="ml-auto" navbar={true}>
          <NavItem className="glow" onClick={this.props.onDecideForMe}>
            <NavLink>{this.props.localization.currentLocale.Migration.ALERT_DECIDEFORMETITLE}</NavLink>
          </NavItem>
          <NavItem onClick={this.props.onExitMigration}>
            <NavLink>{this.props.localization.currentLocale.Migration.LABEL_FINISH_MIGRATION}</NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

interface IConflictDetailsProps {
  currentConflict: MigrationConflict;
  localization: Locale;
}
interface IConflictDetailsState {}
class ConflictDetails extends React.Component<IConflictDetailsProps, IConflictDetailsState> {
  constructor(props: IConflictDetailsProps) {
    super(props);
    this.renderConflictIcon = this.renderConflictIcon.bind(this);
  }

  renderConflictIcon() {
    let icon: JSX.Element;
    switch (this.props.currentConflict.ConflictState) {
      case ConflictType.ConflictResolved:
      case ConflictType.Resolved:
        icon = <Image.warningResolved />;
        break;
      case ConflictType.DeletedResolved:
        icon = <Image.deleteResolved />;
        break;
      case ConflictType.Conflict:
      case ConflictType.ConflictNotResolved:
      case ConflictType.Deleted:
      default:
        icon = <Image.warning />;
        break;
    }

    return icon;
  }

  render() {
    const { currentConflict } = this.props;
    const icon = this.renderConflictIcon();
    let details: any;

    if (currentConflict.AnnotationModel) {
      details = <AnnotationConflictDetails currentConflict={currentConflict} icon={icon} localization={this.props.localization} />;
    } else {
      details = <FavouriteConflictDetails currentConflict={currentConflict} icon={icon} localization={this.props.localization} />;
    }

    return <div className="detailsContainer scrollShadow p-2">{details}</div>;
  }
}

interface IAnnotationConflictDetailsProps {
  currentConflict: MigrationConflict;
  icon: JSX.Element;
  localization: Locale;
}
interface IAnnotationConflictDetailsState {}
class AnnotationConflictDetails extends React.Component<IAnnotationConflictDetailsProps, IAnnotationConflictDetailsState> {
  constructor(props: IAnnotationConflictDetailsProps) {
    super(props);
    this.renderNote = this.renderNote.bind(this);
  }

  renderNote() {
    const note = this.props.currentConflict.AnnotationModel.Note;
    if (note) {
      return (
        <div className="card p-2 flex-grow-1 ml-2 mr-2 mb-2">
          <Label>{this.props.localization.currentLocale.AnnotationView.LABEL_NOTE}</Label>
          <div className="ml-3">{note}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { currentConflict } = this.props;
    return (
      <div className="d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-center mb-2">
          <div className="conflict-icon flex-shrink-0 ml-2 mr-3">{this.props.icon}</div>
          <div className="d-flex align-items-center justify-content-center flex-grow-1 mr-2">
            <p className="m-0 text-justify">{this.props.localization.currentLocale.Migration.LABEL_CONFLICT_ANNOTATION_RECOMMENDED_MODIFIED}</p>
          </div>
        </div>
        <div className="cardsContainer w-100 d-flex align-items-center flex-wrap">
          <div className="card p-2 flex-grow-1 ml-2 mr-2 mb-2">
            <Label>{this.props.localization.currentLocale.Migration.LABEL_ANNOTATIONTYPE}</Label>
            <AnnotationTypeComponent color={currentConflict.AnnotationTypeModel.Color} name={currentConflict.AnnotationTypeModel.Value} />
          </div>
          <div className="card p-2 flex-grow-1 ml-2 mr-2 mb-2">
            <Label>{this.props.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}</Label>
            <div className="ml-3">{Convert.dateToFormattedString(currentConflict.AnnotationModel.LastUpdate, this.props.localization.currentCulture)}</div>
          </div>
          {this.renderNote()}
        </div>
      </div>
    );
  }
}

interface IFavouriteConflictDetailsProps {
  currentConflict: MigrationConflict;
  icon: JSX.Element;
  localization: Locale;
}
interface IFavouriteConflictDetailsState {}
class FavouriteConflictDetails extends React.Component<IFavouriteConflictDetailsProps, IFavouriteConflictDetailsState> {
  render() {
    const { currentConflict } = this.props;
    const { Folder, Value } = currentConflict.FavouriteModel;
    return (
      <div className="d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-center mb-2">
          <div className="conflict-icon flex-shrink-0 ml-2 mr-2">{this.props.icon}</div>
          <div className="d-flex align-items-center justify-content-center flex-grow-1">
            <p className="m-0 text-justify">{this.props.localization.currentLocale.Migration.LABEL_CONFLICT_FAVOURITE_RECOMMENDED_MODIFIED}</p>
          </div>
        </div>
        <div className="cardsContainer w-100 d-flex align-items-center flex-wrap">
          <div className="card p-2 flex-grow-1 ml-2 mr-2 mb-2">
            <Label>{this.props.localization.currentLocale.FavouriteView.LABEL_TITLE}</Label>
            <div className="ml-3">{Value}</div>
          </div>
          <div className="card p-2 flex-grow-1 ml-2 mr-2 mb-2">
            <Label>{this.props.localization.currentLocale.FavouriteView.LABEL_FOLDER}</Label>
            <div className="ml-3">{Folder ? Folder : this.props.localization.currentLocale.FavouriteView.LABEL_NOFOLDER}</div>
          </div>
          <div className="card p-2 flex-grow-1 ml-2 mr-2 mb-2">
            <Label>{this.props.localization.currentLocale.Migration.LABEL_TOCPATH}</Label>
            <div className="ml-3">{currentConflict.Location}</div>
          </div>
        </div>
      </div>
    );
  }
}
