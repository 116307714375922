import * as React from 'react';
import * as Autosuggest from 'react-autosuggest';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Book } from 'src/models/Book';
import {
    ITitle, IUserFormSubmission, IUserFormSubmissionAttachment
} from 'src/models/dto/ReaderModels';
import { Annotation, Favourite, UserFormSubmissionViewModel } from 'src/models/UserContent';
import { Convert } from 'src/utilities/Helpers';

import { JsonFormsCore } from '@jsonforms/core';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';

import * as Messages from '../foundation/Messages';
import { BookContext } from '../state/Contextes';
import { AttachmentForm } from './Controls/AttachmentForm';
import iframeControlTester from './Controls/iframeControlTester';
import RichContentControl from './Controls/RichContentControl';
import richContentControlTester from './Controls/richContentControlTester';
import TableIdWithOffset from './Controls/TableIdWithOffset';
import tableIdWithOffsetTester from './Controls/tableIdWithOffsetTester';
import { TitleSelector } from './Controls/TitleSelector';
import UnboundAnnotationForm from './Controls/UnboundAnnotationForm';

interface IAnnotationCreateDialogProps {
  creationConfirm: (anno: Annotation | Favourite | UserFormSubmissionViewModel, selection: any) => void;
  globalNotesCreationConfirm: (globalNote: string, selection: any) => any;
}

interface IAnnotationCreateDialogState {
  isOpen: boolean;
  currentModel: Annotation | Favourite | UserFormSubmissionViewModel | null;
  selectionObject: any;
}

export class UserContentCreateDialog extends React.Component<IAnnotationCreateDialogProps, IAnnotationCreateDialogState> {
  private annoFormRef = React.createRef<typeof UnboundAnnotationForm>();
  private faveFormRef = React.createRef<UnboundFavouriteForm>();
  private tipFormRef = React.createRef<UnboundTipForm>();

  context: Book;
  static contextType = BookContext;

  constructor(props: IAnnotationCreateDialogProps) {
    super(props);

    this.state = { isOpen: false, currentModel: null, selectionObject: null };
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
  }

  hide() {
    this.setState({ isOpen: false });
  }
  show(model: Annotation | Favourite | UserFormSubmissionViewModel, selection: { firstDocId: number; firstWordCount: number; firstOffset: number; secondDocId: number; secondWordCount: number; secondOffset: number; hadError?: boolean | undefined; } | { DocId: number; YDocHeight: number; YOffset: number; } | null) {
    this.setState({ isOpen: true, currentModel: model, selectionObject: selection });
  }

  render() {
    if (this.state.isOpen && this.state.currentModel) {
      let form;
      if (this.state.currentModel instanceof Annotation) {
        form = (
          <UnboundAnnotationForm
          creationConfirm={(anno) => {
            this.props.creationConfirm(anno, this.state.selectionObject);
            this.hide();
          }}
          globalNoteCreationConfirm={(anno) => {
            this.props.globalNotesCreationConfirm(anno, this.state.selectionObject);
            this.hide();
          }}
            key={this.state.currentModel.Id}
            initialModel={this.state.currentModel}
          />
        );
      } else if (this.state.currentModel instanceof UserFormSubmissionViewModel) {
        form = (
          <UnboundTipForm
            creationConfirm={(tip) => {
              this.props.creationConfirm(tip, this.state.selectionObject);
              this.hide();
            }}
            key={this.state.currentModel.Submission.TableId}
            initialModel={this.state.currentModel}
            editingNode={this.state.currentModel.Submission}
          />
        );
      } else {
        form = (
          <UnboundFavouriteForm
            creationConfirm={(fave) => {
              this.props.creationConfirm(fave, this.state.selectionObject);
              this.hide();
            }}
            key={this.state.currentModel.Id}
            initialModel={this.state.currentModel}
            allFolders={this.context.favouriteFolders.get()}
          />
        );
      }

      return (
        <div>
          <div className="unbound-form-modal" onClick={this.hide} />
          <div className="unbound-form-container">{form}</div>
        </div>
      );
    } else {
      return "";
    }
  }
}

interface IUnboundTipFormProps {
  initialModel: UserFormSubmissionViewModel;
  editingNode: IUserFormSubmission;
  creationConfirm: (userForm: UserFormSubmissionViewModel)=> void;
}

interface IUnboundTipFormState {
  editedModel: UserFormSubmissionViewModel;
  editingNode: IUserFormSubmission;
  currentAttachments: IUserFormSubmissionAttachment[];
  submissionData: string;
  errors: any;
  currentTitles: ITitle[];
}

class UnboundTipForm extends React.Component<IUnboundTipFormProps, IUnboundTipFormState> {
  context: Book;
  static contextType = BookContext;
  formRef: React.RefObject<typeof JsonForms>;
  constructor(props: IUnboundTipFormProps | Readonly<IUnboundTipFormProps>) {
    super(props);
    this.state = {
      editedModel: this.props.initialModel,
      editingNode: this.props.editingNode,
      submissionData: this.props.initialModel.Submission.Submission,
      currentAttachments: [],
      currentTitles: [],
      errors: null,
    };
    this.formRef = React.createRef<typeof JsonForms>();
  }

  async componentDidMount() {
    let titleResult = await this.context.getTitleInfo({ Id: this.context.id });
    let title = titleResult.data.TitleInfo;
    let titles = this.state.currentTitles;
    titles.push(title);
    this.titlesChanged(titles);
  }

  saveTip() {
    if (this.state.errors !== null && this.state.errors.length === 0) {
      let current = this.state.editedModel;
      current.Submission.Submission = JSON.stringify(this.state.submissionData);
      current.AssociatedTitles = this.state.currentTitles.map((t) => t.TitleRef);
      current.Attachments = this.state.currentAttachments;
      this.props.creationConfirm(current);
    }
  }

  titlesChanged = (titles: ITitle[]) => {
    if (titles.length === 0) {
      // ignore changes to 0 items
    } else {
      this.setState(() => ({ currentTitles: titles }));
    }
  };

  handleNewAttachment = (e: IUserFormSubmissionAttachment) => {
    // check file sizes
    if (e.AttachmentData.length > 2097152) {
      Messages.Notify.error(this.context.localization.currentLocale.CommunityView.LABEL_ATTACHMENT_TOO_LARGE);
    } else if (this.state.currentAttachments.length === 5) {
      Messages.Notify.error(this.context.localization.currentLocale.CommunityView.LABEL_ATTACHMENT_TOO_MANY);
    } else if (
      this.state.currentAttachments.length > 0 &&
      this.state.currentAttachments.map((a) => a.AttachmentData.length).reduce((a, b) => a + b, e.AttachmentData.length) > 2097152
    ) {
      Messages.Notify.error(this.context.localization.currentLocale.CommunityView.LABEL_ATTACHMENT_TOO_LARGE_SUM);
    } else {
      let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
      current.push(e);
      this.setState({ currentAttachments: current });
    }
  };
  handleUpdatedAttachment = (e: IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.TableId === e.TableId),
      1
    );
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  deleteAttachment = (e: IUserFormSubmissionAttachment) => {
    if (e !== undefined) {
      let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
      current.splice(
        this.state.currentAttachments.findIndex((x) => x.Index === e.Index),
        1
      );
      this.setState({ currentAttachments: current });
    }
  };
  setFormState = (e: Pick<JsonFormsCore, "data" | "errors">) => {
    this.setState({ submissionData: e.data, errors: e.errors });
  };
  render() {
    const schema = JSON.parse(this.state.editedModel.Definition.DataSchema);
    const uischema = JSON.parse(this.state.editedModel.Definition.ReaderUiSchema);
    let dataJson = this.state.submissionData === "" ? {} : this.state.submissionData;

    return (
      <div className="submissionFormContainer">
        <div className="formInner">
          <Tabs defaultActiveKey={UserFormTab.UserForm} id="userformTab">
            <Tab eventKey={UserFormTab.UserForm} title={"Form"}>
              <JsonForms
                schema={schema}
                uischema={uischema}
                data={dataJson}
                renderers={[
                  ...materialRenderers,
                  // register custom renderers
                  { tester: iframeControlTester, renderer: RichContentControl },
                  { tester: tableIdWithOffsetTester, renderer: TableIdWithOffset },
                  { tester: richContentControlTester, renderer: RichContentControl },
                ]}
                cells={materialCells}
                onChange={(data) => this.setFormState(data)}
              />
            </Tab>
            <Tab eventKey={UserFormTab.Titles} title={"Titles"}>
              <TitleSelector associatedTitles={this.state.currentTitles} onChange={this.titlesChanged} />
            </Tab>
            <Tab eventKey={UserFormTab.Attachments} title={"Attachments"}>
              <AttachmentForm
                currentAttachments={this.state.currentAttachments}
                parentSubmission={this.state.editingNode}
                handleNewAttachment={this.handleNewAttachment}
                handleUpdatedAttachment={this.handleUpdatedAttachment}
                deleteAttachment={this.deleteAttachment}
              />
            </Tab>
          </Tabs>
        </div>
        <FormGroup>
          <Col>
            <Row>
              <Button disabled={this.state.errors === null || this.state.errors.length > 0} className="saveButton" onClick={() => this.saveTip()} variant="outline-primary" color="primary">
                {this.context.localization.currentLocale.TipView.LABEL_SAVE}
              </Button>
            </Row>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

enum UserFormTab {
  UserForm,
  Titles,
  Attachments,
}
interface IUnboundFavouriteFormProps {
  initialModel: Favourite;
  creationConfirm: (anno: Favourite)=> void;
  allFolders: string[];
}
interface IUnboundFavouriteFormState {
  editedModel: Favourite;
  suggestions: string[];
}
class UnboundFavouriteForm extends React.Component<IUnboundFavouriteFormProps, IUnboundFavouriteFormState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IUnboundFavouriteFormProps | Readonly<IUnboundFavouriteFormProps>) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.handleTitleInput = this.handleTitleInput.bind(this);
    this.handleFolderInput = this.handleFolderInput.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);

    this.handleFolderInputBlurred = this.handleFolderInputBlurred.bind(this);
    this.state = {
      editedModel: props.initialModel,
      suggestions: [],
    };
  }

  onModelUpdate(newModel: Favourite) {
    this.setState({ editedModel: newModel });
  }

  handleTitleInput(event: any) {
    let model = this.state.editedModel;
    model.Value = event.target.value;
    this.onModelUpdate(model);
  }
  handleFolderInput(event: any, arg: any) {
    let model = this.state.editedModel;
    model.Folder = arg.newValue;
    this.onModelUpdate(model);
  }
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = (value: any) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value: any) => {
    const inputValue = value.value.trim().toLowerCase();
    const inputLength = inputValue.length as number;
    if (Convert.isEmptyOrSpaces(inputValue as string)) {
      return this.props.allFolders;
    }

    return this.props.allFolders.filter((folder) => folder.toLowerCase().slice(0, inputLength) === inputValue).sort((a, b) => a.localeCompare(b));
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(folder: any) {
    return folder;
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion: string) {
    return <div className="suggestionItem">{suggestion}</div>;
  }
  handleFolderInputBlurred() {
    this.setState({
      suggestions: [],
    });
  }

  componentDidMount() {
    this.setState({ suggestions: this.props.allFolders });
  }

  componentWillUnmount() {}
  shouldRenderSuggestions(): boolean {
    return true;
  }
  render() {
    // Autosuggest will pass through all these props to the input.
    let value = this.state.editedModel.Folder;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.handleFolderInput,
      onBlur: this.handleFolderInputBlurred,
    };
    return (
      <Form
        className="p-2 form-condensed"
        onSubmit={(e) => {
          e.preventDefault();
          this.props.creationConfirm(this.state.editedModel);
        }}
      >
        <h1>{this.context.localization.currentLocale.FavouriteView.LABEL_CREATE_FAVOURITE}</h1>
        <FormGroup>
          <Label for="note">{this.context.localization.currentLocale.FavouriteView.LABEL_TITLE}</Label>
          <Input type="text" name="note" id="note" defaultValue={this.state.editedModel.Value} onChange={this.handleTitleInput} />
        </FormGroup>
        <FormGroup>
          <Label for="note">{this.context.localization.currentLocale.FavouriteView.LABEL_FOLDER}</Label>
          <div className="autoSuggestContainerDiv">
            <Autosuggest
              focusInputOnSuggestionClick={true}
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              inputProps={inputProps}
            />
          </div>
        </FormGroup>
        <button className="btn btn-secondary" type="submit" value="Save">
          {this.context.localization.currentLocale.AnnotationTypeView.LABEL_SAVE}
        </button>
      </Form>
    );
  }
}
