import { useCallback } from 'react';
import { LibrarySession } from 'src/models/AppSession';
import { Book } from 'src/models/Book';
import { IProfessorChat } from 'src/models/dto/ProfessorRequest';

// import { useFetch } from 'src/chatbot/hooks/useFetch';  may be used later

export interface GetModelsRequestProps {
  key: string;
}

export interface GetTitlesRequestProps {
  key: string;
}

export interface ChatRequestProps {
  key: string;
  chatRequest: IProfessorChat;
}

export const useApiService = (library: LibrarySession, book?: Book) => {
  // const fetchService = useFetch();  may be used later

  const getModels = useCallback(
    (params: GetModelsRequestProps, signal?: AbortSignal) => {
      if (signal) {
        signal.onabort = () => {
          console.log('Aborting fetch.');
        }
      }
      return library.getChatModels({});
    },
    [library],
  );

  const getTitles = useCallback(
    (params: GetTitlesRequestProps, signal?: AbortSignal) => {
      if (signal) {
        signal.onabort = () => {
          console.log('Aborting fetch.');
        }
      }
      return library.getChatTitles({});
    },
    [library],
  );

  const chat = useCallback(
    async (params: ChatRequestProps, signal?: AbortSignal) => {
      if (signal) {
        signal.onabort = () => {
          console.log('Aborting fetch.');
        }
      }
      return await book?.getSocketChat(params);
    },
    [book],
  );


  return {
    getModels,
    getTitles,
    chat
  };
};

export default useApiService;
