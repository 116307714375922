import classnames from 'classnames';
import * as React from 'react';
import { Config, Platform } from 'src/Config';
import { IPropsWithChildren } from 'src/models/Interfaces';
import { AnnotationType } from 'src/models/UserContent';
import { Convert } from 'src/utilities/Helpers';
import * as _ from 'underscore';

import { IHtmlElementProps } from '../foundation/Controls';
import { SelectionHandler } from './Selection';

interface IContextMenuProps extends IHtmlElementProps, IPropsWithChildren {
  onActionClicked: (action: ContextMenuActions) => void;
  quickCreateClicked: (type: AnnotationType) => void;
  extraSettings?: IContextMenuOptions;
  sentContext: HTMLElement;
  sentContainer: HTMLElement;
  children?: React.ReactNode;
}
interface IContextMenuState {
  showingItems: any;
}
export class ContextMenu extends React.Component<IContextMenuProps, IContextMenuState> {
  contextMenu: ContextMenuHandler;
  menuRef = React.createRef<HTMLDivElement>();
  selection: SelectionHandler;
  constructor(props: IContextMenuProps) {
    super(props);
    this.state = { showingItems: this.props.children };
    this.filterShowing = this.filterShowing.bind(this);
    this.selection = new SelectionHandler(this.props.sentContext.ownerDocument);
  }
  async componentDidMount() {
    this.contextMenu = new ContextMenuHandler({
      menu: this.menuRef.current!,
      context: this.props.sentContext.ownerDocument.body,
      common: this.props.sentContainer.querySelector("iframe")!,
      selection: this.selection,
      actionHandler: (contextMenu: ContextMenuHandler, action: ContextMenuActions) => {
        this.props.onActionClicked(action);
      },
      selectionChangedCallback: () => void(async () => {
        await this.filterShowing();
      })(),
      ...this.props.extraSettings,
    });
    await this.filterShowing();
  }
  async filterShowing() {
    let showing = React.Children.map(this.props.children, async (child) => {
      if (!this.contextMenu) {
        return child;
      }
      let select = (await this.selection.getSelectionText()).selTxt;
      if ((!select && React.isValidElement(child) && child.props && child.props.shouldShowIfNoSelection) || (select && !Convert.isEmptyOrSpaces(select))) {
        return child;
      }
      return null;
    })?.filter(Boolean);
    const itemsToShow = await Promise.all(showing!);
    this.setState({ showingItems: itemsToShow.filter(Boolean) });
  }
  componentWillUnmount() {}

  render() {
    // If there's no selection, hide items that require selection (e.g. annotations)
    return (
      <div ref={this.menuRef} className={classnames("context-menu", this.props.className)} id={this.props.id} style={this.props.style}>
        {this.state.showingItems}
      </div>
    );
  }
}
export enum ContextMenuActions {
  addAnnotation = "addAnnotation",
  addFavourite = "addFavourite",
  addTip = "addTip",
  addFeedback = "addFeedback",
  print = "print",
  printSelection = "printSelection",
  searchOnline = "searchOnline",
  share = "share",
  copy = "copy",
  search = "search",
  audio = "audio",
}
interface IContextMenuItemProps extends IHtmlElementProps, IPropsWithChildren {
  action: string;
  icon?: React.ReactNode;
  shouldShowIfNoSelection: boolean;
  children?: React.ReactNode;
}
interface IContextMenuItemState {}
export class ContextMenuItem extends React.Component<IContextMenuItemProps, IContextMenuItemState> {
  render() {
    return (
      <div
        className={classnames("context-menu-item", this.props.className)}
        id={this.props.id}
        style={this.props.style}
        data-context-action={this.props.action}
        tabIndex={0}
      >
        {this.props.icon} {this.props.children}
      </div>
    );
  }
}
interface IContextAnnotationTypeMenuItemProps {
  AnnotationType: AnnotationType | null;
  annotationTypeCreate: (AnnotationType: AnnotationType) => void;
  shouldShowIfNoSelection: boolean;
}
interface IContextAnnotationTypeMenuItemState {}
export class ContextAnnotationTypeMenuItem extends React.Component<IContextAnnotationTypeMenuItemProps, IContextAnnotationTypeMenuItemState> {
  render() {
    return (
      <div
        className="context-annotationType-menu-item"
        onMouseDown={(c) => {
          c.preventDefault();
        }}
        onMouseUp={() => {
          if(Config.platform === Platform.ios)
          {
            return;
          }
          if (this.props.AnnotationType !== null) {
            this.props.annotationTypeCreate(this.props.AnnotationType);
          }
        }}
        onTouchStart={() => {
          if(Config.platform !== Platform.ios)
          {
            return;
          }
          if (this.props.AnnotationType !== null) {
            this.props.annotationTypeCreate(this.props.AnnotationType);
          }
        }}
        tabIndex={0}
      >
        {this.props.AnnotationType !== null && <div className="colour-token" style={{ backgroundColor: this.props.AnnotationType.Color }} />}
        {this.props.AnnotationType !== null && <span className="type-name">{this.props.AnnotationType.Value}</span>}
        {this.props.AnnotationType === null && <span className="type-name">Recent types</span>}
      </div>
    );
  }
}

export interface IContextMenuOptions {
  context: HTMLElement;
  menu: HTMLElement;
  common: HTMLElement;
  actionHandler?: (contextMenu: ContextMenuHandler, action: ContextMenuActions) => void;
  margins?: { top: number; right: number; left: number; bottom: number };
  touchMode?: boolean;
  showingDelay?: number;
  selection: SelectionHandler;
  willOpenCallback?: (contextMenu: ContextMenuHandler) => void;
  willCloseCallback?: (contextMenu: ContextMenuHandler) => void;
  didOpenCallback?: (contextMenu: ContextMenuHandler) => void;
  didCloseCallback?: (contextMenu: ContextMenuHandler) => void;
  selectionChangedCallback?: (contextMenu: ContextMenuHandler) => void;
}
export class ContextMenuHandler {
  static readonly defaultSettings: IContextMenuOptions = {
    context: document.createElement("div"),
    common: document.createElement("div"),
    menu: document.createElement("div"),
    actionHandler: () => {},
    margins: { top: 0, right: 0, left: 0, bottom: 0 },
    touchMode: false,
    showingDelay: 800,
    selection: new SelectionHandler(document.createElement("div").ownerDocument),
    willOpenCallback: () => {},
    willCloseCallback: () => {},
    didOpenCallback: () => {},
    didCloseCallback: () => {},
    selectionChangedCallback: () => {},
  };

  context: HTMLElement;
  menu: HTMLElement;
  actionHandler: (contextMenu: ContextMenuHandler, action: string) => void;
  margins: { top: number; right: number; left: number; bottom: number };
  touchMode: boolean;
  showingDelay: number;
  isOpen: boolean;
  willOpenCallback: (contextMenu: ContextMenuHandler) => void;
  willCloseCallback: (contextMenu: ContextMenuHandler)=> void;
  didOpenCallback: (contextMenu: ContextMenuHandler)=> void;
  didCloseCallback: (contextMenu: ContextMenuHandler)=> void;
  selectionChangedCallback: (contextMenu: ContextMenuHandler)=> void;
  lastPosition: { x: number; y: number };
  common: HTMLElement;
  innerWindow: Document;
  lastRightClickTarget: HTMLElement;
  selection: SelectionHandler;
  constructor(options: IContextMenuOptions) {
    let settings = _.defaults(options, ContextMenuHandler.defaultSettings);
    this.context = settings.context;
    this.menu = settings.menu;
    this.actionHandler = settings.actionHandler;
    this.margins = settings.margins;
    this.touchMode = settings.touchMode;
    this.showingDelay = settings.showingDelay;
    this.selection = settings.selection;
    this.willOpenCallback = settings.willOpenCallback;
    this.willCloseCallback = settings.willCloseCallback;
    this.didOpenCallback = settings.didOpenCallback;
    this.didCloseCallback = settings.didCloseCallback;
    this.selectionChangedCallback = settings.selectionChangedCallback;
    this.contextMenuEventHandler = this.contextMenuEventHandler.bind(this);
    this.blurEventHandler = this.blurEventHandler.bind(this);
    this.selectionChangeEventHandler = this.selectionChangeEventHandler.bind(this);
    this.actionEventHandler = this.actionEventHandler.bind(this);

    this.context.addEventListener("contextmenu", this.contextMenuEventHandler);
    this.context.addEventListener("mousedown", this.blurEventHandler);
    this.context.addEventListener("scroll", this.blurEventHandler);
    this.context.addEventListener("touchstart", this.blurEventHandler);
    this.context.addEventListener("resize", this.blurEventHandler);

    this.context.ownerDocument.addEventListener("selectionchange", (e) => void this.selectionChangeEventHandler(e));
    this.menu.addEventListener("blur resize", this.blurEventHandler);
    this.menu.addEventListener(Config.platform === Platform.ios || Config.platform === Platform.android ? "touchstart" : "mouseup", this.actionEventHandler);
    this.menu.addEventListener("mousedown", (e) => e.preventDefault());

    this.lastPosition = { x: 0, y: 0 };
    this.isOpen = false;
    this.common = settings.common;
    this.innerWindow = this.context.ownerDocument!;

    // Quick menu init
    this.openAt(0, 0);
    this.hide();
  }

  /*
        Handle the context menu event for the context
    */
  contextMenuEventHandler(eventargs: any) {
    eventargs.preventDefault();
    eventargs.stopPropagation();
    if (!this.touchMode) {
      let position = this.relativePageCoordinates(eventargs.pageX as number, eventargs.pageY as number, this.common);
      this.openAt(position.x, position.y);
      this.lastRightClickTarget = eventargs.target;
    }

    return false;
  }

  /*
        Handle blur events by hiding the menu
    */
  blurEventHandler() {
    // Not needed because the menu will dismiss if the selection is cleared.
    // If this is uncommented the menu button clicks will not go through
    // because the blur occurs before the action item click.

    if (!this.isOpen) return;

    this.hide();
  }

  relativePageCoordinates(pageX: number, pageY: number, to: HTMLElement) {
    const VERTICAL_OFFSET = 15;
    let bounds = to.getBoundingClientRect();
    return {
      x: pageX + bounds.left,
      y: pageY + bounds.top + VERTICAL_OFFSET,
    };
  }

  /*

    */
  async selectionChangeEventHandler(event: any) {
    event.preventDefault();
    event.stopPropagation();

    let currentText = (await this.selection.getSelectionText()).selTxt;
    if (this.isOpen) {
      if (!currentText) {
        this.hide();
      } else {
        setTimeout(
          (instance: any) => {
            instance._openForSelection();
          },
          1,
          this
        ); // Update position asynchronously
      }
    } else if (currentText && currentText.trim()) {
      setTimeout(
        (text: string, instance: ContextMenuHandler) => void(async () => {
          let delayedText = (await instance.selection.getSelectionText()).selTxt;
          if (!delayedText || delayedText.length === 0 || text !== delayedText) {
            return;
          }
          instance._openForSelection();
        })(),
        this.showingDelay,
        currentText,
        this
      );
    }
    this.selectionChangedCallback(this);
  }

  /*
        Handle changes to last position
    */
  lastPositionUpdateEventHandler(_sender: any, event: { pageX: number; pageY: number; }) {
    let position = this.relativePageCoordinates(event.pageX, event.pageY, this.common);
    this.lastPosition.x = position.x;
    this.lastPosition.y = position.y;
  }

  /*
        Handle click on context's menu items
    */
  actionEventHandler(ev: MouseEvent | TouchEvent) {
    let sender = (ev.target as HTMLElement).closest("[data-context-action]");
    if (sender) {
      this.actionHandler(this, sender.getAttribute("data-context-action")!);
      this.hide();
    }
  }

  /*
    Open the menu at last position
    */
  openAtLastPosition() {
    this.openAt(this.lastPosition.x, this.lastPosition.y);
  }

  /*
        Open the menu on the anchor
    */
  openOn(anchor: HTMLElement) {
    let rect = anchor.getBoundingClientRect();
    if (!rect) {
      return;
    }
    let position = this.relativePageCoordinates(rect.left + rect.width, rect.top + rect.height, this.common);
    this.openAt(position.x, position.y);
  }

  /*
        Open the menu at location x,y 
    */
  openAt(x: number, y: number) {
    // Reset some CSS properties that may have been applied on very small screens.
    this.menu.style.width = "";
    this.menu.style.height = "";
    this.menu.style.overflowX = "";
    this.menu.style.overflowY = "";
    this.willOpenCallback(this);

    let menuSize = this.getRenderedDimensions();
    let windowSize = this.getViewportDimensions();
    let bounds = {
      // Generate the bounds of the screen, including margins.
      top: 0 + this.margins.top,
      left: 0 + this.margins.left,
      right: windowSize.w - this.margins.right,
      bottom: windowSize.h - this.margins.bottom,
    };
    // Place or x,y position within bounds.
    let ax = Math.max(bounds.left, x); // adjusted x
    let ay = Math.max(bounds.top, y); // adjusted y

    // Calculate relative x position (to the left or to the right of the anchor).
    if (x === this.specialLocations.Middle) {
      ax = Math.max(bounds.left, bounds.left + (bounds.right - bounds.left - menuSize.w) / 2); // put it in the center
    } else if (ax + menuSize.w <= bounds.right) {
      // Does the menu fit on the right?
      // ax = ax; // menu is within bounds as is. no changes required
    } else if (ax - menuSize.w >= bounds.left) {
      // Does the menu fit on the left?
      ax = ax - menuSize.w; // it does! adapt the position to put in the left
    } else if (menuSize.w <= bounds.right - bounds.left) {
      // Does the menu fit at all?
      ax = Math.max(bounds.left, (bounds.right - bounds.left - menuSize.w) / 2); // put it in the center
    } // The menu width doesn't fit. This is a challenge...
    else {
      this.menu.style.width = (bounds.right - bounds.left).toString(); // give the menu the available width;
      ax = bounds.left;
      this.menu.style.overflowX = "auto"; // enable scrollbars
    }

    // Calculate relative y position (bottom or top of the anchor).
    if (y === this.specialLocations.Middle) {
      ay = Math.max(bounds.top, (bounds.bottom - bounds.top - menuSize.h) / 2); // put in the center
    } else if (ay + menuSize.h <= bounds.bottom) {
      // Does the menu fit on the bottom?
      // ay = ay; // menu is within bounds as is. no changes required.
    } else if (ay - menuSize.h >= bounds.top) {
      // Does the menu fit on the top?
      ay = ay - menuSize.h; // it does! adapt the position to put above the anchor.
    } else if (menuSize.h <= bounds.bottom - bounds.top) {
      // Does the menu fit at all?
      ay = Math.max(bounds.top, bounds.top + (bounds.bottom - bounds.top - menuSize.h) / 2); // put in the center
    } else {
      // The menu height doesn't fit. This is a challenge...
      this.menu.style.height = (bounds.bottom - bounds.top).toString(); // give the menu the available height;
      ay = bounds.top;
      this.menu.style.overflowY = "auto";
    }
    this.menu.style.position = "fixed";
    this.menu.style.top = ay + "px";
    this.menu.style.left = ax + "px";
    this.menu.style.display = "flex";
    this.isOpen = true;
    this.didOpenCallback(this);
  }
  _openForSelection() {
    let selectionRect = this.getSelectionRect();
    let bounds = this.common.getBoundingClientRect();
    this.openAt(bounds.left + selectionRect.left as number + (selectionRect.width as number / 2), bounds.top + selectionRect.top as number + selectionRect.height as number);
  }
  // from: https://stackoverflow.com/a/26495188
  getSelectionRect() {
    let sel = (this.innerWindow as any).selection;
    let range;
    let rect;
    if (sel) {
      if (sel.type !== "Control") {
        range = sel.createRange();
        range.collapse(true);
      }
    } else if (this.innerWindow.getSelection) {
      sel = this.innerWindow.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0).cloneRange();
        if (range.getBoundingClientRect) {
          if (range.getBoundingClientRect()) {
            rect = range.getBoundingClientRect();
          }
        }

        // Fall back to inserting a temporary element
        if (rect.top === 0 && rect.left === 0) {
          let span = this.innerWindow.createElement("span");
          if (span.getClientRects()) {
            // Ensure span has dimensions and position by
            // adding a zero-width space character
            span.appendChild(this.innerWindow.createTextNode("\u200b"));

            range.insertNode(span);
            rect = span.getBoundingClientRect();

            let spanParent = span.parentNode!;

            spanParent.removeChild(span);

            // Glue any broken text nodes back together
            spanParent.normalize();
          }
        }
      }
    }
    return rect;
  }

  specialLocations = {
    Middle: -9999,
  };

  /*
        Hide the contextual menu
    */
  hide() {
    this.willCloseCallback(this);
    this.menu.style.display = "none";
    this.isOpen = false;
    this.didCloseCallback(this);
  }

  /*
        Get the rendered dimensions of the menu. 
        return {w: int width, h: int height}
    */
  getRenderedDimensions(): { w: number; h: number } {
    if (!this.isOpen) {
      this.menu.style.opacity = "0"; // Temporarly place the menu in the view to allow calculation
      this.menu.style.display = "flex"; // show
    }

    let w = this.menu.offsetWidth || 0;
    let h = this.menu.offsetHeight || 0;

    if (!this.isOpen) {
      this.menu.style.display = "none"; // hide
      this.menu.style.opacity = "";
    }

    return { w: w, h: h };
  }

  /*
        Get the dimensions of the viewport in which the menu should appear.
        return {w: int width, h: int height}
    */
  getViewportDimensions(): { w: number; h: number } {
    let w = this.context.offsetWidth || 0;
    let h = this.context.offsetHeight || 0;
    return { w: w, h: h };
  }
}
