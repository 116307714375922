import * as React from 'react';

export interface IMediaPanelModalState {
    isOpen: boolean;
    resource: string;
    partsListIndex: number;
}

export interface IMediaPanelModalProps {
   linkRequestHandler: any;
}
export class MediaPanelModal extends React.Component<IMediaPanelModalProps, IMediaPanelModalState> {
  iframeRef = React.createRef<HTMLIFrameElement>();
  messageListener : ((event: any) => void) | null = null;

  constructor(props: IMediaPanelModalProps) {
    super(props);
    this.state = {
        isOpen: false,
        resource: "",
        partsListIndex: NaN
    };
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevState.isOpen && this.state.isOpen)
    {
      this.addMessageListener();
    }
    if (prevState.isOpen && !this.state.isOpen)
    {
      window.removeEventListener("message", this.messageListener as EventListener);
    }
  }

  addMessageListener() {
    this.messageListener = (event) => {
      if (event.data && event.data.type === "redirect") {
        let redirectUrl = event.data.url;
        let lastPart = redirectUrl.substring(redirectUrl.lastIndexOf("/") + 1);
        const splitter = lastPart.indexOf("?");
        let action = lastPart.substring(0, splitter);
        let navigateTo = "";
        if (action === "NAV") {
           navigateTo = redirectUrl.substring(0, redirectUrl.indexOf(","));
           navigateTo = navigateTo.replace(".html", "");
           const periods = navigateTo.split('.');
           if (periods.length >= 3) {
             const firstPart = periods.slice(0,2).join('.');
             const rest = periods.slice(2).join('.').toUpperCase();
             navigateTo = `${firstPart}.${rest}`;
           }
           navigateTo = navigateTo + redirectUrl.substring(redirectUrl.indexOf(","));
           navigateTo = navigateTo.replace("NAV?", "NAVSEL?ID_");
           
           navigateTo = navigateTo.replace("#", "");
           const selectorSplitter = navigateTo.indexOf(",");
           const head = navigateTo.substring(navigateTo.indexOf("_") + 1,selectorSplitter);
           const newSel = ",#" + head + "__" + navigateTo.substring(selectorSplitter + 1);
           navigateTo = navigateTo.substring(0,selectorSplitter) + newSel;
           if (!isNaN(this.state.partsListIndex))
           {
              navigateTo = navigateTo.substring(0, navigateTo.indexOf("__") + 2)
              navigateTo = navigateTo + head + "____" + this.state.partsListIndex;
           }
        }
        console.log("Redirect URL received:", redirectUrl);
        this.setState({ isOpen: false });
        this.props.linkRequestHandler(navigateTo);
      }
    }
    window.addEventListener("message", this.messageListener as EventListener);
    
  }

  hide() {
    this.setState({ isOpen: false, resource: "", partsListIndex: NaN });
  }
  
  show(resource: string, partsListIndex: number) {
    this.setState({ isOpen: true, resource: resource.replace(/\n/g, ''), partsListIndex: partsListIndex });
  }


  overrideStyle(event: any) {
    const iframe = event.target as HTMLIFrameElement;
    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDocument) {
      const htmlElement = iframeDocument.querySelector('html');
      const bodyElement = iframeDocument.querySelector('body');
      const imgElement = iframeDocument.querySelector('img');
      const videoElement = iframeDocument.querySelector('video');

      if (htmlElement && bodyElement) {
        htmlElement.style.height = "100%";
        bodyElement.style.height = "100%";
      }

      if (imgElement) {
        imgElement.style.height = "auto";
        imgElement.style.width = "100%";
      }

      if (videoElement) {
        videoElement.style.height = "100%";
        videoElement.style.width = "100%";
      }
    }
  }

  render() {
    if (this.state.isOpen) {
      return (
        <div>
          <div className="unbound-form-modal" onClick={this.hide} />
          <div className="unbound-form-container media-modal-container">
            <iframe onLoad={this.overrideStyle} ref={this.iframeRef} title="resource-iframe" srcDoc={this.state.resource} />
        </div>
        </div>
      );
    } else {
      return "";
    }
  }
}