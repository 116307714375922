import React from 'react';
import { Button, Form } from 'reactstrap';
import { SettingsTarget } from 'src/models/AppSession';
import { ResourcePlatform } from 'src/utilities/Helpers';

import { Loading } from '../foundation/Controls';
import { LibraryContext } from '../state/Contextes';

interface SubscriptionModalProps {
  dismissModal: () => void;
  refreshLibrary: () => void;
}
enum SubscriptionTier {
  Tier1Monthly,
  Tier1Annually,
  Tier2Monthly,
  Tier2Annually,
}

export const SubscriptionModal = (props: SubscriptionModalProps) => {
  const context = React.useContext(LibraryContext)!;
  const [isLoading] = React.useState<boolean>(false);

  const tierButtonClicked = async (subTier: SubscriptionTier) => {
    let userRef = await context.getUserRef({});
    let linkCode = "";
    // Testing URLs
    if (context.originalConfig.get() !== SettingsTarget.Release) {
      switch (subTier) {
        case SubscriptionTier.Tier1Monthly:
          linkCode = "test_eVa7w93hs55eacw9AA";
          break;
        case SubscriptionTier.Tier1Annually:
          linkCode = "test_bIY5o17xI8hqesM3cd";
          break;
        case SubscriptionTier.Tier2Monthly:
          linkCode = "test_dR6g2F5pAgNWgAU5km";
          break;
        case SubscriptionTier.Tier2Annually:
          linkCode = "test_cN25o15pA55eacwfZ1";
          break;
      }
    } else {
      switch (subTier) {
        case SubscriptionTier.Tier1Monthly:
          linkCode = "7sI5kW6Jn5DI4xO144";
          break;
        case SubscriptionTier.Tier1Annually:
          linkCode = "28o8x89Vz9TYfcsdQR";
          break;
        case SubscriptionTier.Tier2Monthly:
          linkCode = "cN2aFg0kZd6a1lCeUW";
          break;
        case SubscriptionTier.Tier2Annually:
          linkCode = "5kAfZAgjX6HMd4k28b";
          break;
      }
    }
    // Example: "https://buy.stripe.com/test_eVa7w93hs55eacw9AA?client_reference_id="
    ResourcePlatform.openNewTab(
      "https://buy.stripe.com/" + linkCode + "?client_reference_id=" + userRef.data.UserRef + "&prefilled_email=" + encodeURIComponent(context.userName)
    );
  };

  return (
    <div className="subscriptionUploadModal">
      <div className="unbound-form-modal" onClick={props.dismissModal} style={{ zIndex: "10" }} />
      <div className="unbound-form-container" style={{ zIndex: "11" }}>
        <Loading isLoading={isLoading} status="Uploading your publication!">
          <Form className="p-2 form-condensed">
            <h1 className="upgradeHeader">{"Upgrade to proLibro premium!"}</h1>
            <div className="tierHolder">
              <div className="tier1">
                <b>Join our basic plan to gain:</b>
                <ul>
                  <li>Up to 5 of your own titles in the proLibro system.</li>
                  <li>Up to 10 queries to The Professor AI every day.</li>
                </ul>
                <div className="buttonHolder">
                  <Button onClick={() => void tierButtonClicked(SubscriptionTier.Tier1Monthly)}>Monthly</Button>
                  <Button onClick={() => void tierButtonClicked(SubscriptionTier.Tier1Annually)} className="annual">
                    Annually (save 20%!)
                  </Button>
                </div>
              </div>
              <div className="tier2">
                <b>Join our ultimate plan to gain:</b>
                <ul>
                  <li>Unlimited titles in the proLibro system up to 10 uploads per day!</li>
                  <li>Unlimited queries to The Professor AI!</li>
                </ul>
                <div className="buttonHolder">
                  <Button onClick={() => void tierButtonClicked(SubscriptionTier.Tier2Monthly)}>Monthly</Button>
                  <Button onClick={() => void tierButtonClicked(SubscriptionTier.Tier2Annually)} className="annual">
                    Annually (save 20%!)
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Loading>
      </div>
    </div>
  );
};
