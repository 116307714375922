import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import {
    Collapse, DropdownToggle, Nav, Navbar, NavItem, NavLink, UncontrolledDropdown
} from 'reactstrap';
import { Languages, Locale } from 'src/localization/Locale';
import { Book, BookLoadingSteps } from 'src/models/Book';
import { AnnotationType, AnnotationTypeSorting } from 'src/models/UserContent';
import { AnnotationTypeFormActions } from 'src/utilities/Enums';
import { Binding, EmitterEvent } from 'src/utilities/Events';
import { Convert } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import {
    ActionIcon, AnnotationTypeComponent, Expander, Icon, ItemCard, SortDropdownItem,
    SortDropdownMenu
} from '../foundation/Controls';
import { AnnotationTypeForm } from '../foundation/Controls/AnnotationTypeForm';
import { RightFlip } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import {
    Action, ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { BookContext } from '../state/Contextes';
import { IModelBinding } from '../state/Generics';

interface IAnnotationTypeViewState {
  formOpen: boolean;
  editing: number;
  createViewOpen: boolean;
  currentAnnotationType: AnnotationType | null;
  createAnnotationType: AnnotationType;
  sorting: AnnotationTypeSorting;
  reversedSort: boolean;
  sortedTypes: AnnotationType[];
}
interface IAnnotationViewProps {
  isActive: boolean;
}
export class AnnotationTypeView extends React.Component<IAnnotationViewProps, IAnnotationTypeViewState> {
  context: Book;
  private formRef = React.createRef<AnnotationTypeForm>();
  private createformRef = React.createRef<AnnotationTypeForm>();
  cogniflow = React.createRef<StandaloneCogniflowContainer>();

  static contextType = BookContext;
  constructor(props: IAnnotationViewProps | Readonly<IAnnotationViewProps>) {
    super(props);
    this.state = {
      editing: 0,
      formOpen: false,
      createViewOpen: false,
      currentAnnotationType: null,
      createAnnotationType: new AnnotationType(),
      sorting: AnnotationTypeSorting.Recent,
      reversedSort: false,
      sortedTypes: [],
    };
    this.editType = this.editType.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.toggleCreateView = this.toggleCreateView.bind(this);
    this.onReady = this.onReady.bind(this);
    this.update = this.update.bind(this);
    this.readAnnotationType = this.readAnnotationType.bind(this);
    this.closeAnnotationTypeForm = this.closeAnnotationTypeForm.bind(this);
    this.onFormToolbarAction = this.onFormToolbarAction.bind(this);
    this.createAnnotationType = this.createAnnotationType.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildAnnotationTypeItem = this.buildAnnotationTypeItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
  }
  segmentInserted() {}
  async editType(item: AnnotationType) {
    await this.readAnnotationType(item);
  }
  onSortingChanged(sort: AnnotationTypeSorting, reversed: boolean) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), AnnotationTypeSorting: sort, AnnotationTypeSortingReversed: reversed });
    this.setState(
      {
        sorting: sort,
        reversedSort: reversed,
      },
      () => void(async () => {
        await this.update(null, 0);
      })()
    );
  }

  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.annotationTypes, this);
    await this.context.initAnnotationTypes();
    this.context.annotationTypes.addListener((listener, event) => void this.update(listener, event));
    await this.update();
    this.context.loading.setLoaded(BookLoadingSteps.annotationTypes);
    this.setState({
      createAnnotationType: {
        Color: "#" + this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_COLOR,
        Value: this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_NAME_DEFAULT,
        Id: 0,
        LastUpdate: new Date(),
      },
    });
  }
  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.annotationTypes, () => void this.onReady());
    this.setState({
      sorting: this.context.appSettings.get().AnnotationTypeSorting,
      reversedSort: this.context.appSettings.get().AnnotationTypeSortingReversed,
    });
  }
  async update(affectedItem?: any, event?: EmitterEvent) {
    if (event === EmitterEvent.delete) {
      this.setState({ currentAnnotationType: null });
      await this.closeAnnotationTypeForm();
    }
    this.setState({ sortedTypes: this.sortAnnotations(this.context.annotationTypes.rows()) }, () => {
      if (this.cogniflow.current && this.cogniflow.current !== null && event !== undefined) {
        this.cogniflow.current.reloadCogniflow();
      }
    });
  }
  sortAnnotations(annoTypes: AnnotationType[]): AnnotationType[] {
    if (this.state.sorting === AnnotationTypeSorting.Title) {
      annoTypes = annoTypes.sort((x1, x2) => {
        let x1v = x1.Value.toLowerCase().replace(/ /g, "");
        let x2v = x2.Value.toLowerCase().replace(/ /g, "");
        if (x1v > x2v) {
          return 1;
        }
        if (x1v < x2v) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.sorting === AnnotationTypeSorting.Recent) {
      annoTypes = annoTypes.sort((x1, x2) => {
        if (x1.LastUpdate > x2.LastUpdate) {
          return 1;
        }
        if (x1.LastUpdate < x2.LastUpdate) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.reversedSort) {
      annoTypes = annoTypes.reverse();
    }
    let count = 0;
    annoTypes.map((x) => {
      x.Index = count;
      count++;
      x.IsFirst = false;
      x.IsLast = false;
    });
    if (annoTypes.length > 0) {
      annoTypes[0].IsFirst = true;
      annoTypes[annoTypes.length - 1].IsLast = true;
    }
    return annoTypes;
  }
  async closeAnnotationTypeForm() {
    await this.checkChanges();
    this.setState({
      formOpen: false,
      createViewOpen: false,
    });
  }
  async readAnnotationType(annotationType: AnnotationType) {
    await this.checkChanges();
    this.setState({
      formOpen: true,
      currentAnnotationType: annotationType,
    });
  }

  async onFormToolbarAction(action: AnnotationTypeFormActions) {
    switch (action) {
      case AnnotationTypeFormActions.create:
        if (this.createformRef.current !== null && this.createformRef.current.valid()) {
          await this.createformRef.current.createAnnotationType();
        }
        await this.closeAnnotationTypeForm();
        break;
      case AnnotationTypeFormActions.back:
        await this.closeAnnotationTypeForm();
        break;
      case AnnotationTypeFormActions.save:
        if (this.formRef.current != null && action === AnnotationTypeFormActions.save && this.formRef.current.valid()) {
          await this.formRef.current.submit();
        }
        break;
      case AnnotationTypeFormActions.delete:
        if (this.formRef.current != null) {
          await this.formRef.current.deleteAnnotationType();
        }
        break;
    }
  }

  private async createAnnotationType() {
    await this.onFormToolbarAction(AnnotationTypeFormActions.create);
  }

  private toggleCreateView() {
    this.setState({
      createViewOpen: !this.state.createViewOpen,
    });
  }
  async checkChanges() {
    if (this.formRef.current && this.formRef.current.state.edited && this.formRef.current.state.form.formValid()) {
      let result = await Messages.Dialog.confirm(
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPESAVE_PROMPT,
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPESAVE_HEADING
      );
      if (result === "true") {
        await this.formRef.current.submit();
      } else {
        this.formRef.current.setState({ edited: false });
      }
    }
  }
  private flowProvider(request: IRequest): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let message = request;
      let items: AnnotationType[] = [];
      if (message.Batches[0].Action === Action.append || message.Batches[0].Action === Action.navAppend) {
        let nextId = message.Batches[0].AnchorMainId + 1;
        items = this.state.sortedTypes.slice(nextId, nextId + message.Batches[0].BatchSize);
      }
      if (message.Batches[0].Action === Action.prepend) {
        items = this.state.sortedTypes.slice(
          message.Batches[0].AnchorMainId - message.Batches[0].BatchSize < 0 ? 0 : message.Batches[0].AnchorMainId - message.Batches[0].BatchSize,
          message.Batches[0].AnchorMainId
        );
      }
      message.Batches[0].Nodes = [];
      message.Batches[0].Nodes = items;
      resolve(message);
    });
  }

  private navigationDone() {}

  componentDidUpdate() {}

  private initializeFlow(anchor?: number): Promise<{ nodes: any[]; targetSpine: number }> {
    return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let result: AnnotationType[] = [];
      if (anchor) {
        result = this.state.sortedTypes.slice(anchor, anchor + 100);
      } else {
        result = this.state.sortedTypes.slice(0, 100);
      }
      resolve({
        nodes: result,
        targetSpine: result[0].Index ? result[0].Index : 0,
      });
    });
  }

  private buildAnnotationTypeItem(node: INode): any {
    let item = node as AnnotationType;
    item = this.context.annotationTypes.get(item.Id)!;
    return <AnnotationTypeListItem item={item} onClick={(i) => void this.readAnnotationType(i)} language={this.context.appSettings.get().LanguageOverride} />;
  }
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-type-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-type-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    batchSize: 20,
  };
  render(): any {
    return (
      <div className="annotationType-view full-height d-flex flex-column position-relative">
        <RightFlip
          breakpoint={768}
          className="flex-fill"
          isOpen={this.state.formOpen}
          panelClassName={{
            left: "d-flex flex-column",
            right: "d-flex flex-column border-left bg-body",
          }}
        >
          {{
            left: (
              <React.Fragment>
                <AnnotationTypeToolbar
                  onCreateNewToggle={this.toggleCreateView}
                  createViewOpen={this.state.createViewOpen}
                  onSortingChange={this.onSortingChanged}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                  initialReversed={this.state.reversedSort}
                  initialSort={this.state.sorting}
                />
                <Expander isOpen={this.state.createViewOpen} className="bg-light border-top border-bottom">
                  <AnnotationTypeForm
                    onActionClicked={() => void this.createAnnotationType()}
                    ref={this.createformRef}
                    key={this.state.createAnnotationType.Id}
                    model={new Binding<AnnotationType|null>(this.state.createAnnotationType)}
                    showSave={true}
                  />
                </Expander>
                {this.state.sortedTypes.length > 0 && (
                  <StandaloneCogniflowContainer
                    provider={this.flowProvider}
                    builder={this.buildAnnotationTypeItem}
                    initialize={this.initializeFlow}
                    segmentsInsertedCallback={this.segmentInserted}
                    navigationDoneCallback={this.navigationDone}
                    extraSettings={this.settings}
                    ref={this.cogniflow}
                  />
                )}
                {this.state.sortedTypes.length === 0 && (
                  <span key={0} className="noUcItems">
                    {this.context.localization.currentLocale.AnnotationTypeView.LABEL_NO_TYPES}
                  </span>
                )}
              </React.Fragment>
            ),
            right: (
              <React.Fragment>
                <AnnotationTypeFormToolbar
                  onActionClicked={(action) => void this.onFormToolbarAction(action)}
                  key={this.state.currentAnnotationType && this.state.currentAnnotationType !== null ? this.state.currentAnnotationType.Id : -1}
                  model={(this.state.currentAnnotationType ? this.context.annotationTypes.bind(this.state.currentAnnotationType) : new Binding<AnnotationType|null>(null)) as Binding<AnnotationType|null>}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                />
                <div className="flex-fill scrollable  listContainer">
                  <AnnotationTypeForm
                    onActionClicked={(action) => void this.onFormToolbarAction(action)}
                    ref={this.formRef}
                    key={this.state.currentAnnotationType && this.state.currentAnnotationType !== null ? this.state.currentAnnotationType.Id : -1}
                    model={(this.state.currentAnnotationType ? this.context.annotationTypes.bind(this.state.currentAnnotationType) : new Binding<AnnotationType|null>(null)) as Binding<AnnotationType|null>}
                    showSave={false}
                  />
                </div>
              </React.Fragment>
            ),
          }}
        </RightFlip>
      </div>
    );
  }
}

interface IAnnotationTypeToolbarProps {
  onSortingChange: (sort: AnnotationTypeSorting, reversed: boolean)=> void;
  onCreateNewToggle: ()=> void;
  createViewOpen: boolean;
  localization: Locale;
  helpEnabled: boolean;
  initialSort: AnnotationTypeSorting;
  initialReversed: boolean;
}
interface IAnnotationTypeToolbarState {}

class AnnotationTypeToolbar extends React.Component<IAnnotationTypeToolbarProps, IAnnotationTypeToolbarState> {
  constructor(props: IAnnotationTypeToolbarProps | Readonly<IAnnotationTypeToolbarProps>) {
    super(props);
  }

  componentDidMount() {
    this.setState({ createViewOpen: false });
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                color="link"
                data-tooltip-id="annotationTypeToolbar"
                data-tooltip-content={this.props.localization.currentLocale.LibraryView.LABEL_SORT_BUTTON}
              >
                <Icon src={<Image.sort />} />
              </DropdownToggle>
              <SortDropdownMenu initial={this.props.initialSort} initialReversed={this.props.initialReversed} onSortSelected={this.props.onSortingChange}>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationTypeSorting.Recent}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationTypeSorting.Title}>
                  {this.props.localization.currentLocale.FavouriteView.LABEL_TITLE}
                </SortDropdownItem>
              </SortDropdownMenu>
            </UncontrolledDropdown>
            <NavItem data-tooltip-id="annotationTypeToolbar" data-tooltip-content={this.props.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_NEW}>
              <ActionIcon onClick={this.props.onCreateNewToggle} src={<Image.plus />} active={this.props.createViewOpen} />
            </NavItem>
            {this.props.helpEnabled && <Tooltip id="annotationTypeToolbar" place="bottom" variant="info" className="primaryColoured" />}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
interface IAnnotationTypeItemProps {
  item: AnnotationType;
  language: Languages;
  onClick: (item: AnnotationType, action: AnnotationTypeItemAction)=> void;
}
enum AnnotationTypeItemAction {
  item,
  showMore,
}
class AnnotationTypeListItem extends React.Component<IAnnotationTypeItemProps, unknown> {
  constructor(props: IAnnotationTypeItemProps | Readonly<IAnnotationTypeItemProps>) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onShowMoreClick = this.onShowMoreClick.bind(this);
  }

  private onClick() {
    this.props.onClick(this.props.item, AnnotationTypeItemAction.item);
  }

  private onShowMoreClick() {
    this.props.onClick(this.props.item, AnnotationTypeItemAction.showMore);
  }

  render() {
    return (
      <ItemCard onClick={this.onClick} showMore={true} onShowMoreClick={this.onShowMoreClick}>
        {{
          title: <AnnotationTypeComponent color={this.props.item.Color} name={this.props.item.Value} tokenClassname="larger-token" />,
          description: this.props.item.LastUpdate && (
            <span style={{ fontStyle: "italic" }}>{Convert.dateToFormattedString(this.props.item.LastUpdate, this.props.language)}</span>
          ),
        }}
      </ItemCard>
    );
  }
}

interface IAnnotationTypeFormToolbarProps extends IModelBinding<AnnotationType|null> {
  onActionClicked: (action: AnnotationTypeFormActions)=> void;
  localization: Locale;
  helpEnabled: boolean;
}

interface IAnnotationTypeFormToolbarState {
  actionsEnabled: boolean;
}
class AnnotationTypeFormToolbar extends React.Component<IAnnotationTypeFormToolbarProps, IAnnotationTypeFormToolbarState> {
  constructor(props: IAnnotationTypeFormToolbarProps | Readonly<IAnnotationTypeFormToolbarProps>) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.state = { actionsEnabled: false };
  }

  onModelUpdate() {
    if (this.props.model.current !== null) {
      this.setState({
        actionsEnabled: true,
      });
    } else {
      this.setState({
        actionsEnabled: false,
      });
    }
  }

  componentDidMount() {
    this.props.model.on(this.onModelUpdate);
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <NavItem data-tooltip-id="annotationTypeToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_BACK}>
              <NavLink className="flip-back" onClick={() => this.props.onActionClicked(AnnotationTypeFormActions.back)}>
                <Icon src={<Image.arrowback />} /> <small>{this.props.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW}</small>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav navbar={true}>
          <NavItem data-tooltip-id="annotationTypeToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_DELETE}>
            <ActionIcon
              src={<Image.delete_item />}
              enabled={this.state.actionsEnabled}
              onClick={() => this.props.onActionClicked(AnnotationTypeFormActions.delete)}
            />
          </NavItem>
          <NavItem data-tooltip-id="annotationTypeToolbar" data-tooltip-content={this.props.localization.currentLocale.Application.ALERT_SAVE}>
            <ActionIcon src={<Image.save />} enabled={this.state.actionsEnabled} onClick={() => this.props.onActionClicked(AnnotationTypeFormActions.save)} />
          </NavItem>
          {this.props.helpEnabled && <Tooltip id="annotationTypeToolbar" place="bottom" variant="info" className="primaryColoured" />}
        </Nav>
      </Navbar>
    );
  }
}
