export enum LibrayToolbarActions {
  filteringChanged,
}

export class GlobalNote {
  TableId: number;
  TableGuid: string;
  UserId: number;
  VersionId: number;
  Type: number;
  Name: string;
  Note: string;
  Breadcrumb: string;
  LastModified: Date;
  CreationDate: Date;
  IsVisible: boolean;
  FriendlyNoteState: GlobalNoteState;
}
export class GlobalNoteRange {
  TableId: number;
  TableGuid: string;
  GlobalNoteId: number;
  DocumentId: number;
  StartTermIndex: number;
  StartTermCharacterIndex: number;
  EndTermIndex: number;
  EndTermCharacterIndex: number;
}
export enum GlobalNoteState {
  Published,
  InReview,
  Rejected,
}
