import * as React from 'react';
import { Form } from 'react-bootstrap';
import { Button, FormGroup, Input } from 'reactstrap';
import { Status } from 'src/network/Requests';
import * as Messages from 'src/ui/foundation/Messages';
import { LibraryContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import { Loading } from '../Controls';

interface TitleUploadModalProps {
  dismissModal: () => void;
  refreshLibrary: () => void;
  showSubscriptionModal: ()=>void;
}
export const TitleUploadModal = (props: TitleUploadModalProps) => {
  const [isOver, setIsOver] = React.useState(false);
  const [files, setFiles] = React.useState<File[]>([]);
  const [pubName, setBookName] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const handleBookNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookName(event.target.value);
  };
  const context = React.useContext(LibraryContext)!;

  // Define the event handlers
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const reader = new FileReader();

    reader.onloadend = async () => {
      if (reader.result!.valueOf.length > 52428800) {
        setError(context.localization.currentLocale.UserBookUploads.ERROR_FILE_TOO_LARGE);
        setFiles([]);
        setLoading(false);
        return;
      }
      setError("");
      let result = await context.UploadBookRequest({ BookName: pubName, BookBytes: Array.from(new Uint8Array(reader.result as ArrayBuffer)), FileName: files[0].name });
      setLoading(false);

      if(!result.valid()){
        if(result.status === Status.SubscriptionLimitReached){
          Messages.Notify.error(context.localization.currentLocale.UserBookUploads.LABEL_SUBSCRIPTION_MAX);
          props.showSubscriptionModal();
        }else{
          Messages.Notify.error(result.errors[0].Message);
        }
      }

      props.dismissModal();
      props.refreshLibrary();
    };

    reader.onerror = () => {
      setError(context.localization.currentLocale.UserBookUploads.ERROR_ISSUE_READING_FILE);
      setLoading(false);
    };

    reader.readAsArrayBuffer(files[0]);
    return reader;
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 1) {
      setError(context.localization.currentLocale.UserBookUploads.ERROR_ONE_FILE_ONLY);
      return;
    }
    // Use FileReader to read file content
    let file = droppedFiles[0];
    if (
      ["application/epub+zip", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].indexOf(
        file.type
      ) === -1
    ) {
      setError(context.localization.currentLocale.UserBookUploads.ERROR_BAD_EXTENSION);
      return;
    }

    setFiles(droppedFiles);
    setError("");
  };

  return (
    <div className="titleUploadModal">
      <div className="unbound-form-modal" onClick={props.dismissModal} style={{ zIndex: "10" }} />
      <div className="unbound-form-container" style={{ zIndex: "11" }}>
        <Loading isLoading={isLoading} status="Uploading your publication!">
          <Form className="p-2 form-condensed" onSubmit={handleSubmit}>
            <h1>{context.localization.currentLocale.UserBookUploads.LABEL_PUBLICATION_UPLOAD_WIZARD}</h1>
            <p style={{ fontStyle: "italic" }}>
              {context.localization.currentLocale.UserBookUploads.LABEL_EXPLANATION}
            </p>
            <p>{context.localization.currentLocale.UserBookUploads.LABEL_TO_BEGIN}</p>
            <FormGroup>
              <Input placeholder={context.localization.currentLocale.UserBookUploads.LABEL_BOOK_NAME} value={pubName} onChange={handleBookNameChanged} />
            </FormGroup>
            <p>{context.localization.currentLocale.UserBookUploads.LABEL_NEXT}</p>
            {!Convert.isEmptyOrSpaces(error) && <p className="fileUploadError">{error}</p>}
            <div
              className="fileDropper"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                backgroundColor: isOver ? "lightgray" : "white",
              }}
            >
              <span>{context.localization.currentLocale.UserBookUploads.LABEL_DRAG_DROP}</span>
            </div>
            {files.length > 0 && (
              <p className="selectedFile">
                {context.localization.currentLocale.UserBookUploads.LABEL_YOUR_SELECTED_FILE} <span className="selectedFileName">{files[0].name}</span>
              </p>
            )}
            <Button disabled={files.length === 0 || Convert.isEmptyOrSpaces(pubName)} className="uploadSubmit" color="primary" type="submit">
              {context.localization.currentLocale.UserBookUploads.LABEL_SUBMIT}
            </Button>
          </Form>
        </Loading>
      </div>
    </div>
  );
};
