import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { Message } from 'src/chatbot/types/chat';
import { updateConversation } from 'src/chatbot/utils/app/conversation';
import { ProfessorContext, ProfessorContextProps } from 'src/ui/state/professor.context';

import { IconCheck, IconCopy, IconEdit, IconRobot, IconTrash, IconUser } from '@tabler/icons-react';

import { CodeBlock } from '../Markdown/CodeBlock';
import { MemoizedReactMarkdown } from '../Markdown/MemoizedReactMarkdown';
import ChatSources from './ChatSources';

export interface Props {
  message: Message;
  messageIndex: number;
  onEdit?: (editedMessage: Message) => void
}

export const ChatMessage: FC<Props> = memo(({ message, messageIndex, onEdit }) => {
  const { t } = useTranslation('chat');

  const {
    state: { selectedConversation, conversations, messageIsStreaming },
    dispatch: homeDispatch,
  } = useContext(ProfessorContext) as ProfessorContextProps;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState(message.content);
  const [messagedCopied, setMessageCopied] = useState(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageContent(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleEditMessage = () => {
    if (message.content !== messageContent) {
      if (selectedConversation && onEdit) {
        onEdit({ ...message, content: messageContent });
      }
    }
    setIsEditing(false);
  };

  const handleDeleteMessage = () => {
    if (!selectedConversation) return;

    const { messages } = selectedConversation;
    const findIndex = messages.findIndex((elm) => elm === message);

    if (findIndex < 0) return;

    if (
      findIndex < messages.length - 1 &&
      messages[findIndex + 1].role === 'assistant'
    ) {
      messages.splice(findIndex, 2);
    } else {
      messages.splice(findIndex, 1);
    }
    const updatedConversation = {
      ...selectedConversation,
      messages,
    };

    const { single, all } = updateConversation(
      updatedConversation,
      conversations,
    );
    homeDispatch({ field: 'selectedConversation', value: single });
    homeDispatch({ field: 'conversations', value: all });
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isTyping && !e.shiftKey) {
      e.preventDefault();
      handleEditMessage();
    }
  };

  const copyOnClick = async () => {
    if (!navigator.clipboard) return;

    await navigator.clipboard.writeText(message.content).then(() => {
      setMessageCopied(true);
      setTimeout(() => {
        setMessageCopied(false);
      }, 2000);
    });
  };

  useEffect(() => {
    setMessageContent(message.content);
  }, [message.content]);


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [isEditing]);

  return (
    <div
      className={`group md-px-4 ${
        message.role === 'assistant'
          ? 'border-bottom border-secondary bg-light text-secondary dark:border-gray-900/50 dark:bg-[#444654] dark:text-gray-100'
          : 'border-bottom border-secondary bg-white text-secondary dark:border-gray-900/50 dark:bg-[#343541] dark:text-gray-100'
      }`}
      style={{ overflowWrap: 'anywhere' }}
    >
      <div className="position-relative mx-auto d-flex p-4 text-base md-max-w-2x1 md-gap-6 md-py-6 lg:max-w-2x1 lg:px-0 xl-max-w-3xl">
        <div className="min-w-[40px] text-right font-bold">
          {message.role === 'assistant' ? (
            <IconRobot size={30} />
          ) : (
            <IconUser size={30} />
          )}
        </div>

        <div className="prose mt-[-2px] w-100 dark:prose-invert chat">
          {message.role === 'user' ? (
            <div className="d-flex w-100">
              {isEditing ? (
                <div className="d-flex w-100 flex-column">
                  <textarea
                    title={t('Message Content')}
                    ref={textareaRef}
                    className="form-control w-100 no-resize border-0"
                    value={messageContent}
                    onChange={handleInputChange}
                    onKeyDown={handlePressEnter}
                    onCompositionStart={() => setIsTyping(true)}
                    onCompositionEnd={() => setIsTyping(false)}
                    style={{
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                      padding: '0',
                      margin: '0',
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap'
                    }}
                  />

                  <div className="mt-10 d-flex justify-content-center gap-4">
                    <button
                      className="btn btn-primary btn-sm rounded-md px-4 py-1 font-weight-medium"
                      onClick={handleEditMessage}
                      disabled={messageContent.trim().length <= 0}
                    >
                      {t('Save & Submit')}
                    </button>
                    <button
                      className="btn btn-sm rounded-md border border-secondary px-4 py-1 font-weight-medium btn-hover-light dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800"
                      onClick={() => {
                        setMessageContent(message.content);
                        setIsEditing(false);
                      }}
                    >
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              ) : (
                // <div className="prose whitespace-pre-wrap dark:prose-invert flex-1">
                //   {message.content}
                // </div>
                <div>
                <div>
                  {message.content}
                </div>
                </div>
              )}

              {!isEditing && (
                <div className="md-mr-8 ml-1 md-ml-0 d-flex flex-column flex-md-row gap-4 gap-md-1 align-items-center align-items-md-start justify-content-end justify-content-md-start">
                  <button
                    title={t('Edit')}
                    className="invisible btn btn-link group-hover:visible focus:visible text-secondary hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    onClick={toggleEditing}
                  >
                    <IconEdit size={20} />
                  </button>
                  <button
                    title={t('Delete')}
                    className="invisible group-hover:visible focus:visible text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    onClick={handleDeleteMessage}
                  >
                    <IconTrash size={20} />
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="d-flex flex-row">
                <MemoizedReactMarkdown
                  className="prose dark:prose-invert flex-1"
                  remarkPlugins={[remarkGfm, remarkMath]}
                  rehypePlugins={[rehypeMathjax]}
                  components={{
                    code: ({ inline, className, children, ...props }) => {
                      if (children.length) {
                        if (children[0] === '▍') {
                          return <span className="animate-pulse cursor-default mt-1">▍</span>
                        }
                    
                        children[0] = (children[0] as string).replace("`▍`", "▍")
                      }
                    
                      const match = /language-(\w+)/.exec(className || '');
                    
                      return !inline ? (
                        <CodeBlock
                          key={Math.random()}
                          language={(match && match[1]) || ''}
                          value={String(children).replace(/\n$/, '')}
                          {...props}
                        />
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    }
                  }
                }
                >
                  {`${message.content}${
                    messageIsStreaming && messageIndex === (selectedConversation?.messages.length ?? 0) - 1 ? '`▍`' : ''
                  }`}
                </MemoizedReactMarkdown>

                <div className="md-mr-8 ml-1 md-ml-0 d-flex flex-column md-flex-row gap-4 md-gap-1 align-items-center align-items-md-start justify-content-end justify-content-md-start">
                  {messagedCopied ? (
                    <IconCheck
                      size={20}
                      className="text-secondary text-green-500 dark:text-green-400"
                    />
                  ) : (
                    <button
                      title={t('Copy')}
                      className="invisible group-hover:visible focus:visible text-secondary hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                      onClick={() => void copyOnClick()}
                    >
                      <IconCopy size={20} />
                    </button>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row">
                <ChatSources sources={message.sources} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
ChatMessage.displayName = 'ChatMessage';
