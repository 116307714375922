import { resolve } from 'promise';
import { Conversation, Source } from 'src/chatbot/types/chat';
import { ImmutableTable, ITable, Single, Table } from 'src/collections/Observable';
import { Locale } from 'src/localization/Locale';
import { Log } from 'src/Logger';
import {
    ContentSegment, ExtendedHeader, FlattenTocNode, FormattedSearchField, FormattedSearchForm,
    NavigationRequest, SearchHeader, SearchSorting, TocNode
} from 'src/models/Content';
import { CogniflowMessage, ResultsCogniflowMessage } from 'src/models/dto/CogniflowRequest';
import { IId } from 'src/models/Interfaces';
import { VersionMeta, VersionTask } from 'src/models/Library';
import {
    Action, Annotation, AnnotationType, Favourite, FormType, HistoryType, UserFormSubmission,
    UserFormSubmissionViewModel
} from 'src/models/UserContent';
import { Wire } from 'src/network/Wire';
import { Communication } from 'src/utilities/Helpers';
import * as _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

import { ChatRequestProps } from '../chatbot/services/useApiService';
import { EmitterEvent, EventHandler, LoadingReport } from '../utilities/Events';
import { AppSettings, LibraryConfig, LibrarySession, LoginType } from './AppSession';
import { ChatPayload } from './Chat';
import { ILogInfo, ILogInfoR } from './dto/AppRequest';
import * as ContentRequest from './dto/ContentRequest';
import { ILibraryCloseVersion } from './dto/LibraryRequest';
import * as ProfessorRequest from './dto/ProfessorRequest';
import * as UCRequest from './dto/UCRequest';
import {
    ExternalLinkingError, ExternalLinkingPayload, ExternalLinkingSection, ICreateExternalLink,
    ICreateExternalLinkR, IReportExternalLink, IReportExternalLinkR
} from './ExternalLinking';
import { Resource } from './Resource';
import { ActionResult } from './Result';

export class SearchTask {
  result: ContentRequest.ISearchExecuteSearchR;
}

export enum BookLoadingSteps {
  favourites = "favourites",
  annotationTypes = "annotationTypes",
  annotations = "annotations",
  tableOfContents = "tableOfContents",
  actions = "actions",
  search = "search",
  content = "content",
  community = "community",
  receiptDialog = "receiptDialog",
  // professor = "professor",
}
export enum SearchFailure {
  InvalidQuery,
  NoResults,
}
export enum NavigationInitiator {
  actionItem = "actionItem",
  annotation = "annotation",
  favourite = "favourite",
  link = "link",
  scrubber = "scrubber",
  search = "search",
  toc = "TOC",
  tip = "Tip",
  professor = "Professor",
}
export interface IContentPermissions {
  CanPrint: boolean;
  CanCopy: boolean;
}

export interface IClientSideActionItem {
  headId: number;
}

export class Book implements IId {
  static readonly loadingSteps: BookLoadingSteps[] = [
    BookLoadingSteps.favourites,
    BookLoadingSteps.annotationTypes,
    BookLoadingSteps.annotations,
    BookLoadingSteps.actions,
    BookLoadingSteps.tableOfContents,
    BookLoadingSteps.search,
    BookLoadingSteps.community,
    BookLoadingSteps.content,
    // BookLoadingSteps.professor,
  ];

  id: number;
  name: string;
  wire: Wire;
  splash: number[];
  loading: LoadingReport;
  taskUpdate: EventHandler<VersionTask>;
  contentNavigationRequested: EventHandler<ContentRequest.INavigationRequest>;
  userFormSubmissionRequested: EventHandler<number>;
  searchFailed: EventHandler<SearchFailure>;
  locationInBookChanged: EventHandler<number>;
  annotationContentClicked: EventHandler<number[]>;
  globalNoteContentClicked: EventHandler<number[]>;
  favouriteContentClicked: EventHandler<number[]>;
  communityContentClicked: EventHandler<number[]>;
  multiAnnoFaveContentClicked: EventHandler<number[][]>;
  reloadAllSegmentsRequired: EventHandler<any>;
  searchRemotelyExecuted: EventHandler<any>;
  printRemotelyExecuted: EventHandler<any>;
  searchCancelled: EventHandler<any>;
  historyPanelRequested: EventHandler<HistoryType>;
  communityEstablished: EventHandler<any>;
  stepLoading: EventHandler<BookLoadingSteps>;
  resources: Resource;
  onUCSyncRequested: EventHandler<number>;
  onBookInternalClosureRequested: EventHandler<ILibraryCloseVersion>;
  displayedHead: number;
  displayedSpine: number;
  navigationActionsAfterBookOpen: Single<IClientSideActionItem[]>;
  localization: Locale;
  appSettings: Single<AppSettings>;
  searchFilteringChanged: EventHandler<boolean>;
  javascript: string;
  style: string;
  shouldCollapseMenu: EventHandler<NavigationInitiator>;
  shouldLimitRendering: boolean;
  isActiveTab: boolean;
  libraryConfig: LibraryConfig;
  appBrandKey: string;
  externalLinkingParseFailed: EventHandler<ExternalLinkingPayload>;
  private loginTypeNotifier: Single<LoginType>;
  contentPermissions: IContentPermissions;
  versionInfo: VersionMeta | null;
  parentContext:LibrarySession;
  // Community status:
  community: UCRequest.ISetCommunityValuesR;

  chatResponseReceived: EventHandler<ChatPayload>;
  chatSocketId: string;
  text: string;
  chunkValue: string;
  updatedConversation: Conversation;
  isFirst : boolean;
  docSources: Source[];

  constructor(
    id: number,
    wire: Wire,
    taskUpdate: EventHandler<VersionTask>,
    localization: Locale,
    appSettings: Single<AppSettings>,
    libConf: LibraryConfig,
    brandKey: string,
    loginTypeNotifier: Single<LoginType>,
    permissions: IContentPermissions,
    versionInfo: VersionMeta | null,
    parentContext: LibrarySession
  ) {
    this.id = id;
    this.wire = wire;
    this.versionInfo = versionInfo;
    this.name = versionInfo ? versionInfo.Title : "";
    this.localization = localization;
    this.appSettings = appSettings;
    this.libraryConfig = libConf;
    this.loginTypeNotifier = loginTypeNotifier;
    this.parentContext = parentContext;
    this.wire.addRequestTransform((request) => {
      request.bookId = this.id;
      return request;
    });
    this.taskUpdate = taskUpdate;
    this.loading = new LoadingReport();
    for (const step of Book.loadingSteps) {
      this.loading.setStart(step);
    }
    this.contentPermissions = permissions;
    this.appBrandKey = brandKey;
    this.favourites = new Table<Favourite>((i: Favourite) => i.Id);
    this.userFormSubmissions = new Table<UserFormSubmissionViewModel>((i: UserFormSubmissionViewModel) => i.Submission.TableGuid);
    this.annotationTypes = new Table<AnnotationType>((i: AnnotationType) => i.Id);
    this.annotations = new Table<Annotation>((i: Annotation) => i.Id);
    this.globalNotes = new Table<UCRequest.IGlobalNoteViewModel>((i: UCRequest.IGlobalNoteViewModel) => i.Note.TableId);
    this.tocRoot = new Table<TocNode>((i: TocNode) => i.Current.Id);
    this.tocHeaderToSpine = new Map<number, number>();
    this.tocSpineIdToHeader = new Table<FlattenTocNode>((i: FlattenTocNode) => i.Current.SpineId);
    this.tocHeaderIdToHeader = new Map<number, FlattenTocNode>();
    this.tocSortedHeaderSpineIds = [];
    this.favouriteFolders = new Single<string[]>(new Array<string>());
    this.contentNavigationRequested = new EventHandler<ContentRequest.INavigationRequest>();
    this.userFormSubmissionRequested = new EventHandler<number>();
    this.locationInBookChanged = new EventHandler<number>();
    this.currentFilter = new Set<number>();
    this.currentResults = new Table<ExtendedHeader>((i: ExtendedHeader) => i.Id);
    this.currentUserFormResults = new Table<UserFormSubmissionViewModel>((i: UserFormSubmissionViewModel) => i.Submission.TableId);
    this.currentTocResults = new ImmutableTable<ExtendedHeader>((i: ExtendedHeader) => i.Id);
    this.currentSuggestions = new Table<string>((i: string) => i);
    this.searchFailed = new EventHandler<SearchFailure>();
    this.annotationContentClicked = new EventHandler<number[]>();
    this.globalNoteContentClicked = new EventHandler<number[]>();
    this.favouriteContentClicked = new EventHandler<number[]>();
    this.multiAnnoFaveContentClicked = new EventHandler<number[][]>();
    this.reloadAllSegmentsRequired = new EventHandler<any>();
    this.searchRemotelyExecuted = new EventHandler<any>();
    this.printRemotelyExecuted = new EventHandler<any>();
    this.searchCancelled = new EventHandler<any>();
    this.historyPanelRequested = new EventHandler<HistoryType>();
    this.communityEstablished = new EventHandler<any>();
    this.stepLoading = new EventHandler<BookLoadingSteps>();
    this.actionsChanged = new EventHandler<string>();
    this.externalLinkingParseFailed = new EventHandler<ExternalLinkingPayload>();
    this.resources = new Resource(Wire.shield(this.wire), this.id);
    this.onUCSyncRequested = new EventHandler<number>();
    this.onBookInternalClosureRequested = new EventHandler<ILibraryCloseVersion>();
    this.navigationActionsAfterBookOpen = new Single<IClientSideActionItem[]>([]);
    this.synchronizeUC = this.synchronizeUC.bind(this);
    this.searchFilteringChanged = new EventHandler<boolean>();
    this.shouldCollapseMenu = new EventHandler<NavigationInitiator>();
    this.community = { IsBulletinsAvailable: false, IsFeedbackAvailable: false, IsTipsAvailable: false };
    this.chatResponseReceived = new EventHandler<ChatPayload>();
    this.docSources = [];
  }

  load() {
    if (this.loading.isLoaded() || this.loading.isLoading()) {
      return;
    }
    for (const step of Book.loadingSteps) {
      this.loading.setLoading(step);
    }
  }

  synchronizeUC(item: any, event: EmitterEvent) {
    if (event !== EmitterEvent.action) {
      this.onUCSyncRequested.dispatch(this.id, this);
    }
  }
  get loginType() {
    return this.loginTypeNotifier.get();
  }
  // begin TOC
  tocRoot: Table<TocNode>;
  tocHeaderToSpine: Map<number, number>;
  tocHeaderIdToHeader: Map<number, FlattenTocNode>;
  tocSpineIdToHeader: Table<FlattenTocNode>; // Map for faster lookup than in tocRoot tree
  tocSortedHeaderSpineIds: number[];
  headertoSpine: Map<number, number>;
  spineToHeader: Map<number, number>;
  async initTOC() {
    return await Communication.handleResponse<ContentRequest.ITocGetInitialR>(
      this.wire,
      "tableOfContents/getInitial",
      {} as ContentRequest.ITocGetInitial,
      (data) => {
        this.tocRoot.insertOrUpdateAll(data.rootNodes);

        const allTocHeaders = this.flattenTocRoot();
        this.tocSpineIdToHeader.insertOrUpdateAll(allTocHeaders);
        this.tocHeaderIdToHeader = new Map(allTocHeaders.map((header) => [header.Current.Id, header]));
        this.tocHeaderToSpine = new Map(allTocHeaders.map((header) => [header.Current.Id, header.Current.SpineId]));
        this.tocSortedHeaderSpineIds = allTocHeaders.map((header) => header.Current.SpineId).sort((a, b) => a - b);
      }
    );
  }

  async convertPersistentDoc(persistentDoc: string): Promise<ActionResult<ContentRequest.IConvertPersistentDocR>> {
    return await Communication.handleResponse<ContentRequest.IConvertPersistentDocR>(
      this.wire,
      "content/convertPersistentDoc",
      {
        PersistentDoc: persistentDoc,
      } as ContentRequest.IConvertPersistentDoc,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async headExists(request: ContentRequest.IHeadExists): Promise<ActionResult<ContentRequest.IHeadExistsR>> {
    return await Communication.handleResponse<ContentRequest.IHeadExistsR>(this.wire, "content/headExists", request, (data, result) => {
      result.data = data;
    });
  }
  async handleExternalLinkPayload(payload: ExternalLinkingPayload) {
    if (payload.ParseError !== ExternalLinkingError.NONE) {
      this.externalLinkingParseFailed.dispatch(payload, this);
    }
    if (!payload.PayloadDict.get(ExternalLinkingSection.ACTION)) {
      return;
    }
    // Block for content navigation links.
    if (payload.PayloadDict.get(ExternalLinkingSection.ACTION) === "nav") {
      let head = -1;
      let endHead = -1;
      if (payload.PayloadDict.get(ExternalLinkingSection.NAV_DOC_ID)) {
        head = +payload.PayloadDict.get(ExternalLinkingSection.NAV_DOC_ID)!;
        // If head was directly in the URL, check it exists. If not, present proper messaging.
        if (!(await this.headExists({ HeadId: head })).data.Exists) {
          payload.ParseError = ExternalLinkingError.BAD_DESTINATION;
          this.externalLinkingParseFailed.dispatch(payload, this);
          return;
        }
      } else if (payload.PayloadDict.get(ExternalLinkingSection.NAV_ID)) {
        let result = await this.convertPersistentDoc(payload.PayloadDict.get(ExternalLinkingSection.NAV_ID)!);
        // If there's a Selector, let's try it as a direct nav.
        if (result.data.HeadId === -1 && !payload.PayloadDict.get(ExternalLinkingSection.NAV_SELECTOR)) {
          payload.ParseError = ExternalLinkingError.BAD_DESTINATION;
          this.externalLinkingParseFailed.dispatch(payload, this);
          return;
        }
        head = result.data.HeadId;
      }
      if (payload.PayloadDict.get(ExternalLinkingSection.NAV_END_DOC_ID)) {
        // If the first doc was a persistent ID, the end one will be as well.
        // If it isn't, the end won't be so just cast.
        if (!payload.PayloadDict.get(ExternalLinkingSection.NAV_ID)) {
          endHead = +payload.PayloadDict.get(ExternalLinkingSection.NAV_END_DOC_ID)!;
        } else {
          let result = await this.convertPersistentDoc(payload.PayloadDict.get(ExternalLinkingSection.NAV_END_DOC_ID)!);
          if (result.data.HeadId === -1) {
            payload.ParseError = ExternalLinkingError.BAD_DESTINATION;
            this.externalLinkingParseFailed.dispatch(payload, this);
            return;
          }
          endHead = result.data.HeadId;
        }
      } else {
        endHead = head;
        // If head was directly in the URL, check it exists. If not, present proper messaging.
        if (!(await this.headExists({ HeadId: endHead })).data.Exists && !payload.PayloadDict.get(ExternalLinkingSection.NAV_SELECTOR)) {
          payload.ParseError = ExternalLinkingError.BAD_DESTINATION;
          this.externalLinkingParseFailed.dispatch(payload, this);
          return;
        }
      }

      // Heads have been set after this point. We move to the navigation.

      // This navigate is for a link containing a range to briefly highlight.
      if (payload.PayloadDict.get(ExternalLinkingSection.TERM_START_INDEX) && payload.PayloadDict.get(ExternalLinkingSection.TERM_END_INDEX)) {
        await this.contentNavigation(
          NavigationRequest.toRange(
            head,
            +payload.PayloadDict.get(ExternalLinkingSection.TERM_START_INDEX)!,
            endHead,
            +payload.PayloadDict.get(ExternalLinkingSection.TERM_END_INDEX)!
          ),
          NavigationInitiator.link
        );
      }
      // Selector navigation link.
      else if (payload.PayloadDict.get(ExternalLinkingSection.NAV_SELECTOR)) {
        await this.contentNavigation(NavigationRequest.toSelector(head, payload.PayloadDict.get(ExternalLinkingSection.NAV_SELECTOR)!), NavigationInitiator.link);
      }
      // Standard head navigation link.
      else {
        await this.contentNavigation(NavigationRequest.toHeader(head), NavigationInitiator.link);
      }
    }
    // This link is a search query link. Search will be run.
    else if (payload.PayloadDict.get(ExternalLinkingSection.ACTION) === "search") {
      this.searchRemotelyExecuted.dispatch(payload.PayloadDict.get(ExternalLinkingSection.SEARCH_QUERY), this);
      await this.executeSearch({ Filter: [], Query: payload.PayloadDict.get(ExternalLinkingSection.SEARCH_QUERY)!, IncludeCommunity: true });
    }
  }
  private flattenTocRoot(): FlattenTocNode[] {
    const flattenedTocRoot: FlattenTocNode[] = [];
    const nodeStack: TocNode[] = this.tocRoot.rows();

    let currentNode: TocNode;
    while (nodeStack.length > 0) {
      currentNode = nodeStack.pop()!;
      flattenedTocRoot.push({
        Children: currentNode.Children.map((child) => child.Current),
        Current: currentNode.Current,
        Depth: currentNode.Depth,
      });
      nodeStack.push(...currentNode.Children);
    }

    return flattenedTocRoot;
  }
  // end TOC
  async logInfo(request: ILogInfo): Promise<ActionResult> {
    return await Communication.handleResponse<ILogInfoR>(this.wire, "application/logInfo", request, () => {});
  }
  // begin AnnotationTypes
  annotationTypes: Table<AnnotationType>;
  async initAnnotationTypes() {
    return await Communication.handleResponse<UCRequest.IAnnotationTypeGetAllR>(
      this.wire,
      "annotationTypes/getAll",
      {} as UCRequest.IAnnotationTypeGetAll,
      (data) => {
        this.annotationTypes.insertOrUpdateAll(data.AnnotationTypes);
        // Add UC Sync listener after init.
        this.annotationTypes.addListener(this.synchronizeUC);
      }
    );
  }
  async createAnnotationType(request: UCRequest.IAnnotationTypeCreate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationTypeCreateR>(
      this.wire,
      "annotationTypes/create",
      {
        Colour: request.Colour,
        Name: request.Name,
      } as UCRequest.IAnnotationTypeCreate,
      (data) => {
        this.annotationTypes.insertOrUpdate(data.AnnotationType);
      }
    );
  }
  async deleteAnnotationType(request: UCRequest.IAnnotationTypeDelete): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationTypeDeleteR>(
      this.wire,
      "annotationTypes/delete",
      {
        Id: request.Id,
      } as UCRequest.IAnnotationTypeDelete,
      () => {
        this.annotations
          .rows()
          .filter((item) => item.AnnotationTypeId === request.Id)
          .map((item) => {
            // Emit an action event while the item is defined so annotations in the content panel get purged.
            this.annotations.emit(item, EmitterEvent.action);
            // Suppress emission of deletions here. Backend has already deleted the associated
            // annotations. This prevents spamming UC Sync requests needlessly.
            this.annotations.deleteById(item.Id, true);
          });

        // Then emit an action event again so that the annotation list panel can update with the new state
        this.annotations.emit(undefined, EmitterEvent.action);

        this.annotationTypes.deleteById(request.Id);
      }
    );
  }
  async updateAnnotationType(request: UCRequest.IAnnotationTypeUpdate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationTypeUpdateR>(
      this.wire,
      "annotationTypes/update",
      {
        Id: request.Id,
        Colour: request.Colour,
        Name: request.Name,
      } as UCRequest.IAnnotationTypeUpdate,
      (data) => {
        this.annotations
          .rows()
          .filter((item) => item.AnnotationTypeId === data.AnnotationType.Id)
          .map((item) => {
            // Have annotations re-render themselves in the content panel, but not fire a Sync. The annotation itself hasn't changed.
            this.annotations.emit(item, EmitterEvent.action);
          });
        // this.reloadAllSegmentsRequired.dispatch(null, this);
        this.annotationTypes.insertOrUpdate(data.AnnotationType);
        // This will reload the annotations panel with the cascading changes.
        this.annotations.emit(undefined, EmitterEvent.update);
      }
    );
  }
  // end AnnotationTypes

  // begin Annotations
  annotations: Table<Annotation>;
    globalNotes: Table<UCRequest.IGlobalNoteViewModel>;
  async initAnnotations() {
    await this.getGlobalNotesForVersion({});
    return Communication.handleResponse<UCRequest.IAnnotationGetAllR>(this.wire, "annotations/getAll", {} as UCRequest.IAnnotationGetAll, (data) => {
      this.annotations.insertOrUpdateAll(data.Annotations);
      // Add UC Sync listener after init.
      this.annotations.addListener(this.synchronizeUC);
    });
  }
  async createAnnotation(request: UCRequest.IAnnotationCreate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationCreateR>(
      this.wire,
      "annotations/create",
      {
        FirstDocId: request.FirstDocId,
        FirstOffset: request.FirstOffset,
        FirstWordCount: request.FirstWordCount,
        SecondDocId: request.SecondDocId,
        SecondOffset: request.SecondOffset,
        SecondWordCount: request.SecondWordCount,
        AnnotationTypeId: request.AnnotationTypeId,
        Note: request.Note,
      } as UCRequest.IAnnotationCreate,
      (data) => {
        this.annotations.insertOrUpdate(data.Annotation);
      }
    );
  }
  async deleteAnnotation(request: UCRequest.IAnnotationDelete): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationDeleteR>(
      this.wire,
      "annotations/delete",
      {
        Id: request.Id,
      } as UCRequest.IAnnotationDelete,
      () => {
        this.annotations.deleteById(request.Id);
      }
    );
  }
  async deleteAnnotations(request: UCRequest.IAnnotationDeleteMany): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationDeleteManyR>(
      this.wire,
      "annotations/deleteManyAnnotations",
      {
        AnnotationIDs: request.AnnotationIDs,
      } as UCRequest.IAnnotationDeleteMany,
      () => {
        request.AnnotationIDs.map((it) => {
          let anno = this.annotations.get(it);
          // Emit an action event while the item is defined so annotations in the content panel get purged.
          this.annotations.emit(anno, EmitterEvent.action);
          // Suppress emission of deletions here. Backend has already deleted the associated
          // annotations. This prevents spamming UC Sync requests needlessly and avoids duplicate messaging.
          this.annotations.deleteById(it, true);
        });
        // Then emit an action event again so that the annotation list panel can update with the new state
        this.annotations.emit(undefined, EmitterEvent.action);

        this.synchronizeUC(null, EmitterEvent.delete);
      }
    );
  }
  async updateAnnotation(request: UCRequest.IAnnotationUpdate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IAnnotationUpdateR>(
      this.wire,
      "annotations/update",
      {
        Id: request.Id,
        Note: request.Note,
        AnnotationTypeId: request.AnnotationTypeId,
      } as UCRequest.IAnnotationUpdate,
      (data) => {
        this.annotations.insertOrUpdate(data.Annotation);
      }
    );
  }
  // end Annotations
  // begin Global Notes
  async putGlobalNote(request: UCRequest.IGlobalNoteCreate): Promise<ActionResult> {
    return Communication.handleResponse<UCRequest.IGlobalNoteViewModel>(
      this.wire,
      "globalNotes/putGlobalNote",
      {
        FirstDocId: request.FirstDocId,
        FirstOffset: request.FirstOffset,
        FirstWordCount: request.FirstWordCount,
        SecondDocId: request.SecondDocId,
        SecondOffset: request.SecondOffset,
        SecondWordCount: request.SecondWordCount,
        Note: request.Note,
      } as UCRequest.IGlobalNoteCreate,
      () => {
      }
    );
  }
  async getGlobalNotesForVersion(request: UCRequest.IGetGlobalNotes): Promise<ActionResult>{
    return Communication.handleResponse<UCRequest.IGetGlobalNotesR>(this.wire, "globalNotes/getGlobalNotes", request, (data) => {
      this.globalNotes.insertOrUpdateAll(data.Notes);
    });
  }
  // end Global Notes
  // begin Favourites
  favouriteFolders: Single<string[]>;
  favourites: Table<Favourite>;

  async initFavourites() {
    this.favourites.addListener(this.updateFolderList.bind(this));

    return await Communication.handleResponse<UCRequest.IFavouriteGetAllR>(this.wire, "favourites/getAll", {} as UCRequest.IFavouriteGetAll, (data) => {
      this.favourites.insertOrUpdateAll(data.Favourites);
      // Add change listeners so when the UC changes a UC Sync is automatically triggered.
      this.favourites.addListener(this.synchronizeUC);
    });
  }

  private updateFolderList() {
    let folders = new Array<string>();
    this.favourites.rows().map((f: Favourite) => (f.Folder !== "" && f.Folder !== null ? folders.push(f.Folder) : ""));
    this.favouriteFolders.set(_.unique(folders));
  }
  async getUCPreview(request: UCRequest.IGetUCPreview): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IGetUCPreviewR>(this.wire, "content/getUCPreview", request, (data, result) => {
      result.data = data;
    });
  }
  async updateFavourite(request: UCRequest.IFavouriteUpdate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IFavouriteUpdateR>(
      this.wire,
      "favourites/update",
      {
        Id: request.Id,
        Title: request.Title,
        Folder: request.Folder,
      } as UCRequest.IFavouriteUpdate,
      (data) => {
        this.favourites.insertOrUpdate(data.Favourite);
      }
    );
  }

  async deleteFavourite(request: UCRequest.IFavouriteDelete): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IFavouriteDeleteR>(
      this.wire,
      "favourites/delete",
      {
        Id: request.Id,
      } as UCRequest.IFavouriteDelete,
      () => {
        this.favourites.deleteById(request.Id);
      }
    );
    }
  async deleteHistory(): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IActionDeleteR>(
      this.wire,
      "actions/resource/delete",
      {
        VersionId: this.id
      } as UCRequest.IActionDelete,
      () => {
        
      }
    );
  }

    async deleteFavourites(request: UCRequest.IFavouriteDeleteMany): Promise<ActionResult> {
        return await Communication.handleResponse<UCRequest.IFavouriteDeleteManyR>(
            this.wire,
            "favourites/deleteManyFavourites",
            {
                FavouriteIDs: request.FavouriteIDs,
            } as UCRequest.IFavouriteDeleteMany,
            () => {
                request.FavouriteIDs.map((it) => {
                    let favo = this.favourites.get(it);
                    // Emit an action event while the item is defined so favourites in the content panel get purged.
                    this.favourites.emit(favo, EmitterEvent.action);
                    // Suppress emission of deletions here. Backend has already deleted the associated
                    // favourites. This prevents spamming UC Sync requests needlessly and avoids duplicate messaging.
                    this.favourites.deleteById(it, true);
                });
                // Then emit an action event again so that the favourite list panel can update with the new state
                this.favourites.emit(undefined, EmitterEvent.action);

                this.synchronizeUC(null, EmitterEvent.delete);
            }
        );
    }

  async createFavourite(request: UCRequest.IFavouriteCreate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IFavouriteCreateR>(
      this.wire,
      "favourites/create",
      {
        DocId: request.DocId,
        YOffset: request.YOffset,
        YDocHeight: request.YDocHeight,
        Title: request.Title,
        Folder: request.Folder,
      } as UCRequest.IFavouriteCreate,
      (data) => {
        this.favourites.insertOrUpdate(data.Favourite);
      }
    );
  }

  // end Favourites

  // region UserFormSubmissions
  userFormSubmissions: Table<UserFormSubmissionViewModel>;
  async initUserFormSubmissions() {
    return await Communication.handleResponse<UCRequest.UserFormSubmissionsGetAllR>(
      this.wire,
      "userFormSubmissions/getAll",
      {} as UCRequest.UserFormSubmissionsGetAll,
      (data) => {
        this.userFormSubmissions.insertOrUpdateAll(data.Submissions);
      }
    );
  }
  async getAttachments(request: UCRequest.UserFormSubmissionsGetAttachments): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.UserFormSubmissionsGetAttachmentsR>(
      this.wire,
      "userFormSubmissions/getAttachments",
      request,
      (data, result) => {
        result.data = data.Attachments;
      }
    );
  }
  async downloadAttachmentNative(request: UCRequest.UserFormSubmissionsDownloadAttachmentNative): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.UserFormSubmissionsDownloadAttachmentNativeR>(
      this.wire,
      "userFormSubmissions/downloadAttachmentNative",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  // end UserFormSubmissions

  // region Actions
  async initActions() {
    const result: ActionResult<Action[]> = await this.getRecentActions(HistoryType.Unspecified);
    if (result.valid() && result.data.length > 0) {
      // Get the last navigation action and save its headId.
      let lastNavigationAction: Action | undefined;
      for (const action of result.data) {
        if (action.Type !== HistoryType.ExternalWebsite && action.Type !== HistoryType.Search) {
          lastNavigationAction = action;
          break;
        }
      }

      if (lastNavigationAction) {
        this.appendNavigationActionsAfterBookOpen({ headId: lastNavigationAction.ObjectId });
      }
    }
  }

  actionsChanged: EventHandler<string>;
  async fetchActions(flowRequest: CogniflowMessage, scope: HistoryType): Promise<ActionResult<CogniflowMessage<Action>>> {
    return await Communication.handleResponse<CogniflowMessage<Action>>(
      this.wire,
      "actions/flow",
      {
        ...flowRequest,
        Scope: scope,
      },
      (data, result) => {
        result.data = data;
      }
    );
  }

  async getRecentActions(scope: HistoryType): Promise<ActionResult<Action[]>> {
    return await Communication.handleResponse<UCRequest.IActionGetRecentR>(
      this.wire,
      "actions/getRecent",
      {
        Scope: scope,
      } as UCRequest.IActionGetRecent,
      (data, result) => {
        result.data = data.Actions;
      },
      () => {
        this.onBookInternalClosureRequested.dispatch({ VersionId: this.id, RemoveVersion: true }, this);
      }
    );
  }

  saveBookLocation(percentage = 0): Promise<any> {
    // If the user has opened a title but not tabbed to it yet. Don't bother saving an invalid history item.
    if (this.displayedHead === null || !this.displayedHead) {
      return resolve();
    }
    return this.saveAction("actions/appClosure/create", { headId: this.displayedHead, percentage }, false);
  }

  saveLinkNavigation(headId: number): Promise<any> {
    this.appendNavigationActionsAfterBookOpen({ headId });
    return this.saveAction("actions/linkNavigation/create", { headId });
  }

  saveTocNavigation(headId: number): Promise<any> {
    this.appendNavigationActionsAfterBookOpen({ headId });
    return this.saveAction("actions/tocNavigation/create", { headId });
  }

  saveSearchNavigation(headId: number): Promise<any> {
    this.appendNavigationActionsAfterBookOpen({ headId });
    return this.saveAction("actions/searchResultNavigation/create", { headId });
  }

  saveScrubberNavigation(headId: number): Promise<any> {
    this.appendNavigationActionsAfterBookOpen({ headId });
    return this.saveAction("actions/scrubberNavigation/create", { headId });
  }

  async saveResourceNavigation(resourceId: number): Promise<any> {
    this.appendNavigationActionsAfterBookOpen({ headId: resourceId });
    return await this.saveAction("actions/resource/create", { resourceId });
  }

  saveSearchPerformed(query: string, filter: number[]): Promise<any> {
    return this.saveAction("actions/searchPerformed/create", { query, filter: JSON.stringify(filter) });
  }

  private async saveAction(url: string, payload: any, dispatch = true) : Promise<ActionResult<any>> {
    return await Communication.handleResponse<UCRequest.IActionCreateR>(
      this.wire,
      url,
      payload,
      () => {
        if (dispatch) {
          this.actionsChanged.dispatch("", this);
        }
      },
      (error) => void(async () => {
        await Log.error(`Could not save action item from url ${url}: `, JSON.stringify(error));
      })()
    );
  }

  private appendNavigationActionsAfterBookOpen(navAction: IClientSideActionItem) {
    const actionItemsAfterBookOpen = this.navigationActionsAfterBookOpen.get();
    actionItemsAfterBookOpen.push(navAction);
    this.navigationActionsAfterBookOpen.set(actionItemsAfterBookOpen);
  }
  // endregion

  // region Search
  currentFilter: Set<number>;
  currentResults: Table<ExtendedHeader>;
  currentUserFormResults: Table<UserFormSubmissionViewModel>;
  currentTocResults: ITable<ExtendedHeader>;
  currentSuggestions: Table<string>;
  async executeSearch(request: ContentRequest.ISearchExecuteSearch, saveAction = true) : Promise<ActionResult<any>>
  {
    return await Communication.handleResponse<ContentRequest.ISearchExecuteSearchR>(this.wire, "search/executeSearch", request, (data) => void(async () => {
      this.registerSearch(data.SearchId);

      if (saveAction) {
        await this.saveSearchPerformed(request.Query, request.Filter);
      }
    })());
  }
  lastIndex = -1;
  async getSuggestions(request: ContentRequest.ISearchGetSuggestions): Promise<ActionResult<ContentRequest.ISearchGetSuggestionsR>> {
    this.lastIndex += 1;
    request.CommunicationIndex = this.lastIndex;
    return await Communication.handleResponse<ContentRequest.ISearchGetSuggestionsR>(this.wire, "search/getSuggestions", request, (data, result) => {
      if (this.lastIndex === data.CommunicationIndex) {
        result.data = data;
      } else {
        result.data = null;
      }
    });
  }
  async getSuggestionsForForm(request: ContentRequest.ISearchGetSuggestions): Promise<ActionResult> {
    this.lastIndex += 1;
    request.CommunicationIndex = this.lastIndex;
    return await Communication.handleResponse<ContentRequest.ISearchGetSuggestionsR>(this.wire, "search/getSuggestions", request, (data, result) => {
      result.data = data.Suggestions;
    });
  }

  registerSearch(search: string) {
    Communication.handleSocket<ContentRequest.ISearchExecuteSocketResponse>(
      this.wire,
      search,
      (message) => {
        this.currentResults.deleteAll();
        this.currentResults.insertOrUpdateAll([new ExtendedHeader()]);
        this.currentTocResults.deleteAll();
        this.currentTocResults.insertOrUpdateAll(Object.values(message.TocResults));
        this.currentUserFormResults.deleteAll();
        // this.currentUserFormResults.insertOrUpdateAll([new UserFormSubmissionViewModel()]);
        this.reloadAllSegmentsRequired.dispatch("", this);
        if (message.Results.length === 0 && message.UserFormSubmissionResults.length === 0) {
          this.searchFailed.dispatch(SearchFailure.NoResults, this);
          this.currentResults.deleteAll();
          this.currentTocResults.deleteAll();
          this.currentUserFormResults.deleteAll();
        }
      },
      () => {},
      () => {
        // TODO: Better error feedback than "no results found"
        this.currentResults.deleteAll();
        this.currentUserFormResults.deleteAll();
        this.currentTocResults.deleteAll();
        this.searchFailed.dispatch(SearchFailure.InvalidQuery, this);
      },
      500
    );
  }

  async getFirstResults(searchSorting: SearchSorting): Promise<ActionResult<SearchHeader[]>> {
    return await Communication.handleResponse<ContentRequest.ISearchGetFirstResultsR>(
      this.wire,
      "search/getFirstResults",
      { SearchSorting: searchSorting } as ContentRequest.ISearchGetFirstResults,
      (data, result) => {
        result.data = data.Results;
      },
      (result) => {
        result.data = [];
      }
    );
  }

  async fetchResults(flowRequest: ResultsCogniflowMessage, searchSorting: SearchSorting): Promise<ActionResult<ResultsCogniflowMessage<SearchHeader>>> {
    flowRequest.SearchSorting = searchSorting;
    return await Communication.handleResponse<ResultsCogniflowMessage<Action>>(
      this.wire,
      "search/flow",
      {
        ...flowRequest,
      },
      (data, result) => {
        result.data = data;
      }
    );
  }

  async cancelSearch(): Promise<ActionResult> {
    return await Communication.handleResponse<ContentRequest.ISearchCancelR>(this.wire, "search/cancel", {} as ContentRequest.ISearchCancel, () => {
      this.currentResults.deleteAll();
      this.currentTocResults.deleteAll();
      this.currentSuggestions.deleteAll();
      this.currentUserFormResults.deleteAll();
      this.reloadAllSegmentsRequired.dispatch("", this);
      this.searchCancelled.dispatch("", this);
    });
  }

  async queryCheck(query: string, includeCommunity: boolean): Promise<ActionResult<boolean>> {
    return await Communication.handleResponse<ContentRequest.ISearchQueryCheckR>(
      this.wire,
      "search/queryCheck",
      { Query: query, IncludeCommunity: includeCommunity} as ContentRequest.ISearchQueryCheck,
      (data, result) => {
        result.data = data.IsValid;
      }
    );
  }

  searchFields: FormattedSearchField[];
  searchForms: FormattedSearchForm[];
  async initSearch() {
    return await Communication.handleResponse<ContentRequest.ISearchGetFieldsR>(this.wire, "search/getFields", {} as ContentRequest.ISearchGetFields, (data) => {
      this.searchFields = data.Fields;
      this.searchForms = data.Forms;
    });
  }
  // endregion

  // region Content
  async fetchCogniflowSegments(flowRequest: CogniflowMessage): Promise<ActionResult<CogniflowMessage<ContentSegment>>> {
    return await Communication.handleResponse<CogniflowMessage<ContentSegment>>(
      this.wire,
      "content/flow",
      {
        ...flowRequest,
      },
      (data, result) => {
        result.data = data;
      }
    );
  }

  async getContentSegments(request: ContentRequest.IGetContentSegments): Promise<ActionResult<ContentSegment[]>> {
    return await Communication.handleResponse<ContentRequest.IGetContentSegmentsR>(this.wire, "content/getContentSegments", request, (data, result) => {
      result.data = data.Segments;
    });
  }

  async getContentLastPosition(): Promise<ActionResult<ContentRequest.IContentNavigationR>> {
    return await Communication.handleResponse<ContentRequest.IContentNavigationR>(this.wire, "content/getLastPosition", {}, (data, result) => {
      result.data = data;
    });
  }

  async contentNavigation(request: ContentRequest.INavigationRequest, initiator: NavigationInitiator) {
    if (request.HeadId === -1) {
      request.HeadId = this.displayedHead;
    }
    this.contentNavigationRequested.dispatch(request, this);

    switch (initiator) {
      case NavigationInitiator.link:
        await this.saveLinkNavigation(request.HeadId);
        break;
      case NavigationInitiator.search:
        await this.saveSearchNavigation(request.HeadId);
        break;
      case NavigationInitiator.scrubber:
        await this.saveScrubberNavigation(request.HeadId);
        break;
      case NavigationInitiator.toc:
        await this.saveTocNavigation(request.HeadId);
        break;
      default:
        break;
    }

    this.displayedHead = request.HeadId;
  }

  async navigate(request: ContentRequest.INavigationRequest): Promise<ActionResult> {
    return await Communication.handleResponse<ContentRequest.IContentNavigationR>(
      this.wire,
      "content/navigate",
      {
        ...request,
      },
      (data, result) => {
        result.data = data;
      }
    );
  }
  // region External links
  async reportExternalLink(request: IReportExternalLink): Promise<ActionResult> {
    return await Communication.handleResponse<IReportExternalLinkR>(this.wire, "application/reportExternalLink", request, () => {});
  }
  async createExternalLink(request: ICreateExternalLink): Promise<ActionResult<ICreateExternalLinkR>> {
    return await Communication.handleResponse<ICreateExternalLinkR>(this.wire, "content/createExternalLink", request, (data, result) => {
      result.data = data;
    });
  }
  // endRegion

  /**
   * Change the current location of the user in the book. The difference between this and `contentNavigation`
   * is that this one is for scroll events in the book while contentNavigation is for big jumps which requires
   * to reload a part of the book.
   *
   * @param newTopmostSpine The current spine that is displayed at the top of the user's screen.
   */
  locationChanged(newTopmostSpine: number, newTopmostHead: number) {
    // Saving the displayed header id first.
    this.displayedSpine = newTopmostSpine;
    this.displayedHead = newTopmostHead;
    // Then, navigating to the spine.
    this.locationInBookChanged.dispatch(newTopmostSpine, this);
  }
  // endregion

  // region Resource
  async requestResource(data: string): Promise<string> {
    let res = await this.resources.openResource(data);
    await this.saveResourceNavigation(parseInt(data, 10));
    return res;
  }

  async openExternalWebsite(externalWebsiteUrl: string, pageTitle: string) {
    await this.resources.openExternalWebsite(externalWebsiteUrl);
    return await this.saveAction("actions/externalWebsite/create", { externalWebsiteUrl, pageTitle });
  }
  // endregion

  // region Print/Copy
  async printSelectionChanged(request: ContentRequest.IPrintSelectionChanged): Promise<ActionResult<ContentRequest.IPrintSelectionChangedR>> {
    return await Communication.handleResponse<ContentRequest.IPrintSelectionChangedR>(this.wire, "print/selectionChanged", request, (data, result) => {
      result.data = data;
    });
  }
  async printSegments(request: ContentRequest.ISegmentsPrint): Promise<ActionResult<ContentRequest.ISegmentsPrintR>> {
    return await Communication.handleResponse<ContentRequest.ISegmentsPrintR>(this.wire, "print/segmentsPrint", request, (data, result) => {
      result.data = data;
    });
  }
  async printSelection(request: ContentRequest.ISelectionPrint): Promise<ActionResult<ContentRequest.ISelectionPrintR>> {
    return await Communication.handleResponse<ContentRequest.ISelectionPrintR>(this.wire, "print/selectionPrint", request, (data, result) => {
      result.data = data;
    });
  }
  async requestCopy(request: ContentRequest.ICopyRequested): Promise<ActionResult<ContentRequest.ICopyRequestedR>> {
    return await Communication.handleResponse<ContentRequest.ICopyRequestedR>(this.wire, "print/copyRequested", request, (data, result) => {
      result.data = data;
    });
  }
  async requestPrintSelection(request: ContentRequest.IPrintSelectionRequest): Promise<ActionResult<ContentRequest.IPrintSelectionRequestR>> {
    return await Communication.handleResponse<ContentRequest.IPrintSelectionRequestR>(this.wire, "print/printSelectionRequested", request, (data, result) => {
      result.data = data;
    });
  }
  async printAnnotations(request: ContentRequest.IPrintAnnotations): Promise<ActionResult<ContentRequest.IPrintAnnotationsR>> {
    return await Communication.handleResponse<ContentRequest.IPrintAnnotationsR>(this.wire, "print/printAnnotations", request, (data, result) => {
      result.data = data;
    });
    }

  async printFavourites(request: ContentRequest.IPrintFavourites): Promise<ActionResult<ContentRequest.IPrintFavouritesR>> {
    return await Communication.handleResponse<ContentRequest.IPrintFavouritesR>(this.wire, "print/printFavourites", request, (data, result) => {
        result.data = data;
    });
}

  async printSearches(request: ContentRequest.IPrintSearches): Promise<ActionResult<ContentRequest.IPrintSearchesR>> {
    return await Communication.handleResponse<ContentRequest.IPrintSearchesR>(this.wire, "print/printSearches", request, (data, result) => {
      result.data = data;
    });
  }

  async requestAnnotationPrintPreviews(request: UCRequest.IRequestAnnotationPrintPreviews): Promise<ActionResult<UCRequest.IRequestAnnotationPrintPreviewsR>> {
    return await Communication.handleResponse<UCRequest.IRequestAnnotationPrintPreviewsR>(
      this.wire,
      "print/requestAnnotationPrintPreviews",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async requestFavouritePrintPreviews(request: UCRequest.IRequestFavouritePrintPreviews): Promise<ActionResult<UCRequest.IRequestFavouritePrintPreviewsR>> {
        return await Communication.handleResponse<UCRequest.IRequestFavouritePrintPreviewsR>(
            this.wire,
            "print/requestFavouritePrintPreviews",
            request,
            (data, result) => {
                result.data = data;
            }
        );
  }
  async requestSearchPrintPreviews(request: UCRequest.IRequestSearchPrintPreviews): Promise<ActionResult<UCRequest.IRequestSearchPrintPreviewsR>> {
    return await Communication.handleResponse<UCRequest.IRequestSearchPrintPreviewsR>(this.wire, "print/requestSearchPrintPreviews", request, (data, result) => {
      result.data = data;
    });
  }
  // endregion

  // region UserForms
  tips: Table<UserFormSubmissionViewModel>;
  async createUserForm(request: UCRequest.IUserFormCreate): Promise<ActionResult> {
    return await Communication.handleResponse<UCRequest.IUserFormCreateR>(this.wire, "userFormSubmissions/create", request, (data, result) => {
      result.data = data;
    });
  }

  async getTipDefinition() {
    let testTip = new UserFormSubmissionViewModel();
    testTip.Submission = new UserFormSubmission();
    let result = await this.getActiveUserFormDefinition({ FormType: 0 });
    testTip.Definition = result.data?.UserFormDefinition;
    testTip.Submission.Submission = ``;
    return testTip;
  }

  async getActiveUserFormDefinition(request: UCRequest.IGetActiveTipDefinition): Promise<ActionResult<UCRequest.IGetActiveTipDefinitionR>> {
    return await Communication.handleResponse<UCRequest.IGetActiveTipDefinitionR>(
      this.wire,
      "userFormSubmissions/getActiveUserFormDefinition",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async getUserFormSubmission(request: UCRequest.IGetUserFormSubmission): Promise<ActionResult<UCRequest.IGetUserFormSubmissionR>> {
    return await Communication.handleResponse<UCRequest.IGetUserFormSubmissionR>(
      this.wire,
      "userFormSubmissions/getUserFormSubmission",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }

  async getNextCriticalSubmission(userFormSubmissionRef: string): Promise<ActionResult<UCRequest.IGetNextCriticalUserFormSubmissionR>> {
    return await Communication.handleResponse<UCRequest.IGetNextCriticalUserFormSubmissionR>(
      this.wire,
      "userFormSubmissions/getNextCriticalSubmission",
      { UserFormSubmissionRef: userFormSubmissionRef } as UCRequest.IGetNextCriticalUserFormSubmission,
      (data, result) => {
        result.data = data;
      },
      () => {}
    );
  }

  async setCommunityValues(): Promise<ActionResult<UCRequest.ISetCommunityValuesR>> {
    return await Communication.handleResponse<UCRequest.ISetCommunityValuesR>(
      this.wire,
      "userFormSubmissions/setCommunityValues",
      {},
      (data) => {
        this.community = data;
      },
      () => {}
    );
  }

  async getFeedbackDefinition() {
    let testFeedback = new UserFormSubmissionViewModel();
    testFeedback.Submission = new UserFormSubmission();
    let result = await this.getActiveUserFormDefinition({ FormType: FormType.Feedback });
    testFeedback.Definition = result.data?.UserFormDefinition;
    testFeedback.Submission.Submission = ``;
    return testFeedback;
  }
  // endregion

  // region Titles
  async getTitleInfo(request: UCRequest.IGetTitleInfo): Promise<ActionResult<UCRequest.IGetTitleInfoR>> {
    return await Communication.handleResponse<UCRequest.IGetTitleInfoR>(this.wire, "library/getTitleInfo", request, (data, result) => {
      result.data = data;
    });
  }
  
  async getSocketChat(params: ChatRequestProps
    ): Promise<ActionResult<ProfessorRequest.IProfessorChatR>> {
      // let socketId = uuidv4();
      const chatRequest: ProfessorRequest.IProfessorChat = params.chatRequest;
      chatRequest.SocketId = this.chatSocketId;
      return Communication.handleResponse<ProfessorRequest.IProfessorChatR>(
        this.wire,
        "professor/socketChat",
        chatRequest,
        (data, result) => {
          result.data = data;
          // this.registerChat(data.SocketId);
        },
        (result) => {
          result.data = [];
        }
      );
    }
    
    registerChat(
      /* socketId: string */
      ) {
        this.chatSocketId = uuidv4();
        Communication.handleSocket<ChatPayload>(
          this.wire,
          this.chatSocketId,
      (message) => {
        this.chatResponseReceived.dispatch(message, this);
      },
      () => {
        console.log("disconnect");
      },
      () => {
        console.log("error");
      },
      1000
    );
  }
  // endregion
}
