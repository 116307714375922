import * as React from 'react';
import { LibrarySession } from 'src/models/AppSession';
import { LibraryContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/ReaderModels';
import { DataItem, DataRow, DataTable } from '../DataTable';
import { Action, IBatch, INode, IRequest, IResponse } from '../StandaloneCogniflow';

interface ITitleSelectorProps {
  associatedTitles: Models.ITitle[];
  onChange: (newTitles: Models.ITitle[]) => void;
}

export class TitleSelector extends React.Component<ITitleSelectorProps> {
  context: LibrarySession;
  static contextType = LibraryContext;
  associatedTitlesTable = React.createRef<DataTable>();
  availableTitlesTable = React.createRef<DataTable>();
  constructor(props: ITitleSelectorProps | Readonly<ITitleSelectorProps>) {
    super(props);
    this.state = {
      associatedTitles: this.props.associatedTitles,
    };
  }
  // #region Titles
  private generateTitle = (node: INode) => {
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} className="" key={1} value={node.TitleName} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.changeTitleAttribution} />;
  };

  componentDidUpdate(prevProps: ITitleSelectorProps) {
    if (this.props.associatedTitles.length === 1) {
      this.associatedTitlesTable.current!.reload();
      this.availableTitlesTable.current!.reload();
    }

    if (prevProps.associatedTitles.length !== this.props.associatedTitles.length) {
      this.associatedTitlesTable.current!.reload();
      this.availableTitlesTable.current!.reload();
    }
  }
  private changeTitleAttribution = (node: INode) => {
    // Click is in the Associated Titles and should be removed.
    let holder: Models.ITitle[] = [];
    holder = JSON.parse(JSON.stringify(this.props.associatedTitles));
    if (this.props.associatedTitles.some((x) => x.TitleRef === node.TitleRef)) {
      const index = this.props.associatedTitles.indexOf(node as Models.ITitle);
      if (index > -1) {
        holder.splice(index, 1);
      }
    }
    // Click is in the AvailableTitles and should be added
    else {
      holder.push(node as Models.ITitle);
    }
    this.props.onChange(holder);
  };

  private initializeLocalTitles = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.props.associatedTitles;
      if (result === null) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });

  private availableTitleFlowProvider = (request: IRequest): Promise<IResponse> => 
    this.context.flowTitles({
        FlowRequest: request.Batches[0],
        ExcludeTitles: this.props.associatedTitles.map((x) => x.TitleRef),
        HideInactiveTitles: true!,
      }).then((result) => {
        if (result.valid()) {
          return { Batches: [Convert.indexify(result.data.FlowResponse)] } as IResponse;
        } else {
          throw new Error("Result was not valid.");
        }
    });

    private initializeAvailableTitles = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
      new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
        if (this.props.associatedTitles === null) {
          reject();
          return;
        }
        this.context.flowTitles({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          ExcludeTitles: this.props.associatedTitles.map((x) => x.TitleRef),
          HideInactiveTitles: true!,
        }).then(result => {
          if (result.valid()) {
            resolve({
              nodes: Convert.indexify(result.data.FlowResponse).Nodes,
              targetSpine: 0,
            });
          } else {
            reject();
          }
        }).catch(reject);
      });
  private localTitlesFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });

  private productQueryExecute = (query: string) => {
    this.availableTitlesTable.current!.reload(query);
  };
  // #endregion

  render(): React.ReactNode {
    let setting = JSON.parse(JSON.stringify(Models.genericDataSettings));
    return (
      <div className="full-width full-height productTitles">
        <p>
          Select titles that should be associated with this user form. Click a title on the top to remove the association and click a title on the bottom to add
          the association.
        </p>
        <span className="tableTitle">Associated titles</span>
        <div className="titleTables">
          <div className="associatedTitles">
            <DataTable
              tableClassName="full-height"
              headers={["Title"]}
              headerFlexes={[2]}
              flowProvider={this.localTitlesFlowProvider}
              initializeFlowProvider={this.initializeLocalTitles}
              objectBuilder={this.generateTitle}
              ref={this.associatedTitlesTable}
              settingsOverride={setting}
            />
          </div>
          <span className="tableTitle">Available titles</span>
          <div className="availableTitles">
            <DataTable
              tableClassName="full-height"
              headers={["Title"]}
              headerFlexes={[2]}
              flowProvider={this.availableTitleFlowProvider}
              initializeFlowProvider={this.initializeAvailableTitles}
              objectBuilder={this.generateTitle}
              ref={this.availableTitlesTable}
              settingsOverride={setting}
              searchQueryComitted={this.productQueryExecute}
            />
          </div>
        </div>
      </div>
    );
  }
}
