// based on https://github.com/Tyson-Skiba/react-callback/blob/main/index.tsx, but the npm package has peer dependencies react 16.2.0 for some reason, which is not compatible with our version of react.
import React from 'react';

type Callback = (() => void) | undefined;

interface CallbackProps {
  callback?: Callback;
  children: React.ReactChild | React.ReactNodeArray;
  isBody: boolean;
}

const onMount = (element: HTMLDivElement | HTMLLinkElement | null, callback: Callback) => {
  if (callback) callback();
  if (element && element.parentElement) element.parentElement.removeChild(element);
};

export const CallbackWrapper: React.FC<CallbackProps> = ({ callback, children, isBody }) => (
  <>
    {children}
    {isBody ? (
      <div ref={(element) => onMount(element, callback)} style={{ display: "none" }} />
    ) : (
      <link ref={(element) => onMount(element, callback)} style={{ display: "none" }} />
    )}
  </>
);
