import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import { Collapse, Nav, Navbar, NavItem } from 'reactstrap';
import { ITable } from 'src/collections/Observable';
import { Log } from 'src/Logger';
import { FontSizes, LoginType } from 'src/models/AppSession';
import {
    Book, BookLoadingSteps, IClientSideActionItem, NavigationInitiator
} from 'src/models/Book';
import { GlobalNote } from 'src/models/Community';
import {
    ContentPopupModel, ContentSegment, ExtendedHeader, HitDirection, NavigationRequest,
    NavigationRequestType
} from 'src/models/Content';
import { CogniflowMessage } from 'src/models/dto/CogniflowRequest';
import { IContentNavigationR, INavigationRequest } from 'src/models/dto/ContentRequest';
import { IGlobalNoteViewModel } from 'src/models/dto/UCRequest';
import { ActionResult } from 'src/models/Result';
import {
    Annotation, AnnotationType, Favourite, UserFormSubmissionViewModel
} from 'src/models/UserContent';
import { Status } from 'src/network/Requests';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';
import * as _ from 'underscore';

import { Image } from '../foundation/Assets';
import {
    ContextAnnotationTypeMenuItem, ContextMenu, ContextMenuActions, ContextMenuItem
} from '../foundation/ContextMenu';
import { ActionIcon, ConcreteContentSegment, ContentPopup, Icon } from '../foundation/Controls';
import * as Messages from '../foundation/Messages';
import { PrintModalControl } from '../foundation/PrintModalControl';
import { ScrollBar } from '../foundation/ScrollBar';
import { Scrubber } from '../foundation/Scrubber';
import { SelectionHandler } from '../foundation/Selection';
import {
    ICogniflowNavigationResponse, ICogniflowOptionalSettings, INode, IRequest, IResponse,
    StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { StandaloneCogniflowFrameContainer } from '../foundation/StandaloneCogniflowFrame';
import { UserContentCreateDialog } from '../foundation/UserContentCreateDialog';
import { UserContentMultiSelector } from '../foundation/UserContentMultiSelector';
import { BookContext } from '../state/Contextes';
import { BookViewToggle } from './BookView';
import { MediaPanelModal } from './MediaPanelModal';
import { ScrollNavigationModal } from './ScrollNavigationModal';
import { ContentResultToolbar } from './SearchView';

const content_css = require("!!raw-loader!src/assets/css/content.css").default;
const content_jquery = require("!!raw-loader!jquery").default;
const content_api = require("!!raw-loader!src/assets/js/ContentAPI.js").default;
const content_mathJax = require("!!raw-loader!src/assets/js/tex-mml-svg.js").default;
const content_place = require("!!raw-loader!src/assets/js/Place.js").default;

export class ContentView extends React.Component {
  private readerRef = React.createRef<ContentReader>();
  render() {
    return (
      <div
        onBlur={() => {
          if (this.readerRef.current) {
            this.readerRef.current.hideContextMenu();
          }
        }}
        className="content-view full-height d-flex flex-column"
      >
        <ContentToolbar contentReader={this.readerRef} />
        <div className="flex-fill bg-void d-flex flex-column" style={{ height: "100%" }}>
          <ContentReader ref={this.readerRef} />
        </div>
      </div>
    );
  }
}

interface IContentToolbarState {
  navigationActionItemIndex: number;
  navigationActions: IClientSideActionItem[];
  searchActive: boolean;
  currentHead: number;
  isReading: boolean;
  isPaused: boolean;
  rate: number;
}
interface IContentToolbarProps {
  contentReader: React.RefObject<ContentReader>;
}

export class ContentToolbar extends React.Component<IContentToolbarProps, IContentToolbarState> {
  context: Book;
  static contextType = BookContext;
  utterance: SpeechSynthesisUtterance;

  constructor(props: IContentToolbarProps) {
    super(props);
    this.updateActionItems = this.updateActionItems.bind(this);
    this.navigateToPreviousAction = this.navigateToPreviousAction.bind(this);
    this.navigateToNextAction = this.navigateToNextAction.bind(this);
    this.navigateToAction = this.navigateToAction.bind(this);
    this.onNextHit = this.onNextHit.bind(this);
    this.onPreviousHit = this.onPreviousHit.bind(this);
    this.onSearchCancelled = this.onSearchCancelled.bind(this);
    this.checkResults = this.checkResults.bind(this);
    this.beginReading = this.beginReading.bind(this);
    this.stopReading = this.stopReading.bind(this);
    this.pauseReading = this.pauseReading.bind(this);
    this.resumeReading = this.resumeReading.bind(this);
    this.readingSegment = this.readingSegment.bind(this);
    this.onReadEnd = this.onReadEnd.bind(this);
    this.backwardReading = this.backwardReading.bind(this);
    this.forwardReading = this.forwardReading.bind(this);
    this.state = { navigationActionItemIndex: 0, navigationActions: [], searchActive: false, currentHead: 0, isReading: false, isPaused: false, rate: 1, };
  }

  componentDidMount() {
    this.context.navigationActionsAfterBookOpen.addListener(this.updateActionItems);
    this.context.currentResults.addListener(this.checkResults);
  }

  componentWillUnmount() {
    this.context.navigationActionsAfterBookOpen.removeListener(this.updateActionItems);
  }

  checkResults() {
    this.setState({ searchActive: this.context.currentResults.rows().length > 0 });
  }

  private updateActionItems() {
    const navigationActions = this.context.navigationActionsAfterBookOpen.get();

    this.setState({
      navigationActionItemIndex: navigationActions.length - 1,
      navigationActions,
    });
  }

  private async navigateToPreviousAction() {
    if (this.state.navigationActionItemIndex > 0) {
      await this.navigateToAction(this.state.navigationActionItemIndex - 1);
    }
  }

  private async navigateToNextAction() {
    if (this.state.navigationActionItemIndex < this.state.navigationActions.length - 1) {
      await this.navigateToAction(this.state.navigationActionItemIndex + 1);
    }
  }

  private async navigateToAction(newIndex: number) {
    const action = this.state.navigationActions[newIndex];

    if (!action) {
      return;
    }

    this.setState({ navigationActionItemIndex: newIndex });
    await this.context.contentNavigation(NavigationRequest.toHeader(action.headId), NavigationInitiator.actionItem);
  }

  private beginReading()
  {
    if (!this.state.isReading) {
      this.setState((prevState) => ({
        isReading: !prevState.isReading,
      }));
      this.readingSegment(this.state.rate);
    }
  }

  readingSegment( speed: number ) {
    this.setState(() => ({
      currentHead: this.context.displayedHead,
    }));
    let currentSegment = this.props.contentReader.current!.getSpineText(this.state.currentHead);
    void this.props.contentReader.current?.currentReadingDocChanged(this.state.currentHead + 1, this.state.currentHead);
    this.utterance = new SpeechSynthesisUtterance(currentSegment);  
    this.utterance.rate = speed;
    speechSynthesis.speak(this.utterance);
    this.utterance.onend = this.onReadEnd;
  }

  stopReading() {
    speechSynthesis.cancel();
    void this.props.contentReader.current?.readingStopped();
    this.setState((prevState) => ({
      isReading: !prevState.isReading,
      rate: 1,
      isPaused: false,
    }));
  }

  pauseReading() {
    speechSynthesis.pause();
    this.setState(() => ({
      isPaused: true,
    }));
  }

  resumeReading() {
    if (this.state.currentHead === this.context.displayedHead) {
      speechSynthesis.resume();
    } else {
      speechSynthesis.cancel();
      this.readingSegment(this.state.rate);
    }
    this.setState(() => ({
      isPaused: false,
    }));
  }

  backwardReading() {
    if (this.state.rate <= 1) {
      this.setState((prevState) => ({
        rate: prevState.rate - 0.2,
      }));
    } else {
      this.setState((prevState) => ({
        rate: prevState.rate - 1,
      }));
    }
    speechSynthesis.cancel();
    this.readingSegment(this.state.rate);
  }

  forwardReading() {
    if (this.state.rate < 1) {
      this.setState((prevState) => ({
        rate: prevState.rate + 0.2,
      }));
    } else {
      this.setState((prevState) => ({
        rate: prevState.rate + 1,
      }));
    }
    speechSynthesis.cancel();
    this.readingSegment(this.state.rate);
  }

  onReadEnd = async () => {
    await this.props.contentReader.current!.content.cogniflow.navigateToNode(this.context.displayedSpine + 1);
    this.readingSegment(this.state.rate);
  }

 async onSearchCancelled() {
    await this.context.cancelSearch();
  }
  

  async onNextHit() {
    await this.context.contentNavigation(NavigationRequest.toHit(this.context.displayedHead, HitDirection.NextHit), NavigationInitiator.search);
  }
  async onPreviousHit() {
    await this.context.contentNavigation(NavigationRequest.toHit(this.context.displayedHead, HitDirection.PreviousHit), NavigationInitiator.search);
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <div className="bookview-toggle-placeholder">
            <BookViewToggle
              helpEnabled={this.context.appSettings.get().HelpEnabled}
              localization={this.context.localization}
              active={true}
              onToggle={() => {}}
              isPlaceholder={true}
            />
          </div>
          <Nav navbar={true}>
            <NavItem data-tooltip-id="historyNav" data-tooltip-content={this.context.localization.currentLocale.ContentView.LABEL_STEP_BACK}>
              <ActionIcon onClick={() => void this.navigateToPreviousAction()} src={<Image.arrowprev />} enabled={this.state.navigationActionItemIndex > 0} />
            </NavItem>
            <NavItem data-tooltip-id="historyNav" data-tooltip-content={this.context.localization.currentLocale.ContentView.LABEL_STEP_FORWARD}>
              <ActionIcon
                onClick={() => void this.navigateToNextAction()}
                src={<Image.arrownext />}
                enabled={this.state.navigationActionItemIndex < this.state.navigationActions.length - 1}
              />
            </NavItem>
            {!this.state.isReading && false && (
              <NavItem data-tooltip-id="historyNav" data-tooltip-content={"Start Reading"}>
                <ActionIcon
                  onClick={ this.beginReading }
                  src={<Image.reading />}
                />
              </NavItem>
            )}
            {this.state.isReading && false && this.state.isPaused && (
              <NavItem data-tooltip-id="historyNav" data-tooltip-content={"Pause Reading"}>
                <ActionIcon
                  onClick={this.resumeReading}
                  src={<Image.play />}
                />
              </NavItem>
            )}
            {this.state.isReading && false && !this.state.isPaused && (
              <NavItem data-tooltip-id="historyNav" data-tooltip-content={"Start Reading"}>
                <ActionIcon
                  onClick={this.pauseReading}
                  src={<Image.pause />}
                />
              </NavItem>
            )}
            {this.state.isReading && false && (
              <NavItem data-tooltip-id="historyNav" data-tooltip-content={"Stop Reading"}>
                <ActionIcon
                  onClick={this.stopReading}
                  src={<Image.stop />}
                />
              </NavItem>
            )}
            {this.state.isReading && false && (this.state.rate >= 0.3) && (
              <NavItem data-tooltip-id="historyNav" data-tooltip-content={"Lower the speed"}>
                <ActionIcon
                  onClick={this.backwardReading}
                  src={<Image.backward />}
                />
              </NavItem>
            )}
            {this.state.isReading && false && (this.state.rate <= 9) && (
              <NavItem data-tooltip-id="historyNav" data-tooltip-content={"Higher the speed"}>
                <ActionIcon
                  onClick={this.forwardReading}
                  src={<Image.forward />}
                />
              </NavItem>
            )}
            {this.context.appSettings && this.context.appSettings.get().HelpEnabled && (
              <Tooltip id="historyNav" place="bottom" variant="info" className="primaryColoured" />
            )}
          </Nav>
          <ContentResultToolbar
            hasResults={this.state.searchActive}
            localization={this.context.localization}
            helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
            onNextHitClicked={() => void this.onNextHit()}
            onPreviousHitClicked={() => void this.onPreviousHit()}
            onSearchCancelled={() => void this.onSearchCancelled()}
          />
        </Collapse>
      </Navbar>
    );
  }
}

export interface IContentReaderProps {}

export interface IContentReaderState {
  ready: boolean;
  quickAnnotationTypes: AnnotationType[];
  tocHitResults?: ITable<ExtendedHeader>;
  showScrollModal: boolean;
  scrollSpine: number;
  /** The topmost spine that the user currently see. */
  displayedSpine: number;
}

export class ContentReader extends React.Component<IContentReaderProps, IContentReaderState> {
  context: Book;
  static contextType = BookContext;
  containerDiv: HTMLElement;
  content: ContentHandler;
  selection: SelectionHandler;
  flowFrame?: JSX.Element;
  lastRequest?: INavigationRequest;
  private currentReadingDoc: number;
  private annotationCreateDialog = React.createRef<UserContentCreateDialog>();
  private userContentMultiSelector = React.createRef<UserContentMultiSelector>();
  private printModal = React.createRef<PrintModalControl>();
  private mediaPanelModal = React.createRef<MediaPanelModal>();
  private contextMenu = React.createRef<ContextMenu>();
  private scrubber = React.createRef<Scrubber>();
  private scrollbar = React.createRef<ScrollBar>();
  private shareInput = React.createRef<HTMLTextAreaElement>();
  private shareDiv = React.createRef<HTMLDivElement>();
  private flowRef = React.createRef<StandaloneCogniflowFrameContainer>();
  constructor(props: IContentReaderProps | Readonly<IContentReaderProps>) {
    super(props);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.extendedGenerateSegment = this.extendedGenerateSegment.bind(this);
    this.buildPopup = this.buildPopup.bind(this);
    this.insertedSegmentCallback = this.insertedSegmentCallback.bind(this);
    this.onReady = this.onReady.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.onNavigationRequested = this.onNavigationRequested.bind(this);
    this.favesChanged = this.favesChanged.bind(this);
    this.annosChanged = this.annosChanged.bind(this);
    this.searchUpdate = this.searchUpdate.bind(this);
    this.actionClicked = this.actionClicked.bind(this);
    this.showMultiUCSelector = this.showMultiUCSelector.bind(this);
    this.scrollChanged = this.scrollChanged.bind(this);
    this.onArrowUp = this.onArrowUp.bind(this);
    this.onArrowDown = this.onArrowDown.bind(this);
    this.openNavigationModal = this.openNavigationModal.bind(this);
    this.onScrollFromCogniflow = this.onScrollFromCogniflow.bind(this);
    this.onScrollNavigationRequested = this.onScrollNavigationRequested.bind(this);
    this.onScrollNavigationCanceled = this.onScrollNavigationCanceled.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.updateTypes = this.updateTypes.bind(this);
    this.reloadAllSegments = this.reloadAllSegments.bind(this);
    this.quickCreateAnnotation = this.quickCreateAnnotation.bind(this);
    this.updateAppSettings = this.updateAppSettings.bind(this);
    this.createUserContentItem = this.createUserContentItem.bind(this);
    this.showMultiUCSelector = this.showMultiUCSelector.bind(this);
    this.checkKeys = this.checkKeys.bind(this);
    this.printRequested = this.printRequested.bind(this);
    this.specialNavToSelector = this.specialNavToSelector.bind(this);
    this.setInnerReady = this.setInnerReady.bind(this);
    this.hideContextMenu = this.hideContextMenu.bind(this);
    this.handleCopying = this.handleCopying.bind(this);
    this.readingStopped = this.readingStopped.bind(this);
    this.currentReadingDocChanged = this.currentReadingDocChanged.bind(this);

    this.state = {
      ready: false,
      quickAnnotationTypes: [],
      showScrollModal: false,
      scrollSpine: 1,
      displayedSpine: 1,
    };
  }
  scripts: any;
  head: any;
  componentDidMount() {
    this.context.appSettings.addListener(this.updateAppSettings);
    this.context.loading.stepLoading.on(BookLoadingSteps.content, () => void this.onReady());
    this.context.contentNavigationRequested.on((r) => void this.onNavigationRequested(r));
    this.context.locationInBookChanged.on(this.scrollTo);
    this.context.annotationTypes.addListener(this.updateTypes);
    this.context.multiAnnoFaveContentClicked.on((ids) => void this.showMultiUCSelector(ids));
    this.context.printRemotelyExecuted.on(() => void this.printRequested());

    this.setState({ tocHitResults: this.context.currentTocResults });
  }

  componentWillUnmount() {
    this.context.appSettings.removeListener(this.updateAppSettings);
    this.context.loading.stepLoading.off(BookLoadingSteps.content, () => void this.onReady());
    this.context.contentNavigationRequested.off((r) => void this.onNavigationRequested(r));
    this.context.locationInBookChanged.off(this.scrollTo);
    this.context.favourites.removeListener((val) => void this.favesChanged(val));
    this.context.annotations.removeListener((val) => void this.annosChanged(val));
    this.context.currentTocResults.removeListener(this.searchUpdate);
    this.context.multiAnnoFaveContentClicked.off((ids) => void this.showMultiUCSelector(ids));
    this.context.reloadAllSegmentsRequired.off(this.reloadAllSegments);
    this.context.printRemotelyExecuted.off(() => void this.printRequested());
  }

  getCurrentHeadText = () => (this.content.cogniflow.getNodeBySecondary(this.context.displayedHead) as ContentSegment).Content.replace(/<[^>]*>?/gm, '').replace(/  +/g, ' ').replace(/(\r\n|\n|\r)/gm, "");

  getHeadText = (headId : number) => (this.content.cogniflow.getNodeBySecondary(headId) as ContentSegment).Content.replace(/<[^>]*>?/gm, '').replace(/  +/g, ' ').replace(/(\r\n|\n|\r)/gm, "");

  getSpineText = (spineId : number) => (this.content.cogniflow.getNodeByMain(spineId) as ContentSegment).Content.replace(/<[^>]*>?/gm, '').replace(/  +/g, ' ').replace(/(\r\n|\n|\r)/gm, "");

async printRequested() {
    if (ResourcePlatform.CanPrint()) {
      await this.actionClicked(ContextMenuActions.print);
    }
  }
  async showMultiUCSelector(IDs: number[][]) {
    if (Convert.isEmptyOrSpaces((await this.selection.getSelectionText()).selTxt)) {
      this.userContentMultiSelector.current!.show(IDs[0], IDs[1], IDs[2]);
    }
  }

  async currentReadingDocChanged(newReadingDocId: number, oldReadingDocId: number){
    this.currentReadingDoc = oldReadingDocId;
    await this.segmentChanged([this.currentReadingDoc, newReadingDocId]);
  }

  async readingStopped(){
    if(this.currentReadingDoc === null){
      return;
    }
    await this.segmentChanged([this.currentReadingDoc]);
    this.currentReadingDoc = -1;
  }

  updateTypes() {
    let items = this.context.annotationTypes
      .rows()
      .slice()
      .sort((a, b) => new Date(b.LastUpdate).getTime() - new Date(a.LastUpdate).getTime());
    this.setState({
      quickAnnotationTypes: items.slice(0, 3),
    });
  }

  hideContextMenu() {
    if (this.contextMenu.current) {
      this.contextMenu.current.contextMenu.hide();
    }
  }

  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.content, this);
    let result = await this.context.resources.getBookAssets();
    if (result.valid()) {
      this.context.style = result.data.stylesheet;
      this.context.javascript = result.data.javascript;
      this.scripts = [
        {
          src: content_jquery,
          head: true,
        },
        {
          src: content_place,
          head: true,
        },
        {
          src: content_api,
          head: true,
        },
        {
          src: this.context.javascript,
          head: false,
        },
        { src: content_mathJax, head: true },
      ];
      this.head = (
        <React.Fragment>
          <style>{content_css}</style>
          <style>{this.context.style}</style>
        </React.Fragment>
      );
      // this.context.loading.setLoaded(BookLoadingSteps.content);
    }
    this.context.favourites.addListener((val) => void this.favesChanged(val));
    this.context.annotations.addListener((val) => void this.annosChanged(val));
    this.context.globalNotes.addListener(() => this.globalNotesChanged);
    this.context.currentTocResults.addListener(this.searchUpdate);
    this.context.reloadAllSegmentsRequired.on(this.reloadAllSegments);
    this.setInnerReady();
  }
  private setInnerReady() {
    setTimeout(() => {
      if (
        this.context.loading.isLoading(BookLoadingSteps.content) &&
        this.context.loading.isLoaded(BookLoadingSteps.annotationTypes) &&
        this.context.loading.isLoaded(BookLoadingSteps.annotations) &&
        this.context.loading.isLoaded(BookLoadingSteps.favourites) &&
        this.context.loading.isLoaded(BookLoadingSteps.community)
      ) {
        this.context.loading.setLoaded(BookLoadingSteps.content);
        this.setState({
          ready: true,
        });
      } else {
        this.setInnerReady();
      }
    }, 200);
  }

  private async favesChanged(val: Favourite | undefined) {
    if (val) {
      await this.segmentChanged([val.DocumentId]);
    }
  }
  private async annosChanged(val: Annotation | undefined) {
    if (val) {
      let docs: number[] = [];
      val.Ranges.map((range) => {
        docs.push(range.DocId);
      });

      await this.segmentChanged(docs);
    }
  }
  private globalNotesChanged = async (val: IGlobalNoteViewModel | undefined) => {
    if (val) {
      let docs: number[] = [];
      val.Ranges.map((range) => {
        docs.push(range.DocumentId);
      });

      await this.segmentChanged(docs);
    } else {
      let docs: number[] = [];
      this.context.globalNotes.rows().forEach((element) => {
        element.Ranges.map((range) => {
          docs.push(range.DocumentId);
        });
      });
      await this.segmentChanged(docs);
    }
  };

  private searchUpdate() {
    this.setState({
      tocHitResults: this.context.currentTocResults,
    });
  }

  reloadAllSegments() {
    let docs = this.content.cogniflow.getAllSecondaryIds();
    setTimeout(() => void(async () => {
      await this.segmentChanged(docs);
    })(), 100);
  }

  async segmentChanged(docIds: number[]) {
    let result = await this.context.getContentSegments({ DocIds: docIds, ShouldHighlight: true });
    this.content?.cogniflow?.replaceNodes(result.data);
    if (result.data.some((x) => x.SpineId === 0)) {
      this.content.replacePopups(result.data.filter((x) => x.SpineId === 0));
    }
  }
  async specialNavToSelector(request: INavigationRequest) : Promise<boolean> {
    switch (request.Type) {
      case NavigationRequestType.annotation: {
        let anno = this.content.book.annotations.get(request.AnnoId)!;
        if (this.content.cogniflow.hasSecondaryId(anno.Ranges[0].DocId)) {
          await this.content.cogniflow.navigateToNode(anno.Ranges[0].Spine, 0, () =>
            Promise.resolve({
              mainId: anno.Ranges[0].Spine,
              element: this.content.findAnnotationAnchor(this.content.root, anno.Id),
            })
          );
          return true;
        }
        break;
      }
      case NavigationRequestType.favourite: {
        let fave = this.content.book.favourites.get(request.FavId)!;
        if (this.content.cogniflow.hasSecondaryId(fave.DocumentId)) {
          await this.content.cogniflow.navigateToNode(fave.Spine, 0, () => 
            Promise.resolve({
              mainId: fave.Spine,
              element: this.content.findFavouriteAnchor(this.content.root, fave.Id),
            })
          );
          return true;
        }
        break;
      }
      case NavigationRequestType.selector: {
        if (this.content.cogniflow.hasSecondaryId(request.HeadId)) {
          let node = this.content.cogniflow.getNodeBySecondary(request.HeadId) as ContentSegment;
          await this.content.cogniflow.navigateToNode(node.SpineId, 0, () => 
            Promise.resolve({ 
              mainId: node.SpineId, 
              selector: request.ContentSelector 
            })
          );
          return true;
        }
        break;
      }
    }
    return false;
  }
  private async onNavigationRequested(request: INavigationRequest) {
    if (this.content === undefined) {
      setTimeout(() => void(async () => {
        await this.onNavigationRequested(request);
      })(), 500);
      return;
    }
    if (
      (request.HitDirection === HitDirection.NextHit || request.HitDirection === HitDirection.PreviousHit) &&
      request.HeadId &&
      this.content.cogniflow.getAllSecondaryIds().indexOf(request.HeadId) > 0
    ) {
      if (await this.content.scrollToHit(request.HitDirection)) {
        return;
      }
    }
    if (request.Type === NavigationRequestType.header || request.Type === NavigationRequestType.toc) {
      if (this.content.cogniflow.hasSecondaryId(request.HeadId)) {
        await this.content.cogniflow.navigateToNodeBySecondaryId(request.HeadId);
        return;
      }
    }
    if (request.Type === NavigationRequestType.relativeOffset) {
      if (this.content.cogniflow.hasSecondaryId(request.HeadId)) {
        let seg = this.content.cogniflow.getNodeBySecondary(request.HeadId) as ContentSegment;
        let concSeg = this.content.cogniflow.getSegmentByMain(seg.SpineId);
        await this.content.cogniflow.navigateToNode(seg.SpineId, concSeg!.getBoundingClientRect().height * request.RelativeOffset, () => 
          Promise.resolve({
            mainId: seg.SpineId,
            offset: concSeg!.getBoundingClientRect().height * request.RelativeOffset,
          })
        );
        return;
      }
    }
    if (
      request.Type === NavigationRequestType.selector ||
      request.Type === NavigationRequestType.annotation ||
      request.Type === NavigationRequestType.favourite
    ) {
      if (await this.specialNavToSelector(request)) {
        return;
      }
    }
    let result: ActionResult<IContentNavigationR> = await this.context.navigate(request);
    if (result.valid()) {
      if (request.HitDirection === undefined || request.HitDirection === HitDirection.Undefined) {
        this.content.feed(result.data);
        if (!result.data.InPlace) {
          this.scrollTo(result.data.TargetMainId);
        }
      } else {
        request.HeadId = result.data.TargetHeadId;
        this.lastRequest = request;
        this.content.feed(result.data);
        if (!result.data.InPlace) {
          this.scrollTo(result.data.TargetMainId);
        }
      }
    }
    else {
      if(result.errors && result.errors.length > 0)
      {
        console.error(result.errors[0].Message);
      }
      Messages.Notify.error(this.context.localization.currentLocale.Application.TITLE_ERROR);
    }
  }
  private async checkKeys(event: any) {
    if (event.keyCode === 33) {
      this.content.cogniflow.scrollingRef.current!.scrollBy(
        -this.content.cogniflow.rootRef.current!.scrollHeight,
        -this.content.cogniflow.rootRef.current!.scrollHeight
      );
    }
    if (event.keyCode === 34) {
      this.content.cogniflow.scrollingRef.current!.scrollBy(
        this.content.cogniflow.rootRef.current!.scrollHeight,
        this.content.cogniflow.rootRef.current!.scrollHeight
      );
    }
    if (event.keyCode === 38) {
      this.content.cogniflow.scrollingRef.current!.scrollBy(
        -this.content.cogniflow.rootRef.current!.scrollHeight / 6,
        -this.content.cogniflow.rootRef.current!.scrollHeight / 6
      );
    }
    if (event.keyCode === 40) {
      this.content.cogniflow.scrollingRef.current!.scrollBy(
        this.content.cogniflow.rootRef.current!.scrollHeight / 6,
        this.content.cogniflow.rootRef.current!.scrollHeight / 6
      );
    }
    if (event.ctrlKey || event.metaKey) {
      if (event.keyCode === 70) {
        event.preventDefault();
        event.stopPropagation();
        if (this.context.isActiveTab) {
          this.context.searchRemotelyExecuted.dispatch("", this);
        }
        return false;
      }
      if (event.keyCode === 80) {
        event.preventDefault();
        event.stopPropagation();
        if (this.context.isActiveTab && this.context.contentPermissions.CanPrint) {
          await this.printRequested();
        }
        return false;
      }
      if (event.keyCode === 67) {
        event.preventDefault();
        event.stopPropagation();
        if (this.context.contentPermissions.CanCopy) {
          await this.handleCopying();
        }
        return false;
      }
    }
    return true;
  }

  public handleContentLinkClick(resource: string, partsListIndex: number) {
    this.mediaPanelModal.current?.show(resource, partsListIndex);
  }

  private async handleCopying() {
    try {
      let sel = await this.selection.getSelectionObject();
      this.selection.setCopy();
      let res = await this.context.requestCopy({
        FirstDocId: sel.firstDocId,
        FirstOffset: sel.firstWordCount,
        LastOffset: sel.secondWordCount,
        SecondDocId: sel.secondDocId,
      });
      if (res && res.valid() && res.data && res.data != null && res.data.RequestApproved) {
        Messages.Notify.success(
          Convert.formatString(this.context.localization.currentLocale.PrintView.COPY_APPROVED, [
            res.data.InitialRemainingWords - res.data.RemainingWords,
            res.data.RemainingWords,
          ]) as string
        );
      } else {
        this.shareDiv.current!.style.display = "";
        this.shareInput.current!.value = " ";
        this.shareInput.current!.focus();
        this.shareInput.current!.select();
        document.execCommand("selectall", false);
        document.execCommand("copy", false);
        this.shareInput.current!.value = "";
        this.shareInput.current!.blur();
        this.shareDiv.current!.style.display = "none";
        setTimeout(() => {
          if (res.errors && res.errors.length > 0 && res.status === Status.AuthorizationFailed) {
            Messages.Notify.error(this.context.localization.currentLocale.PrintView.COPY_FAILED_AUTHFAILED);
          } else if (res.errors && res.errors.length > 0 && res.status === Status.BadRequest) {
            Messages.Notify.error(this.context.localization.currentLocale.PrintView.COPY_FAILED_BADREQUEST);
          } else if (res.valid() && !res.data.RequestApproved) {
            Messages.Notify.error(
              Convert.formatString(this.context.localization.currentLocale.PrintView.COPY_REFUSED, [
                res.data.RemainingWords,
                res.data.InitialRemainingWords,
              ]) as string
            );
          }
        }, 300);
      }
    } catch (e) {
      Messages.Notify.error(this.context.localization.currentLocale.PrintView.COPY_FAILED_BADREQUEST);
    }
  }

  private async handlePrintSelection() {
    let sel = await this.selection.getSelectionObject();
    let selFrag = this.selection.getSelectionContent();
    if (selFrag === undefined) {
      return;
    }
    let res = await this.context.requestPrintSelection({
      FirstDocId: sel.firstDocId,
      FirstOffset: sel.firstWordCount,
      LastOffset: sel.secondWordCount,
      SecondDocId: sel.secondDocId,
    });
    if (res && res.valid() && res.data && res.data != null && res.data.RequestApproved) {
      let rawHtml = "";
      let hasAnnos = selFrag.querySelector("span[data-anno]") !== null || selFrag.querySelector("span[data-globalNote]") !== null;
      for (let i = 0; i < selFrag.childNodes.length; i++) {
        if ((selFrag.childNodes[i] as any).outerHTML !== undefined) {
          rawHtml += (selFrag.childNodes[i] as any).outerHTML;
        } else if ((selFrag.childNodes[i] as any).textContent !== undefined) {
          rawHtml += (selFrag.childNodes[i] as any).textContent;
        }
      }
      await this.printModal.current!.printSelection(
        sel,
        (this.content.cogniflow.getNodeBySecondary(sel.firstDocId) as ContentSegment).SpineId,
        (this.content.cogniflow.getNodeBySecondary(sel.secondDocId) as ContentSegment).SpineId,
        rawHtml,
        hasAnnos
      );
    } else {
      setTimeout(() => {
        if (res.errors && res.errors.length > 0 && res.status === Status.AuthorizationFailed) {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_AUTHFAILED);
        } else if (res.errors && res.errors.length > 0 && res.status === Status.BadRequest) {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
        } else if (res.valid() && !res.data.RequestApproved) {
          Messages.Notify.error(
            Convert.formatString(this.context.localization.currentLocale.PrintView.PRINT_REFUSED, [res.data.SelectedWords, res.data.MaxWords]) as string
          );
        }
      }, 300);
    }
  }

  private async initializeFlow(anchor?: number, searchQuery?: string, recurse?: boolean): Promise<{ nodes: any[]; targetSpine: number }> {
    if (!this.flowRef.current!.flow() || !this.flowRef.current!.document()!) {
      return { nodes: [], targetSpine: -1 };
    }
    if (!this.content || recurse) {
      this.content = new ContentHandler(this.flowRef.current!.flow()!, this.flowRef.current!.document()!, this.context, this);
      if (this.content.apiServer.isInitialized() === true) {
        await this.flowRef.current!.loadBodyScripts();
      } else {
        return this.delay(100).then(async () => this.initializeFlow(anchor, "", true));
      }
    }
    this.content.contentDocument.removeEventListener("keydown", (e) => void this.checkKeys(e), false);
    this.content.contentDocument.addEventListener("keydown", (e) => void this.checkKeys(e), false);

    let result: ActionResult<IContentNavigationR> = await this.context.getContentLastPosition();
    if (result.valid()) {
      this.context.displayedHead = result.data.TargetHeadId;
      this.context.displayedSpine = result.data.TargetMainId;
      this.setState({ displayedSpine: result.data.TargetMainId });
      return {
        nodes: result.data.Nodes,
        targetSpine: result.data.TargetMainId,
      };
    } else {
      const msg = result.errors && result.errors.length > 0 ? result.errors[0].Message : "Could not get last position";
      throw new Error(msg);
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  private async flowProvider(request: IRequest): Promise<IResponse> {
    let result: ActionResult<CogniflowMessage> = await this.context.fetchCogniflowSegments(Convert.cogniflowRequestToMessage(request));
    if (result.data) {
      let response: IResponse = Convert.cogniflowMessageToResponse(result.data);
      return response;
    }
    else {
      const msg = result.errors && result.errors.length > 0 ? result.errors[0].Message : "Could not fetch cogniflow segments";
      throw new Error(msg);
    }
  }
  private extendedGenerateSegment(node: INode, attributes?: any, key?: number): JSX.Element {
    let highlights = this.context.annotations.rows().filter((x) => x.Ranges.some((y) => y.DocId === node.HeadId));
    let relatedTypes = highlights.map((x) => this.context.annotationTypes.get(x.AnnotationTypeId)!);
    let globalNotes = this.context.globalNotes.rows().filter((x) => x.Ranges.some((y) => y.DocumentId === node.HeadId));
    let classes = attributes["className"];
    if((node as ContentSegment).HeadId === this.currentReadingDoc){
      classes += " isReading";
    }
    return (
      <ConcreteContentSegment
        classes={classes}
        headAttr={this.settings.segmentDataDescriptor.secondaryIdDataAttribute}
        head={attributes[this.settings.segmentDataDescriptor.secondaryIdDataAttribute]}
        spineAttr={this.settings.segmentDataDescriptor.mainIdDataAttribute}
        spine={attributes[this.settings.segmentDataDescriptor.mainIdDataAttribute]}
        key={key}
        item={node as ContentSegment}
        segmentAdded={this.content.apiServer.triggerSegmentAdded}
        highlights={highlights}
        relatedTypes={relatedTypes}
        commentCount={0}
        globalNotes={globalNotes}
      />
    );
  }
  private insertedSegmentCallback() {}

  private async navigationDone() {
    if (this.lastRequest) {
      let direction = HitDirection.NextHit;
      if (this.lastRequest.HitDirection === HitDirection.FirstHitInSegment) {
        direction = HitDirection.FirstHitInSegment;
      }
      if (this.lastRequest.HitDirection === HitDirection.PreviousHit) {
        direction = HitDirection.LastHitInSegment;
      }
      await this.content.scrollToHit(direction, this.lastRequest.HeadId);
      this.lastRequest = undefined;
    }
    if (this.content.currentPopup) {
      this.content.showPopup(this.content.currentPopup);
      this.content.currentPopup = undefined;
    }
  }

  async createFavourite(faveArg: any, title: string, folder: string) {
    let result = await this.context.createFavourite({
      DocId: faveArg.DocId,
      Title: title,
      YDocHeight: faveArg.YDocHeight,
      YOffset: faveArg.YOffset,
      Folder: folder,
    });
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.FavouriteView.LABEL_FAVOURITE_UPDATED);
    } else {
      Messages.Notify.error(result.errors[0].Message);
    }
  }

  async createUserContentItem(item: Annotation | Favourite | UserFormSubmissionViewModel, selection: any) {
    if (item instanceof UserFormSubmissionViewModel) {
      let url = await this.context.createExternalLink({
        FirstDocId: selection.firstDocId,
        FirstOffset: selection.firstWordCount,
        SecondDocId: selection.secondDocId,
        SecondOffset: selection.secondWordCount,
      });
      item.Submission.DeepLink = url.data.Url;
      let result = await this.context.createUserForm({ 
        UserForm: item,
        DocumentId: selection.firstDocId,
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.TipView.LABEL_TIP_UPDATED);
      } else {
        Messages.Notify.error(result.errors[0].Message);
      }
    } else if (item instanceof Annotation) {
      let result = await this.context.createAnnotation({
        FirstDocId: selection.firstDocId,
        FirstOffset: selection.firstOffset,
        FirstWordCount: selection.firstWordCount,
        SecondDocId: selection.secondDocId,
        SecondOffset: selection.secondOffset,
        SecondWordCount: selection.secondWordCount,
        AnnotationTypeId: item.AnnotationTypeId,
        Note: item.Note,
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_UPDATED);
      } else {
        Messages.Notify.error(result.errors[0].Message);
      }
    } else {
      await this.createFavourite(selection, item.Value, item.Folder);
    }
  }
  globalNotesCreationConfirm = async (note: string, selection: any) => {
    let concreteNote = { Note: note } as GlobalNote;
    let result = await this.context.putGlobalNote({
      FirstDocId: selection.firstDocId,
      FirstOffset: selection.firstOffset,
      FirstWordCount: selection.firstWordCount,
      SecondDocId: selection.secondDocId,
      SecondOffset: selection.secondOffset,
      SecondWordCount: selection.secondWordCount,
      Note: concreteNote,
    });
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.ALERT_GLOBAL_NOTE_SUCCESS);
      await this.context.getGlobalNotesForVersion({});
    } else {
      Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.ALERT_GLOBAL_NOTE_FAILURE);
    }
  };

  async quickCreateAnnotation(type: AnnotationType) {
    let selObj = await this.selection.getSelectionText();
    if (!selObj.selTxt) {
      await Log.error("Annotation range had 0 length!", null);
      Messages.Notify.error(this.context.localization.currentLocale.ContentView.ALERT_SELECTIONERROR);
      return;
    }
    let annoSelection = null;
    try {
      annoSelection = await this.selection.getSelectionObject(selObj.sel);
    } catch (w) {
      await Log.error("Annotation could not be established!", w);
      Messages.Notify.error(this.context.localization.currentLocale.ContentView.ALERT_SELECTIONERROR);
      return;
    }
    this.contextMenu.current!.contextMenu.hide();
    if (annoSelection !== null) {
      let result = await this.context.createAnnotation({
        FirstDocId: annoSelection.firstDocId,
        FirstOffset: annoSelection.firstOffset,
        FirstWordCount: annoSelection.firstWordCount,
        SecondDocId: annoSelection.secondDocId,
        SecondOffset: annoSelection.secondOffset,
        SecondWordCount: annoSelection.secondWordCount,
        AnnotationTypeId: type.Id,
        Note: "",
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_UPDATED);
      } else {
        Messages.Notify.error(result.errors[0].Message);
      }
    }
  }

  private updateAppSettings() {
    if (this.content) {
      this.content.adjustFontSize();
    }
  }


  async actionClicked(action: ContextMenuActions) {
    switch (action) {
      case ContextMenuActions.addTip: {
        let tipSelection: { firstDocId: number; firstWordCount: number; firstOffset: number; secondDocId: number; secondWordCount: number; secondOffset: number; hadError?: boolean; } | null = null;
        try {
          tipSelection = await this.selection.getSelectionObject();
        } catch (e) {
          tipSelection = null;
          try {
            let docId = this.content.cogniflow.getSecondaryDataId(
              this.contextMenu.current!.contextMenu.lastRightClickTarget.closest(".ContentSegment") as HTMLElement
            )!;
            tipSelection = {
              // The range is not a valid one (userform or rich content segment.)
              firstDocId: docId,
              firstOffset: 0,
              secondOffset: 0,
              firstWordCount: 0,
              secondWordCount: 0,
              secondDocId: docId,
            };
          } catch (ex) {}
        }
        if (tipSelection != null) {
          await this.context.getTipDefinition().then((testTip) => this.annotationCreateDialog.current!.show(testTip, tipSelection));
        }
        break;
      }
      case ContextMenuActions.addFeedback: {
        let feedbackSelection: {firstDocId: number; firstWordCount: number; firstOffset: number; secondDocId: number; secondWordCount: number; secondOffset: number; hadError?: boolean;} | null = null;
        try {
          feedbackSelection = await this.selection.getSelectionObject();
        } catch (e) {
          feedbackSelection = null;
          try {
            let docId = this.content.cogniflow.getSecondaryDataId(
              this.contextMenu.current!.contextMenu.lastRightClickTarget.closest(".ContentSegment") as HTMLElement
            )!;
            feedbackSelection = {
              // The range is not a valid one (userform or rich content segment.)
              firstDocId: docId,
              firstOffset: 0,
              secondOffset: 0,
              firstWordCount: 0,
              secondWordCount: 0,
              secondDocId: docId,
            };
          } catch (ex) {}
        }
        if (feedbackSelection !== null) {
          const testFeedback = await this.context.getFeedbackDefinition();
          if(testFeedback) {
            this.annotationCreateDialog.current!.show(testFeedback, feedbackSelection);
          }
        }
        break;
      }
      case ContextMenuActions.addAnnotation: {
        let selObj = await this.selection.getSelectionText();
        if (selObj.selTxt.length === 0) {
          await Log.error("Annotation range had 0 length!", null);
          Messages.Notify.error(this.context.localization.currentLocale.ContentView.ALERT_SELECTIONERROR);
          return;
        }
        let annoSelection = null;
        try {
          annoSelection = await this.selection.getSelectionObject(selObj.sel);
        } catch (w) {
          await Log.error("Annotation could not be established!", w);
          Messages.Notify.error(this.context.localization.currentLocale.ContentView.ALERT_SELECTIONERROR);
          return;
        }
        let testAnno = new Annotation();
        if (this.context.annotationTypes.length > 0) {
          testAnno.AnnotationTypeId = this.context.annotationTypes
            .rows()
            .sort((a, b) => new Date(b.LastUpdate).getTime() - new Date(a.LastUpdate).getTime())[0].Id;
        }
        testAnno.Id = 0;
        testAnno.LastUpdate = new Date(Date.now());
        testAnno.Note = "";
        testAnno.Preview = "";
        testAnno.Ranges = [];
        this.annotationCreateDialog.current!.show(testAnno, annoSelection);
        break;
      }
      case ContextMenuActions.addFavourite: {
        let selFave: { DocId: number; YDocHeight: number; YOffset: number } | null = null;
        try {
          selFave = await this.selection.getFavourite();
          if (selFave === null) {
            throw Error("null fave. try fallback.");
          }
        } catch (e) {
          selFave = null;
          try {
            let docId = this.content.cogniflow.getSecondaryDataId(
              this.contextMenu.current!.contextMenu.lastRightClickTarget.closest(".ContentSegment") as HTMLElement
            )!;
            selFave = {
              // The range is not a valid one (userform or rich content segment.)
              DocId: docId,
              YDocHeight: 1,
              YOffset: 0,
            };
          } catch (ex) {
            await Log.error("Annotation could not be established!", ex);
            Messages.Notify.error(this.context.localization.currentLocale.ContentView.ALERT_SELECTIONERROR);
          }
        }
        if (selFave !== null) {
          let testFave = new Favourite();
          testFave.Folder = "";
          testFave.LastUpdate = new Date(Date.now());
          testFave.Value = "";
          testFave.Folder = "";
          this.annotationCreateDialog.current!.show(testFave, selFave);
        }
        break;
      }
      case ContextMenuActions.print: {
        let printSelection: {firstDocId: number; firstWordCount: number; firstOffset: number; secondDocId: number; secondWordCount: number; secondOffset: number; hadError?: boolean;} | null = null;
        try {
          printSelection = await this.selection.getSelectionObject();
        } catch (e) {
          printSelection = null;
          try {
            let docId = this.content.cogniflow.getSecondaryDataId(
              this.contextMenu.current!.contextMenu.lastRightClickTarget.closest(".ContentSegment") as HTMLElement
            )!;
            printSelection = {
              // The range is not a valid one (userform or rich content segment.)
              firstDocId: docId,
              firstOffset: 0,
              secondOffset: 0,
              firstWordCount: 0,
              secondWordCount: 0,
              secondDocId: docId,
            };
          } catch (ex) {}
        }
        if (printSelection && printSelection !== null && this.printModal.current !== null) {
          try {
            this.printModal.current.show(
              printSelection,
              (this.content.cogniflow.getNodeBySecondary(printSelection.firstDocId) as ContentSegment).SpineId,
              (this.content.cogniflow.getNodeBySecondary(printSelection.secondDocId) as ContentSegment).SpineId
            );
          } catch (e) {
            await Log.error("Error making print.", e);
          }
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_ERROR);
        }
        break;
      }
      case ContextMenuActions.printSelection: {
        let selectPrint: {firstDocId: number; firstWordCount: number; firstOffset: number; secondDocId: number; secondWordCount: number; secondOffset: number; hadError?: boolean;} | null = null;
        try {
          selectPrint = await this.selection.getSelectionObject();
        } catch (e) {
          selectPrint = null;
        }
        if (selectPrint && selectPrint !== null && this.printModal.current !== null) {
          await Log.info("Going to get spines");
          try {
            await this.handlePrintSelection();
          } catch (e) {
            await Log.error("Error making print selection.", e);
          }
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_ERROR);
        }
        break;
      }
      case ContextMenuActions.search: {
        let searchSelection = (await this.selection.getSelectionText()).selTxt;
        if (searchSelection.trim().split(" ").length > 1) {
          searchSelection = '"' + searchSelection + '"';
        }
        this.context.searchRemotelyExecuted.dispatch(searchSelection, this);
        await this.context.executeSearch({ Filter: Array.from(this.context.currentFilter), Query: searchSelection, IncludeCommunity: true });
        break;
      }
      case ContextMenuActions.searchOnline: {
        let searchOnlineSelection = (await this.selection.getSelectionText()).selTxt;
        await this.context.openExternalWebsite(Convert.GetSearchURL(searchOnlineSelection, this.context.appSettings.get().SearchEngine), searchOnlineSelection);
        break;
      }
      case ContextMenuActions.share: {
        let shareSelection = null;
        let shareContent = "";
        try {
          let shareObj = await this.selection.getSelectionText();
          shareContent = shareObj.selTxt;
          shareSelection = await this.selection.getSelectionObject(shareObj.sel);
          // The range is not a valid one (userform or rich content segment.)
          if (Convert.isEmptyOrSpaces(shareContent)) {
            shareSelection.firstOffset = 0;
            shareSelection.secondOffset = 0;
            shareSelection.firstWordCount = 0;
            shareSelection.secondWordCount = 0;
            shareSelection.secondDocId = shareSelection.firstDocId;
            shareContent = "";
          }
        } catch (e) {
          shareSelection = null;
          shareContent = "";
          try {
            let docId = this.content.cogniflow.getSecondaryDataId(
              this.contextMenu.current!.contextMenu.lastRightClickTarget.closest(".ContentSegment") as HTMLElement
            )!;
            shareSelection = {
              // The range is not a valid one (userform or rich content segment.)
              firstDocId: docId,
              firstOffset: 0,
              secondOffset: 0,
              firstWordCount: 0,
              secondWordCount: 0,
              secondDocId: docId,
            };
          } catch (ex) {}
        }
        if (shareSelection !== null) {
          await this.context
            .createExternalLink({
              FirstDocId: shareSelection.firstDocId,
              FirstOffset: shareSelection.firstWordCount,
              SecondDocId: shareSelection.secondDocId,
              SecondOffset: shareSelection.secondWordCount,
            })
            .then((result) => {
              if (result.errors && result.errors.length > 0) {
                Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_CREATED_FAILURE);
              } else {
                let formattedUrl = result.data.Url;
                if (shareContent !== null) {
                  formattedUrl = this.createCitation(result.data.Url, shareContent);
                }
                this.shareDiv.current!.style.display = "";
                this.shareInput.current!.value = formattedUrl;
                this.shareInput.current!.focus();
                this.shareInput.current!.select();
                document.execCommand("selectall", false);
                document.execCommand("copy", false);
                this.shareInput.current!.value = "";
                this.shareInput.current!.blur();
                this.shareDiv.current!.style.display = "none";
                Messages.Notify.success(this.context.localization.currentLocale.Application.EXTERNAL_LINK_CREATED_SUCCESS);
              }
            });
        }
        break;
      }
      case ContextMenuActions.copy:
        await this.handleCopying();
        break;
      case ContextMenuActions.audio: {
        if (this.content.selection) {
          let utterance = new SpeechSynthesisUtterance((await this.content.selection.getSelectionText()).selTxt);
          speechSynthesis.speak(utterance);
          break;
        }
      }
    }
    this.selection.clearSelection();
  }
  private createCitation(rawUrl: string, selectionContent: string): string {
    if (!this.context.versionInfo) {
      return rawUrl;
    }
    let formattedUrl = "";
    if (selectionContent.length > 500) {
      formattedUrl = '"' + selectionContent.substring(0, 500).trim() + ' [...]"\n';
    } else {
      formattedUrl = '"' + selectionContent.trim() + '"\n';
    }
    formattedUrl +=
      "[" +
      this.context.versionInfo.Creator +
      " (" +
      new Date(this.context.versionInfo.Date).getFullYear() +
      "), " +
      this.context.versionInfo.Title +
      ", " +
      this.context.versionInfo.Publisher +
      "]\n";
    formattedUrl += rawUrl;
    return formattedUrl;
  }
  private onArrowUp() {
    this.content.cogniflow.scrollingRef.current!.scrollBy(
      -this.content.cogniflow.rootRef.current!.scrollHeight / 6,
      -this.content.cogniflow.rootRef.current!.scrollHeight / 6
    );
  }
  private onArrowDown() {
    this.content.cogniflow.scrollingRef.current!.scrollBy(
      this.content.cogniflow.rootRef.current!.scrollHeight / 6,
      this.content.cogniflow.rootRef.current!.scrollHeight / 6
    );
  }

  private scrollChanged(previousValue: number, value: number) {
    this.setState({
      showScrollModal: true,
      scrollSpine: value,
    });
  }

  private openNavigationModal(value: number) {
    this.setState({
      showScrollModal: true,
      scrollSpine: value,
    });
  }

  private onScrollFromCogniflow(spine: number, head?: number) {
    this.context.locationChanged(spine, head!);
  }


  private async onScrollNavigationRequested(spineId: number) {
    const headerId = this.context.tocSpineIdToHeader.get(spineId)!.Current.Id;
    await this.context.contentNavigation(NavigationRequest.toHeader(headerId), NavigationInitiator.scrubber);
  }

  private onScrollNavigationCanceled() {
    this.scrollTo(this.state.displayedSpine);
  }

  private scrollTo(spine: number) {
    this.setState({
      displayedSpine: spine,
      showScrollModal: false,
    });

    if (this.scrubber.current) {
      this.scrubber.current.scrollToSpine(spine);
    }
  }
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "SpineId",
      mainIdDataAttribute: "data-cogniflow--spine",
      secondaryIdDataAttribute: "data-cogniflow--headid",
      secondaryIdNodeAttribute: "HeadId",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      contentAttribute: "ContentSegment",
      applyDirectlyToSegment: true,
    },
    containerClasses: "d-flex flex-column ",
    segmentContainerClasses: "html body",
    scrollingClasses: "BookView tex2jax_ignore",
    applyFooterSpace: true,
    batchSize: 20,
  };

  frameBlurred = () => {
    setTimeout(() => {
      if (this.contextMenu.current) {
        this.contextMenu.current.contextMenu.hide();
      }
    }, 200);
  };
  buildPopup(pop: INode) {
    return this.content.buildPopup(pop as ContentPopupModel);
  }
  render() {
    let contextMenu;
    let annoCreateDialog;
    let userContentMultiSelector;
    let printModal;
    let scrubber;
    let scrollbar;
    let mediaPanelModal;
    let types: JSX.Element[] = [];

    this.updateAppSettings();

    if (this.context.loginType === LoginType.User) {
      // types.push(<ContextAnnotationTypeMenuItem key={-1} AnnotationType={null} annotationTypeCreate={() => {}} shouldShowIfNoSelection={false} />);
      for (let i = 0; i < this.state.quickAnnotationTypes.length; i++) {
        types.push(
          <ContextAnnotationTypeMenuItem
            key={this.state.quickAnnotationTypes[i].Id}
            AnnotationType={this.state.quickAnnotationTypes[i]}
            annotationTypeCreate={(t) => void this.quickCreateAnnotation(t)}
            shouldShowIfNoSelection={false}
          />
        );
      }
    }

    if (this.state.ready) {
      if (this.content !== undefined) {
        annoCreateDialog = (
          <UserContentCreateDialog
            ref={this.annotationCreateDialog}
            creationConfirm={(item, selection) => void this.createUserContentItem(item, selection)}
            globalNotesCreationConfirm={this.globalNotesCreationConfirm}
          />
        );
        userContentMultiSelector = <UserContentMultiSelector ref={this.userContentMultiSelector} />;
        printModal = <PrintModalControl ref={this.printModal} maxWords={this.context.libraryConfig.PrintMaxWordCount} />;
        mediaPanelModal = <MediaPanelModal ref={this.mediaPanelModal} linkRequestHandler={this.content.processContentLink.bind(this)} />;
        scrubber = (
          <Scrubber
            ref={this.scrubber}
            iframeBody={this.content.contentDocument.body}
            sortedValues={this.context.tocSortedHeaderSpineIds}
            initialValue={this.state.displayedSpine}
            currentTocResults={this.state.tocHitResults}
            onScroll={this.scrollChanged}
            onHandlePressed={this.openNavigationModal}
          />
        );
        scrollbar = <ScrollBar scrubber={scrubber} onArrowDown={this.onArrowDown} onArrowUp={this.onArrowUp} />;

        if (this.containerDiv !== null) {
          contextMenu = (
            <ContextMenu
              ref={this.contextMenu}
              quickCreateClicked={(t) => void this.quickCreateAnnotation(t)}
              sentContainer={this.containerDiv}
              sentContext={this.content.contentDocument.documentElement}
              onActionClicked={(action) => void this.actionClicked(action)}
            >
              {types}
              {this.context.loginType === LoginType.User && (
                <ContextMenuItem shouldShowIfNoSelection={false} action={ContextMenuActions.addAnnotation} icon={<Icon src={<Image.annotations />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_ADD_ANNOTATION}
                </ContextMenuItem>
              )}
              {this.context.loginType === LoginType.User && (
                <ContextMenuItem shouldShowIfNoSelection={true} action={ContextMenuActions.addFavourite} icon={<Icon src={<Image.favourite />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_ADD_FAVOURITE}
                </ContextMenuItem>
              )}
              {this.context.community.IsTipsAvailable && this.context.loginType === LoginType.User && this.context.loginType === LoginType.User && (
                <ContextMenuItem shouldShowIfNoSelection={true} action={ContextMenuActions.addTip} icon={<Icon src={<Image.tip />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_SUBMIT_TIP}
                </ContextMenuItem>
              )}
              {this.context.community.IsFeedbackAvailable && this.context.loginType === LoginType.User && this.context.loginType === LoginType.User && (
                <ContextMenuItem shouldShowIfNoSelection={true} action={ContextMenuActions.addFeedback} icon={<Icon src={<Image.feedback />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_SEND_FEEDBACK}
                </ContextMenuItem>
              )}
              {ResourcePlatform.CanPrint() && this.context.contentPermissions.CanPrint && (
                <ContextMenuItem shouldShowIfNoSelection={true} action={ContextMenuActions.print} icon={<Icon src={<Image.print />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_PRINT}
                </ContextMenuItem>
              )}
              {ResourcePlatform.CanPrint() && this.context.contentPermissions.CanPrint && (
                <ContextMenuItem shouldShowIfNoSelection={false} action={ContextMenuActions.printSelection} icon={<Icon src={<Image.print />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_PRINT_SELECTION}
                </ContextMenuItem>
              )}
              <ContextMenuItem shouldShowIfNoSelection={false} action={ContextMenuActions.search} icon={<Icon src={<Image.search />} />}>
                {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_SEARCH}
              </ContextMenuItem>
              <ContextMenuItem shouldShowIfNoSelection={false} action={ContextMenuActions.searchOnline} icon={<Icon src={<Image.search />} />}>
                {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_SEARCH_ONLINE}
              </ContextMenuItem>
              <ContextMenuItem shouldShowIfNoSelection={false} action={ContextMenuActions.share} icon={<Icon src={<Image.link />} />}>
                {this.context.localization.currentLocale.ContentView.LABEL_SHARE_BUTTON}
              </ContextMenuItem>
              {this.context.contentPermissions.CanCopy && (
                <ContextMenuItem shouldShowIfNoSelection={false} action={ContextMenuActions.copy} icon={<Icon src={<Image.copy />} />}>
                  {this.context.localization.currentLocale.ContentView.LABEL_CONTEXT_MENU_COPY}
                </ContextMenuItem>
              )}
              {this.context.loginType === LoginType.User && (
                <ContextMenuItem shouldShowIfNoSelection={true} action={ContextMenuActions.audio} icon={<Icon src={<Image.reading />} />}>
                  {"Read Selection"}
                </ContextMenuItem>
              )}
            </ContextMenu>
          );
          this.selection = new SelectionHandler(this.content.contentDocument.documentElement.ownerDocument);
          this.content.selection = this.selection;
        }
      }
      // Sets the border gutters based on the setting.
      let readerClass = "content-reader";
      if (!this.context.appSettings.get().ContentViewGuttersEnabled) {
        readerClass = "content-reader-unbound";
      }
      if (
        this.context.isActiveTab &&
        this.context.style !== undefined &&
        this.context.javascript !== undefined &&
        this.context.style !== null &&
        this.context.javascript !== null
      ) {
        this.flowFrame = (
          <StandaloneCogniflowFrameContainer
            ref={this.flowRef}
            className={readerClass}
            provider={this.flowProvider}
            initialize={this.initializeFlow}
            builder={this.extendedGenerateSegment}
            addonBuilder={this.buildPopup}
            segmentsInsertedCallback={this.insertedSegmentCallback}
            navigationDoneCallback={() => void this.navigationDone()}
            frameBlurred={this.frameBlurred}
            extraSettings={this.settings}
            topMostHasChanged={this.onScrollFromCogniflow}
            head={this.head}
            scripts={this.scripts}
          />
        );
      }

      let frameContainer = (
        <div
          className="content-reader-container position-relative"
          ref={(div) => {
            this.containerDiv = div!;
          }}
        >
          {this.flowFrame}
          {this.content !== undefined ? contextMenu : ""}
          {this.content !== undefined ? annoCreateDialog : ""}
          {this.content !== undefined ? userContentMultiSelector : ""}
          {this.content !== undefined ? printModal : ""}
          {this.content !== undefined ? mediaPanelModal : ""}
          {this.state.showScrollModal && (
            <ScrollNavigationModal
              spineIdToHeader={this.context.tocSpineIdToHeader}
              headerToSpine={this.context.tocHeaderToSpine}
              currentSpine={this.state.scrollSpine}
              currentTocResults={this.state.tocHitResults}
              onNavigationRequested={(spineId) => void this.onScrollNavigationRequested(spineId)}
              localization={this.context.localization}
              onNavigationCanceled={this.onScrollNavigationCanceled}
            />
          )}
          {scrollbar}
          <div ref={this.shareDiv} style={{ display: "none" }}>
            <textarea title="shareTextArea" ref={this.shareInput} />
          </div>
        </div>
      );
      return frameContainer;
    } else {
      return null;
    }
  }
}

export class ContentHandler {
  cogniflow: StandaloneCogniflowContainer;
  contentDocument: Document;
  root: HTMLDivElement;
  book: Book;
  contentReader: ContentReader;

  apiServer: {
    triggerNavigation: () => void;
    triggerSegmentAdded: (segment: any) => void;
    launch: () => void;
    findItem: (selector: string) => HTMLElement;
    isInitialized: () => boolean;
  };
  api: any;
  currentPopup: ContentSegment | undefined;
  selection: SelectionHandler | null;
  constructor(cogniflow: StandaloneCogniflowContainer, content: Document, context: Book, contentReader: ContentReader) {
    this.cogniflow = cogniflow;
    this.book = context;
    this.contentDocument = content;
    this.root = cogniflow.scrollingRef.current!;
    this.selection = null;
    this.contentReader = contentReader;
    this.onLinkClicked = this.onLinkClicked.bind(this);
    this.markIconClicked = this.markIconClicked.bind(this);
    this.routeClick = this.routeClick.bind(this);
    this.registerEvents();
    this.scrollToHit = this.scrollToHit.bind(this);
    this.navigateToSelector = this.navigateToSelector.bind(this);
    this.annotationClicked = this.annotationClicked.bind(this);
  }

  private registerEvents() {
    this.contentDocument.removeEventListener("click", (e) => void this.routeClick(e));
    this.contentDocument.addEventListener("click", (e) => void this.routeClick(e));
    this.apiServer = (this.contentDocument as any).ContentAPIServer;
    this.api = (this.contentDocument as any).ContentAPI;
    (this.contentDocument as any).defaultView.msgPost = this.msgPost.bind(this);
  }

  async routeClick(e: MouseEvent) {
    let target = (e.target as HTMLElement).closest("a[href]");
    if (target) {
      await this.onLinkClicked(target, e);
      return;
    }
    target = (e.target as HTMLElement).closest("span[data-anno]");
    if (target) {
      await this.markIconClicked(target);
      return;
    }
    target = (e.target as HTMLElement).closest("span[data-globalnote]");
    if (target) {
      this.globalNoteIconClicked(target);
      return;
    }
    target = (e.target as HTMLElement).closest(".mark-icon");
    if (target) {
      await this.markIconClicked(target);
      return;
    }
  }

  public adjustFontSize() {
    const { ReaderFontSize } = this.book.appSettings.get();
    let currentFontSizeClass = "";
    switch (ReaderFontSize) {
      case FontSizes.Smallest:
        currentFontSizeClass = "font-smallest";
        break;
      case FontSizes.Smaller:
        currentFontSizeClass = "font-smaller";
        break;
      case FontSizes.Normal:
        currentFontSizeClass = "font-medium";
        break;
      case FontSizes.Larger:
        currentFontSizeClass = "font-larger";
        break;
      case FontSizes.Largest:
        currentFontSizeClass = "font-largest";
        break;
    }

    const body = this.contentDocument.querySelector(".cogniflow-segments");
    if (body) {
      body.className = "";
      body.classList.add(...[currentFontSizeClass, "cogniflow-segments", "html", "body"]);
    }
  }

  public async msgPost(cmd: string, arg: string) {
    let pager = this.contentDocument.querySelector(".api-modal-context");
    if (pager) {
      pager.classList.remove("api-modal-context");
    }
    await this.processContentLink(cmd + "?" + arg);
  }

  private async onLinkClicked(sender: any, e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (!sender.getAttribute("href")) {
      return;
    }
    let hrefValue: string = sender.getAttribute("href")!.trim();
    if (hrefValue.startsWith("prolibro-")) {
      await this.book.reportExternalLink({ Url: hrefValue });
    } else if (hrefValue.indexOf("http") === 0 || hrefValue.indexOf("https") === 0) {
      await this.book.openExternalWebsite(hrefValue, hrefValue);
    } else {
      await this.processContentLink(hrefValue, e);
    }
  }
  public processContentLink = async (href: string, e?: MouseEvent) => {
    let result = "";
    let lastPart = href.substring(href.lastIndexOf("/") + 1);
    const splitter = lastPart.indexOf("?");
    if (splitter <= -1) {
      return;
    }
    let action = lastPart.substring(0, splitter);
    let param = lastPart.substring(splitter + 1);
    let request: NavigationRequest | undefined;
    let headerId: number | undefined;
    switch (action) {
      case "NAV":
        headerId = parseInt(param);
        if (_.isFinite(headerId)) {
          request = NavigationRequest.toHeader(headerId);
        }
        break;
      case "POP":
        headerId = parseInt(param);
        if (_.isFinite(headerId)) {
          request = NavigationRequest.toHeader(headerId);
          request.Type = NavigationRequestType.popup;
        }
        break;
      case "NAVSEL": {
        const selectorSplitter = param.indexOf(",");
        if (selectorSplitter > -1) {
          headerId = -1;
          if (isNaN(Number(param.substring(0, selectorSplitter)))) {
            let persistentDoc = param.substring(param.indexOf(":") + 1, selectorSplitter);
            await this.book.convertPersistentDoc(persistentDoc).then(async (res) => {
              let selector = param.substring(selectorSplitter + 1);
              if (res.valid()) {
                request = NavigationRequest.toSelector(res.data.HeadId, selector);
                await this.book.contentNavigation(request, NavigationInitiator.link);
              }
            });
            return;
          } else {
            headerId = parseInt(param.substring(0, selectorSplitter));
            let selector = param.substring(selectorSplitter + 1);

            if (_.isFinite(headerId)) {
              request = NavigationRequest.toSelector(headerId, selector);
            }
          }
        }
        break;
      }
      case "RES":
        if (e) {
          // This is to check args for argument resources. If no callbackDoc defined in the args, add one. Indicates the content segment that made the call.
          if (param.indexOf(",") > -1) {
            let closestHead = +(e.target as HTMLElement)!.closest(".ContentSegment")!.getAttribute("data-cogniflow--headid")!;
            let spots = param.split("$$");
            let outSpots: string[] = [];
            spots.forEach((spot) => {
              if (spot.indexOf("callbackDoc") > -1) {
                outSpots.push(spot);
              } else {
                outSpots.push(spot + "$callbackDoc=" + closestHead.toString());
              }
            });
            param = outSpots.join("$$");
          }
          result = await this.book.requestResource(param);
        } else {
          result = await this.book.requestResource(param);
        }
        if (ResourcePlatform.IsWebPlatform()) {
          let targetElement = e?.target as HTMLElement;
          let innerText = "";
          if (targetElement) {
            innerText = targetElement.innerText;
          }
          let numericValue = parseInt(innerText);
          let partsListIndex = NaN;
          if (!isNaN(numericValue)) {
            partsListIndex = numericValue;
          }
          this.contentReader.handleContentLinkClick(result, partsListIndex);
        }
        break;
    }

    if (request) {
      await this.book.contentNavigation(request, NavigationInitiator.link);
    }
  };

  private async annotationClicked(sender: any) {
    if (this.selection === null) {
      return;
    }
    if (Convert.isEmptyOrSpaces((await this.selection.getSelectionText()).selTxt)) {
      this.book.annotationContentClicked.dispatch(
        (sender.dataset.anno as string).split(",").map((value) => parseInt(value, 10)),
        this
      );
    }
  }
  private favouriteClicked(sender: any) {
    this.book.favouriteContentClicked.dispatch(
      (sender.dataset.fav as string).split(",").map((value) => parseInt(value, 10)),
      this
    );
  }
  private globalNoteClicked(sender: any) {
    this.book.globalNoteContentClicked.dispatch(
      (sender.dataset.globalnote as string).split(",").map((value) => parseInt(value, 10)),
      this
    );
  }
  private async markIconClicked(sender: any) {
    if (!sender.dataset.anno && !sender.dataset.fav && !sender.dataset.globalnote) {
      return;
    }
    // Only 1 fave, no annotations
    if (!sender.dataset.globalnote && !sender.dataset.anno && sender.dataset.fav && sender.dataset.fav.split(",").length === 1) {
      this.favouriteClicked(sender);
    }
    // Only 1 anno, no faves
    else if (!sender.dataset.globalnote && !sender.dataset.fav && sender.dataset.anno && sender.dataset.anno.split(",").length === 1) {
      await this.annotationClicked(sender);
    }
    // Only 1 globalnote, nothing else
    else if (sender.dataset.globalnote && !sender.dataset.fav && !sender.dataset.anno && sender.dataset.globalnote.split(",").length === 1) {
      this.globalNoteClicked(sender);
    }

    // Some combination of all 3
    else {
      let faves: number[] = [];
      if (sender.dataset.fav) {
        faves = (sender.dataset.fav as string).split(",").map((value) => parseInt(value, 10));
      }
      let annos: number[] = [];
      if (sender.dataset.anno) {
        annos = (sender.dataset.anno as string).split(",").map((value) => parseInt(value, 10));
      }
      let globalNotes: number[] = [];
      if (sender.dataset.globalnote) {
        globalNotes = (sender.dataset.globalnote as string).split(",").map((value) => parseInt(value, 10));
      }

      this.book.multiAnnoFaveContentClicked.dispatch([annos, faves, globalNotes], this);
    }
  }
  private globalNoteIconClicked(sender: any) {
    this.globalNoteClicked(sender);
  }

  public feed(batch: IContentNavigationR) {
    this.apiServer.triggerNavigation();
    let message: CogniflowMessage = new CogniflowMessage();
    message.Batches.push(batch);
    this.currentPopup = batch.Popup;
    if (this.currentPopup) {
      if (batch.InPlace) {
        this.showPopup(this.currentPopup);
        this.currentPopup = undefined;
        if (this.cogniflow.hasSecondaryId(batch.TargetHeadId)) {
          return;
        }
      }
    }

    if (!batch.InPlace) {
      this.cogniflow.clearAddonElements();
    }
    let nav: ((navigation: any) => Promise<ICogniflowNavigationResponse | null>) | undefined = this.getNavigationOffsetFunc(batch);
    this.cogniflow.replaceAllNodes(batch.Nodes, batch.TargetMainId, nav, batch);
  }

  private getNavigationOffsetFunc(navigation: IContentNavigationR): any {
    if (navigation.Annotation !== 0) {
      return this.navigateToAnnotation.bind(this);
    } else if (navigation.Favourite !== 0) {
      return this.navigateToFavourite.bind(this);
    } else if (navigation.RelativeOffset !== 0) {
      return this.navigateToRelativeOffset.bind(this);
    } else if (navigation.Selector.length > 0) {
      return this.navigateToSelector.bind(this);
    }
    return undefined;
  }

  private navigateToRelativeOffset(navigation: IContentNavigationR): { offset: number; mainId: number } {
    let seg = this.cogniflow.getSegmentByMain(navigation.TargetMainId);
    let offset = 0;
    if (seg) {
      let segRect = seg.getBoundingClientRect();
      offset = segRect.height * navigation.RelativeOffset;
    }
    return { offset: offset, mainId: navigation.TargetMainId };
  }

  public async scrollToHit(dir: HitDirection, headId?: number): Promise<boolean> {
    if (dir === HitDirection.FirstHitInSegment) {
      let node = this.cogniflow.getNodeBySecondary(headId!) as ContentSegment;
      if (!node) {
        return false;
      }
      let segment = this.cogniflow.getSegmentByMain(node.SpineId)!;
      let hits = Array.from(segment.querySelectorAll(".HIT.HIT_CONNECTOR"));
      let tops = hits.filter((x) => x.getBoundingClientRect().top- 50 > 0).map((x) => x.getBoundingClientRect().top- 50);
      if (tops.length === 0) {
        return false;
      }
      this.root.scrollTop = this.root.scrollTop + tops[0] - 50;
      return true;
    }
    if (dir === HitDirection.NextHit) {
      let hits = Array.from(this.root.querySelectorAll(".HIT.HIT_CONNECTOR"));
      let tops = hits.filter((x) => x.getBoundingClientRect().top- 50 > 1).map((x) => x);

      if (tops.length === 0) {
        return false;
      }
      let segment = tops[0].closest(".ContentSegment") as HTMLDivElement;
      let spine = +segment.dataset["cogniflow-Spine"]!;
      if (this.cogniflow.navigateWillRequireAppend(spine, (tops[0] as HTMLDivElement).offsetTop)) {
        await this.cogniflow.navigateToNode(
          spine,
          (tops[0] as HTMLDivElement).offsetTop,
          async (direction) => {
            await this.scrollToHit(direction as HitDirection);
            return null;
          },
          dir
        );
      } else {
        this.root.scrollTop = this.root.scrollTop + tops[0].getBoundingClientRect().top - 50;
      }
      return true;
    } else if (dir === HitDirection.PreviousHit) {
      let hits = Array.from(this.root.querySelectorAll(".HIT.HIT_CONNECTOR"));
      let tops = hits.filter((x) => x.getBoundingClientRect().top- 50 < 0).map((x) => x.getBoundingClientRect().top- 50);

      if (tops.length === 0) {
        return false;
      }
      this.root.scrollTop = this.root.scrollTop + tops[tops.length - 1] - 50;
      return true;
    } else {
      let node = this.cogniflow.getNodeBySecondary(headId!) as ContentSegment;
      if (!node) {
        return false;
      }
      let segment = this.cogniflow.getSegmentByMain(node.SpineId)!;
      let hits = Array.from(segment.querySelectorAll(".HIT.HIT_CONNECTOR"));
      let tops = hits.filter((x) => x.getBoundingClientRect().top- 50 > 0).map((x) => x.getBoundingClientRect().top- 50);
      if (tops.length === 0) {
        return false;
      }
      this.root.scrollTop = this.root.scrollTop + tops[tops.length - 1] - 50;
      return true;
    }
  }

  private navigateToAnnotation(navigation: IContentNavigationR): { element: HTMLElement; mainId: number } | { offset: number; mainId: number } {
    // todo: perform from popup
    let annotationTarget: HTMLElement | undefined = this.findAnnotationAnchor(this.root, navigation.Annotation);

    if (!annotationTarget) {
      return { offset: 0, mainId: navigation.TargetMainId };
    }
    return { element: annotationTarget, mainId: navigation.TargetMainId };
  }
  findAnnotationAnchor(parent: HTMLDivElement, id: number): HTMLElement | undefined {
    let annotations = parent.querySelectorAll("span[data-anno]")!;
    for (let i = 0; i < annotations.length; i++) {
      let ids = annotations[i].getAttribute("data-anno")!.split(",");
      if (ids.indexOf(id.toString()) !== -1) {
        return annotations[i] as HTMLElement;
      }
    }
    return undefined;
  }
  navigateToFavourite(navigation: IContentNavigationR): { element: HTMLElement; mainId: number } | { offset: number; mainId: number } {
    // todo: perform from popup
    const favouriteId = navigation.Favourite;
    if (!favouriteId) {
      return { offset: 0, mainId: navigation.TargetMainId };
    }
    let targetFavourite: HTMLElement | undefined = this.findFavouriteAnchor(this.root, favouriteId);

    if (!targetFavourite) {
      let seg = this.cogniflow.getSegmentByMain(navigation.TargetMainId);
      let fave = this.book.favourites.get(favouriteId);
      let offset = 0;
      if (seg && fave) {
        let segRect = seg.getBoundingClientRect();
        offset = segRect.height * (fave.YOffset / (fave.YDocHeight === 0 ? 1 : fave.YDocHeight));
      }
      return { offset: offset, mainId: navigation.TargetMainId };
    }

    return { element: targetFavourite, mainId: navigation.TargetMainId };
  }
  findFavouriteAnchor(parent: HTMLElement, id: number): HTMLElement | undefined {
    let favourites = parent.querySelectorAll(".mark-icon[data-fav]")!;
    for (let i = 0; i < favourites.length; i++) {
      let ids = (favourites[i].getAttribute("data-fav") || "").split(",");
      if (ids.indexOf(id.toString()) !== -1) {
        return favourites[i] as HTMLElement;
      }
    }
    return undefined;
  }
  private async navigateToSelector(navigation: IContentNavigationR) : Promise<ICogniflowNavigationResponse> {
    try {
      let item = this.apiServer.findItem(navigation.Selector);
      if (item) {
        return { mainId: navigation.TargetMainId, element: item } as ICogniflowNavigationResponse;
      }
    } catch (e) {
      const error = e as Error;
      await Log.warn("Could not JQuery fetch with selector. " + error.message + `\nIn book ${this.book.versionInfo!.Title}`);
    }

    return { mainId: navigation.TargetMainId, selector: navigation.Selector } as ICogniflowNavigationResponse;
  }

  public showPopup(popup: ContentSegment) {
    if (
      this.cogniflow.state.addonElements.length > 0 &&
      (this.cogniflow.state.addonElements[this.cogniflow.state.addonElements.length - 1] as ContentPopupModel).popupSegment.HeadId === popup.HeadId
    ) {
      // Already a popup for this open on top...
      return;
    }
    this.cogniflow.addAddonElement({ index: this.cogniflow.state.addonElements.length, popupSegment: popup } as ContentPopupModel);
  }
  public buildPopup(popup: ContentPopupModel) {
    let segment = this.cogniflow.generateSegment(popup.popupSegment);
    return (
      <ContentPopup
        isTop={popup.index === this.cogniflow.state.addonElements.length - 1}
        popIndex={popup.index}
        key={popup.index}
        onClose={this.cogniflow.removeAddonElement}
        addonIndex={popup.index}
        segment={segment!}
        currentFontsize={this.getFontsizeClass()}
      />
    );
  }
  private getFontsizeClass(): string {
    switch (this.book.appSettings.get().ReaderFontSize) {
      case FontSizes.Smallest:
        return "font-smallest";
      case FontSizes.Smaller:
        return "font-smaller";
      case FontSizes.Normal:
        return "font-medium";
      case FontSizes.Larger:
        return "font-larger";
      case FontSizes.Largest:
        return "font-largest";
    }
  }
  public replacePopups(popups: ContentSegment[]) {
    let holder = this.cogniflow.state.addonElements;
    holder.map((x: INode, i) => {
      popups.map((y: ContentSegment) => {
        if (x.popupSegment.HeadId === y.HeadId) {
          holder[i] = { index: i, popupSegment: y } as ContentPopupModel;
        }
      });
    });
    this.cogniflow.replaceAllAddonElements(holder);
  }
}
