import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Button, Col, DropdownProps, Form, FormGroup, Input, Label, Row } from 'reactstrap';
// import { Resource } from 'src/models/Resource';
import { Annotation, AnnotationType } from 'src/models/UserContent';
import { Binding } from 'src/utilities/Events';

import { BookContext } from '../../state/Contextes';
import { AnnotationTypeComponent } from '../Controls';
import { AnnotationTypeForm } from './AnnotationTypeForm';
import { ProLibroMarkdown } from './proLibroMarkdown';

interface UnboundAnnotationFormProps {
  initialModel: Annotation;
  creationConfirm: (model: Annotation) => void;
  globalNoteCreationConfirm: (globalNote: string) => any;
}

const UnboundAnnotationForm: React.FC<UnboundAnnotationFormProps> = (props: UnboundAnnotationFormProps) => {
  const context = useContext(BookContext);
  const localFormRef = useRef<AnnotationTypeForm>(null);
  const concreteFormRef = useRef<HTMLFormElement>(null);
  // let noteResource: Resource;
  const [state, setState] = useState({
    editedModel: props.initialModel,
    dropdownOpen: false,
    typeMode: false,
    globalNoteMode: false,
    markdownSelectedTab: "write",
    ready: false,
  });

  const [value, setValue] = React.useState(state.editedModel.Note);

  context.annotationTypes.addListener(() => {
    let newModel = state.editedModel;
    let items = context.annotationTypes
      .rows()
      .slice()
      .sort((a, b) => new Date(b.LastUpdate).getTime() - new Date(a.LastUpdate).getTime());
    newModel.AnnotationTypeId = items[0] ? items[0].Id : 0;
    setState((prevState) => ({ ...prevState, typeMode: false, editedModel: newModel }));
  });
  
  const onModelUpdate = (newModel: Annotation) => {
    setState((prevState) => ({ ...prevState, editedModel: newModel }));
    setValue(newModel.Note);
  };

  const handleMDEditorInput = (val?: string) => {
    if (val !== undefined) {
      let model = state.editedModel;
      model.Note = val;
      onModelUpdate(model);
    }
  };

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let model = state.editedModel;
    if (typeof event === "string") {
      model.Note = event;
    } else {
      model.Note = event.target.value;
    }
    onModelUpdate(model);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDropdownSelection = (eventKey: string | null, _: DropdownProps) => {
    if (!eventKey) return;
    let model = state.editedModel;
    model.AnnotationTypeId = +eventKey;
    onModelUpdate(model);
  };

  const createAnnotationType = async () => {
    let result = await localFormRef.current?.createAnnotationType();
    if (result && localFormRef.current?.valid()) {
      let newModel = state.editedModel;
      newModel.AnnotationTypeId = context.annotationTypes
        .rows()
        .slice()
        .sort((a, b) => new Date(b.LastUpdate).getTime() - new Date(a.LastUpdate).getTime())[0].Id ?? 0;
      setState((prevState) => ({ ...prevState, typeMode: false, editedModel: newModel }));
    }
  };

  const goBack = () => {
    setState((prevState) => ({ ...prevState, typeMode: false }));
  };

  let allTypes: AnnotationType[];
  let selectedType: AnnotationType;
  if (!context.annotationTypes.rows().length) {
    allTypes = [];
    selectedType = new AnnotationType();
  } else {
    allTypes = context.annotationTypes.rows();
    selectedType = context.annotationTypes.get(state.editedModel.AnnotationTypeId)!;
  }

  if (state.typeMode) {
    return (
      <div>
        <h1>{context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_NEW}</h1>
        <AnnotationTypeForm
          onActionClicked={() => void createAnnotationType()}
          model={new Binding<AnnotationType | null>(new AnnotationType())}
          goBackClicked={goBack}
          ref={localFormRef}
          showSave={true}
        />
      </div>
    );
  }

  let finalForm: React.ReactNode;

  if (concreteFormRef.current && concreteFormRef.current.clientWidth > 405) {
    finalForm = <ProLibroMarkdown value={value} onChange={handleMDEditorInput} intialPreview="live" />;
  } else if (concreteFormRef.current && concreteFormRef.current.clientWidth <= 405) {
    finalForm = <Input type="text" name="note" id="note" defaultValue={state.editedModel.Note} onChange={handleInput} />;
  } else {
    finalForm = <Input type="text" name="note" id="note" defaultValue={state.editedModel.Note} onChange={handleInput} />;
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, ready: true }));
    }, 200);
  }

  return (
    <Form
      className="p-2 form-condensed"
      onSubmit={(e) => {
        e.preventDefault();
        if (state.globalNoteMode === true) {
          props.globalNoteCreationConfirm(state.editedModel.Note);
        } else {
          props.creationConfirm(state.editedModel);
        }
      }}
      innerRef={concreteFormRef}
    >
      <h1>{state.globalNoteMode ? "New Global Note" : context.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_CREATION}</h1>
      <FormGroup>
        <Label for="note">{context.localization.currentLocale.AnnotationView.LABEL_NOTE}</Label>
        {finalForm}
      </FormGroup>
      <FormGroup>
        <Label>{context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW}</Label>
        {state.globalNoteMode === false && (
          <Row>
            {allTypes.length > 0 && (
              <Col xs="auto">
                <div className="dropdownContainer">
                  <Dropdown onSelect={onDropdownSelection}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <AnnotationTypeComponent color={selectedType.Color} name={selectedType.Value} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {allTypes
                        .slice()
                        .sort((a, b) => new Date(b.LastUpdate).getTime() - new Date(a.LastUpdate).getTime())
                        .map((item) => (
                          <Dropdown.Item eventKey={item.Id.toString()} key={item.Id} as="div">
                            <AnnotationTypeComponent color={item.Color} name={item.Value} />
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            )}
            <Col xs="auto">
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  setState((prevState) => ({ ...prevState, typeMode: true }));
                }}
                style={{ marginBottom: "10px" }}
              >
                {context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_NEW}
              </Button>
            </Col>
            {context.parentContext.canManageContentReview() && (
              <Col xs="auto">
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    setState((prevState) => ({ ...prevState, globalNoteMode: true }));
                  }}
                  style={{ marginBottom: "10px" }}
                >
                  {context.localization.currentLocale.AnnotationTypeView.LABEL_SET_GLOBAL_NOTE}
                </Button>
              </Col>
            )}
          </Row>
        )}
        {state.globalNoteMode === true && (
          <Row>
            <Col xs="auto">
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  setState((prevState) => ({ ...prevState, globalNoteMode: false }));
                }}
                style={{ marginBottom: "10px" }}
              >
                {"Return to Annotation"}
              </Button>
            </Col>
          </Row>
        )}
      </FormGroup>
      <button className="btn btn-secondary" type="submit" value="Save">
        {context.localization.currentLocale.AnnotationTypeView.LABEL_SAVE}
      </button>
    </Form>
  );
};

UnboundAnnotationForm.displayName = "UnboundAnnotationForm";

export default UnboundAnnotationForm;
