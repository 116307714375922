import * as React from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { Locale } from 'src/localization/Locale';
import { Book } from 'src/models/Book';
import { GlobalNoteState } from 'src/models/Community';
import { IGlobalNoteViewModel } from 'src/models/dto/UCRequest';
import { Resource } from 'src/models/Resource';
import { Wire } from 'src/network/Wire';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';

import { Loading } from '../foundation/Controls';
import { BookContext } from '../state/Contextes';

export interface IGlobalNoteModalState {}

export interface IGlobalNoteModalProps {
  show: boolean;
  handleClose: (userFormSubmissionRef: string) => void;
  currentGlobalNote: IGlobalNoteViewModel;
  localization: Locale;
  loading: boolean;
  children?: React.ReactNode;
}
export class GlobalNoteModal extends React.Component<IGlobalNoteModalProps, IGlobalNoteModalState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IGlobalNoteModalProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount(): void {
    this.globalNoteResource = new Resource(Wire.shield(this.context.wire), -1);
  }

  globalNoteResource: Resource;
  markdownLinkClicked() {
    return "webMedia";
  }
  async markdownLinkOverride(e: React.MouseEvent) {
    if ((e.target as HTMLAnchorElement).target === "webMedia") {
      e.preventDefault();
      e.stopPropagation();
      if (this.globalNoteResource) {
        await this.globalNoteResource.openExternalWebsite((e.target as HTMLAnchorElement).href);
      }
    }
  }
  render(): any {
    let paddedId = this.props.currentGlobalNote.Note.TableId.toString().padStart(6, "0");
    return (
      <div className="innerGlobalNote flex-fill globalNoteContainer" onClick={() => this.props.handleClose("")}>
        <div className="unbound-form-modal globalNoteModal" />
        <div
          className="unbound-form-bulletins globalNoteContent"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="full-width full-height">
            <div className="communityFormContainer" style={{ display: "flex" }}>
              <Loading className="full-width full-height" isLoading={this.props.show} theme="opaque" status="Loading...">
                <div className="form-container">
                  <div className="submissionInfo">
                    <h3>
                      <span className="globalNoteSubHeader">
                        {this.props.currentGlobalNote.Note.Name +
                          (this.props.currentGlobalNote.Note.FriendlyNoteState === GlobalNoteState.InReview
                            ? " [" + this.props.localization.currentLocale.GlobalNotesView.LABEL_IN_REVIEW + "]"
                            : "") +
                          (this.props.currentGlobalNote.Note.FriendlyNoteState === GlobalNoteState.Published &&
                          this.context.parentContext.canManageContentReview()
                            ? " [" + this.props.localization.currentLocale.GlobalNotesView.LABEL_PUBLISHED + "]"
                            : "")}
                      </span>
                    </h3>
                    <h5>
                      <span className="globalNoteSubHeader">Global Note ID: {paddedId}</span>
                    </h5>
                    <span className="globalNoteExplain">{this.props.localization.currentLocale.GlobalNotesView.ALERT_EXPLANATION}</span>
                    <div className="globalNoteDetails">
                      <FormGroup className="sideBySide">
                        <div>
                          <span className="detailHeader">{this.props.localization.currentLocale.GlobalNotesView.LABEL_CREATED_ON} </span>
                          <span>{Convert.dateToFormattedString(this.props.currentGlobalNote.Note.CreationDate, this.props.localization.currentCulture)}</span>
                        </div>
                        <div>
                          <span className="detailHeader">{this.props.localization.currentLocale.GlobalNotesView.LABEL_LAST_MODIFIED} </span>
                          <span>{Convert.dateToFormattedString(this.props.currentGlobalNote.Note.LastModified, this.props.localization.currentCulture)}</span>
                        </div>
                      </FormGroup>
                    </div>
                    <span className="noteHeader">{this.props.localization.currentLocale.GlobalNotesView.LABEL_NOTE_HEADER}</span>
                    <FormGroup>
                      <div className="globalNoteNoteBox">
                        {ResourcePlatform.toMarkdown(this.props.currentGlobalNote.Note.Note, () => this.markdownLinkOverride, this.markdownLinkClicked)}
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </Loading>
            </div>
          </div>
          <Button className="saveButton" onClick={() => this.props.handleClose("")} variant="outline-primary" color="primary">
            {this.props.localization.currentLocale.GlobalNotesView.LABEL_DISMISS}
          </Button>
        </div>
      </div>
    );
  }
}
