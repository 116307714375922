import * as React from 'react';
import { Book } from 'src/models/Book';
import { IGlobalNoteViewModel } from 'src/models/dto/UCRequest';
import { Annotation, Favourite } from 'src/models/UserContent';

import { BookContext } from '../state/Contextes';
import { AnnotationList, GlobalNoteList } from '../views/AnnotationView';
import { FavouriteList } from '../views/FavouriteView';

interface IUserContentMultiSelectorProps {}

interface IUserContentMultiSelectorState {
  isOpen: boolean;
  currentAnnos: Annotation[] | null;
  currentFaves: Favourite[] | null;
  currentGlobalNotes: IGlobalNoteViewModel[] | null;
}

export class UserContentMultiSelector extends React.Component<IUserContentMultiSelectorProps, IUserContentMultiSelectorState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IUserContentMultiSelectorProps) {
    super(props);

    this.state = { isOpen: false, currentAnnos: null, currentFaves: null, currentGlobalNotes: null };
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.itemSelected = this.itemSelected.bind(this);
  }

  hide() {
    this.setState({ isOpen: false });
  }
  show(annoIDs: number[], faveIDs: number[], globalNoteIDs: number[]) {
    let annos: Annotation[] = [];
    let faves: Favourite[] = [];
    let globalNotes: IGlobalNoteViewModel[] = [];
    annoIDs.map((x) => {
      let anno = this.context.annotations.get(+x);
      if (anno) {
        annos.push(anno);
      }
    });
    annos.sort((x1, x2) => {
      if (x1.LastUpdate > x2.LastUpdate) {
        return -1;
      }
      if (x1.LastUpdate < x2.LastUpdate) {
        return 1;
      }
      return 0;
    });
    faveIDs.map((x) => {
      let fave = this.context.favourites.get(+x);
      if (fave) {
        faves.push(fave);
      }
    });
    faves.sort((x1, x2) => {
      if (x1.LastUpdate > x2.LastUpdate) {
        return -1;
      }
      if (x1.LastUpdate < x2.LastUpdate) {
        return 1;
      }
      return 0;
    });
    globalNoteIDs.map((x) => {
      let globalNote = this.context.globalNotes.get(+x);
      if (globalNote) {
        globalNotes.push(globalNote);
      }
    });
    globalNotes.sort((x1, x2) => {
      if (x1.Note.LastModified > x2.Note.LastModified) {
        return -1;
      }
      if (x1.Note.LastModified < x2.Note.LastModified) {
        return 1;
      }
      return 0;
    });
    this.setState({ isOpen: true, currentAnnos: annos, currentFaves: faves, currentGlobalNotes: globalNotes });
  }

  itemSelected(item: Annotation | Favourite | IGlobalNoteViewModel) {
    this.hide();
    if (Object.prototype.hasOwnProperty.call(item, "Note") && Object.prototype.hasOwnProperty.call(item, "AnnotationTypeId")) {
      this.context.annotationContentClicked.dispatch([(item as Annotation).Id], this);
    }
    // Global Note
    else if (Object.prototype.hasOwnProperty.call(item, "Note")) {
      this.context.globalNoteContentClicked.dispatch([(item as IGlobalNoteViewModel).Note.TableId], this);
    } else {
      this.context.favouriteContentClicked.dispatch([(item as Favourite).Id], this);
    }
  }

  render() {
    if (this.state.isOpen && (this.state.currentAnnos || this.state.currentFaves || this.state.currentGlobalNotes)) {
      let annoSection;
      let faveSection;
      let globalNoteSection;
      if (this.state.currentAnnos) {
        annoSection = <AnnotationList items={this.state.currentAnnos} onItemClicked={this.itemSelected} selectedAnnotationIDs={[]} />;
      }
      if (this.state.currentFaves) {
        faveSection = <FavouriteList items={this.state.currentFaves} onItemClicked={this.itemSelected} selectedFavouriteIDs={[]} localization={this.context.localization} />;
      }
      if (this.state.currentGlobalNotes) {
        globalNoteSection = <GlobalNoteList items={this.state.currentGlobalNotes} onItemClicked={this.itemSelected} selectedGlobalNoteIDs={[]} localization={this.context.localization} />;
      }

      return (
        <div>
          <div className="unbound-form-modal" onClick={this.hide} />
          <div className="unbound-form-container">
            {this.state.currentAnnos && this.state.currentAnnos.length ? <h3>Global Notes</h3> : ""}
            <div className="ucContainer">{globalNoteSection}</div>
            {this.state.currentAnnos && this.state.currentAnnos.length ? <h3>Annotations</h3> : ""}
            <div className="ucContainer">{annoSection}</div>
            {this.state.currentFaves && this.state.currentFaves.length ? <h3>Favourites</h3> : ""}
            <div className="ucContainer">{faveSection}</div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}
