import React from 'react';
import { TwitterPicker } from 'react-color';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { Book } from 'src/models/Book';
import { AnnotationType } from 'src/models/UserContent';
import * as Messages from 'src/ui/foundation/Messages';
import { BookContext } from 'src/ui/state/Contextes';
import { IModelBinding } from 'src/ui/state/Generics';
import { AnnotationTypeFormActions } from 'src/utilities/Enums';
import { FormState } from 'src/utilities/FormState';
import { Validator } from 'src/utilities/Validator';

interface IAnnotationTypeFormProps extends IModelBinding<AnnotationType|null> {
    onActionClicked: (action: AnnotationTypeFormActions)=> void;
    goBackClicked?: () => void;
    showSave: boolean;
  }
  
  interface IAnnotationTypeFormState {
    form: FormState;
    edited: boolean;
  }
  
  export class AnnotationTypeForm extends React.Component<IAnnotationTypeFormProps, IAnnotationTypeFormState> {
    context: Book;
    static contextType = BookContext;
  
    constructor(props: IAnnotationTypeFormProps | Readonly<IAnnotationTypeFormProps>) {
      super(props);
      this.onModelUpdate = this.onModelUpdate.bind(this);
      this.handleInput = this.handleInput.bind(this);
      this.submit = this.submit.bind(this);
      this.createAnnotationType = this.createAnnotationType.bind(this);
      this.handleColourChange = this.handleColourChange.bind(this);
      let form = new FormState({
        id: 0,
        name: "",
        colourObj: { hex: "#FFFFFF" },
      });
      this.state = {
        form,
        edited: false,
      };
    }
  
    onModelUpdate() {
      if (this.props.model.current && this.props.model.current !== null) {
        const annotationType = this.props.model.current;
        this.setState({
          form: this.state.form.update({
            id: annotationType.Id,
            name: annotationType.Value,
            colourObj: { hex: annotationType.Color },
          }),
          edited: false,
        });
      } else {
        this.setState({
          form: this.state.form.reset(),
          edited: false,
        });
      }
    }
    componentDidMount() {
      this.props.model.on(this.onModelUpdate);
      this.state.form.addValidator(
        "name",
        Validator.notNullOrWhitespace,
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPENAMENEEDED_PROMPT
      );
    }
  
    componentWillUnmount() {
      this.props.model.off(this.onModelUpdate);
    }
  
    async submit() {
      let form = this.state.form;
      if (this.state.edited) {
        if (form.formValid()) {
          let requ = {
            Id: form.values.id,
            Colour: form.values.colourObj.hex,
            Name: form.values.name,
          };
          let result = await this.context.updateAnnotationType(requ);
          if (result.valid()) {
            Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPESAVED);
          } else {
            Messages.Notify.error(result.errors[0].Message);
          }
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEERROR);
        }
      }
    }
  
    async createAnnotationType(): Promise<boolean> {
      let form = this.state.form;
      if (this.state.edited) {
        if (form.formValid()) {
          let requ = {
            Colour: form.values.colourObj.hex,
            Name: form.values.name,
          };
          let result = await this.context.createAnnotationType(requ);
          if (result.valid()) {
            Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPESAVED);
            return true;
          } else {
            Messages.Notify.error(result.errors[0].Message);
            return false;
          }
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEERROR);
          return false;
        }
      }
      return false;
    }
  
    handleInput(event: { target: { name: string; type: string; value?: any; checked?: boolean | undefined; }; }) {
      this.setState({
        form: this.state.form.change(event.target),
        edited: true,
      });
    }
  
    handleColourChange(event: any) {
      this.setState({
        form: this.state.form.change({ name: "colourObj", type: "colour", value: event }),
        edited: true,
      });
      this.setState({
        form: this.state.form.change({ name: "name", type: "text", value: this.state.form.values.name }),
        edited: true,
      });
    }
  
    async deleteAnnotationType(): Promise<boolean> {
      if (
        (await Messages.Dialog.confirm(
          this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPEDELETE_PROMPT,
          this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPEDELETE_HEADING
        )) === "true"
      ) {
        let form = this.state.form;
        let result = await this.context.deleteAnnotationType({
          Id: form.values.id,
        });
        if (result.valid()) {
          Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEDELETED);
          return true;
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEERROR);
        }
      }
      return false;
    }
    valid(): boolean {
      return this.state.form.formValid();
    }
  
    render() {
      if (!this.state || !this.state.form) {
        return "";
      }
      let defaultColors = [
        "#FFFF00",
        "#FF33FF",
        "#33FF00",
        "#40E0D0",
        "#66FF00",
        "#00D084",
        "#CCFF00",
        "#0099FF",
        "#0693E3",
        "#7BDCB5",
        "#8ED1FC",
        "#ABB8C3",
        "#DA99FF",
        "#F78DA7",
        "#EB144C",
        "#FF6900",
        "#FCB900",
      ];
      let existingColors = [
        ...new Set(
          this.context.annotationTypes
            .rows()
            .sort((x1, x2) => {
              if (x1.LastUpdate > x2.LastUpdate) {
                return 1;
              }
              if (x1.LastUpdate < x2.LastUpdate) {
                return -1;
              }
              return 0;
            })
            .reverse()
            .map((item: { Color: string; }) => item.Color.toUpperCase())
        ),
      ];
      let finalColors = [...new Set(existingColors.concat(defaultColors))].slice(0, 17);
      return (
        <Form className={"annotation-view-create-form p-2 form-condensed"}>
          <FormGroup>
            <Input
              type="text"
              name="name"
              value={this.state.form.values.name}
              invalid={this.state.form.invalid("name")}
              onChange={this.handleInput}
              placeholder={"Name"}
            />
            <FormFeedback tooltip={false}>{this.state.form.getErrors("name")}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <div style={{ padding: "8px" }}>
              <span style={{ backgroundColor: this.state.form.values.colourObj.hex }}>
                {this.context.localization.currentLocale.AnnotationTypeView.LABEL_EXAMPLE_ANNOTATION}
              </span>
            </div>
          </FormGroup>
          <div className="colourPickerContainer">
            <TwitterPicker colors={finalColors} color={this.state.form.values.colourObj} width="250" onChangeComplete={this.handleColourChange} />
          </div>
          <Row>
            {this.props.showSave && (
              <Col xs="auto">
                <Button
                  onClick={(e: any) => {
                    if (this.state.form.formValid()) {
                      e.preventDefault();
                      this.props.onActionClicked(AnnotationTypeFormActions.save);
                    }
                  }}
                >
                  {this.context.localization.currentLocale.AnnotationTypeView.LABEL_SAVE}
                </Button>
              </Col>
            )}
            {this.props.goBackClicked && (
              <Col xs="auto">
                <Button
                  onClick={(e: any) => {
                    e.preventDefault();
                    this.props.goBackClicked!();
                  }}
                >
                  {this.context.localization.currentLocale.Application.ALERT_CANCEL}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      );
    }
  }