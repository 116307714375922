import * as React from 'react';
import * as Autosuggest from 'react-autosuggest';
import ReactDOMClient from 'react-dom/client';
import { MultiSelect } from 'react-multi-select-component';
import { Tooltip } from 'react-tooltip';
import {
    Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Label, Nav, Navbar,
    NavItem, NavLink
} from 'reactstrap';
import { Locale } from 'src/localization/Locale';
import { Book, BookLoadingSteps, NavigationInitiator, SearchFailure } from 'src/models/Book';
import {
    ExtendedHeader, FormattedSearchField, FormattedSearchForm, FormFilter, HitDirection, ListItem,
    ListType, NavigationRequest, QueryCommand, SearchFormInput, SearchHeader, SearchHeaderType,
    SearchSorting, SearchViewMode
} from 'src/models/Content';
import { ResultsCogniflowMessage } from 'src/models/dto/CogniflowRequest';
import { HistoryType } from 'src/models/UserContent';
import { Gauge } from 'src/ui/foundation/Controls/Gauge/src/Gauge';
import { EmitterEvent } from 'src/utilities/Events';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';
import { PrintBox } from 'src/utilities/Printbox';
import { SearchPrint } from 'src/utilities/SearchPrint';
import * as _ from 'underscore';

import { Image } from '../foundation/Assets';
import { ActionIcon, Expander, ExpanderClose, Icon, Loading } from '../foundation/Controls';
import { RightFlip } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import {
    ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { BookContext } from '../state/Contextes';

const printStyle_css = require("!!raw-loader!src/assets/css/searchPrint.css").default;
interface ISearchViewViewProps {
  isActive: boolean;
}
interface ISearchViewViewState {
  ready: boolean;
  isSearching: boolean;
  isQueryValid: boolean;
  currentFieldPrefix: string;
  formOpen: boolean;
  filteringActive: boolean;
  communityIncluded: boolean;
  searchSorting: SearchSorting;
  searchViewMode: SearchViewMode;
  xResults: number;
  selectedSearch: SearchHeader[];
}
export class SearchView extends React.Component<ISearchViewViewProps, ISearchViewViewState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: ISearchViewViewProps | Readonly<ISearchViewViewProps>) {
    super(props);
    this.state = {
      ready: false,
      isSearching: false,
      isQueryValid: true,
      communityIncluded: true,
      currentFieldPrefix: "",
      formOpen: false,
      filteringActive: false,
      searchSorting: SearchSorting.Ranked,
      searchViewMode: SearchViewMode.Expanded,
      xResults: 100,
      selectedSearch: [],
    };
    this.onExecuteSearch = this.onExecuteSearch.bind(this);
    this.onReady = this.onReady.bind(this);
    this.update = this.update.bind(this);
    this.updateSearchStatus = this.updateSearchStatus.bind(this);
    this.onSearchCancelled = this.onSearchCancelled.bind(this);
    this.onPrintSelectedSearch = this.onPrintSelectedSearch.bind(this);
    this.searchFailed = this.searchFailed.bind(this);
    this.onSearchResultClicked = this.onSearchResultClicked.bind(this);
    this.onDropdownFieldClicked = this.onDropdownFieldClicked.bind(this);
    this.onAdvancedToolsClicked = this.onAdvancedToolsClicked.bind(this);
    this.onPreviousSearchesClicked = this.onPreviousSearchesClicked.bind(this);
    this.onExecuteSearchWithProps = this.onExecuteSearchWithProps.bind(this);
    this.goBackFlip = this.goBackFlip.bind(this);
    this.setFilteringState = this.setFilteringState.bind(this);
    this.filteringStateChanged = this.filteringStateChanged.bind(this);
    this.onNextHit = this.onNextHit.bind(this);
    this.onPreviousHit = this.onPreviousHit.bind(this);
    this.onResultSortChanged = this.onResultSortChanged.bind(this);
    this.onCollapseToggled = this.onCollapseToggled.bind(this);
  }

  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.search, this);
    this.context.currentResults.addListener(this.update);
    await this.context.initSearch();
    this.context.loading.setLoaded(BookLoadingSteps.search);
  }
  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.search, () => void this.onReady());
    this.context.searchFailed.on(this.searchFailed);
    this.context.searchRemotelyExecuted.on(this.updateSearchStatus);
    this.context.searchFilteringChanged.on(this.setFilteringState);
    this.context.searchCancelled.on(() => {
      this.setState({ isSearching: false });
    });
    this.setState({ searchSorting: this.context.appSettings.get().SearchSorting, searchViewMode: this.context.appSettings.get().SearchViewMode });
  }

  componentWillUnmount() {
    this.context.currentResults.removeListener(this.update.bind(this));
    this.context.loading.stepLoading.off(BookLoadingSteps.search, () => void this.onReady());
    this.context.searchRemotelyExecuted.off(this.updateSearchStatus);
    this.context.searchFailed.off(this.searchFailed);
    this.context.searchFilteringChanged.off(this.setFilteringState);
    this.context.searchCancelled.off(() => {
      this.setState({ isSearching: false });
    });
  }
  componentDidUpdate() {
    if (this.context.searchFields && this.context.searchFields.length) {
      this.context.searchFields[0].Name = this.context.localization.currentLocale.SearchView.LABEL_SEARCH_KEYWORD;
    }
    // Is Prox
    if (this.context.searchForms && this.context.searchForms.length) {
      // Prox form
      this.context.searchForms[this.context.searchForms.length - 2].FormInputs[0].Name =
        this.context.localization.currentLocale.SearchTemplatePhrase.LABEL_TEMPLATE;
      this.context.searchForms[this.context.searchForms.length - 2].FormInputs[1].Name =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_ORDPROX;
      this.context.searchForms[this.context.searchForms.length - 2].FormInputs[1].Suffix =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_SUFFIX_WORDS;
      this.context.searchForms[this.context.searchForms.length - 2].FormInputs[2].Name = this.context.localization.currentLocale.SearchTemplate.ADVANCED_UNPROX;
      this.context.searchForms[this.context.searchForms.length - 2].FormInputs[2].Suffix =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_SUFFIX_WORDS;
      this.context.searchForms[this.context.searchForms.length - 2].Name = this.context.localization.currentLocale.SearchTemplate.TITLE_PROX;
      // Anno form
      this.context.searchForms[this.context.searchForms.length - 1].FormInputs[0].Name =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_ANNOTATIONS;
      this.context.searchForms[this.context.searchForms.length - 1].FormInputs[0].Suffix =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_SUFFIX_TYPE;
      this.context.searchForms[this.context.searchForms.length - 1].FormInputs[1].Name = this.context.localization.currentLocale.SearchTemplate.ADVANCED_NOTES;
      this.context.searchForms[this.context.searchForms.length - 1].FormInputs[1].Suffix =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_SUFFIX_TYPE;
      this.context.searchForms[this.context.searchForms.length - 1].FormInputs[2].Name =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_HIGHLIGHTS;
      this.context.searchForms[this.context.searchForms.length - 1].FormInputs[2].Suffix =
        this.context.localization.currentLocale.SearchTemplate.ADVANCED_SUFFIX_TYPE;
      this.context.searchForms[this.context.searchForms.length - 1].Name = this.context.localization.currentLocale.SearchTemplate.TITLE_ANNOTATIONS;
    }
  }
  shouldComponentUpdate(nextProps: any): boolean {
    return nextProps.isActive;
  }
  searchFailed(value: SearchFailure) {
    if (value === SearchFailure.InvalidQuery) {
      Messages.Notify.error(this.context.localization.currentLocale.SearchView.ALERT_INVALIDFIELD_PROMPT);
    } else if (value === SearchFailure.NoResults) {
      Messages.Notify.warning(this.context.localization.currentLocale.SearchView.ALERT_INVALIDSEARCH_PROMPT);
    }
  }

  setFilteringState(newVal: boolean) {
    this.setState({ filteringActive: newVal });
  }
  filteringStateChanged() {
    this.context.searchFilteringChanged.dispatch(!this.state.filteringActive, this);
  }
  communityInclusionChanged = () => {
    this.setState({ communityIncluded: !this.state.communityIncluded });
  };

  update(value: ExtendedHeader | undefined, event: EmitterEvent) {
    let readyState = true;
    // No results found :(
    if (
      (event === EmitterEvent.insert || event === EmitterEvent.update) &&
      this.context.currentResults.rows().length === 0 &&
      this.context.currentUserFormResults.rows().length === 0
    ) {
      Messages.Notify.warning(this.context.localization.currentLocale.SearchView.ALERT_INVALIDSEARCH_PROMPT);
      readyState = false;
    }
    this.setState({
      ready: readyState,
      isSearching: false,
    });
  }

  updateSearchStatus(query: string): void {
    if (!Convert.isEmptyOrSpaces(query)) {
      this.setState({ ready: false, isSearching: true });
    }
  }

  async onExecuteSearch(rawQuery: string) {
    // No results found :(
    if (!this.state.isQueryValid) {
      Messages.Notify.error(this.context.localization.currentLocale.SearchView.ALERT_INVALIDFIELD_PROMPT);
      return;
    }
    let finalQuery = this.state.currentFieldPrefix + rawQuery;
    // If there was a prefix, close the query grouping.
    if (this.state.currentFieldPrefix !== "") {
      finalQuery += "]";
    }
    await this.onExecuteSearchWithProps(this.state.filteringActive ? Array.from(this.context.currentFilter) : [], finalQuery);
  }

  async onExecuteSearchWithProps(filter: number[], query: string) {
    this.setState({ ready: false, isSearching: true, formOpen: false });
    await this.context.executeSearch({
      Filter: filter,
      Query: query,
      IncludeCommunity: this.state.communityIncluded,
    });
  }

  async onSearchCancelled() {
    await this.context.cancelSearch();
    this.setState({ isSearching: false });
  }

  onPrintSelectedSearch = async () => {
    // todo: add ability for the user to set xResults or select all
    let searches: JSX.Element[] = [];

    let previewResult = await this.context.requestSearchPrintPreviews({
      SearchViewMode: this.state.searchViewMode,
      SearchSorting: this.state.searchSorting,
      XResults: this.state.xResults,
    });

    if (!previewResult.valid()) {
      Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
      return;
    }
    previewResult.data.FormattedSearch.map((searchItem) => {
      searches.push(<SearchPrint isCollapsed={this.state.searchViewMode === SearchViewMode.Collapsed} model={searchItem} context={this.context} />);
    });
    
    const div = document.createElement("div");

    const printRoot = ReactDOMClient.createRoot(div);
    printRoot.render(
      <PrintBox headScript="" headStyle={printStyle_css}>
        {searches}
      </PrintBox>
    );
    
    let tries = 0;
    while( div.innerHTML === "" || tries >= 10) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      tries++;
    }

    let result = await this.context.printSearches({
      FormattedPrintPayload: div.innerHTML,
      SearchSorting: this.state.searchSorting,
      XResults: this.state.xResults,
    });
    if (result.valid() && !Convert.isEmptyOrSpaces(result.data.RawContent)) {
      // Do native call for print on web
      const win = ResourcePlatform.openNewTabWithContent(result.data.RawContent, "Print", false);
      // Adding a timeout to let the ContentView window react to the tab opening properly.
      // Without this, the whole browser freezes while the print preview is opened.
      if (win) {
        setTimeout(() => win.print(), 1000);
      }
    } else if (!result.valid()) {
      Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
    }
  };

  async onNextHit() {
    await this.context.contentNavigation(NavigationRequest.toHit(this.context.displayedHead, HitDirection.NextHit), NavigationInitiator.search);
  }
  async onPreviousHit() {
    await this.context.contentNavigation(NavigationRequest.toHit(this.context.displayedHead, HitDirection.PreviousHit), NavigationInitiator.search);
  }

  async onSearchResultClicked(result: SearchHeader) {
    if (result.HeaderType === SearchHeaderType.ContentResult) {
      this.state.selectedSearch.push(result);
      await this.context.contentNavigation(NavigationRequest.toHit(result.ItemId, HitDirection.FirstHitInSegment), NavigationInitiator.search);
    } else {
      this.context.userFormSubmissionRequested.dispatch(result.ItemId, this);
    }
  }
  onDropdownFieldClicked(dropdownIndex: number) {
    this.setState({ currentFieldPrefix: this.context.searchFields[dropdownIndex].FormattedPrefix });
  }

  onAdvancedToolsClicked() {
    this.setState({ formOpen: true });
  }
  onPreviousSearchesClicked() {
    this.context.historyPanelRequested.dispatch(HistoryType.Search, this);
  }
  goBackFlip() {
    this.setState({ formOpen: false });
  }
  onResultSortChanged() {
    let newState = SearchSorting.ReadingOrder;
    if (this.state.searchSorting !== SearchSorting.Ranked) {
      newState = SearchSorting.Ranked;
    }
    this.context.appSettings.set({ ...this.context.appSettings.get(), SearchSorting: newState });
    this.setState({ searchSorting: newState });
  }
  onCollapseToggled() {
    let newState = SearchViewMode.Expanded;
    if (this.state.searchViewMode !== SearchViewMode.Collapsed) {
      newState = SearchViewMode.Collapsed;
    }
    this.context.appSettings.set({ ...this.context.appSettings.get(), SearchViewMode: newState });
    this.setState({ searchViewMode: newState });
  }
  render() {
    return (
      <div className="result-view full-height d-flex flex-column">
        <RightFlip
          breakpoint={768}
          className="flex-fill"
          isOpen={this.state.formOpen}
          panelClassName={{
            left: "d-flex flex-column",
            right: "d-flex flex-column border-left bg-body",
          }}
        >
          {{
            left: (
              <React.Fragment>
                <ResultToolbar
                  onAdvancedToolsClicked={this.onAdvancedToolsClicked}
                  onPreviousSearchesClicked={this.onPreviousSearchesClicked}
                  dropdownItems={this.context.searchFields}
                  isSearching={this.state.isSearching}
                  onExecuteSearch={(rawQuery) => void this.onExecuteSearch(rawQuery)}
                  hasResults={this.context.currentResults.rows().length > 0 || this.context.currentUserFormResults.rows().length > 0}
                  onSearchCancelled={() => void this.onSearchCancelled()}
                  onPrintSelectedSearch={() => void this.onPrintSelectedSearch()}
                  onCollapseToggled={this.onCollapseToggled}
                  flipOpen={this.state.formOpen}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                  filteringActive={this.state.filteringActive}
                  communityIncluded={this.state.communityIncluded}
                  onFilteringStateChanged={this.filteringStateChanged}
                  onCommunityInclusionChanged={this.communityInclusionChanged}
                  onNextHitClicked={() => void this.onNextHit}
                  onPreviousHitClicked={() => void this.onPreviousHit}
                  onResultSortChanged={this.onResultSortChanged}
                  searchSorting={this.state.searchSorting}
                  searchViewMode={this.state.searchViewMode}
                />
                <Loading
                  className="flex-fill"
                  style={{ height: "0" }}
                  isLoading={this.state.isSearching}
                  status={this.context.localization.currentLocale.SearchView.LABEL_SEARCH_LOADING}
                >
                  <ResultFlow
                    isSearching={this.state.isSearching}
                    onSearchResultClicked={(result) => void this.onSearchResultClicked(result)}
                    searchSorting={this.state.searchSorting}
                    searchViewMode={this.state.searchViewMode}
                  />
                </Loading>
              </React.Fragment>
            ),
            right: (
              <React.Fragment>
                <SearchFormsToolbar onGoBackClicked={this.goBackFlip} localization={this.context.localization} />
                <div className="flex-fill scrollable">
                  <SearchForms
                    onExecuteSearchWithProps={(filter, query) => void this.onExecuteSearchWithProps(filter, query)}
                    Forms={this.context.searchForms ? this.context.searchForms : []}
                    IncludeCommunity={this.state.communityIncluded}
                  />
                </div>
              </React.Fragment>
            ),
          }}
        </RightFlip>
      </div>
    );
  }
}

interface ISuggestionsBoxProps {
  onSuggestionClicked: (value: string | number)=> void;

  currentSuggestions: string[];
  compareWord: string;
  isOpen: boolean;
  localization: Locale;
}

interface ISuggestionsBoxState {
  value: string;
}
export class SuggestionsBox extends React.Component<ISuggestionsBoxProps, ISuggestionsBoxState> {
  constructor(props: ISuggestionsBoxProps) {
    super(props);
    this.state = {
      value: "",
    };
  }

  render() {
    // Render nothing if the box shouldn't be open.
    let visibility = "search-suggestion-dropdown";

    if (!this.props.isOpen) {
      visibility += " collapsed";
    }

    let suggestions = [];
    if (
      this.props.currentSuggestions.length === 0 ||
      (this.props.currentSuggestions.length === 1 &&
        (this.props.compareWord === this.props.currentSuggestions[0] || this.props.currentSuggestions[0].trim() === "")) ||
      this.props.compareWord === ""
    ) {
      suggestions.push(
        <div
          onClick={() => {
            this.props.onSuggestionClicked(1);
          }}
          key={1}
        >
          <span className="search-suggestion-item">{this.props.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER}</span> <br />
        </div>
      );
      suggestions.push(
        <div
          onClick={() => {
            this.props.onSuggestionClicked(2);
          }}
          key={2}
        >
          <span className="search-suggestion-item">{this.props.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE}</span> <br />
        </div>
      );
    } else {
      {
        suggestions = this.props.currentSuggestions.map((item) => (
          <div
            onClick={() => {
              this.props.onSuggestionClicked(item);
            }}
            key={item}
          >
            <span className="search-suggestion-item">{item}</span> <br />
          </div>
        ));
      }
    }
    return (
      <div className="search-suggestion-container">
        <div className={visibility}>{suggestions}</div>
      </div>
    );
  }
}

interface IResultProps {
  onSearchResultClicked: (value: SearchHeader)=> void;
  searchSorting: SearchSorting;
  isSearching: boolean;
  searchViewMode: SearchViewMode;
}

interface IResultState {
  ready: boolean;
  results: number;
}

export class ResultFlow extends React.PureComponent<IResultProps, IResultState> {
  context: Book;
  static contextType = BookContext;
  cogniflow = React.createRef<StandaloneCogniflowContainer>();
  constructor(props: IResultProps | Readonly<IResultProps>) {
    super(props);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildResultItem = this.buildResultItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.onReady = this.onReady.bind(this);
    this.update = this.update.bind(this);
    this.state = { ready: false, results: 0 };
  }
  onReady() {
    this.context.currentResults.addListener(this.update);
    this.context.loading.setLoaded(BookLoadingSteps.search);
    this.setState({
      ready: true,
    });
  }

  componentDidMount() {
    this.onReady();
  }

  componentWillUnmount() {
    this.context.currentResults.removeListener(this.update);
  }

  noResultsFound() {
    Messages.Notify.warning(this.context.localization.currentLocale.SearchView.ALERT_INVALIDSEARCH_PROMPT);
  }

  update() {
    this.setState({ results: this.context.currentResults.length });
  }

  private initializeFlow(): Promise<{ nodes: SearchHeader[]; targetSpine: number }> {
    return this.context.getFirstResults(this.props.searchSorting)
      .then(result => {
        if (result.valid()) {
          return {
            nodes: result.data,
            targetSpine: result.data.length > 0 ? result.data[0].Index : 0,
          };
        } else {
          throw new Error("Result is not valid");
        }
      });
  }

  componentDidUpdate() {
    if (this.cogniflow.current && this.state.results > 0 && !this.props.isSearching) {
      this.cogniflow.current.reloadCogniflow();
    }
  }
  private flowProvider(request: IRequest): Promise<IResponse> {
    return this.context.fetchResults(
        Convert.cogniflowRequestToMessage(request) as ResultsCogniflowMessage,
        this.props.searchSorting
    ).then((result) => {
      if (!result.valid()) {
        throw new Error("Result is not valid");
      }
      return Convert.cogniflowMessageToResponse(result.data);
    });
  }
  private buildResultItem(node: INode): JSX.Element {
    return (
      <ResultItem
        onSearchResultClicked={this.props.onSearchResultClicked}
        source={node as SearchHeader}
        localization={this.context.localization}
        searchViewMode={this.props.searchViewMode}
      />
    );
  }

  private navigationDone() {
    if (this.context.loading.isLoading(BookLoadingSteps.search)) {
      this.context.loading.setLoaded(BookLoadingSteps.search);
    }
  }
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-result-index",
      secondaryIdDataAttribute: "unused",
      secondaryIdNodeAttribute: "unused",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      contentAttribute: "",
      applyDirectlyToSegment: false,
    },
    scrollingClasses: "flex-fill",
    batchSize: 20,
  };
  render() {
    if (this.state.ready && this.state.results > 0 && !this.props.isSearching) {
      return (
        <StandaloneCogniflowContainer
          provider={this.flowProvider}
          builder={this.buildResultItem}
          initialize={this.initializeFlow}
          extraSettings={this.settings}
          navigationDoneCallback={this.navigationDone}
          ref={this.cogniflow}
        />
      );
    } else if (!this.props.isSearching) {
      return (
        <div className="no-search-container">
          <span className="no-search-text">{this.context.localization.currentLocale.SearchView.LABEL_NOSEARCH}</span>
        </div>
      );
    } else {
      return "";
    }
  }
}

interface IResultItemProps {
  source: SearchHeader;
  onSearchResultClicked: (value: SearchHeader)=> void;
  localization: Locale;
  searchViewMode: SearchViewMode;
}

interface IResultItemState {}
export class ResultItem extends React.Component<IResultItemProps, IResultItemState> {
  render() {
    let counter = 0;
    let exes: JSX.Element[] = [];
    let collapsed = " collapsed";
    if (this.props.searchViewMode !== SearchViewMode.Collapsed) {
      exes = this.props.source.Excerpts.map((item) => {
        counter += 1;
        // Replace any XML token with their text equivalents. Underscore doesn't do the "apos" one so added a bunch just in case.
        let decode = _.unescape(item.Content)
          .replace(/&apos;/g, "'")
          .replace(/&quot;/g, '"')
          .replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&amp;/g, "&");
        if (item.IsHighlight) {
          return (
            <span key={counter} className="HIT">
              {decode}
            </span>
          );
        } else {
          return <span key={counter}>{decode}</span>;
        }
      });
      collapsed = "";
    }
    let icon = null;
    let collapseAddition = "";
    if (this.props.source.HeaderType === SearchHeaderType.Bulletin) {
      icon = <Image.bulletin />;
      collapseAddition = " collapsed";
    } else if (this.props.source.HeaderType === SearchHeaderType.Tip) {
      icon = <Image.tip />;
      collapseAddition = " collapsed";
    }

    return (
      <div
        className={"searchHeader" + collapsed}
        onClick={() => {
          this.props.onSearchResultClicked(this.props.source);
        }}
        data-index={this.props.source.Index}
        data-headid={this.props.source.ItemId}
        data-datapos={this.props.source.Index}
        data-isfirst={this.props.source.IsFirst}
        data-islast={this.props.source.IsLast}
      >
        <div className={"topBar" + collapseAddition}>
          {this.props.source.HeaderType === SearchHeaderType.ContentResult && (
            <div className="meter">
              <div className="meterCell">
                {this.props.searchViewMode !== SearchViewMode.Collapsed ? (
                  <Gauge
                    color={"var(--meterFillPrimaryColour)"}
                    value={Math.round(this.props.source.Relevancy * 100.0)}
                    width={100}
                    height={60}
                    min={0}
                    label=""
                  />
                ) : (
                  <Gauge
                    color={"var(--meterFillPrimaryColour)"}
                    value={Math.round(this.props.source.Relevancy * 100.0)}
                    width={75}
                    height={45}
                    min={0}
                    label=""
                  />
                )}
              </div>
            </div>
          )}
          {(this.props.source.HeaderType === SearchHeaderType.Tip || this.props.source.HeaderType === SearchHeaderType.Bulletin) && (
            <div className="userFormIconCollapsed">{icon}</div>
          )}
          <div className="title">
            <h4 className={"title-text" + collapsed}>{this.props.source.Title}</h4>
          </div>
        </div>
        <span className="path">{this.props.source.Path}</span>
        <div className="excerpt">{exes}</div>
        {this.props.source.MoreCount > 0 && this.props.searchViewMode !== SearchViewMode.Collapsed && (
          <div className="moreOccurencesCell">
            <em className="more">{this.props.source.MoreCount + " " + this.props.localization.currentLocale.SearchView.LABEL_SEARCH_MORE_RESULTS_FORMAT} </em>
          </div>
        )}
      </div>
    );
  }
}

interface IResultToolbarState {}

interface IResultToolbarProps {
  onExecuteSearch: (rawQuery: string)=> void;
  onSearchCancelled: (clickEvent: any)=> void;
  onPrintSelectedSearch: (clickEvent: any)=> void;
  onAdvancedToolsClicked: (event: any)=> void;
  onPreviousSearchesClicked: (event: any)=> void;
  onFilteringStateChanged: (event: any) => void;
  onNextHitClicked: (event: any) => void;
  onPreviousHitClicked: (event: any) => void;
  onResultSortChanged: () => void;
  onCollapseToggled: () => void;
  onCommunityInclusionChanged: () => void;
  hasResults: boolean;
  isSearching: boolean;
  dropdownItems: FormattedSearchField[];
  flipOpen: boolean;
  localization: Locale;
  helpEnabled: boolean;
  filteringActive: boolean;
  communityIncluded: boolean;
  searchSorting: SearchSorting;
  searchViewMode: SearchViewMode;
}

class ResultToolbar extends React.Component<IResultToolbarProps, IResultToolbarState> {
  public searchBarRef: React.RefObject<SearchBar>;
  constructor(props: IResultToolbarProps | Readonly<IResultToolbarProps>) {
    super(props);
    this.changeSort = this.changeSort.bind(this);
    this.changeCollapseState = this.changeCollapseState.bind(this);
    this.searchBarRef = React.createRef<SearchBar>();
  }
  changeSort() {
    this.props.onResultSortChanged();
  }
  changeCollapseState() {
    this.props.onCollapseToggled();
  }

  render() {
    let hide = this.props.hasResults || this.props.isSearching ? "" : " modalHide";
    return (
      <div>
        <Navbar color="light" light={true} expand="xs" className="flex-shrink-0">
          <Nav navbar={true}>
            <NavItem data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.TableofContentsView.LABEL_TOC_FILTERING}>
              <ActionIcon
                onClick={this.props.onFilteringStateChanged}
                src={this.props.filteringActive ? <Image.filteron /> : <Image.filteroff />}
                active={this.props.filteringActive}
              />
            </NavItem>
            <NavItem data-tooltip-id="searchToolbar" data-tooltip-content={"Include community"}>
              <ActionIcon onClick={this.props.onCommunityInclusionChanged} src={<Image.community />} active={this.props.communityIncluded} />
            </NavItem>

            {this.props.helpEnabled && <Tooltip id="searchToolbar" place="bottom" variant="info" className="primaryColoured" />}
          </Nav>
          <Nav navbar={true} className="ml-auto">
            {this.props.searchViewMode === SearchViewMode.Collapsed ? (
              <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_EXPAND_RESULTS}>
                <ActionIcon onClick={this.changeCollapseState} src={<Image.TOCExpand />} />
              </NavItem>
            ) : (
              <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_COLLAPSE_RESULTS}>
                <ActionIcon onClick={this.changeCollapseState} src={<Image.TOCcollapse />} />
              </NavItem>
            )}
            {this.props.searchSorting === SearchSorting.Ranked ? (
              <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_SEARCH_RANK}>
                <ActionIcon onClick={this.changeSort} src={<Image.rank />} />
              </NavItem>
            ) : (
              <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_SEARCH_READING}>
                <ActionIcon onClick={this.changeSort} src={<Image.reading />} />
              </NavItem>
            )}

            <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_PRINT_RESULTS}>
              <ActionIcon onClick={this.props.onPrintSelectedSearch} src={<Image.print />} />
            </NavItem>

            <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_CANCELSEARCH}>
              <ActionIcon onClick={this.props.onSearchCancelled} src={<Image.cancel_search />} />
            </NavItem>
            {/* Deemed redundant with content view search navs. <NavItem className={hide} data-for="searchToolbar" data-tip={this.props.localization.currentLocale.SearchView.LABEL_PREVIOUS_RESULT}>
              <ActionIcon onClick={this.props.onPreviousHitClicked} src={<Image.hit_previous />} />
            </NavItem>
            <NavItem className={hide} data-for="searchToolbar" data-tip={this.props.localization.currentLocale.SearchView.LABEL_NEXT_RESULT}>
              <ActionIcon onClick={this.props.onNextHitClicked} src={<Image.hit_next />} />
            </NavItem>*/}
            {this.props.helpEnabled && <Tooltip id="searchToolbar" place="bottom" variant="info" className="primaryColoured" />}
          </Nav>
        </Navbar>
        {!this.props.flipOpen ? (
          <SearchBar
            onAdvancedToolsClicked={this.props.onAdvancedToolsClicked}
            onPreviousSearchesClicked={this.props.onPreviousSearchesClicked}
            dropDownItems={this.props.dropdownItems}
            onExecuteSearch={this.props.onExecuteSearch}
            ref={this.searchBarRef}
            isSearching={this.props.isSearching}
            includeCommunity={this.props.communityIncluded}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
interface IContentResultToolbarState {}

interface IContentResultToolbarProps {
  onSearchCancelled: (clickEvent: any)=> void;
  onNextHitClicked: (event: any) => void;
  onPreviousHitClicked: (event: any) => void;
  hasResults: boolean;
  localization: Locale;
  helpEnabled: boolean;
}

export class ContentResultToolbar extends React.Component<IContentResultToolbarProps, IContentResultToolbarState> {
  constructor(props: IContentResultToolbarProps | Readonly<IContentResultToolbarProps>) {
    super(props);
  }

  render() {
    let hide = this.props.hasResults ? "" : " modalHide";
    return (
      <Nav navbar={true} className="ml-auto">
        <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_CANCELSEARCH}>
          <ActionIcon onClick={this.props.onSearchCancelled} src={<Image.cancel_search />} />
        </NavItem>
        <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_PREVIOUS_RESULT}>
          <ActionIcon onClick={this.props.onPreviousHitClicked} src={<Image.hit_previous />} />
        </NavItem>
        <NavItem className={hide} data-tooltip-id="searchToolbar" data-tooltip-content={this.props.localization.currentLocale.SearchView.LABEL_NEXT_RESULT}>
          <ActionIcon onClick={this.props.onNextHitClicked} src={<Image.hit_next />} />
        </NavItem>
        {this.props.helpEnabled && <Tooltip id="searchToolbar" place="bottom" variant="info" className="primaryColoured" />}
      </Nav>
    );
  }
}
interface ISearchBarState {
  splitButtonOpen: boolean;
  activeField: number;
  suggestions: string[];
  currentQuery: string;
  queryValid: boolean;
}

interface ISearchBarProps {
  onExecuteSearch: (rawQuery: string)=> void;
  onAdvancedToolsClicked: (event: any)=> void;
  onPreviousSearchesClicked: (event: any)=> void;
  dropDownItems: FormattedSearchField[];
  isSearching: boolean;
  includeCommunity: boolean;
}
class SearchBar extends React.Component<ISearchBarProps, ISearchBarState> {
  context: Book;
  static contextType = BookContext;
  localInput: React.RefObject<HTMLDivElement>;
  constructor(props: ISearchBarProps | Readonly<ISearchBarProps>) {
    super(props);
    this.state = { splitButtonOpen: false, activeField: 0, suggestions: [], currentQuery: "", queryValid: true };
    this.handleSearchBarInput = this.handleSearchBarInput.bind(this);
    this.handleSearchBarInputBlurred = this.handleSearchBarInputBlurred.bind(this);
    this.toggleSplit = this.toggleSplit.bind(this);
    this.checkEnterPressed = this.checkEnterPressed.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.setCurrentState = this.setCurrentState.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.searchExecuted = this.searchExecuted.bind(this);
    this.searchCancelled = this.searchCancelled.bind(this);
    this.localInput = React.createRef<HTMLDivElement>();
  }

  toggleSplit() {
    this.setState({
      splitButtonOpen: !this.state.splitButtonOpen,
    });
  }

  checkEnterPressed(event: any) {
    if (event.keyCode === 13 && this.state.queryValid) {
      this.localInput.current!.querySelector("input")!.blur();
      this.props.onExecuteSearch(this.props.dropDownItems[this.state.activeField].FormattedPrefix + this.state.currentQuery);
      this.setState({ suggestions: [] });
    }
  }

  public setCurrentState(newQuery: ISearchBarState) {
    this.setState(newQuery);
  }

  componentDidMount() {
    this.context.searchRemotelyExecuted.on(this.searchExecuted);
    this.context.searchCancelled.on(this.searchCancelled);
    this.setState({
      suggestions: [
        this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
        this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
      ],
    });
  }
  componentWillUnmount() {
    this.context.searchRemotelyExecuted.off(this.searchExecuted);
    this.context.searchCancelled.off(this.searchCancelled);
  }
  searchExecuted(newQuery: string) {
    // this.localInput.current!.querySelector("input")!.focus();
    if (!Convert.isEmptyOrSpaces(newQuery)) {
      this.handleSearchBarInput(null, { newValue: newQuery });
      this.handleSearchBarInputBlurred();
    } else {
      this.localInput.current!.querySelector("input")!.select();
    }
  }
  searchCancelled() {
    this.setState({ queryValid: true, currentQuery: "", suggestions: [] });
  }

  // In progress search changes

  onFieldDropdownItemClicked(e: any) {
    this.setState({ activeField: +e });
  }
  globalCounterSuggestions = 0;
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic below, so just use it.
  onSuggestionsFetchRequested(value: any) {
    this.globalCounterSuggestions += 1;
    let localCounter = this.globalCounterSuggestions;
    setTimeout(() => void(async () => {
      if (localCounter === this.globalCounterSuggestions) {
        let newSuggestions = await this.getSuggestions(value);
        if (newSuggestions === null) {
          return;
        }
        if (newSuggestions.length === 0) {
          newSuggestions = [
            this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
            this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
          ];
        }
        this.setState({
          suggestions: newSuggestions,
        });
      }
    })(), 250);
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [
        this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
        this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
      ],
    });
  }
  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = async (value: any) => {
    const inputValue = value.value.trim().toLowerCase();
    let result = await this.context.getSuggestions({
      CommunicationIndex: -1,
      Query: this.props.dropDownItems[this.state.activeField].FormattedPrefix + inputValue,
      IncludeCommunity: this.props.includeCommunity,
    });
    if (result.data === null) {
      return null;
    }
    return result.data.Suggestions;
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(value: any) {
    let newQuery = "";
    let words = this.state.currentQuery.trim().split(" ");
    newQuery = "";

    if (value === this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER) {
      return this.state.currentQuery.trim().substring(0, this.state.currentQuery.length - 1);
    }
    if (value === this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE) {
      return this.state.currentQuery.trim().substring(0, this.state.currentQuery.length - 1) + " ";
    }
    // Test for non-command characters in english and french.
    let regexTest = /^[a-z0-9àâçéèêëîïôûùüÿñæœ]+$/i;
    words.map((item, index) => {
      if (item === "") {
      } else if (index === 0) {
        if (index === words.length - 1) {
          // Check for commands on the first item so suggestion selection doesn't wipe the command.
          if (item[0] === "[" || item[0] === '"') {
            let cmdInd = 0;
            if (item[0] === "[") {
              cmdInd = item.lastIndexOf(":");
            } else {
              cmdInd = item.lastIndexOf('"');
            }
            value = item.substring(0, cmdInd + 1) + value;
          } else if (!regexTest.exec(item[0])) {
            let nonAlpha = item[0];
            let i = 1;
            while (!regexTest.exec(item[i])) {
              nonAlpha += item[i];
              i++;
              if (i > item.length - 1) {
                break;
              }
            }
            value = nonAlpha + " " + value;
          }
          newQuery += value + " ";
        } else {
          newQuery += item;
        }
      } else if (index === words.length - 1) {
        if (!regexTest.exec(item[0])) {
          let nonAlpha = item[0];
          let i = 1;
          while (!regexTest.exec(item[i])) {
            nonAlpha += item[i];
            i++;
            if (i > item.length - 1) {
              break;
            }
          }
          value = nonAlpha + " " + value;
        }
        newQuery += " " + value + " ";
      } else {
        newQuery += " " + item;
      }
    });
    return newQuery;
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion: string) {
    return <div className="suggestionItem">{suggestion}</div>;
  }
  globalCounter = 0;
  handleSearchBarInput(event: any, arg: any) {
    this.setState({ currentQuery: arg.newValue }, () => {
      this.globalCounter += 1;
      let localCounter = this.globalCounter;
      setTimeout(() => void(async () => {
        if (localCounter === this.globalCounter) {
          if (Convert.isEmptyOrSpaces(this.state.currentQuery)) {
            this.setState({
              queryValid: true,
              suggestions: [],
            });
            return;
          }
          let result = await this.context.queryCheck(this.state.currentQuery, this.props.includeCommunity);
          let sugg = this.state.suggestions;
          if (sugg.length === 0) {
            sugg = [
              this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
              this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
            ];
          }
          if (result.data) {
            this.setState({ queryValid: result.data });
          } else {
            this.setState({
              queryValid: result.data,
              suggestions: sugg,
            });
          }
        }
      })(), 250);
    });
  }
  handleSearchBarInputBlurred() {
    this.setState({
      suggestions: [],
    });
  }
  // ==========================

  render() {
    let localization = this.context.localization;

    if (this.props.dropDownItems) {
      this.props.dropDownItems[0].Name = localization.currentLocale.SearchView.LABEL_SEARCH_KEYWORD;
    }

    const inputProps = {
      placeholder: this.props.dropDownItems ? this.props.dropDownItems[this.state.activeField].Name : "",
      value: this.state.currentQuery,
      onChange: this.handleSearchBarInput,
      onBlur: this.handleSearchBarInputBlurred,
    };

    let invalidQueryClass = this.state.queryValid ? "" : "invalidQuery ";
    return (
      <div className="searchBar-container">
        <InputGroup>
          <Dropdown isOpen={this.state.splitButtonOpen} toggle={this.toggleSplit}>
            <DropdownToggle split />
            <DropdownMenu>
              {this.props.dropDownItems &&
                this.props.dropDownItems.map((item, i) => {
                  if (i === this.state.activeField) {
                    return (
                      <DropdownItem
                        onClick={() => {
                          this.onFieldDropdownItemClicked(i);
                        }}
                        key={i}
                        active
                      >
                        {item.Name}
                      </DropdownItem>
                    );
                  }
                  return (
                    <DropdownItem
                      onClick={() => {
                        this.onFieldDropdownItemClicked(i);
                      }}
                      key={i}
                    >
                      {item.Name}
                    </DropdownItem>
                  );
                })}
              <DropdownItem divider />
              <DropdownItem onClick={this.props.onAdvancedToolsClicked}>{localization.currentLocale.SearchView.LABEL_SEARCHFORMS}</DropdownItem>
              <DropdownItem onClick={this.props.onPreviousSearchesClicked}>{localization.currentLocale.SearchView.LABEL_PREVIOUSSEARCHES}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <div ref={this.localInput} className={invalidQueryClass + "searchAutoSuggestContainer"} tabIndex={2} onKeyDown={this.checkEnterPressed}>
            <form
              onSubmit={
                (e) => {
                  e.preventDefault();
                  // e.stopPropagation();
                } /** This form is used to force the "submit" button on android instead of "next" which can show sometimes */
              }
            >
              <Autosuggest
                focusInputOnSuggestionClick={true}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                shouldRenderSuggestions={() => !this.props.isSearching}
              />
            </form>
          </div>
          <Button
            onClick={() => {
              if (this.state.queryValid) {
                this.props.onExecuteSearch(this.props.dropDownItems[this.state.activeField].FormattedPrefix + this.state.currentQuery);
              }
            }}
            color="secondary"
          >
            {localization.currentLocale.SearchView.LABEL_DOSEARCH}
          </Button>
        </InputGroup>
      </div>
    );
  }
}

interface ISearchFormsState {}
interface ISearchFormsProps {
  Forms: FormattedSearchForm[];
  IncludeCommunity: boolean;
  onExecuteSearchWithProps: (filter: number[], query: string)=> void;
}
class SearchForms extends React.Component<ISearchFormsProps, ISearchFormsState> {
  render() {
    return this.props.Forms.map((item, i) => (
      <SearchForm
        onExecuteSearchWithProps={this.props.onExecuteSearchWithProps}
        initialForm={item}
        key={i}
        formIndex={i}
        totalCount={this.props.Forms.length}
        includeCommunity={this.props.IncludeCommunity}
      />
    ));
  }
}

interface ISearchFormState {
  isExpanded: boolean;
  openStates: boolean[];
  currentForm: FormattedSearchForm;
  inputQueries: string[];
  queryValidations: boolean[];
  selectedTypes: any[][];
  suggestions: string[][];
  selectedRadio: number[];
  formFilter: Set<number>;
}
interface ISearchFormProps {
  initialForm: FormattedSearchForm;
  formIndex: number;
  totalCount: number;
  includeCommunity: boolean;
  onExecuteSearchWithProps: (filter: number[], query: string)=> void;
}
class SearchForm extends React.Component<ISearchFormProps, ISearchFormState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: ISearchFormProps) {
    super(props);

    let suggestionBoxes = [];
    let sugg = [];
    let queries = [];
    let types = [];
    let radios = [];
    for (let j = 0; j < props.initialForm.FormInputs.length; j++) {
      suggestionBoxes.push(false);
      sugg.push([]);
      queries.push("");
      types.push([]);
      radios.push(0);
      if (props.initialForm.FormInputs[j].ListType === ListType.Radio) {
        radios[j] = props.initialForm.FormInputs[j].ListItems.findIndex((it) => it.IsSelected);
      }
    }

    let filter = new Set<number>();
    if (props.initialForm.FormFilter && props.initialForm.FormFilter.DefaultFilter) {
      filter = props.initialForm.FormFilter.DefaultFilter;
    }

    this.state = {
      isExpanded: false,
      openStates: [],
      currentForm: props.initialForm,
      inputQueries: queries,
      queryValidations: [],
      selectedTypes: types,
      suggestions: sugg,
      selectedRadio: radios,
      formFilter: filter,
    };

    this.onExpansion = this.onExpansion.bind(this);
    this.toggle = this.toggle.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
    this.executeSearchForm = this.executeSearchForm.bind(this);
    this.internalSearchFired = this.internalSearchFired.bind(this);
    this.onFormFilterChanged = this.onFormFilterChanged.bind(this);
    this.checkEnterPressed = this.checkEnterPressed.bind(this);
  }
  onExpansion() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }
  toggle(index: number) {
    let dropdowns = this.state.openStates;
    dropdowns[index] = !this.state.openStates[index];
    this.setState({ openStates: dropdowns });
  }
  itemClicked(item: number, inputIndex: number) {
    let radios = this.state.selectedRadio;
    radios[item] = inputIndex;
    this.setState({ selectedRadio: radios });
  }
  getIndicesOf(strToFind: string, totalString: string, caseSensitive: boolean): number[] {
    let searchStrLen = strToFind.length;
    if (searchStrLen === 0) {
      return [];
    }
    let startIndex = 0;
    let index = 0;
    let indices = [];
    if (!caseSensitive) {
      totalString = totalString.toLowerCase();
      strToFind = strToFind.toLowerCase();
    }
    index = totalString.indexOf(strToFind, startIndex);
    while (index > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
      index = totalString.indexOf(strToFind, startIndex);
    }
    return indices;
  }
  async executeSearchForm() {
    // These are the resolution indices for each input
    // They allow the same input to occupy >1 place in the resolution
    let realIndices: number[][] = [];
    // This holder is used to build the realIndices. Found indices are replaced with
    // a number of spaces equal to the length of the indice to match length.
    let holderResolution = this.state.currentForm.Resolution;
    // This is the unchanged resolution for the form. Indicates which inputs interact and how.
    // This allows form resolution to be very complex.
    let resolution = this.state.currentForm.Resolution;
    // This represents the IDs defined in this form representing inputs.
    // A resolution like 1&2&3|4 will have an idCount of 4.
    // _these must line up_
    let idCount = this.state.currentForm.FormInputs.length;
    // This represents all the hit locations (where IDs are).
    // Will be sorted ascending to build the query left to right.
    let allHits: number[] = [];

    let openingParenths = this.getIndicesOf("(", holderResolution, false);
    let closingParenths = this.getIndicesOf(")", holderResolution, false);

    // Loop through the IDs in reverse. This way hits to ID "1" don't affect hits for id "11"
    for (let index = idCount; index > 0; index--) {
      // Get all position indices of the ID.
      let tempArr = this.getIndicesOf(index.toString(), holderResolution, false);
      // Add the array to the "real indices". Will represent all the hit locations for a given input.
      realIndices.push(tempArr);
      // Add the raw hit positions to the "allHits"
      allHits = allHits.concat(tempArr);
      // Replace the ID in the resolution string with spaces to avoid false positives and maintain the index positions.
      // This avoid the false positive of "1" being hit twice after "11" being processed.
      holderResolution = holderResolution.replace(new RegExp(index.toString(), "g"), " ".repeat(index.toString().length));
    }
    // Reverse the "real indices" so that the last element represents the last ID.
    // This way, if there were 5 IDs, index 4 of this array properly represents ID 5 (they are 1 based)
    // and index 0 represents ID 1.
    realIndices = realIndices.reverse();
    // Sort the hits ascending for the left->right construction of the for query.
    allHits = allHits.sort((a, b) => a - b);

    // Will represent the final query.
    let finalFormQuery = "";

    // Loop through the total hits for the resolution.
    allHits.map((i) => {
      // For each ID, find the one that contains the global ID.
      // This way, we treat the correct input.
      for (let j = realIndices.length - 1; j >= 0; j--) {
        // If contained, process. Otherwise skip.
        if (realIndices[j].indexOf(i) === -1) {
          continue;
        }
        // Set an empty final query for this field.
        let finalQuery = "";
        // Get the current value for the input.
        let queryVal = this.state.inputQueries[j];
        // Get the input of the form.
        let input = this.state.currentForm.FormInputs[j];
        // If the input is null, empty or invalid, skip it.
        if (!input || !this.state.inputQueries[j] || this.state.inputQueries[j].length === 0 || !this.state.queryValidations[j]) {
          break;
        }
        // Parse the input with the termination of queries.
        finalQuery += this.parseInput(input, j, queryVal, true);

        // TODO: Improve group handling. Currently elements in a group need to all be set or it fails.
        // Officially form resolutions don't support groups. This would be a future support.
        let leadingParenth = 0;
        let trailingParenth = 0;
        let operatorFind = -1;
        let operator = "";

        leadingParenth = openingParenths.filter((x) => x < i).length - this.getIndicesOf("(", finalFormQuery, false).length;
        trailingParenth = closingParenths.filter((x) => x < i).length - this.getIndicesOf(")", finalFormQuery, false).length;

        while (resolution[i + operatorFind] === "(" || resolution[i + operatorFind] === ")") {
          operatorFind--;
        }
        if (finalFormQuery.length > 0) {
          operator = resolution[i + operatorFind];
        }

        if (leadingParenth > trailingParenth) {
          leadingParenth -= trailingParenth;
          trailingParenth = 0;
        } else if (leadingParenth === trailingParenth) {
          leadingParenth = 0;
          trailingParenth = 0;
        }
        // Append the query to the form query. If the query has no operator, or it's the first element to append, omit the operator.
        finalFormQuery += ")".repeat(trailingParenth) + operator + "(".repeat(leadingParenth) + finalQuery;
      }
    });

    // Fire event to execute the form.
    await this.internalSearchFired(finalFormQuery);
  }

  async internalSearchFired(query: string) {
    let result = await this.context.queryCheck(query, this.props.includeCommunity);
    if (result.data) {
      this.props.onExecuteSearchWithProps(Array.from(this.state.formFilter), query);
    } else {
      Messages.Notify.error(this.context.localization.currentLocale.SearchView.ALERT_INVALIDFIELD_PROMPT);
    }
  }

  parseInput(input: SearchFormInput, stateIndex: number, queryVal: string, terminateQueries = false): string {
    let index = stateIndex;
    let escaper = /[^0-9A-Za-zàÀâÂäÄçÇéÉèÈêÊëËîÎïÏôÔùÙûÛüÜ]/gim;
    let fieldingSection1 = "";
    let finalQuery = "";

    // Handle inputs marked as OrderedProx or as Unordered prox. Fetch fielding section of selected distance.
    if (input.Query === QueryCommand.OProx || input.Query === QueryCommand.UProx || input.Query === QueryCommand.Field) {
      if (input.ListType === ListType.Radio) {
        fieldingSection1 = input.ListItems[this.state.selectedRadio[index]].Value;
      } else {
        this.state.selectedTypes[index].map((item, i) => {
          if (fieldingSection1.length === 0 || i === 0) {
            fieldingSection1 += item.Value.replace(escaper, "\\$&");
          } else {
            fieldingSection1 += "|" + item.Value.replace(escaper, "\\$&");
          }
        });
      }
      // Ordered prox with no distance set is a phrase (distance 0)
      if ((input.Query === QueryCommand.OProx && fieldingSection1 === "") || (input.Query === QueryCommand.UProx && fieldingSection1 === "")) {
        fieldingSection1 = "5";
      }
      finalQuery = input.CommandPrefix + fieldingSection1 + ":" + queryVal;
      // If a search is executed, group queries should be terminated.
      if (terminateQueries) {
        finalQuery = finalQuery.trim();
        finalQuery += "]";
      }
    } else if (input.Query === QueryCommand.Annotation || input.Query === QueryCommand.Highlight || input.Query === QueryCommand.Note) {
      let names: string[] = [];
      if (input.ListType === ListType.Radio) {
        let options: ListItem[] = [];
        this.context.annotationTypes.rows().map((type) => {
          let name = type.Value;
          if (!names.includes(name) && this.context.annotations.rows().some((it) => it.AnnotationTypeId === type.Id)) {
            options.push({ Label: type.Value, Value: type.Id.toString(), IsSelected: false });
            names.push(name);
          }
        });
        fieldingSection1 = this.context.annotationTypes.get(+options[this.state.selectedRadio[index]].Value)!.Value.replace(escaper, "\\$&");
      } else {
        if (this.state.selectedTypes[index].length === 0) {
          fieldingSection1 = "*";
        } else {
          // Else build the section 1 fielding with the selected type names.
          this.state.selectedTypes[index].map((item, i) => {
            let name = this.context.annotationTypes.get(+item.value)!.Value;
            if ((!names.includes(name) && fieldingSection1.length === 0) || i === 0) {
              fieldingSection1 += name.replace(escaper, "\\$&");
              names.push(name);
            } else if (!names.includes(name)) {
              fieldingSection1 += "|" + name.replace(escaper, "\\$&");
              names.push(name);
            }
          });
        }
      }
      finalQuery = input.CommandPrefix + fieldingSection1 + ":" + queryVal;
      // If a search is executed, group queries should be terminated.
      if (terminateQueries) {
        finalQuery = finalQuery.trim();
        finalQuery += "]";
      }
    } else if (input.Query === QueryCommand.AutoTerm) {
      // Autoterm has no command prefix or fielding section. It's just a regular query. Essentially a searchbar.
      finalQuery = queryVal;
      if (terminateQueries) {
        finalQuery = finalQuery.trim();
      }
    }
    return finalQuery;
  }

  onFormFilterChanged(newFilter: Set<number>) {
    this.setState({ formFilter: newFilter });
  }
  // In progress search changes
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  async onSuggestionsFetchRequested(i: number) {
    let newSuggestions = await this.getSuggestions(i);
    if (newSuggestions.length === 0) {
      newSuggestions = [
        this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
        this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
      ];
    }
    let stateObj = this.state.suggestions;
    stateObj[i] = newSuggestions;
    this.setState({ suggestions: stateObj });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested(i: number) {
    let stateObj = this.state.suggestions;
    stateObj[i] = [
      this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
      this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
    ];
    this.setState({ suggestions: stateObj });
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = async (i: number) => {
    let finalQ = this.parseInput(this.props.initialForm.FormInputs[i], i, this.state.inputQueries[i]);
    let result = await this.context.getSuggestions({ CommunicationIndex: -1, Query: finalQ, IncludeCommunity: this.props.includeCommunity });
    if (result.data === null) {
      return [];
    }
    return result.data.Suggestions;
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(inputIndex: number, value: any) {
    let newQuery = "";
    let words = this.state.inputQueries[inputIndex].trim().split(" ");
    newQuery = "";

    if (value === this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER) {
      return this.state.inputQueries[inputIndex].trim().substring(0, this.state.inputQueries[inputIndex].length - 1);
    }
    if (value === this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE) {
      return this.state.inputQueries[inputIndex].trim().substring(0, this.state.inputQueries[inputIndex].length - 1) + " ";
    }

    words.map((item, index) => {
      if (item === "") {
      } else if (index === 0) {
        if (index === words.length - 1) {
          // Check for commands on the first item so suggestion selection doesn't wipe the command.
          if (item[0] === "[" || item[0] === '"') {
            let cmdInd = 0;
            if (item[0] === "[") {
              cmdInd = item.lastIndexOf(":");
            } else {
              cmdInd = item.lastIndexOf('"');
            }
            value = item.substring(0, cmdInd + 1) + value;
          } else if (!/^[a-z0-9]+$/i.exec(item[0])) {
            let nonAlpha = item[0];
            let i = 1;
            while (!/^[a-z0-9]+$/i.exec(item[i])) {
              nonAlpha += item[i];
              i++;
              if (i > item.length - 1) {
                break;
              }
            }
            value = nonAlpha + " " + value;
          }
          newQuery += value + " ";
        } else {
          newQuery += item;
        }
      } else if (index === words.length - 1) {
        if (!/^[a-z0-9]+$/i.exec(item[0])) {
          let nonAlpha = item[0];
          let i = 1;
          while (!/^[a-z0-9]+$/i.exec(item[i])) {
            nonAlpha += item[i];
            i++;
            if (i > item.length - 1) {
              break;
            }
          }
          value = nonAlpha + " " + value;
        }
        newQuery += " " + value + " ";
      } else {
        newQuery += " " + item;
      }
    });
    return newQuery;
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion: string) {
    return <div className="suggestionItem"> {suggestion} </div>;
  }
  globalCounter = 0;

  handleSearchBarInput(i: number, event: any, arg: any) {
    let stateObj = this.state.inputQueries;
    stateObj[i] = arg.newValue;
    this.setState({ inputQueries: stateObj }, () => {
      this.globalCounter += 1;
      let localCounter = this.globalCounter;
      setTimeout(() => void(async () => {
        if (localCounter === this.globalCounter) {
          if (Convert.isEmptyOrSpaces(this.state.inputQueries[i])) {
            let validations = this.state.queryValidations;
            validations[i] = true;
            let suggestions = this.state.suggestions;
            suggestions[i] = [];
            this.setState({
              queryValidations: validations,
              suggestions: suggestions,
            });
            return;
          }
          let finalQ = this.parseInput(this.props.initialForm.FormInputs[i], i, this.state.inputQueries[i], true);
          let result = await this.context.queryCheck(finalQ, this.props.includeCommunity);

          let suggObject = this.state.suggestions;
          if (suggObject[i].length === 0) {
            suggObject[i] = [
              this.context.localization.currentLocale.SearchView.LABEL_REMOVECHARACTER,
              this.context.localization.currentLocale.SearchView.LABEL_REPLACECHARACTERWITHSPACE,
            ];
          }
          if (result.data) {
            let validations = this.state.queryValidations;
            validations[i] = result.data;
            this.setState({ queryValidations: validations });
          } else {
            let validations = this.state.queryValidations;
            validations[i] = result.data;
            this.setState({
              queryValidations: validations,
              suggestions: suggObject,
            });
          }
        }
      })(), 50);
    });
  }
  handleSearchBarInputBlurred() {
    this.setState({
      suggestions: [],
    });
  }

  async checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      await this.executeSearchForm();
    }
  }
  render() {
    let form = this.state.currentForm;

    let inputs = form.FormInputs.map((item, i) => {
      let selectionControl: JSX.Element | string = "";

      let options: ListItem[] = []; // [{ label: "One", value: 1 }, { label: "Two", value: 2 }, { label: "Three", value: 3 }];

      // Prox searches should have items defined. Usually will be radio list type.
      if (item.Query === QueryCommand.OProx || item.Query === QueryCommand.UProx || item.Query === QueryCommand.Field) {
        options = item.ListItems;
        // Annotation searches will have to be populated from the types having annotations.
      } else if (item.Query === QueryCommand.Annotation || item.Query === QueryCommand.Highlight || item.Query === QueryCommand.Note) {
        let names: string[] = [];
        this.context.annotationTypes.rows().map((type) => {
          let name = type.Value;
          if (!names.includes(name) && this.context.annotations.rows().some((it) => it.AnnotationTypeId === type.Id)) {
            let opt = { Label: type.Value, Value: type.Id.toString(), IsSelected: false };
            options.push(opt);
            // Check last index, if it's the selected radio, set it.
            if (options[options.length - 1] && options.length - 1 === this.state.selectedRadio[i]) {
              options[options.length - 1].IsSelected = true;
            }
            names.push(name);
          }
        });
      }

      // If it's radio and none are selected, select the first.
      if (options && options.length > 0 && item.ListType === ListType.Radio && !options.some((opt) => opt.IsSelected)) {
        options[0].IsSelected = true;
      }
      // If there's an option selected, select it. Deselect others
      else if (options && options.length > 0 && item.ListType === ListType.Radio) {
        options.map((x) => (x.IsSelected = false));
        options[this.state.selectedRadio[i]].IsSelected = true;
      }
      // In the dropdown item ones there's nothing being set as selected so the dropdown is not being made.
      if (options && options.length > 0) {
        // Multi list definitions define multi select Items.
        let formattedOptions = options.map((itt) => ({ label: itt.Label, value: itt.Value }));
        if (item.ListType === ListType.Multi) {
          selectionControl = (
            <MultiSelect
              options={formattedOptions}
              value={this.state.selectedTypes[i]}
              onChange={(selected: any[]) => {
                let thing = this.state.selectedTypes;
                thing[i] = selected;
                this.setState({ selectedTypes: thing });
              }}
              labelledBy={this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW}
              disableSearch={true}
              overrideStrings={{
                selectSomeItems: this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW,
                allItemsAreSelected: this.context.localization.currentLocale.SearchTemplate.VALUE_ANY,
                selectAll: this.context.localization.currentLocale.SearchTemplate.VALUE_ANY,
                search: "Search",
              }}
            />
          );
          // Radio list definitions define dropdown items.
        } else {
          selectionControl = (
            <Dropdown isOpen={this.state.openStates[i]} toggle={() => this.toggle(i)}>
              {options.map((li, ind) => {
                if (!li.IsSelected) {
                  return "";
                }
                return (
                  <DropdownToggle caret key={ind}>
                    {li.Label}
                  </DropdownToggle>
                );
              })}

              <DropdownMenu>
                {options.map((li, ind) => (
                  <DropdownItem
                    onClick={() => {
                      this.itemClicked(i, ind);
                    }}
                    key={ind}
                  >
                    {li.Label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          );
        }
      }
      //  --> current value

      const inputProps = {
        placeholder: "",
        value: this.state.inputQueries[i],
        onChange: (event: any, arg: any) => this.handleSearchBarInput(i, event, arg),
        onBlur: () => this.handleSearchBarInputBlurred(),
      };
      let invalidQueryClass = this.state.queryValidations[i] ? "" : "invalidQuery ";
      return (
        <div className="search-form-input-container" key={i}>
          <Label className="form-input-label">{item.Name}</Label>
          {item.Prefix ? <Label className="form-input-prefix">{item.Prefix}</Label> : ""}
          {selectionControl}
          {item.Suffix ? <Label className="form-input-suffix">{item.Suffix}</Label> : ""}
          <div className={invalidQueryClass + "searchFormAutoSuggestContainer"} tabIndex={2} onKeyDown={(e) => void this.checkEnterPressed(e)}>
            <Autosuggest
              focusInputOnSuggestionClick={true}
              suggestions={this.state.suggestions[i]}
              onSuggestionsFetchRequested={() => this.onSuggestionsFetchRequested(i)}
              onSuggestionsClearRequested={() => this.onSuggestionsClearRequested(i)}
              getSuggestionValue={(value: any) => this.getSuggestionValue(i, value)}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
            />
          </div>
        </div>
      );
    });

    return (
      <div className="grouped-container">
        <div onClick={this.onExpansion}>
          <label className="grouped-header-label">{this.props.initialForm.Name}</label>{" "}
          <ExpanderClose isOpen={this.state.isExpanded} onClick={this.onExpansion} />
        </div>
        <Expander isOpen={this.state.isExpanded}>
          <div className="form-body">
            {inputs}
            {this.props.initialForm.FormFilter ? (
              <SearchFormFilter onFilterChanged={this.onFormFilterChanged} FormFilter={this.props.initialForm.FormFilter} />
            ) : (
              ""
            )}
            <Button className="search-button" onClick={() => void this.executeSearchForm()}>
              {this.context.localization.currentLocale.SearchView.LABEL_DOSEARCH}
            </Button>
          </div>
        </Expander>
      </div>
    );
  }
}
interface ISearchFormsToolbarState {}
interface ISearchFormsToolbarProps {
  onGoBackClicked: ()=> void;
  localization: Locale;
}
class SearchFormsToolbar extends React.Component<ISearchFormsToolbarProps, ISearchFormsToolbarState> {
  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Nav navbar={true}>
          <NavItem>
            <NavLink
              className="flip-back"
              onClick={this.props.onGoBackClicked}
              data-tooltip-id="searchToolbar"
              data-tooltip-content={this.props.localization.currentLocale.Application.LABEL_BACK}
            >
              <Icon src={<Image.arrowback />} />
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

interface ISearchFormFilterState {
  currentFilter: Set<number>;
  currentFormFilter: FormFilter;
  checkStates: boolean[];
}
interface ISearchFormFilterProps {
  onFilterChanged: (newFilter: Set<number>)=> void;
  FormFilter: FormFilter;
}
class SearchFormFilter extends React.Component<ISearchFormFilterProps, ISearchFormFilterState> {
  constructor(props: ISearchFormFilterProps) {
    super(props);
    let bools: boolean[] = [];
    if (props.FormFilter.FilterOptions !== null) {
      props.FormFilter.FilterOptions.map((item) => {
        bools.push(item.IsSelected);
      });
    }
    this.state = {
      currentFormFilter: props.FormFilter,
      currentFilter: props.FormFilter.DefaultFilter ? props.FormFilter.DefaultFilter : new Set<number>(),
      checkStates: bools,
    };
    this.filterChanged = this.filterChanged.bind(this);
    this.buildFilter = this.buildFilter.bind(this);
  }

  buildFilter() {
    let newFilter = new Set<number>();

    // Build the new filter from the checked controls
    for (let i = 0; i < this.state.currentFormFilter.FilterOptions.length; i++) {
      if (this.state.currentFormFilter.FilterOptions[i].IsSelected) {
        newFilter = new Set<number>([...newFilter, ...this.state.currentFormFilter.FilterOptions[i].Filter]);
      }
    }
    // Empty filter with a default. Use default.
    if (newFilter.size === 0 && this.state.currentFormFilter.DefaultFilter) {
      this.setState({ currentFilter: this.state.currentFormFilter.DefaultFilter }, () => {
        // Broadcast the new filter to the form.
        this.props.onFilterChanged(this.state.currentFilter);
      });
    }
    // There's a filter set using the controls. Use that.
    else {
      this.setState({ currentFilter: newFilter }, () => {
        // Broadcast the new filter to the form.
        this.props.onFilterChanged(this.state.currentFilter);
      });
    }
  }

  filterChanged(event: any, controlIndex: number) {
    let filter = this.state.currentFormFilter;
    filter.FilterOptions[controlIndex].IsSelected = event.target.checked;
    this.setState({ currentFormFilter: filter }, () => {
      this.buildFilter();
    });
  }

  render() {
    let inputs = this.state.currentFormFilter.FilterOptions
      ? this.state.currentFormFilter.FilterOptions.map((item, i) => (
          <label className="filterInput" key={i}>
            <input
              className="filterCheckbox"
              onChange={(e) => {
                this.filterChanged(e, i);
              }}
              checked={item.IsSelected}
              type="checkbox"
              value="value"
            />
            {item.Label}
          </label>
        ))
      : "";

    return (
      <div>
        {this.state.currentFormFilter.FilterDescription ? <h4>{this.state.currentFormFilter.FilterDescription}</h4> : ""}
        {inputs}
      </div>
    );
  }
}
