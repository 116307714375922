import { createContext, Dispatch } from 'react';
import { ActionType } from 'src/chatbot/hooks/useCreateReducer';
import { Conversation } from 'src/chatbot/types/chat';
import { KeyValuePair } from 'src/chatbot/types/data';
import { FolderType } from 'src/chatbot/types/folder';

import { ProfessorInitialState } from './professor.state';

export interface ProfessorContextProps {
  state: ProfessorInitialState;
  dispatch: Dispatch<ActionType<ProfessorInitialState>>;
  handleNewConversation: () => void;
  handleCreateFolder: (name: string, type: FolderType) => void;
  handleDeleteFolder: (folderId: string) => void;
  handleUpdateFolder: (folderId: string, name: string) => void;
  handleSelectConversation: (conversation: Conversation) => void;
  handleUpdateConversation: (
    conversation: Conversation,
    data: KeyValuePair,
  ) => void;
}

export const ProfessorContext = createContext<ProfessorContextProps | undefined>(undefined);
