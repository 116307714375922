import { AppSession } from './models/AppSession';
import { LogType } from './models/dto/AppRequest';

export class Log {
  private constructor() {} // prevent the instantiation, as a static class.

  static async error(msg: string, err: any) {
    await Log.write("ERROR", `${msg} | ${String(err)}`);
  }
  static async info(msg: string) {
    await Log.write("INFO", msg);
  }
  static async warn(msg: string) {
    await Log.write("WARN", msg);
  }
  static async fatal(msg: string) {
    await Log.write("FATAL", msg);
  }

  private static async write(type: string, msg: string) {
    try {
      if (type === "ERROR") {
        await AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Error });
      }
      if (type === "INFO") {
        await AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Info });
      }
      if (type === "WARN") {
        await AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Warning });
      }
      if (type === "FATAL") {
        await AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Fatal });
      }
    } catch (e) {
      console.log("Could not put logger info.");
      console.log(e);
    }
  }
}
