import { Conversation } from 'src/chatbot/types/chat';

export const updateConversation = (
  updatedConversation: Conversation,
  allConversations: Conversation[],
) => {
  const updatedConversations = allConversations.map((c) => {
    if (c.id === updatedConversation.id) {
      return updatedConversation;
    }

    return c;
  });

  saveConversation();
  saveConversations();

  return {
    single: updatedConversation,
    all: updatedConversations,
  };
};

export const saveConversation = () => {
  // localStorage.setItem('selectedConversation', JSON.stringify(conversation));
};

export const saveConversations = () => {
  //  localStorage.setItem('conversationHistory', JSON.stringify(conversations));
};
